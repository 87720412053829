<template>
  <div class="search_box">
    <div class="input_container with_icon focus">
      <img src="@/../public/images/search_9fa6b2.svg" alt="검색" />
      <input
        ref="searchInput"
        id="searchInput"
        type="text"
        :placeholder="placeHolder"
        @keyup.enter="search($event)"
        @input="saveKeyword($event)"
        autocomplete="off"
      />
      <button
        v-if="hasRemoveButton && isButtonShow"
        class="remove_button"
        @click="remove"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultSearchInput",
  emits: ["handleKeywordSearch", "handleRemoveInputValue"],
  props: {
    /**
     * placeholder 설정
     */
    placeHolder: {
      type: String,
      required: false,
    },
    hasRemoveButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputKeyword: null,
      isButtonShow: false,
    };
  },
  methods: {
    saveKeyword(event) {
      this.inputKeyword = event.target.value;
      if (this.inputKeyword.length > 0) {
        this.isButtonShow = true;
      }
    },
    search(event) {
      this.$emit("handleKeywordSearch", event.target.value);
    },
    remove() {
      this.isButtonShow = false;
      this.$refs.searchInput.value = null;

      this.$emit("handleRemoveInputValue");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/component/inputs";

.remove_button {
  background: url("@/../public/images/x_white2.svg") no-repeat center;
  background-size: contain;
  width: 18px;
  height: 18px;
}
</style>
