export default class PrimaryDefect{
    constructor(primaryDefect){
        this.id = null;
        this.inspectionNetworkMapId = null;
        this.type = null;
        this.currentState = null;
        this.cause = null;
        this.solution = null;
        this.thumbnailsUri = null;
        this.createdDatetime = null;
        this.wrDatetime = null;

        if(primaryDefect){
            this.id = primaryDefect.id;
            this.inspectionNetworkMapId = primaryDefect.inspectionNetworkMapId;
            this.type = primaryDefect.type;
            this.currentState = primaryDefect.currentState;
            this.cause = primaryDefect.cause;
            this.solution = primaryDefect.solution;
            this.thumbnailsUri = primaryDefect.thumbnailsUri;
            this.createdDatetime = primaryDefect.createdDatetime;
            this.wrDatetime = primaryDefect.wrDatetime;
        }
    }
}