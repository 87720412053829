import * as THREE from 'three';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// Vertex Shader


const vertexShader2d = `  
  out vec4 vColor;
  uniform float size;
  uniform float opacity;
  uniform bool colorMap;
  uniform float minHeight;
  uniform float maxHeight;
  uniform mat4 customModelMatrix;
  uniform float height;
  uniform bool heightInfoVisible;


  #ifdef USE_INTENSITY
    vec3 calculateColor(float intensity) {
      float value = 1.0 - intensity;
      value = clamp(value, 0.0, 1.0);
      float h = value * 5.0 + 1.0;
      float k = floor(h);
      float f = h - k;
      if (int(k) % 2 == 0) {
        f = 1.0 - f;
      }
      float n = 1.0 - f;
      if (k <= 1.0) {
        return vec3(n, 0.0, 1.0);
      } else if (k == 2.0) {
        return vec3(0.0, n, 1.0);
      } else if (k == 3.0) {
        return vec3(0.0, 1.0, n);
      } else if (k == 4.0) {
        return vec3(n, 1.0, 0.0);
      } else {
        return vec3(1.0, n, 0.0);
      }
    }
  #endif
  
  void main() {    
    if(heightInfoVisible){
      if(position.y > height - 0.5f && position.y < height + 0.5f){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,1.0);
      }
      else{
        #ifdef USE_INTENSITY
          vec3 colors[7];
          colors[0] = vec3(0.65, 0.4, 0.65);
          colors[1] = vec3(0.65, 0.45, 0.65);
          colors[2] = vec3(0.4, 0.4, 0.9);
          colors[3] = vec3(0.4, 0.8, 0.4);
          colors[4] = vec3(0.8, 0.8, 0.4);
          colors[5] = vec3(0.8, 0.6, 0.4);
          colors[6] = vec3(0.8, 0.4, 0.4);

          vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
          vec3 vWorldPosition = worldPosition.xyz;
            
          float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);

          float section = heightFactor * 6.0;
          int idx = int(section);
          float fract = section - float(idx);
          vec3 heightColor;
          if (idx < 6) {
            heightColor = mix(colors[idx], colors[idx + 1], fract);
          } else {
            heightColor = colors[6];
          }
          gl_PointSize = size;
          if(colorMap){
            vColor = vec4(heightColor, opacity);
          }
          else{
            vec3 color = calculateColor(intensity / 255.0);
            vColor = vec4(color, opacity);
          }
        #else
          gl_PointSize = size;
          vColor = vec4(color, opacity);
        #endif
      }
    }
    else{
      #ifdef USE_INTENSITY
        vec3 colors[7];
        colors[0] = vec3(0.65, 0.4, 0.65);
        colors[1] = vec3(0.65, 0.45, 0.65);
        colors[2] = vec3(0.4, 0.4, 0.9);
        colors[3] = vec3(0.4, 0.8, 0.4);
        colors[4] = vec3(0.8, 0.8, 0.4);
        colors[5] = vec3(0.8, 0.6, 0.4);
        colors[6] = vec3(0.8, 0.4, 0.4);

        vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
        vec3 vWorldPosition = worldPosition.xyz;
          
        float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);

        float section = heightFactor * 6.0;
        int idx = int(section);
        float fract = section - float(idx);
        vec3 heightColor;
        if (idx < 6) {
          heightColor = mix(colors[idx], colors[idx + 1], fract);
        } else {
          heightColor = colors[6];
        }
        gl_PointSize = size;
        if(colorMap){
          vColor = vec4(heightColor, opacity);
        }
        else{
          vec3 color = calculateColor(intensity / 255.0);
          vColor = vec4(color, opacity);
        }
      #else
        gl_PointSize = size;
        vColor = vec4(color, opacity);
      #endif
    }
        
    vec4 mvPosition = modelViewMatrix * vec4(position.x, 0, position.z, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

const vertexShader3d = `  
  out vec4 vColor;
  uniform float size;
  uniform float opacity;
  uniform bool colorMap;
  uniform float minHeight;
  uniform float maxHeight;
  uniform mat4 customModelMatrix;
  uniform float height;
  uniform float lowerHeight;
  uniform float upperHeight;
  uniform bool heightInfoVisible;

  #ifdef USE_INTENSITY
    vec3 calculateColor(float intensity) {
      float value = 1.0 - intensity;
      value = clamp(value, 0.0, 1.0);
      float h = value * 5.0 + 1.0;
      float k = floor(h);
      float f = h - k;
      if (int(k) % 2 == 0) {
        f = 1.0 - f;
      }
      float n = 1.0 - f;
      if (k <= 1.0) {
        return vec3(n, 0.0, 1.0);
      } else if (k == 2.0) {
        return vec3(0.0, n, 1.0);
      } else if (k == 3.0) {
        return vec3(0.0, 1.0, n);
      } else if (k == 4.0) {
        return vec3(n, 1.0, 0.0);
      } else {
        return vec3(1.0, n, 0.0);
      }
    }
  #endif
  
  void main() {
    if(heightInfoVisible){
      if(position.y > height-0.5f && position.y < height+0.5f){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.8);
      }
      else if(position.y > lowerHeight-0.5f && position.y < lowerHeight+0.5f)
      {
        vColor = vec4(222.0/255.0,53.0/255.0,53.0/255.0,0.8);
        gl_PointSize = 2.0;
      }
      else if(position.y > upperHeight-0.5f && position.y < upperHeight+0.5f)
      {
        vColor = vec4(222.0/255.0,53.0/255.0,53.0/255.0,0.8);
        gl_PointSize = 2.0;
      }
      else{      
        #ifdef USE_INTENSITY
          vec3 colors[7];
          colors[0] = vec3(0.65, 0.4, 0.65);
          colors[1] = vec3(0.65, 0.45, 0.65);
          colors[2] = vec3(0.4, 0.4, 0.9);
          colors[3] = vec3(0.4, 0.8, 0.4);
          colors[4] = vec3(0.8, 0.8, 0.4);
          colors[5] = vec3(0.8, 0.6, 0.4);
          colors[6] = vec3(0.8, 0.4, 0.4);

          vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
          vec3 vWorldPosition = worldPosition.xyz;
            
          float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);

          float section = heightFactor * 6.0;
          int idx = int(section);
          float fract = section - float(idx);
          vec3 heightColor;
          if (idx < 6) {
            heightColor = mix(colors[idx], colors[idx + 1], fract);
          } else {
            heightColor = colors[6];
          }
          gl_PointSize = size;
          if(colorMap){
            vColor = vec4(heightColor, opacity);
          }
          else{
            vec3 color = calculateColor(intensity / 255.0);
            vColor = vec4(color, opacity);
          }
        #else
          gl_PointSize = size;
          vColor = vec4(color, 0.3);
        #endif
      }
    }
    else{
      #ifdef USE_INTENSITY
        vec3 colors[7];
        colors[0] = vec3(0.65, 0.4, 0.65);
        colors[1] = vec3(0.65, 0.45, 0.65);
        colors[2] = vec3(0.4, 0.4, 0.9);
        colors[3] = vec3(0.4, 0.8, 0.4);
        colors[4] = vec3(0.8, 0.8, 0.4);
        colors[5] = vec3(0.8, 0.6, 0.4);
        colors[6] = vec3(0.8, 0.4, 0.4);

        vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
        vec3 vWorldPosition = worldPosition.xyz;
          
        float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);

        float section = heightFactor * 6.0;
        int idx = int(section);
        float fract = section - float(idx);
        vec3 heightColor;
        if (idx < 6) {
          heightColor = mix(colors[idx], colors[idx + 1], fract);
        } else {
          heightColor = colors[6];
        }
        gl_PointSize = size;
        if(colorMap){
          vColor = vec4(heightColor, opacity);
        }
        else{
          vec3 color = calculateColor(intensity / 255.0);
          vColor = vec4(color, opacity);
        }
      #else
        gl_PointSize = size;
        vColor = vec4(color, 0.3);
      #endif
    }
    
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

// Fragment Shader
const fragmentShader = `
  in vec4 vColor;
  
  void main() {        
    gl_FragColor = vColor;
  }
`;


export default class PCInspectionViewer{
    constructor(containerElement2D, containerElement3D, pointCloudParams, loading, isMobile, waypoints) {        
        this.container2d = containerElement2D;
        this.container3d = containerElement3D;        
        this.pointCloudParams = pointCloudParams;
        this.loading = loading;
        this.isMobile = isMobile;
        this.waypoints = waypoints;        
        const fps = 15;
        this.interval = 1000/fps;
        this.wpColors=["#01FAFE", "#01FFA9", "#1465F5", "#7B20ED", "#5A5A8D"];
        this.wpCheckedColors=["#FFFFFF", "#FFD700", "#FF69B4", "#00FF00", "#FFFF00"];
        this.wpCompletedColors=["#006672", "#006D47", "#002C6C", "#3A007D", "#2E2E5A"];
        this.wpArrowId = ["arrow1", "arrow2","arrow3","arrow4","arrow5"];
        this.wpLineId  = ["line1", "line2","line3","line4","line5"];
        this.tempWaypointGroup2d = [new THREE.Group(),new THREE.Group(),new THREE.Group(),new THREE.Group(),new THREE.Group()];
        this.tempWaypointGroup3d = [new THREE.Group(),new THREE.Group(),new THREE.Group(),new THREE.Group(),new THREE.Group()];
        this.gltfLoader = new GLTFLoader();
        this.droneModel2D = [null,null,null,null,null]
        this.droneModel3D = [null,null,null,null,null]
        this.init();        
    }

    init() {
        this.scene2d = new THREE.Scene();
        this.scene3d = new THREE.Scene();

        // 렌더러 설정        
        this.renderer2d = new THREE.WebGLRenderer();
        this.renderer2d.setSize(this.container2d.offsetWidth, this.container2d.offsetHeight);
        this.renderer2d.sortObjects = true;
        this.container2d.appendChild(this.renderer2d.domElement);

        this.renderer3d = new THREE.WebGLRenderer();
        this.renderer3d.setSize(this.container3d.offsetWidth, this.container3d.offsetHeight);
        this.renderer3d.sortObjects = true;
        this.container3d.appendChild(this.renderer3d.domElement);

        this.initView();

        var centerPos2d = new THREE.Vector3( 0,0,0 );
        this.scene2d.add( new THREE.ArrowHelper( new THREE.Vector3( 1,0,0 ), centerPos2d, 5, 0xFF0000, 1, 0.3 ) );  //x red
        this.scene2d.add( new THREE.ArrowHelper( new THREE.Vector3( 0,0,-1 ), centerPos2d, 5, 0x00FF00, 1, 0.3 ) ); 

        var centerPos3d = new THREE.Vector3( 0,0,0 );
        this.scene3d.add( new THREE.ArrowHelper( new THREE.Vector3( 5,0, 0 ), centerPos3d, 5, 0xFF0000, 1, 0.3 ) );  //x red
        this.scene3d.add( new THREE.ArrowHelper( new THREE.Vector3( 0,0,-5 ), centerPos3d, 5, 0x00FF00, 1, 0.3 ) );  //y green
        this.scene3d.add( new THREE.ArrowHelper( new THREE.Vector3( 0,5,0 ),  centerPos3d, 5, 0x0000FF, 1, 0.3 ) ); 

        this.initLineRegionScene();
        this.initUndrsideRegionScene();
        this.initRectRegionScene();

        const modelMatrix = new THREE.Matrix4();

        this.uniforms = {
          height: { value: 0 }, // initial value
          lowerHeight: {value:0},
          upperHeight: {value:0},
          heightInfoVisible: { type: 'b', value: false },
          minHeight: {value : this.pointCloudParams.height.min},
          maxHeight: {value : this.pointCloudParams.height.max},
          customModelMatrix: {value : modelMatrix},
          colorMap: { type: 'b', value: this.pointCloudParams.mode == "height"? true : false},
          size : {value : this.pointCloudParams.pointSize},
          opacity : {value : this.pointCloudParams.opacity}
        };

        this.material2d = new THREE.ShaderMaterial({
            vertexShader: vertexShader2d,
            fragmentShader: fragmentShader,
            transparent :true,
            vertexColors: true, 
            uniforms: this.uniforms           
        });

        this.material3d = new THREE.ShaderMaterial({
            vertexShader: vertexShader3d,
            fragmentShader: fragmentShader,
            transparent :true,
            vertexColors: true,    
            uniforms: this.uniforms        
        });

        this.windowResizeEvent = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.windowResizeEvent);

        this.render();
    }

    initView() {   
        this.camera2d = new THREE.PerspectiveCamera(75, this.container2d.offsetWidth / this.container2d.offsetHeight, 1, 1000);
        this.controls2d = new OrbitControls(this.camera2d, this.renderer2d.domElement);
        this.controls2d.enableRotate = false;
        this.camera2d.position.set(0, 100, 0);
        this.camera2d.lookAt(new THREE.Vector3(0, 0, 0));
        this.controls2d.screenSpacePanning = true;
        this.controls2d.update();

        this.camera3d = new THREE.PerspectiveCamera(75, this.container3d.offsetWidth / this.container3d.offsetHeight, 1, 1000);
        this.controls3d = new OrbitControls(this.camera3d, this.renderer3d.domElement);
        this.camera3d.position.set(0, 5, 50);
        this.controls3d.screenSpacePanning = true;
        this.controls3d.update();

        let ambientLight_2d = new THREE.AmbientLight(0xffffff); 
        this.scene2d.add(ambientLight_2d);

        let ambientLight_3d = new THREE.AmbientLight(0xffffff); 
        this.scene3d.add(ambientLight_3d);
    }

    initFitParams(lineFitParams, undersideFitParams, circleFitParams, rectangleFitParams){
      this.lineFitParams = lineFitParams;
      this.undersideFitParams = undersideFitParams;
      this.circleFitParams = circleFitParams;
      this.rectangleFitParams = rectangleFitParams;
    }

    // ########################## init regions (start) ###########################
    initLineRegionScene(){
      this.linePoints = [];
      this.linePointsRaw = [];
      this.numLineRegionPoints = 2;
      this.linePointsCount=0;
      this.lineGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
      this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00, linewidth: 2  })
      this.lineRegion = new THREE.Line(this.lineGeometry, this.lineMaterial);

      this.scene2d.add(this.lineRegion);
      
      if(this.isMobile){        
        this.lineRegionPointGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
        this.lineRegionPointMaterial = new THREE.PointsMaterial({ color: 0x00ff00, size: 1 });
        this.lineRegionPoints = new THREE.Points(this.lineRegionPointGeometry, this.lineRegionPointMaterial);
        this.scene2d.add(this.lineRegionPoints);
      }
    }

    initUndrsideRegionScene(){      
      this.numUndersideRegionPoints = 8;
      this.undersidePointsCount=0;
      this.undersidePoints = []
      this.undersidePointsFirst =[]
      this.undersideGeometryFirst = new THREE.BufferGeometry().setFromPoints(this.undersidePointsFirst);
      this.undersideMaterialFirst = new THREE.LineBasicMaterial({ color: 0x00ff00, linewidth: 2  })
      this.undersideRegionFirst = new THREE.Line(this.undersideGeometryFirst, this.undersideMaterialFirst);
      this.undersidePointsSecond =[]
      this.undersideGeometrySecond = new THREE.BufferGeometry().setFromPoints(this.undersidePointsSecond);
      this.undersideMaterialSecond = new THREE.LineBasicMaterial({ color: 0x008800, linewidth: 2  })
      this.undersideRegionSecond = new THREE.Line(this.undersideGeometrySecond, this.undersideMaterialSecond);
      this.transformedUndersidePointsFirst =[]
      this.transformedUndersidePointsSecond =[]

      this.scene2d.add(this.undersideRegionFirst);
      this.scene2d.add(this.undersideRegionSecond);

      if(this.isMobile){
        this.undersideRegionPointGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePoints);
        this.undersideRegionPointMaterial = new THREE.PointsMaterial({ color: 0x00ff00, size: 1 });
        this.undersideRegionPoints = new THREE.Points(this.undersideRegionPointGeometry, this.undersideRegionPointMaterial);
        this.scene2d.add(this.undersideRegionPoints);
      }
      
      this.undersideGeometry3d = new THREE.BufferGeometry();
      this.undersideVertices3d = new Float32Array([]);
      this.undersideGeometry3d.setAttribute('position', new THREE.BufferAttribute(this.undersideVertices3d, 3));
      this.undersideGeometry2d = new THREE.BufferGeometry();
      this.undersideVertices2d = new Float32Array([]);
      this.undersideGeometry2d.setAttribute('position', new THREE.BufferAttribute(this.undersideVertices2d, 3));

      this.undersideMaterial3d = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.5
      });
      this.undersideMesh3d = new THREE.Mesh(this.undersideGeometry3d, this.undersideMaterial3d);
      this.undersideMesh2d = new THREE.Mesh(this.undersideGeometry2d, this.undersideMaterial3d);
      this.scene3d.add(this.undersideMesh3d);
      this.scene2d.add(this.undersideMesh2d);
    }

    initRectRegionScene(){
      this.rectPoints = [];
      this.clicked = false;
      this.rectGeometry = new THREE.BufferGeometry().setFromPoints(this.rectPoints);
      this.rectMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00, linewidth: 2})
      this.rectRegion = new THREE.Line(this.rectGeometry, this.rectMaterial);
      this.scene2d.add(this.rectRegion);

      this.cubeGeometry = new THREE.BoxGeometry(0, 0, 0);
      this.cubeMaterial = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          transparent: true,
          opacity: 0.5
      });
      this.cubeRegion = new THREE.Mesh(this.cubeGeometry, this.cubeMaterial);
      this.scene3d.add(this.cubeRegion);
  }

    // ########################## init regions (end) ###########################
    animate() {
        this.animationFrameId = requestAnimationFrame(this.animate.bind(this));
        this.renderer2d.render(this.scene2d, this.camera2d);
        this.renderer3d.render(this.scene3d, this.camera3d);
    }
    
    onWindowResize() {
      this.camera2d.aspect = this.container2d.offsetWidth / this.container2d.offsetHeight;
      this.camera2d.updateProjectionMatrix();
      this.renderer2d.setSize(this.container2d.offsetWidth, this.container2d.offsetHeight);
      this.camera3d.aspect = this.container3d.offsetWidth / this.container3d.offsetHeight;
      this.camera3d.updateProjectionMatrix();
      this.renderer3d.setSize(this.container3d.offsetWidth, this.container3d.offsetHeight);
    }

    render(){
        let now,delta;
        let then = Date.now();
        const frame= () => {
          this.animationFrameId = requestAnimationFrame(frame);
          now = Date.now();
          delta = now - then;
          if(delta < this.interval) return
          this.renderer3d.render(this.scene3d,this.camera3d);
          this.renderer2d.render(this.scene2d,this.camera2d);
          then = now - (delta%this.interval);
        }
        this.animationFrameId = requestAnimationFrame(frame);
    }

    loadPCD(pcdFile) {
        return new Promise((resolve, reject) => {
          this.loading.startLoadingAnimation();
          const loader = new PCDLoader();
          loader.load(pcdFile, (points) => {
              const geometry = points.geometry;
              geometry.rotateX(-Math.PI / 2); 
              geometry.computeBoundingBox();
              this.heightLimitMax = geometry.boundingBox.max.y;
              this.heightLimitMin = geometry.boundingBox.min.y * 0.9;
              let hasIntensity = geometry.attributes.intensity !== undefined;
              let hasRGB = geometry.attributes.color !== undefined;
  
              const applyIntensityToShader = (material) => {
                const originalOnBeforeCompile = material.onBeforeCompile;
                if(hasIntensity){
                  material.onBeforeCompile = (shader) => {
                    shader.vertexShader = '#define USE_INTENSITY\nattribute float intensity;\n' + shader.vertexShader;
                    shader.vertexShader = shader.vertexShader.replace(
                        '#include <begin_vertex>',
                        'vIntensity = intensity;\n#include <begin_vertex>'
                    );
                    shader.fragmentShader = '#define USE_INTENSITY\n' + fragmentShader;
                    if(originalOnBeforeCompile){
                      originalOnBeforeCompile(shader);
                    }
                  };
                }
                else if(hasRGB) {
                  material.onBeforeCompile = (shader) => {
                    shader.fragmentShader = fragmentShader;
                    if(originalOnBeforeCompile){
                      originalOnBeforeCompile(shader);
                    }
                  };
                }
                else {
                  reject(new Error("PCD 파일에 intensity 또는 rgb 속성이 없습니다."));
                  return;
                }
            };
                  
              applyIntensityToShader(this.material2d);
              const pointCloud2D = new THREE.Points(geometry, this.material2d);
              this.scene2d.add(pointCloud2D);
    
              applyIntensityToShader(this.material3d);
              const pointCloud3D = new THREE.Points(geometry, this.material3d);
              this.scene3d.add(pointCloud3D);
              this.loading.stopLoadingAnimation();
  
              resolve();
  
          },
          (xhr)=>{
            this.loading.updateLoadingText(xhr.timeStamp);
            if(xhr.lengthComputable){
              let progress = xhr.loaded/xhr.total*100;
              this.loading.$refs.progress_bar.value = progress;
            }
            else{
              this.loading.$refs.progress_bar.style.display = 'none'
            }
          },
          (error) => {
            this.loading.stopLoadingAnimation();
              reject(error); 
          });
        });  
    }

    removeGroupFromScene(group, scene) {
      group.children.forEach((mesh) => {
          if (mesh.geometry) mesh.geometry.dispose();
          if (mesh.material) {
              if (Array.isArray(mesh.material)) {
                  mesh.material.forEach(mat => mat.dispose());
              } else {
                  mesh.material.dispose();
              }
          }
      });
  
      // 그룹을 씬에서 제거
      scene.remove(group);
  
      group = new THREE.Group();
    }

    removeObjectFromScene(scene, object) {      
      if (object.isMesh || object.isPoints) {
          if (object.geometry) {
              object.geometry.dispose();
          }
          if (object.material) {
              if (Array.isArray(object.material)) {
                  object.material.forEach(material => material.dispose());
              } else {
                  object.material.dispose();
              }
          }
      }
        
      scene.remove(object);
        
      if (object.children) {
          while (object.children.length > 0) {
              this.removeObjectFromScene(object, object.children[0]);
          }
      }
    }

    dispose() {      
      cancelAnimationFrame(this.animationFrameId);      

      if (this.scene2d) {
        while (this.scene2d.children.length > 0) {
            const object = this.scene2d.children[0];
            this.removeObjectFromScene(this.scene2d, object);
        }
        this.scene2d = null;
      }
      
      if (this.scene3d) {
          while (this.scene3d.children.length > 0) {
              const object = this.scene3d.children[0];
              this.removeObjectFromScene(this.scene3d, object);
          }
          this.scene3d = null;
      }
          
      if (this.renderer2d) {
        this.renderer2d.dispose();
        this.renderer2d = null;
      }
      if (this.renderer3d) {
        this.renderer3d.dispose();
        this.renderer3d = null;
      }
          
      if (this.controls2d) {
        this.controls2d.dispose();
        this.controls2d = null;
      }
      if (this.controls3d) {
        this.controls3d.dispose();
        this.controls3d = null;
      }
          
      window.removeEventListener('resize', this.windowResizeEvent);        
    
      if (this.loadingBar && this.loadingBar.parentNode) {
        this.loadingBar.parentNode.removeChild(this.loadingBar);
        this.loadingBar = null;
      }
    }

    setHeightInfoVisibe(visible){      
      this.material3d.uniforms.heightInfoVisible.value=visible;
      this.material2d.uniforms.heightInfoVisible.value=visible;
    }

    // ########################## clear regions (start) ###########################
    lineClear(){
      this.linePoints=[];
      this.linePointsRaw=[];
      let newGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
      this.lineRegion.geometry = newGeometry;
      this.lineRegion.geometry.verticesNeedUpdate = true;
      if(this.isMobile){
        let newPointsGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
        this.lineRegionPoints.geometry = newPointsGeometry;
        this.lineRegionPoints.geometry.verticesNeedUpdate = true;
      }      
    
      this.linePointsCount =0;
    
      while(this.scene3d.getObjectByName('plane_region')){
        let selectedObject = this.scene3d.getObjectByName('plane_region');
        selectedObject.geometry.dispose();
        selectedObject.material.dispose();
        this.scene3d.remove(selectedObject);
      }
    }

    undersideClear(){
      this.undersidePoints=[];
      this.undersidePointsFirst=[];
      let newFirstGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePointsFirst);
      this.undersideRegionFirst.geometry = newFirstGeometry;
      this.undersideRegionFirst.geometry.verticesNeedUpdate = true;
      this.undersidePointsSecond=[];
      let newSecondGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePointsSecond);
      this.undersideRegionSecond.geometry = newSecondGeometry;
      this.undersideRegionSecond.geometry.verticesNeedUpdate = true;
      if(this.isMobile){
        let newPointsGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePoints);
        this.undersideRegionPoints.geometry = newPointsGeometry;
        this.undersideRegionPoints.geometry.verticesNeedUpdate = true;
      }

      this.undersidePointsCount=0;     

      var undersideVertices3d = new Float32Array([]);
      this.undersideGeometry3d.setAttribute('position', new THREE.BufferAttribute(undersideVertices3d, 3));
      this.undersideGeometry2d.setAttribute('position', new THREE.BufferAttribute(undersideVertices3d, 3));
    }

    rectClear(){
      this.rectPoints=[];
      let newGeometry = new THREE.BufferGeometry().setFromPoints(this.rectPoints);
      this.rectRegion.geometry = newGeometry;
      this.rectRegion.geometry.verticesNeedUpdate = true;

      let newCubeGeometry = new THREE.BoxGeometry(0,0,0);
      this.cubeRegion.geometry = newCubeGeometry;
    }

    // ########################## clear regions (end) ###########################


    setHeight(height){
      this.material3d.uniforms.height.value=height;
      this.material2d.uniforms.height.value=height;      
      
    }

    setHeightRange(lower, upper){
      this.material3d.uniforms.lowerHeight.value=lower;
      this.material3d.uniforms.upperHeight.value=upper;
    }

    getInterSectionPoint(event){
      let rect = this.renderer2d.domElement.getBoundingClientRect();
      let mouse = new THREE.Vector2();
      let raycaster = new THREE.Raycaster();

      mouse.x = ( (event.clientX - rect.left) / rect.width ) * 2 - 1;
      mouse.y = - ( (event.clientY - rect.top)/  rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, this.camera2d);

      let plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
      let intersectPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersectPoint);

      return intersectPoint;
    }

    getInterSectionPointMobile(event){
      let rect = this.renderer2d.domElement.getBoundingClientRect();
      let touch = event.touches[0];
      let mouse = new THREE.Vector2();
      let raycaster = new THREE.Raycaster();

      mouse.x = ( (touch.clientX - rect.left) / rect.width ) * 2 - 1;
      mouse.y = - ( (touch.clientY - rect.top)/  rect.height) * 2 + 1;
      
      raycaster.setFromCamera(mouse, this.camera2d);

      let plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
      let intersectPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersectPoint);

      return intersectPoint;
    }

    drawWaypoint(droneNum, x, z, y, wpRotation){
      this.wpArrowDraw(droneNum, x, z, y, wpRotation, false, false);
      if(this.waypoints[droneNum].length==0){
        this.wpLineDraw(droneNum, 0, 0, 0, x, z, y);
      }else{
        this.wpLineDraw(
          droneNum,
          this.waypoints[droneNum][this.waypoints[droneNum].length-1].x, 
         -this.waypoints[droneNum][this.waypoints[droneNum].length-1].y, 
         this.waypoints[droneNum][this.waypoints[droneNum].length-1].z, 
          x, z, y);
      }            
    }

    resetWaypoints(droneNum){      
      this.arrowLineClear(droneNum);
      if(this.waypoints[droneNum].length==0) return;      
      for(let i=0;i<this.waypoints[droneNum].length; i++){        
        this.wpArrowDraw(droneNum, 
                         this.waypoints[droneNum][i].x, 
                         -this.waypoints[droneNum][i].y,
                         this.waypoints[droneNum][i].z,
                         this.waypoints[droneNum][i].yaw,
                         this.waypoints[droneNum][i].getChecked(),
                         this.waypoints[droneNum][i].getCompleted(),
                         )
        if(i==0){
          this.wpLineDraw(droneNum,
                          0,0,0, 
                          this.waypoints[droneNum][i].x, 
                          -this.waypoints[droneNum][i].y, 
                          this.waypoints[droneNum][i].z,
                          )
        }
        else{
          this.wpLineDraw(droneNum,
                          this.waypoints[droneNum][i-1].x, 
                          -this.waypoints[droneNum][i-1].y, 
                          this.waypoints[droneNum][i-1].z,
                          this.waypoints[droneNum][i].x, 
                          -this.waypoints[droneNum][i].y, 
                          this.waypoints[droneNum][i].z,                           
                          )
        }
      }
    }
    drawTempWaypoint(paths,num){
      const wpVertices = new Float32Array([
        0.0, 0.001,  0.0,
       -1.0, 0.001,  0.0,
        0.0, 0.001, -1.0,
   
        0.0, 0.001,  0.0,
        0.0, 0.001, -1.0,
        1.0, 0.001, -1.0,
   
        0.0, 0.001,  0.0,
        0.0, 0.001,  1.0,
       -1.0, 0.001,  0.0,
   
        0.0, 0.001,  0.0,
        0.0, 0.001,  1.0,
        1.0, 0.001,  1.0
       ]);
       let wpLinePoints2d = [];
       let wpLinePoints3d = [];

      for(let i=0; i<paths.length; i++){
        if(i!=0){
          for(let j=0;j<wpVertices.length/3;j++){
            let delX = Math.cos(paths[i].yaw)*wpVertices[j*3+0] - Math.sin(paths[i].yaw)*wpVertices[j*3+2];
            let delZ = Math.sin(paths[i].yaw)*wpVertices[j*3+0] + Math.cos(paths[i].yaw)*wpVertices[j*3+2];
            wpVertices[j*3+0] = delX;
            wpVertices[j*3+2] = delZ;  
          }
    
          const wpGeometry2d = new THREE.BufferGeometry();
          wpGeometry2d.setAttribute('position', new THREE.BufferAttribute(wpVertices, 3));

          const wpGeometry3d = new THREE.BufferGeometry();
          wpGeometry3d.setAttribute('position', new THREE.BufferAttribute(wpVertices, 3));
    
          let wpMaterial = new THREE.MeshBasicMaterial({
            color: this.wpColors[num],
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0.5
          });
    
          const wpMesh2d = new THREE.Mesh(wpGeometry2d, wpMaterial);
          const wpMesh3d = new THREE.Mesh(wpGeometry3d, wpMaterial);
          wpMesh2d.position.set(paths[i].x,0,-paths[i].y);
          wpMesh3d.position.set(paths[i].x,paths[i].z,-paths[i].y);
          this.tempWaypointGroup2d[num].add(wpMesh2d);
          this.tempWaypointGroup3d[num].add(wpMesh3d);
        }
        wpLinePoints2d.push(new THREE.Vector3(paths[i].x,0,-paths[i].y));
        wpLinePoints3d.push(new THREE.Vector3(paths[i].x,paths[i].z,-paths[i].y));  
      }
      let wpLineGeometry2d = new THREE.BufferGeometry().setFromPoints(wpLinePoints2d);
      let wpLineGeometry3d = new THREE.BufferGeometry().setFromPoints(wpLinePoints3d);
      let wpColor = this.wpColors[num]
      let wpLineMaterial = new THREE.LineDashedMaterial({ 
        color: wpColor,
        linewidth: 5,
        scale:1,
        dashSize:2,
        gapSize:1,
        transparent: true,
        opacity: 0.5
      })
      let wpLine2d = new THREE.Line(wpLineGeometry2d, wpLineMaterial);
      let wpLine3d = new THREE.Line(wpLineGeometry3d, wpLineMaterial);

      wpLine2d.computeLineDistances();
      wpLine3d.computeLineDistances();

      this.tempWaypointGroup2d[num].add(wpLine2d);
      this.tempWaypointGroup3d[num].add(wpLine3d);

      this.scene2d.add(this.tempWaypointGroup2d[num]);
      this.scene3d.add(this.tempWaypointGroup3d[num]);
    }
    deleteTempWaypoint(num){
      this.removeGroupFromScene(this.tempWaypointGroup2d[num], this.scene2d);
      this.removeGroupFromScene(this.tempWaypointGroup3d[num], this.scene3d);
    }
    
    wpArrowDraw(droneNum, x, z, y, wpRotation, checked, completed){
      const wpVertices = new Float32Array([
        0.0, 0.001,  0.0,
       -1.0, 0.001,  0.0,
        0.0, 0.001, -1.0,
   
        0.0, 0.001,  0.0,
        0.0, 0.001, -1.0,
        1.0, 0.001, -1.0,
   
        0.0, 0.001,  0.0,
        0.0, 0.001,  1.0,
       -1.0, 0.001,  0.0,
   
        0.0, 0.001,  0.0,
        0.0, 0.001,  1.0,
        1.0, 0.001,  1.0
       ]);
    

      for(let i=0;i<wpVertices.length/3;i++){
        let delX = Math.cos(wpRotation)*wpVertices[i*3+0] - Math.sin(wpRotation)*wpVertices[i*3+2];
        let delZ = Math.sin(wpRotation)*wpVertices[i*3+0] + Math.cos(wpRotation)*wpVertices[i*3+2];
        wpVertices[i*3+0] = delX;
        wpVertices[i*3+2] = delZ;  
      }

      const wpGeometry3d = new THREE.BufferGeometry();
      wpGeometry3d.setAttribute('position', new THREE.BufferAttribute(wpVertices, 3));

      const wpGeometry2d = new THREE.BufferGeometry();
      wpGeometry2d.setAttribute('position', new THREE.BufferAttribute(wpVertices, 3));

      let arrowColor = this.wpColors[droneNum]
      if(checked) arrowColor = this.wpCheckedColors[droneNum]
      if(completed) arrowColor = this.wpCompletedColors[droneNum]      

      let wpMaterial = new THREE.MeshBasicMaterial({
        color: arrowColor,
        side: THREE.DoubleSide,
        transparent: false,
        opacity: 0.5
      });

      const wpMesh3d = new THREE.Mesh(wpGeometry3d, wpMaterial);
      const wpMesh2d = new THREE.Mesh(wpGeometry2d, wpMaterial);
      wpMesh3d.name = this.wpArrowId[droneNum];
      wpMesh2d.name = this.wpArrowId[droneNum];

      wpMesh3d.position.x += x;
      wpMesh3d.position.y += y;
      wpMesh3d.position.z += z;
      
      wpMesh2d.position.x += x;
      wpMesh2d.position.z += z;
      
      this.scene3d.add(wpMesh3d);
      this.scene2d.add(wpMesh2d);
    }

    wpLineDraw(droneNum, prevX, prevZ, prevY, x, z, y){
      let wpLinePoints2d = [];
      let wpLinePoints3d = [];
      wpLinePoints2d.push(new THREE.Vector3(prevX, 0, prevZ));
      wpLinePoints2d.push(new THREE.Vector3(x, 0, z));
      
      wpLinePoints3d.push(new THREE.Vector3(prevX, prevY, prevZ));
      wpLinePoints3d.push(new THREE.Vector3(x, y, z));
      
      
      let wpLineGeometry2d = new THREE.BufferGeometry().setFromPoints(wpLinePoints2d);
      let wpLineGeometry3d = new THREE.BufferGeometry().setFromPoints(wpLinePoints3d);

      let wpColor = this.wpColors[droneNum]
      let wpLineMaterial = new THREE.LineDashedMaterial({ 
        color: wpColor,
        linewidth: 5,
        scale:1,
        dashSize:2,
        gapSize:1
      })

      let wpLine2d = new THREE.Line(wpLineGeometry2d, wpLineMaterial);
      let wpLine3d = new THREE.Line(wpLineGeometry3d, wpLineMaterial);

      wpLine2d.computeLineDistances();
      wpLine3d.computeLineDistances();

      wpLine2d.name = this.wpLineId[droneNum];
      wpLine3d.name = this.wpLineId[droneNum];

      this.scene2d.add(wpLine2d);
      this.scene3d.add(wpLine3d);
    }

    arrowLineClear(droneNum){
      while(this.scene3d.getObjectByName(this.wpArrowId[droneNum])){
        let selectedObject = this.scene3d.getObjectByName(this.wpArrowId[droneNum]);
        selectedObject.geometry.dispose();
        selectedObject.material.dispose();
        this.scene3d.remove(selectedObject);
      }
      while(this.scene2d.getObjectByName(this.wpArrowId[droneNum])){
        let selectedObject = this.scene2d.getObjectByName(this.wpArrowId[droneNum]);
        selectedObject.geometry.dispose();
        selectedObject.material.dispose();
        this.scene2d.remove(selectedObject);
      }
      while(this.scene3d.getObjectByName(this.wpLineId[droneNum])){
        let selectedObject = this.scene3d.getObjectByName(this.wpLineId[droneNum]);
        selectedObject.geometry.dispose();
        selectedObject.material.dispose();
        this.scene3d.remove(selectedObject);
      }
      while(this.scene2d.getObjectByName(this.wpLineId[droneNum])){
        let selectedObject = this.scene2d.getObjectByName(this.wpLineId[droneNum]);
        selectedObject.geometry.dispose();
        selectedObject.material.dispose();
        this.scene2d.remove(selectedObject);
      }
    }


    // ########################## draw regions (start) ###########################
    // ################ draw line (start) #################
    setNumLinePoints(nLinePoints){     
      this.lineClear();   
      this.numLineRegionPoints = nLinePoints;        
    }
    getLinePointsCount(){return this.linePointsCount;}
    getLinePoints(){return this.linePointsRaw;}

    checkLineRegion(){return this.linePointsRaw.length === this.numLineRegionPoints;}

    setStartPointLine(pointX, pointZ, height){      
      if(this.numLineRegionPoints == this.linePointsCount)
      {
        this.lineClear();
        return;
      }
      this.linePointsRaw.push({x: pointX, y: -pointZ, z: height});
      if(this.linePoints.length==0)
      {
        this.linePoints.push(new THREE.Vector3(pointX, 0, pointZ));
      }
      else
      {
        this.linePoints[this.linePoints.length-1].set(pointX, 0, pointZ);
      }
      this.linePointsCount+=1;      
    }

    setStartPointLineMobile(pointX, pointZ, height){
      if(this.numLineRegionPoints == this.linePointsCount)
      {
        this.lineClear();
        return;
      }      
     
      this.linePointsRaw.push({x: pointX, y: -pointZ, z: height});
      this.linePoints.push(new THREE.Vector3(pointX, 0, pointZ));

      let newGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
      this.lineRegion.geometry = newGeometry;
      this.lineRegion.geometry.verticesNeedUpdate = true;

      newGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
      this.lineRegionPoints.geometry = newGeometry;
      this.lineRegionPoints.geometry.verticesNeedUpdate = true;
           
      this.linePointsCount+=1;
    }

    fitRegionLineDraw(pointX, pointZ){
      if(this.linePointsCount==this.numLineRegionPoints) return;
      if(this.linePoints.length>0 && this.linePoints.length<=this.numLineRegionPoints)
      {

        if(this.linePointsCount == this.linePoints.length)
        { 
          this.linePoints.push(new THREE.Vector3(pointX, 0, pointZ));
        }
        else
        {
          this.linePoints[this.linePointsCount].set(pointX, 0, pointZ);
        }

        let newGeometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
        this.lineRegion.geometry = newGeometry;
        this.lineRegion.geometry.verticesNeedUpdate = true;
      } 
    }

    fitRegionLineSurfaceDraw(height){      
      if(this.linePointsCount==this.numLineRegionPoints)
      {          
        for(let i=0;i<this.linePointsCount-1;i++)
        {
          let deltaX  = this.linePoints[i].x - this.linePoints[i+1].x;
          let deltaZ  = this.linePoints[i].z - this.linePoints[i+1].z;
          let deltaY  = this.linePointsRaw[i].z - this.linePointsRaw[i+1].z
          let slopeY = deltaZ/deltaX;
          let slopeZ = deltaY/deltaX;
          let rotationY = Math.atan(slopeY);
          let rotationZ = Math.atan(slopeZ);

          let width = Math.sqrt(deltaX*deltaX + deltaZ*deltaZ);          
          if(height==0) height = 0.1
          
          let planeGeometry = new THREE.PlaneGeometry(width, height); 
          let planeMaterial = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0.5
          });
          let planeRegion = new THREE.Mesh(planeGeometry, planeMaterial);         

          planeRegion.rotation.y = -rotationY;
          planeRegion.rotation.z =  rotationZ;
          planeRegion.position.set((this.linePoints[i].x + this.linePoints[i+1].x)/2,
                                   (this.linePointsRaw[i].z + this.linePointsRaw[i+1].z)/2,
                                   (this.linePoints[i].z + this.linePoints[i+1].z)/2);
                                    
          planeRegion.name = 'plane_region';
          this.scene3d.add(planeRegion);
        }
      }
    }
    // ################ draw line (end) #################
    // ################ draw underside (start) #################

    setUndersideNumRegionPoints(num){
      // this.lineClear();
      this.undersideClear();        
      this.numUndersideRegionPoints=num;
    }

    getUndersidePointsCount(){return this.undersidePointsCount;}
    getUndersidePoints(){return this.undersidePoints;}
    getUndersidePointsFirst(){return this.undersidePointsFirst;}
    getUndersidePointsSecond(){return this.undersidePointsSecond;}

    checkUndersideRegion(){
      // 아래는 auto일 경우, manual일 경우 추가
      if(this.undersidePointsFirst.length !== this.numUndersideRegionPoints/2) return false;
      if(this.undersidePointsSecond.length !== this.numUndersideRegionPoints/2) return false;
      
      return true;
    }

    setRegionPointsUnderside(pointX, pointZ){
      if(this.numUndersideRegionPoints == this.undersidePointsCount){
        this.undersideClear();
        return;
      }
      if(this.undersidePoints.length==0){
        this.undersidePoints.push(new THREE.Vector3(pointX, 0, pointZ));
        this.undersidePointsFirst.push(new THREE.Vector3(pointX, 0, pointZ));
      }
      else{
        this.undersidePoints[this.undersidePoints.length-1].set(pointX, 0, pointZ);
        if(this.undersidePointsCount<this.numUndersideRegionPoints/2){
          this.undersidePointsFirst[this.undersidePoints.length-1].set(pointX, 0, pointZ);
        }
        else{
          if(this.undersidePointsSecond.length==0){
            this.undersidePointsSecond.push(new THREE.Vector3(pointX, 0, pointZ));
          }
          else{
            this.undersidePointsSecond[this.undersidePointsCount-this.numUndersideRegionPoints/2].set(pointX, 0, pointZ);
          }
        }
      }
      this.undersidePointsCount+=1;
    }

    setRegionPointsUndersideMobile(pointX, pointZ){
      if(this.numUndersideRegionPoints == this.undersidePointsCount){
        this.undersideClear();
        return;
      }
      this.undersidePoints.push(new THREE.Vector3(pointX, 0, pointZ));
      if(this.undersidePointsCount<this.numUndersideRegionPoints/2){        
        this.undersidePointsFirst.push(new THREE.Vector3(pointX, 0, pointZ));
      } else{        
        this.undersidePointsSecond.push(new THREE.Vector3(pointX, 0, pointZ));
      }
      this.undersidePointsCount+=1;     

      let newGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePointsFirst);
      this.undersideRegionFirst.geometry = newGeometry;
      this.undersideRegionFirst.geometry.verticesNeedUpdate = true;

      newGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePointsSecond);
      this.undersideRegionSecond.geometry = newGeometry;
      this.undersideRegionSecond.geometry.verticesNeedUpdate = true;

      newGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePoints);
      this.undersideRegionPoints.geometry = newGeometry;
      this.undersideRegionPoints.geometry.verticesNeedUpdate = true;
    }

    fitRegionUndersideBorderDraw(pointX, pointZ){      
      if(this.undersidePoints.length>0 && this.undersidePoints.length<=this.numUndersideRegionPoints){
        if(this.undersidePointsCount == this.undersidePoints.length){ 
          this.undersidePoints.push(new THREE.Vector3(pointX, 0, pointZ));
          if(this.undersidePointsCount<this.numUndersideRegionPoints/2){
            this.undersidePointsFirst.push(new THREE.Vector3(pointX, 0, pointZ));
          }
          else{
            if(this.undersidePointsSecond.length>0 && this.undersidePointsSecond.length < this.numUndersideRegionPoints/2)
              this.undersidePointsSecond.push(new THREE.Vector3(pointX, 0, pointZ));
          }
        }
        else{           
          this.undersidePoints[this.undersidePointsCount].set(pointX, 0, pointZ);
          if(this.undersidePointsCount<this.numUndersideRegionPoints/2){            
            this.undersidePointsFirst[this.undersidePointsCount].set(pointX, 0, pointZ);            
          }
          else{
            if(this.undersidePointsSecond.length!=0)
              this.undersidePointsSecond[this.undersidePointsCount-this.numUndersideRegionPoints/2].set(pointX, 0, pointZ);              
          }
        }
      }
      let newGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePointsFirst);
      this.undersideRegionFirst.geometry = newGeometry;
      this.undersideRegionFirst.geometry.verticesNeedUpdate = true;

      newGeometry = new THREE.BufferGeometry().setFromPoints(this.undersidePointsSecond);
      this.undersideRegionSecond.geometry = newGeometry;
      this.undersideRegionSecond.geometry.verticesNeedUpdate = true;
    }

    fitRegionUndersideSurfaceDraw(lowerHeight, upperHeight){
      if(this.undersidePointsCount !== this.numUndersideRegionPoints) return;
      let xValues = this.undersidePointsFirst.map(function(v) { return v.x; });
      let xMinValue = Math.min.apply(null, xValues);
      let xMaxValue = Math.max.apply(null, xValues);

      let zValues = this.undersidePointsFirst.map(function(v) { return v.z; });
      let zMinValue = Math.min.apply(null, zValues);
      let zMaxValue = Math.max.apply(null, zValues);

      this.transformedUndersidePointsFirst=[];
      this.transformedUndersidePointsSecond=[];
      for(let i=0;i<this.numUndersideRegionPoints/2;i++){
        this.transformedUndersidePointsFirst.push([-this.undersidePointsFirst[i].x, 
                                                   -this.undersidePointsFirst[i].z])
        this.transformedUndersidePointsSecond.push([-this.undersidePointsSecond[i].x, 
                                                    -this.undersidePointsSecond[i].z])
      }

      if(Math.abs(xMaxValue-xMinValue) > Math.abs(zMaxValue-zMinValue)){
        this.undersidePointsFirst.sort(function(a, b) {
          return a.x - b.x;
        });
        this.undersidePointsSecond.sort(function(a, b) {
          return a.x - b.x;
        });
      }
      else{
        this.undersidePointsFirst.sort(function(a, b) {
          return a.z - b.z;
        });
        this.undersidePointsSecond.sort(function(a, b) {
          return a.z - b.z;
        });
      }
      let tempArray3d = new Float32Array(this.numUndersideRegionPoints/2*36);
      let tempArray2d = new Float32Array(this.numUndersideRegionPoints/2*18);
      for(let i=0; i<this.numUndersideRegionPoints/2-1; i++){
        tempArray3d[i*36]   = (this.undersidePointsFirst[i].x);
        tempArray3d[i*36+1] = parseFloat(upperHeight);
        tempArray3d[i*36+2] = (this.undersidePointsFirst[i].z)
        
        tempArray3d[i*36+3] = (this.undersidePointsSecond[i].x);
        tempArray3d[i*36+4] = parseFloat(upperHeight);
        tempArray3d[i*36+5] = (this.undersidePointsSecond[i].z);

        tempArray3d[i*36+6] = (this.undersidePointsSecond[i+1].x);
        tempArray3d[i*36+7] = parseFloat(upperHeight);
        tempArray3d[i*36+8] = (this.undersidePointsSecond[i+1].z);

        tempArray3d[i*36+9]  = (this.undersidePointsSecond[i+1].x);
        tempArray3d[i*36+10] = parseFloat(upperHeight);
        tempArray3d[i*36+11] = (this.undersidePointsSecond[i+1].z);

        tempArray3d[i*36+12] = (this.undersidePointsFirst[i].x);
        tempArray3d[i*36+13] = parseFloat(upperHeight);
        tempArray3d[i*36+14] = (this.undersidePointsFirst[i].z)

        tempArray3d[i*36+15] = (this.undersidePointsFirst[i+1].x);
        tempArray3d[i*36+16] = parseFloat(upperHeight);
        tempArray3d[i*36+17] = (this.undersidePointsFirst[i+1].z)

        tempArray3d[i*36+18] = (this.undersidePointsFirst[i].x);
        tempArray3d[i*36+19] = parseFloat(lowerHeight);
        tempArray3d[i*36+20] = (this.undersidePointsFirst[i].z)
        
        tempArray3d[i*36+21] = (this.undersidePointsSecond[i].x);
        tempArray3d[i*36+22] = parseFloat(lowerHeight);
        tempArray3d[i*36+23] = (this.undersidePointsSecond[i].z);

        tempArray3d[i*36+24] = (this.undersidePointsSecond[i+1].x);
        tempArray3d[i*36+25] = parseFloat(lowerHeight);
        tempArray3d[i*36+26] = (this.undersidePointsSecond[i+1].z);

        tempArray3d[i*36+27] = (this.undersidePointsSecond[i+1].x);
        tempArray3d[i*36+28] = parseFloat(lowerHeight);
        tempArray3d[i*36+29] = (this.undersidePointsSecond[i+1].z);

        tempArray3d[i*36+30] = (this.undersidePointsFirst[i].x);
        tempArray3d[i*36+31] = parseFloat(lowerHeight);
        tempArray3d[i*36+32] = (this.undersidePointsFirst[i].z)

        tempArray3d[i*36+33] = (this.undersidePointsFirst[i+1].x);
        tempArray3d[i*36+34] = parseFloat(lowerHeight);
        tempArray3d[i*36+35] = (this.undersidePointsFirst[i+1].z)


        tempArray2d[i*18]   = (this.undersidePointsFirst[i].x);
        tempArray2d[i*18+1] = 0;
        tempArray2d[i*18+2] = (this.undersidePointsFirst[i].z)
        
        tempArray2d[i*18+3] = (this.undersidePointsSecond[i].x);
        tempArray2d[i*18+4] = 0;
        tempArray2d[i*18+5] = (this.undersidePointsSecond[i].z);

        tempArray2d[i*18+6] = (this.undersidePointsSecond[i+1].x);
        tempArray2d[i*18+7] = 0;
        tempArray2d[i*18+8] = (this.undersidePointsSecond[i+1].z);

        tempArray2d[i*18+9]  = (this.undersidePointsSecond[i+1].x);
        tempArray2d[i*18+10] = 0;
        tempArray2d[i*18+11] = (this.undersidePointsSecond[i+1].z);

        tempArray2d[i*18+12] = (this.undersidePointsFirst[i].x);
        tempArray2d[i*18+13] = 0;
        tempArray2d[i*18+14] = (this.undersidePointsFirst[i].z)

        tempArray2d[i*18+15] = (this.undersidePointsFirst[i+1].x);
        tempArray2d[i*18+16] = 0;
        tempArray2d[i*18+17] = (this.undersidePointsFirst[i+1].z)
      }
      
      this.undersideGeometry3d.setAttribute('position', new THREE.BufferAttribute(tempArray3d, 3));
      this.undersideGeometry2d.setAttribute('position', new THREE.BufferAttribute(tempArray2d, 3));
    }

    // ################ draw underside (end) #################
    // ################ draw retangle (start) #################
    setRectClicked(clicked){this.rectClicked = clicked;}
    getRectClicked(){return this.rectClicked;}
    getRectPoints(){return this.rectPoints;}

    checkRectRegion(){return this.rectPoints.length === 5;}

    setStartPointRect(startX, startZ){
      if(this.rectPoints.length==0){ 
        this.rectPoints.push(new THREE.Vector3(startX, 0, startZ));
      }
      else{
        this.rectPoints[0].set(startX, 0, startZ);
      }
      this.rectClicked = true;
    }

    fitRegionRectDraw(startPoint, endPoint, endX, endZ, lowerHeight, upperHeight){
      if(this.rectPoints.length==1) 
      {
        this.rectPoints.push(new THREE.Vector3(startPoint.x, 0, endZ));
        this.rectPoints.push(new THREE.Vector3(endX, 0, endZ));
        this.rectPoints.push(new THREE.Vector3(endX, 0, -startPoint.y));
        this.rectPoints.push(new THREE.Vector3(startPoint.x, 0, -startPoint.y));
      }
      else
      {
        this.rectPoints[1].set(startPoint.x, 0, endZ);
        this.rectPoints[2].set(endX, 0, endZ);
        this.rectPoints[3].set(endX, 0, -startPoint.y);
        this.rectPoints[4].set(startPoint.x, 0, -startPoint.y);
  
      }
      let newGeometry = new THREE.BufferGeometry().setFromPoints(this.rectPoints);
      this.rectRegion.geometry = newGeometry;
      this.rectRegion.geometry.verticesNeedUpdate = true;
  
      let newCubeGeometry = new THREE.BoxGeometry(Math.abs(startPoint.x-endPoint.x), 
                                                  upperHeight-lowerHeight, 
                                                  Math.abs(startPoint.y-endPoint.y));
      this.cubeRegion.geometry = newCubeGeometry;
      let heightPosition = (parseFloat(upperHeight)+parseFloat(lowerHeight))/2.0;
      this.cubeRegion.position.set((startPoint.x+endX)/2.0, heightPosition, (-startPoint.y+endZ)/2.0);    
    }
    createDroneModel(droneNum){
      this.deleteDroneModel();
      this.gltfLoader.load(
        '/drone/scene.gltf',
        (gltf)=>{
          let drone = gltf.scene;
          drone.scale.set(0.1, 0.1, 0.1);
  
          let geometrySphere = new THREE.SphereGeometry(1);
          let materialSphere = new THREE.MeshBasicMaterial({color: this.wpColors[droneNum],
                                                            transparent: true,
                                                            opacity:0.5});
          let sphere= new THREE.Mesh(geometrySphere ,materialSphere );
  
          let droneModel2D = new THREE.Group();
          droneModel2D.add(drone);
          droneModel2D.add(sphere);
          droneModel2D.position.set(0,0,0);
  
  
          this.scene2d.add(droneModel2D);
          this.droneModel2D[droneNum] = droneModel2D;

          let droneModel3D = droneModel2D.clone();
          this.droneModel3D[droneNum] = droneModel3D;

          this.scene3d.add(droneModel3D);
        }
      );
    }
    deleteDroneModel(droneNum){
      if(this.droneModel2D[droneNum]){
        this.scene2d.remove(this.droneModel2D[droneNum]);
      }
      if(this.droneModel3D[droneNum]){
        this.scene3d.remove(this.droneModel3D[droneNum]);
      }
    }

    // ################ draw retangle (end) #################
    // ########################## draw regions (end) ###########################
}

