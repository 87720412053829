class DroneMonitor{
  constructor(module, droneNum){
    var options = {
      host: '211.224.129.230',
      port: 30004
    }
    this.module = module;
    this.droneNum = droneNum;
    this.mqttClient  = mqtt.connect(`ws://${options.host}:${options.port}`);
    this.mqttClient.on('connect', function(){
        console.log(`ID : ${module.droneSerialNumber}이 연결되었습니다.`)
      }
    );
    window.onbeforeunload = ()=>{
      if(this.mqttClient){
        this.mqttClient.end();
        console.log(`ID : ${module.droneSerialNumber}이 연결 해제되었습니다.`);
      } 
    }
  }
  closeMqtt(){
    this.mqttClient.end();
    console.log(`ID : ${this.module.droneSerialNumber}이 연결 해제되었습니다.`);
  }
  subSiriusState(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SIRIUS_STATE`);
    this.siriusStateCallback = callback;
  }
  subSiriusError(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SIRIUS_ERROR`);
    this.siriusErrorCallback = callback;
  }
  subDataState(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DATA_STATE`);
    this.dataStateCallback = callback;
  }
  subDataError(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DATA_ERROR`);
    this.dataErrorCallback = callback;
  }
  subSlamWarning(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SLAM_WARNING`);
    this.slamWarningCallback = callback;
  }
  subLocalizationState(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/LOCALIZATION_STATE`);
    this.localizationStateCallback = callback;
  }
  subLocalizationWarning(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/LOCALIZATION_WARNING`);
    this.localizationWarningCallback = callback;
  }
  subWaypointError(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WAYPOINT_ERROR`);
    this.waypointErrorCallback = callback;
  }
  subGlobalPose(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GLOBAL_POSE`);
    this.globalPoseCallback = callback;
  }
  subLocalPose(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/LOCAL_POSE`);
    this.localPoseCallback = callback;
  }
  subState(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/STATE`);
    this.stateCallback = callback;
  }
  subBattery(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/BATTERY`);
    this.batteryCallback = callback;
  }
  subStatusText(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/STATUS_TEXT`);
    this.statusTextCallback = callback;
  }
  subSensorState(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SENSOR_STATE`);
    this.sensorStateCallback = callback;
  }
  subGroundSpeed(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GROUND_SPEED`);
    this.groundSpeedCallback = callback;
  }
  subGpsMode(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GPS_MODE`);
    this.gpsModeCallback = callback;
  }
  subGpsStatus(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GPS_STATUS`);
    this.gpsStatusCallback = callback;
  }
  subRc(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/RC`);
    this.rcCallback = callback;
  }
  subLte(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/LTE`);
    this.lteCallback = callback;
  }
  // subArrival(callback){
  //   this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/ARRIVAL`);
  //   this.arrivalCallback = callback;
  // }
  subMapSaveComplete(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MAP_SAVE_COMPLETE`);
    this.mapSaveCompleteCallback = callback;
  }
  // sub_ftp_info(callback){
  //   this.mqtt_client.subscribe(`/from/${this.module.droneSerialNumber}/FTP_INFO`);
  //   this.ftp_info_callback = callback;
  // }
  // subParams(callback){
  //   this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/PARAMS`);
  //   this.paramsCallback = callback;
  // }
  // sub_thumbnail(callback){
  //   this.mqtt_client.subscribe(`/from/${this.client_id}/THUMBNAIL`);
  //   this.thumbnail_callback = callback;
  // }
  subSlamState(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SLAM_STATE`);
    this.slamStateCallback = callback;
  }
  subStorage(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/STORAGE`);
    this.storageCallback = callback;
  }
  // sub_gimbal_pitch(callback){
  //   this.mqtt_client.subscribe(`/from/${this.client_id}/GIMBAL_PITCH`);
  //   this.gimbal_pitch_callback = callback;
  // }
  subDjiConnection(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DJI_CONNECTION`);
    this.djiConnectionCallback = callback;
  }
  subDjiModulePoseStop(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DJI_INIT_POSE`);
    this.djiModulePoseStopCallback = callback;
  }
  subWaypoint(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WAYPOINT`);
    this.waypointCallback = callback;
  }
  subTempWaypoint(callback){
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WAYPOINT_PATH`);
    this.tempWaypointCallback = callback;
  }
  readMsg(){
    this.mqttClient.on('message', (topic, message)=>{
      try{
        var data = JSON.parse(message);
        if(topic == `/from/${this.module.droneSerialNumber}/SIRIUS_STATE` && this.siriusStateCallback){
          if(this.droneNum != undefined){
            this.siriusStateCallback(data, this.droneNum);
          }
          else{
            this.siriusStateCallback(data);
          }
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/SIRIUS_ERROR` && this.siriusErrorCallback){
          this.siriusErrorCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/DATA_STATE` && this.dataStateCallback){
          this.dataStateCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/DATA_ERROR` && this.dataErrorCallback){
          this.dataErrorCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/SLAM_WARNING` && this.slamWarningCallback){
          this.slamWarningCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/LOCALIZATION_STATE` && this.localizationStateCallback){
          this.localizationStateCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/LOCALIZATION_WARNING` && this.localizationWarningCallback){
          this.localizationWarningCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/WAYPOINT_ERROR` && this.waypointErrorCallback){
          this.waypointErrorCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/GLOBAL_POSE` && this.globalPoseCallback){
          this.globalPoseCallback(data);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/LOCAL_POSE` && this.localPoseCallback){
          this.localPoseCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/STATE` && this.stateCallback){
          this.stateCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/BATTERY` && this.batteryCallback){
          this.batteryCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/STATUS_TEXT` && this.statusTextCallback){
          this.statusTextCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/SENSOR_STATE` && this.sensorStateCallback){
          this.sensorStateCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/GROUND_SPEED` && this.groundSpeedCallback){
          this.groundSpeedCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/GPS_MODE` && this.gpsModeCallback){
          this.gpsModeCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/GPS_STATUS` && this.gpsStatusCallback){
          this.gpsStatusCallback(data);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/RC` && this.rcCallback){
          this.rcCallback(data);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/LTE` && this.lteCallback){
          this.lteCallback(data);
        }
        // else if(topic == `/from/${this.module.droneSerialNumber}/ARRIVAL` && this.arrivalCallback){
        //   this.arrivalCallback(data,this.droneNum);
        // }
  //       else if(topic == `/from/${this.client_id}/FTP_INFO` && this.ftp_info_callback){
  //         this.ftp_info_callback(data);
  //       }
        // else if(topic == `/from/${this.module.droneSerialNumber}/PARAMS` && this.paramsCallback){
        //   this.paramsCallback(data);
        // }
  //       else if(topic == `/from/${this.client_id}/THUMBNAIL` && this.thumbnail_callback){
  //         this.thumbnail_callback(data);
  //       }
        else if(topic == `/from/${this.module.droneSerialNumber}/SLAM_STATE` && this.slamStateCallback){
          if(this.droneNum != undefined){
            this.slamStateCallback(data, this.droneNum);
          }
          else{
            this.slamStateCallback(data);  
          }
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/STORAGE` && this.storageCallback){
          this.storageCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/MAP_SAVE_COMPLETE` && this.mapSaveCompleteCallback){
          this.mapSaveCompleteCallback();
        }
  //       else if(topic == `/from/${this.client_id}/GIMBAL_PITCH` && this.gimbal_pitch_callback){
  //         this.gimbal_pitch_callback(data, this.inspection_drone_num);
  //       }
        else if(topic == `/from/${this.module.droneSerialNumber}/DJI_CONNECTION` && this.djiConnectionCallback){
          this.djiConnectionCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/DJI_INIT_POSE` && this.djiModulePoseStopCallback){
          this.djiModulePoseStopCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/WAYPOINT` && this.waypointCallback){
          this.waypointCallback(data, this.droneNum);
        }
        else if(topic == `/from/${this.module.droneSerialNumber}/WAYPOINT_PATH` && this.tempWaypointCallback){
          this.tempWaypointCallback(data, this.droneNum);
        }
      }
      catch(error){
        console.error('An error occurred:', error);
      }
    });
  }
}

export default DroneMonitor