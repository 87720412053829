<template>
  <div
    class="default_select_box_wrap"
    :class="className ? className : ''"
    ref="selectBox"
  >
    <button type="button" class="select_box" @click="handleClickToggle">
      <template v-if="optionTag">
        <div class="option_tag_box">
          <p v-if="selectedName" class="selected_name">{{ selectedName }}</p>
          <p v-else class="default_name">{{ defaultName }}</p>
          <span v-if="needOptionTag" :class="optionTagClassName">{{
            optionTag
          }}</span>
        </div>
      </template>
      <template v-else>
        <p v-if="selectedName" class="selected_name">{{ selectedName }}</p>
        <p v-else class="default_name">{{ defaultName }}</p></template
      >
      <div class="select_icon" :class="{ open: isOptionOpen }"></div>
    </button>
    <div v-if="isOptionOpen" class="option_box">
      <!-- 옵션이 들어가는 자리 (무조건 ul > li > p 형태여야 디자인 먹힘) -->
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { addOutsideClickHandler } from "@/shared/util/handleOutsideClickHandler";

export default {
  name: "DefaultSelectBox",
  emits: ["controlOptionToggle"],
  props: {
    isOptionOpen: {
      // 옵션 박스의 열고 닫음을 결정한다.
      type: Boolean,
      required: false,
    },
    defaultName: {
      // 아무것도 선택하지 않았을 때 보여질 이름
      type: [String, Number],
      required: true,
    },
    selectedName: {
      // 옵션 선택시 보여질 이름
      type: [String, Number],
      required: false,
    },
    optionTag: {
      type: String,
      required: false,
    },
    needOptionTag: {
      type: Boolean,
      required: false,
    },
    optionTagClassName: {
      type: String,
      required: false,
    },
    className: {
      // class 를 추가해서 ui 등의 변경에 사용 (simple, focus, right ...)
      type: String,
      required: false,
    },
  },
  methods: {
    /**
     * 셀렉트 옵션 박스를 열고 닫는 역할. isOptionOpen을 props로 관리
     */
    handleClickToggle() {
      this.$emit("controlOptionToggle");
    },
  },
  mounted() {
    // ref selectBox 이외의 영역을 클릭했을 때 닫히도록
    this.clickHandlerRemover = addOutsideClickHandler(
      this.$refs.selectBox,
      () => {
        if (this.isOptionOpen) {
          this.$emit("update:isOptionOpen", false);
        }
      }
    );
  },
  beforeUnmount() {
    // ref selectBox 이외의 영역을 클릭했을 때 닫히도록
    if (this.clickHandlerRemover) {
      this.clickHandlerRemover();
    }
  },
};
</script>
<style lang="scss" scoped></style>
