import * as THREE from 'three';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

// Vertex Shader
const vertexShader = `
  varying float vIntensity;
  varying vec3 vColor;

  void main() {
    gl_PointSize = 1.0;

    #ifdef USE_INTENSITY
      vIntensity = intensity;
    #else
      vColor = color;
    #endif

    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

// Fragment Shader
const fragmentShader = `
  varying float vIntensity;
  varying vec3 vColor;

  vec3 calculateColor(float intensity) {
    float value = 1.0 - intensity;
    value = clamp(value, 0.0, 1.0);
    float h = value * 5.0 + 1.0;
    float k = floor(h);
    float f = h - k;
    if (int(k) % 2 == 0) {
      f = 1.0 - f;
    }
    float n = 1.0 - f;
    if (k <= 1.0) {
      return vec3(n, 0.0, 1.0);
    } else if (k == 2.0) {
      return vec3(0.0, n, 1.0);
    } else if (k == 3.0) {
      return vec3(0.0, 1.0, n);
    } else if (k == 4.0) {
      return vec3(n, 1.0, 0.0);
    } else {
      return vec3(1.0, n, 0.0);
    }
  }

  void main() {
    #ifdef USE_INTENSITY
      vec3 color = calculateColor(vIntensity / 255.0);
      gl_FragColor = vec4(color, 0.5);
    #else
      gl_FragColor = vec4(vColor, 0.5);
    #endif
  }
`;


export default class PCReportViewer{
    constructor(containerElement, loading) {        
        this.container = containerElement;
        this.loading = loading;
        const fps = 15;
        this.interval = 1000/fps;
        this.init();        
        this.cameraAngleList=[];
        this.points;
        this.droneModel;
        this.gltfLoader = new GLTFLoader();
        this.loader = new PCDLoader();
        this.maxX =0;
        this.minX =0;
        this.maxY =0;
        this.minY =0;
        this.positionsArray = [];
        this.lineGeometry = new THREE.BufferGeometry();
    }

    init() {
        if(this.renderer) this.container.removeChild(this.renderer.domElement);

        this.scene = new THREE.Scene();       

        // 렌더러 설정
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.renderer.sortObjects = true;
        this.container.appendChild(this.renderer.domElement);

        this.initView();

        var centerPos = new THREE.Vector3( 0,0,0 );
        this.scene.add( new THREE.ArrowHelper( new THREE.Vector3( 5,0, 0 ), centerPos, 5, 0xFF0000, 1, 0.3 ) );  //x red
        this.scene.add( new THREE.ArrowHelper( new THREE.Vector3( 0,0,-5 ), centerPos, 5, 0x00FF00, 1, 0.3 ) );  //y green
        this.scene.add( new THREE.ArrowHelper( new THREE.Vector3( 0,5,0 ),  centerPos, 5, 0x0000FF, 1, 0.3 ) ); 
  
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent :true,
            vertexColors: true,            
        });

        this.windowResizeEvent = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.windowResizeEvent);

        this.render();
    }

    initView() {   
        if(this.controls) this.controls.dispose();

        this.camera = new THREE.PerspectiveCamera(75, this.container.offsetWidth / this.container.offsetHeight, 1, 1000);
        this.camera.position.set(0, 5, 50);
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);        
        this.controls.screenSpacePanning = true;
        this.controls.update();
        this.controls.addEventListener('change',()=>{
          this.updateDotSizes();
          let controlsPostion = this.controls.object.position;
          this.cameraAngleList.forEach((cameraAngle,index)=>{
            let distance = controlsPostion.distanceTo(cameraAngle.position);
            if (distance > 25 && !cameraAngle.isDot) {
              this.convertToDot(cameraAngle, index);
            }else if (distance <= 25 && cameraAngle.isDot) {
              this.convertToCameraAngle(cameraAngle, index);
            }
          })
        })

        this.renderer.setClearColor(0x000000, 1);

        let ambientLight = new THREE.AmbientLight(0xffffff); 
        this.scene.add(ambientLight);
    }
    
    onWindowResize() {
      if(this.camera){
        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
      }
    }

    render(){
        let now,delta;
        let then = Date.now();
        const frame= () => {
          this.animationFrameId = requestAnimationFrame(frame);
          now = Date.now();
          delta = now - then;
          if(delta < this.interval) return
          this.renderer.render(this.scene,this.camera);
          then = now - (delta%this.interval);
        }
        this.animationFrameId = requestAnimationFrame(frame);
    }

    loadPCD(pcdFile) {
      return new Promise((resolve, reject) => {
        this.loading.startLoadingAnimation();
  
        this.loader.load(pcdFile, (points) => {
          const geometry = points.geometry;
          geometry.rotateX(-Math.PI / 2);
  
          let hasIntensity = geometry.attributes.intensity !== undefined;
          let hasRGB = geometry.attributes.color !== undefined;
  
          if (hasIntensity) {
            this.material.onBeforeCompile = (shader) => {
              shader.vertexShader = '#define USE_INTENSITY\nattribute float intensity;\n' + vertexShader;
              shader.vertexShader = shader.vertexShader.replace(
                '#include <begin_vertex>',
                'vIntensity = intensity;\n#include <begin_vertex>'
              );
              shader.fragmentShader = '#define USE_INTENSITY\n' + fragmentShader;
            };
          }
          else if (hasRGB) {
            this.material.onBeforeCompile = (shader) => {
              shader.vertexShader = vertexShader;
              shader.fragmentShader = fragmentShader;
            };
          }
          else {
            reject(new Error("PCD 파일에 intensity 또는 rgb 속성이 없습니다."));
            return;
          }
  
          this.material.needsUpdate = true;
  
          this.points = new THREE.Points(geometry, this.material);
          this.scene.add(this.points);
  
          this.loading.stopLoadingAnimation();
          resolve();
        },
        (xhr) => {
          this.loading.updateLoadingText(xhr.timeStamp);
          if(xhr.lengthComputable){
            let progress = xhr.loaded/xhr.total*100;
            this.loading.$refs.progress_bar.value = progress;
          }
          else{
            this.loading.$refs.progress_bar.style.display = 'none'
          }
        },
        (error) => {
          this.loading.stopLoadingAnimation();
          console.log(error);
          reject(error);
        });
      });
    }
    disposeScene(){
      if(this.points){
        this.removeObjectFromScene(this.scene, this.points);
        this.points = null;
      }
      if(this.cameraAngleList){
        for(let i=0; i<this.cameraAngleList.length; i++){
          this.removeObjectFromScene(this.scene,this.cameraAngleList[i]);
        }
        this.cameraAngleList=[];
      }
    }

    removeObjectFromScene(scene, object) {      
      if(object.geometry){
        object.geometry.dispose();
      }
      if(object.material){
        if(Array.isArray(object.material)){
          object.material.forEach(material => material.dispose());
        }else{
          object.material.dispose();
        }
      }
        
      scene.remove(object);
        
      if(object.children){
        while(object.children.length > 0){
          this.removeObjectFromScene(object, object.children[0]);
        }
      }
    }

    
    dispose() {      
      cancelAnimationFrame(this.animationFrameId);
      if (this.scene) {
        while (this.scene.children.length > 0) {
            const object = this.scene.children[0];
            this.removeObjectFromScene(this.scene, object);
        }
        this.scene = null;
      }
          
      if (this.renderer) {
        this.renderer.dispose();
        this.renderer = null;
      }
          
      if (this.controls) {
        this.controls.dispose();
        this.controls = null;
      }
          
      if (this.camera) {
        this.camera = null;
      }
          
      window.removeEventListener('resize', this.windowResizeEvent);
          
    
      if (this.loadingBar && this.loadingBar.parentNode) {
        this.loadingBar.parentNode.removeChild(this.loadingBar);
        this.loadingBar = null;
      }
    }
    createCameraAngle(photo,analysisInfo){
      const vertices = [
        -0.45, -0.3, -0.5,   // 왼쪽 아래
        0.45, -0.3, -0.5,    // 오른쪽 아래
        0.45, 0.3, -0.5,     // 오른쪽 위
        -0.45, 0.3, -0.5,    // 왼쪽 위
        0, 0, 0,     // 정점
      ];
    
      const indices = [
        0, 1,
        1, 2,
        2, 3,
        3, 0,
        0, 4,
        1, 4,
        2, 4,
        3, 4,
      ];
    
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
      geometry.setIndex(indices);

      var material;

      let color;
      if(!analysisInfo){
        color = 0xFFFFFF;
      }
      else{
        if(analysisInfo.crackCount != 0){
          color = 0xFF69B4;
        }
        else{
          color = 0x008be1;
        }
      }
    
      material = new THREE.MeshBasicMaterial({ color: color });

      const cameraAngle = new THREE.LineSegments(geometry, material);
  
      cameraAngle.position.set(photo.x,photo.z,-photo.y);
      cameraAngle.rotation.order = 'YXZ';
      cameraAngle.rotation.set(-photo.pitch,photo.yaw-Math.PI/2, 0);
  
      this.scene.add(cameraAngle);
      if(analysisInfo){
        cameraAngle.analysisInfo = true;
        if(analysisInfo.crackCount != 0){
          cameraAngle.isCrack = true;
        }
        else{
          cameraAngle.isCrack = false;
        }
      }
      else{
        cameraAngle.analysisInfo = false;
      }
      cameraAngle.isDot = false;
      cameraAngle.selected = false;
      cameraAngle.albumId = photo.photo.albumId;
      this.cameraAngleList.push(cameraAngle)
    }
    deleteCameraAngle(album){
      let deleteCameraAngleList = this.cameraAngleList.filter(cameraAngle=>cameraAngle.albumId == album.id);
      for(let i=0; i<deleteCameraAngleList.length; i++){
        this.removeObjectFromScene(this.scene,deleteCameraAngleList[i]);
      }
      this.cameraAngleList = this.cameraAngleList.filter(cameraAngle=>cameraAngle.albumId != album.id);
    }  
    selectCameraAngle(number){
      this.cameraAngleList[number].selected = true;
      this.cameraAngleList[number].material.color = new THREE.Color(0x00FD10);
      this.droneModel.position.copy(this.cameraAngleList[number].position);
      this.droneModel.rotation.copy(this.cameraAngleList[number].rotation);
      this.droneModel.rotation.set(0, this.cameraAngleList[number].rotation._y, 0);

      var position = this.cameraAngleList[number].position.clone();
      var direction_vector = this.cameraAngleList[number].getWorldDirection(new THREE.Vector3());
      position.add(direction_vector.multiplyScalar(50));
      this.camera.position.set(position.x,position.y,position.z);
      this.controls.target.set(this.cameraAngleList[number].position.x, this.cameraAngleList[number].position.y, this.cameraAngleList[number].position.z);
      this.controls.update();
    }
    cancelCameraAngle(prenumber){
      let analysisInfo = this.cameraAngleList[prenumber].analysisInfo;
      let isCrack = this.cameraAngleList[prenumber].isCrack;
      this.cameraAngleList[prenumber].selected = false;

      let color;
      if(analysisInfo){
        if(isCrack){
          color = 0xFF69B4;
        }
        else{
          color = 0x008be1;
        }
      }
      else{
        color = 0xFFFFFF;
      }
      this.cameraAngleList[prenumber].material.color = new THREE.Color(color);
      // cameraAngle.scale.set(scaleFactor, scaleFactor, scaleFactor);
    }
    updateCameraAngleCrack(number,isCrack){
      this.cameraAngleList[number].isCrack = isCrack;
    }
    updateDotSizes(){
      let cameraPosition = this.camera.position;
      this.cameraAngleList.forEach(cameraAngle => {
        if(cameraAngle.isDot){
          let distance = cameraPosition.distanceTo(cameraAngle.position);
          let scaleFactor = distance * 0.025; // 거리와 비례한 크기 조정 비율
          cameraAngle.scale.set(scaleFactor, scaleFactor, scaleFactor);
        }
      });
    }
    convertToDot(cameraAngle, index) {
      let selected = this.cameraAngleList[index].selected;
      let isCrack = this.cameraAngleList[index].isCrack;
      let analysisInfo = this.cameraAngleList[index].analysisInfo;
      let sphereGeometry = new THREE.SphereGeometry(0.15, 32, 32); // 구의 크기와 세그먼트 설정
      let color;
      if(selected){
        color = 0x00FD10;  
      }
      else{
        if(analysisInfo){
          if(isCrack){
            color = 0xFF69B4;
          }
          else{
            color = 0x008be1;
          }
        }
        else{
          color = 0XFFFFFF;
        }
      }
      let sphereMaterial = new THREE.MeshBasicMaterial({ color: color});
      let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.copy(cameraAngle.position);
      sphere.rotation.copy(cameraAngle.rotation);
      
      let cameraPosition = this.camera.position;
      let distance = cameraPosition.distanceTo(cameraAngle.position);
      let scaleFactor = distance * 0.025; // 거리와 비례한 크기 조정 비율
      sphere.scale.set(scaleFactor, scaleFactor, scaleFactor);
    
      this.scene.remove(cameraAngle);
      this.scene.add(sphere);
      
      sphere.albumId =  this.cameraAngleList[index].albumId
      this.cameraAngleList[index] = sphere;
      this.cameraAngleList[index].isDot = true;
      if(analysisInfo){
        this.cameraAngleList[index].analysisInfo = true;
        if(isCrack){
          this.cameraAngleList[index].isCrack = true;
        }
        else{
          this.cameraAngleList[index].isCrack = false;
        }
      }
      else{
        this.cameraAngleList[index].analysisInfo = false;
      }

      this.cameraAngleList[index].selected = selected;
    }
    convertToCameraAngle(dot,index) {
      let vertices = [
        -0.45, -0.3, -0.5,   // 왼쪽 아래
        0.45, -0.3, -0.5,    // 오른쪽 아래
        0.45, 0.3, -0.5,     // 오른쪽 위
        -0.45, 0.3, -0.5,    // 왼쪽 위
        0, 0, 0,     // 정점
      ];
    
      let indices = [
        0, 1,
        1, 2,
        2, 3,
        3, 0,
        0, 4,
        1, 4,
        2, 4,
        3, 4,
      ];
    
      let selected = this.cameraAngleList[index].selected;
      let isCrack = this.cameraAngleList[index].isCrack;
      let analysisInfo = this.cameraAngleList[index].analysisInfo;
      let geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
      geometry.setIndex(indices);
      let color;
      if(selected){
        color = 0x00FD10;  
      }
      else{
        if(analysisInfo){
          if(isCrack){
            color = 0xFF69B4;
          }
          else{
            color = 0x008be1;
          }
        }
        else{
          color = 0XFFFFFF;
        }
      }

      let material = new THREE.MeshBasicMaterial({ color: color});

      let cameraAngle = new THREE.LineSegments(geometry, material);
      cameraAngle.position.copy(dot.position);
      cameraAngle.rotation.copy(dot.rotation);

      this.scene.remove(dot);     

      this.scene.add(cameraAngle);

      cameraAngle.albumId = this.cameraAngleList[index].albumId
      this.cameraAngleList[index] = cameraAngle;
      this.cameraAngleList[index].isDot = false;
      if(analysisInfo){
        this.cameraAngleList[index].analysisInfo = true;
        if(isCrack){
          this.cameraAngleList[index].isCrack = true;
        }
        else{
          this.cameraAngleList[index].isCrack = false;
        }
      }
      else{
        this.cameraAngleList[index].analysisInfo = false;
      }
      this.cameraAngleList[index].selected = selected;
    }
    createDroneModel(){
      if(this.droneModel){
        this.scene.remove(this.droneModel);
      }
      this.gltfLoader.load(
        '/drone/scene.gltf',
        (gltf)=>{
          let drone = gltf.scene;
          drone.scale.set(0.1, 0.1, 0.1);
  
          let geometrySphere = new THREE.SphereGeometry(1);
          let materialSphere = new THREE.MeshBasicMaterial({color: "#FFFFFF",
                                                            transparent: true,
                                                            opacity:0.5});
          let sphere= new THREE.Mesh(geometrySphere ,materialSphere );
  
          let droneModel = new THREE.Group();
          droneModel.add(drone);
          droneModel.add(sphere);
          droneModel.position.set(0,0,0);
  
          if(this.scene) this.scene.add(droneModel);
          this.droneModel = droneModel;
        }
      );
    }
    createPointsShader(callback,array,params){
      var positions = new Float32Array(array.length * 3);
      var colors = new Float32Array(array.length * 3);
      let base_value;
      for (let i = 0; i < array.length; i++) {
        if(params.mode == "intensity"){
          base_value = array[i].i/255;
        }// intensity_mode
        else{
          base_value = array[i].y/100;
        }// height_mode
        var value = 1- base_value;
  
        value = Math.max(value, 0);
        value = Math.min(value, 1);
  
        var h = value*5 + 1;
        var k = Math.floor(h);
        var f = h - k;
        if(!(k & 1)){
          f = 1 - f;
        }
  
        var n = 1 - f;
  
        if(k<=1){
          colors[i * 3] = n.toFixed(1);
          colors[i * 3 + 1] = 0;
          colors[i * 3 + 2] = 1;
        }
        else if(k==2){
          colors[i * 3] = 0;
          colors[i * 3 + 1] = n.toFixed(1);
          colors[i * 3 + 2] = 1;
        }
        else if(k==3){
          colors[i * 3] = 0;
          colors[i * 3 + 1] = 1;
          colors[i * 3 + 2] = n.toFixed(1);
        }
        else if(k==4){
          colors[i * 3] = n.toFixed(1);
          colors[i * 3 + 1] = 1;
          colors[i * 3 + 2] = 0;
        }
        else if(k>=5){
          colors[i * 3] = 1;
          colors[i * 3 + 1] = n.toFixed(1);
          colors[i * 3 + 2] = 0;
        }
  
        // x, y, z 값을 배열에 할당
        positions[i * 3] = array[i].x;
        positions[i * 3 + 1] = array[i].y;
        positions[i * 3 + 2] = array[i].z;
  
        // min_x, max_x, min_y, max_y 값 갱신
        if (-array[i].z < this.minX) {
          this.minX = -array[i].z;
        } else if (-array[i].z > this.maxX) {
          this.maxX = -array[i].z;
        }
        if (-array[i].x < this.minY) {
          this.minY = -array[i].x;
        } else if (-array[i].x > this.maxY) {
          this.maxY = -array[i].x;
        }
      }
      const particleGeometry = new THREE.BufferGeometry();
      particleGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      particleGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
      const vertexShader = `
      out vec4 vColor;
      uniform float size;
  
      void main() {
        vColor = vec4(color,0.3);
        gl_PointSize = size;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
      `;
  
      const fragmentShader = `
        in vec4 vColor;
  
        void main() {
          gl_FragColor = vColor;
        }
      `;
  
      // 포인트 클라우드를 그리기 위한 ShaderMaterial 생성
      const shaderMaterial = new THREE.ShaderMaterial({
        vertexShader : vertexShader,
        fragmentShader : fragmentShader,
        transparent :true,
        vertexColors: true,
        uniforms : {
          size: { value : params.pointSize },
        },
      });
  
      // 포인트 클라우드 객체 생성
      const point = new THREE.Points(particleGeometry, shaderMaterial);
      this.scene.add(point);
      callback(point);
    }
    drawLine(x,y,z){
      this.positionsArray.push(x,y,z);
  
      if(this.line != undefined){
        this.scene.remove(this.line);
      }
      
      this.lineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(this.positionsArray), 3));
      var material = new THREE.LineBasicMaterial({ color: 0x00fff2, linewidth: 2 });
      this.line = new THREE.Line(this.lineGeometry, material);
      this.scene.add(this.line);
    }
    raycaster = (event)=>{
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      var rect = this.renderer.domElement.getBoundingClientRect();
    
      // if(this.isMobile){
      //   mouse.x = ((event.touches[0].clientX-rect.left)/(rect.width)) * 2 - 1;
      //   mouse.y = -((event.touches[0].clientY-rect.top) / (rect.height)) * 2 + 1;
      // }
      // else{
      mouse.x = ((event.clientX-rect.left) / (rect.width)) * 2 - 1;
      mouse.y = -((event.clientY-rect.top)  / (rect.height) ) * 2 + 1;
      // }
      // 레이캐스터와 카메라 위치 업데이트
      raycaster.setFromCamera(mouse, this.camera);
    
      // // 레이와 교차하는 객체들의 배열 생성
      var children = raycaster.intersectObjects(this.scene.children);
      let selectedChildren = this.raycasterCallback(children);
      if(selectedChildren){
        let index = this.cameraAngleList.findIndex(cameraAngle => cameraAngle == selectedChildren.object);
        return index
      }
      else{
        return null
      }
    }
    raycasterCallback(data){
      for(let i=0; i<data.length; i++){
        if(data[i].object.type == 'LineSegments' || data[i].object.type == 'Mesh'){
          return data[i];
        }
      }
    }
}

