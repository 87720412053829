export default class Alert{
  constructor(alert){
    this.state = null;
    this.text = null;
    
    if(alert){
      this.state = alert.state;
      this.text = alert.text;
    }
  }
}