export const userListArray = [
  {
    id: 1,
    profileImageUrl: null,
    fullName: "권지민",
    userId: "jmkwon1",
    position: "사원",
    field: "신석기 부서",
    role: "manager",
    contact: "01066513289",
    facility: ["강동 1교", "산성우 1교", "장흥-부산천교"],
    loggedIn: true,
    work: "on",
  },
  {
    id: 2,
    profileImageUrl: "https://picsum.photos/100",
    fullName: "홍길홍",
    userId: "ghhong2",
    position: "대리",
    field: "조선시대 부서",
    role: "manager",
    contact: "01032325656",
    facility: [
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
    ],
    loggedIn: true,
    work: "stay",
  },
  {
    id: 3,
    profileImageUrl: null,
    fullName: "권지민",
    userId: "jmkwon3",
    position: "사원",
    field: "신석기 부서",
    role: "manager",
    contact: "01066513289",
    facility: ["강동 1교", "산성우 1교", "장흥-부산천교"],
    loggedIn: true,
    work: "on",
  },
  {
    id: 4,
    profileImageUrl: "https://picsum.photos/100",
    fullName: "홍길홍",
    userId: "ghhong4",
    position: "대리",
    field: "조선시대 부서",
    role: "manager",
    contact: "01032325656",
    facility: [
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
    ],
    loggedIn: true,
    work: "stay",
  },
  {
    id: 5,
    profileImageUrl: null,
    fullName: "김길동",
    userId: "kkdong5",
    position: null,
    field: null,
    role: "general",
    contact: null,
    facility: null,
    loggedIn: false,
    work: null,
  },
  {
    id: 6,
    profileImageUrl: null,
    fullName: "김길동",
    userId: "kkdong6",
    position: null,
    field: null,
    role: "manager",
    contact: null,
    facility: null,
    loggedIn: false,
    work: null,
  },
  {
    id: 7,
    profileImageUrl: null,
    fullName: "권지민",
    userId: "jmkwon7",
    position: "사원",
    field: "신석기 부서",
    role: "manager",
    contact: "01066513289",
    facility: ["강동 1교", "산성우 1교", "장흥-부산천교"],
    loggedIn: true,
    work: "on",
  },
  {
    id: 8,
    profileImageUrl: "https://picsum.photos/100",
    fullName: "홍길홍",
    userId: "ghhong8",
    position: "대리",
    field: "조선시대 부서",
    role: "manager",
    contact: "01032325656",
    facility: [
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
    ],
    loggedIn: true,
    work: "stay",
  },
  {
    id: 9,
    profileImageUrl: null,
    fullName: "김길동",
    userId: "kkdong9",
    position: null,
    field: null,
    role: "manager",
    contact: null,
    facility: null,
    loggedIn: false,
    work: null,
  },
  {
    id: 10,
    profileImageUrl: null,
    fullName: "권지민",
    userId: "jmkwon10",
    position: "사원",
    field: "신석기 부서",
    role: "manager",
    contact: "01066513289",
    facility: ["강동 1교", "산성우 1교", "장흥-부산천교"],
    loggedIn: true,
    work: "on",
  },
  {
    id: 11,
    profileImageUrl: "https://picsum.photos/100",
    fullName: "홍길홍",
    userId: "ghhong11",
    position: "대리",
    field: "조선시대 부서",
    role: "manager",
    contact: "01032325656",
    facility: [
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
      "장흥-부산천교",
    ],
    loggedIn: true,
    work: "stay",
  },
  {
    id: 12,
    profileImageUrl: null,
    fullName: "김길동",
    userId: "kkdong12",
    position: null,
    field: null,
    role: "manager",
    contact: null,
    facility: null,
    loggedIn: false,
    work: null,
  },
];
