export const countByFacilityId = (arr) => {
  const facilityCountMap = new Map();

  // 배열 순회하면서 facilityId를 기준으로 개수 세기
  arr.forEach((item) => {
    const facilityId = item.facilityId;

    if (facilityCountMap.has(facilityId)) {
      // facilityId가 이미 있으면 count 증가, albums 배열에 item 추가
      const existingData = facilityCountMap.get(facilityId);
      existingData.count += 1;
      existingData.albums.push(item);
    } else {
      // facilityId가 없으면 초기화
      facilityCountMap.set(facilityId, { count: 1, albums: [item] });
    }
  });

  // 결과 배열 생성
  const result = [];
  facilityCountMap.forEach((value, facilityId) => {
    result.push({ facilityId, count: value.count, albums: value.albums });
  });

  return result;
};
