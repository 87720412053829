/**
 *
 * @param {Array} array
 * @returns url이 비워져 있지 않은 요소를 모아서 새로운 배열을 반환한다. (원본 배열은 변경되지 않는다.)
 */

export const filterEmptyMapUrl = (array) => {
  // array가 null이거나 undefined일 경우 빈 배열을 반환
  if (!Array.isArray(array)) {
    return [];
  }

  const newArray = array.filter((map) => map.url !== "");
  return newArray;
};
