<template>
  <div class="pattern_capture_list" :class="{ on: isCaptureList }">
    <div class="pattern_capture_list_title">캡처 목록</div>
    <div class="pattern_capture_list_content">
      <div
        class="pattern_capture_list_item button"
        v-for="(captureInfo, index) in captureInfoList"
        :key="index"
        @click="selectCaptureInfo(index)"
      >
        <div class="pattern_capture_list_item_name">{{ captureInfo.name }}</div>
        <div class="pattern_capture_list_item_subbutton_container">
          <div
            class="pattern_capture_list_item_summary"
            @click.stop="showSummaryModal(index)"
          ></div>
          <div
            class="pattern_capture_list_item_delete"
            @click.stop="deleteCaptureInfo(index)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["selectCaptureInfo", "deleteCaptureInfo", "showSummaryModal"],
  props: {
    isCaptureList: Boolean,
    captureInfoList: Array,
    elevataionSetList: Array,
  },
  methods: {
    selectCaptureInfo(index) {
      this.$emit("selectCaptureInfo", index);
    },
    deleteCaptureInfo(index) {
      if (confirm("해당 캡처를 삭제하시겠습니까?")) {
        this.$emit("deleteCaptureInfo", index);
      } else {
        return 0;
      }
    },
    showSummaryModal(index) {
      this.$emit("showSummaryModal", index);
    },
  },
};
</script>

<style>
  .pattern_capture_list{
    position:fixed;
    top: calc(50% - 250px);
    left: -300px;
    display: flex;
    flex-direction: column;
    background-color: rgba(32,32,32,0.85);
    border: 1px solid rgba(1, 250, 254, 0.3);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    width: 300px;
    height: 500px;
    padding: 10px 10px 10px 10px;
    z-index: 9;
    transition: 1s all;
  }
  .pattern_capture_list.on{
    left: 0px;
    transition: 1s all;
  }
  .pattern_capture_list_title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    color:rgb(255,255,255);
    font-size: 16px;  
  }
  .pattern_capture_list_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    overflow: auto;
  }
  .pattern_capture_list_content::-webkit-scrollbar {
    width: 4px;
  }
  /* 스크롤바 트랙 스타일 */
  .pattern_capture_list_content::-webkit-scrollbar-track {
    background: transparent;    
  }
  /* 스크롤바 손잡이 스타일 */
  .pattern_capture_list_content::-webkit-scrollbar-thumb {
    background: rgba(1,250,254,0.8);
    border-radius: 5px;
  }
  /* 스크롤바 손잡이:hover 스타일 */
  .pattern_capture_list_content::-webkit-scrollbar-thumb:hover {
    background: rgba(1,250,254,1);
  }
  .pattern_capture_list_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    margin-bottom: 10px;
    flex-shrink: 0;
  }
  .pattern_capture_list_item_name {
    flex-grow: 1;
    font-size: 12px;
  }
  .pattern_capture_list_item_subbutton_container
  {
    display: flex;
    width: 60px;
    height: 100%;
    background-color: transparent;
  }

  .pattern_capture_list_item_delete{
    width: 30px;
    height: 100%;
    background: url('@/../public/images/x_white.svg') no-repeat center;
    background-size: 20px 20px;
  }
  .pattern_capture_list_item:hover .pattern_capture_list_item_delete{
    background-image: url('@/../public/images/x_regular.svg');
    background-size: 20px 20px;
  }
  .pattern_capture_list_item:hover .pattern_capture_list_item_delete:hover{
    background-image: url('@/../public/images/x_hover.svg');
    background-size: 20px 20px;
  }

.pattern_capture_list_item_delete {
  width: 30px;
  height: 100%;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
}
.pattern_capture_list_item:hover .pattern_capture_list_item_delete {
  background-image: url("@/../public/images/x_regular.svg");
  background-size: 20px 20px;
}
.pattern_capture_list_item:hover .pattern_capture_list_item_delete:hover {
  background-image: url("@/../public/images/x_hover.svg");
  background-size: 20px 20px;
}

.pattern_capture_list_item_summary{
  width: 30px;
  height: 100%;
  background: url('@/../public/images/summary_white.svg') no-repeat center;
  background-size: 20px 20px;
}
.pattern_capture_list_item:hover .pattern_capture_list_item_summary{
  background-image: url('@/../public/images/summary.svg');
  background-size: 20px 20px;
}
.pattern_capture_list_item:hover .pattern_capture_list_item_summary:hover{
  background-image: url('@/../public/images/summary_hover.svg');
  background-size: 20px 20px;
}
</style>
