import * as THREE from 'three';

export default class BoundingRange {
  constructor(scene2d, scene3d, material2d, material3d, camera2d, control2d, camera3d, control3d) {
    this.scene2d = scene2d;
    this.scene3d = scene3d;
    this.material2d = material2d;
    this.material3d = material3d;
    this.camera2d = camera2d;
    this.control2d = control2d;
    this.camera3d = camera3d;
    this.control3d = control3d;
    this.bounding3DBoxGroup = new THREE.Group();

    this.minHeight = Infinity;
    this.maxHeight = -Infinity;
    this.minWidth = Infinity;
    this.maxWidth = -Infinity;
    this.minDepth = Infinity;
    this.maxDepth = -Infinity;

    this.minHeightPlane = null;
    this.maxHeightPlane = null;
    this.minWidthPlane = null;
    this.maxWidthPlane = null;
    this.minDepthPlane = null;
    this.maxDepthPlane = null;

    this.rangeMinHeight = null;
    this.rangeMaxHeight = null;
    this.rangeMinWidth = null;
    this.rangeMaxWidth = null;
    this.rangeMinDepth = null;

    this.axis =0;
    this.boxLineSegments = null;
  }

  getMinHeight(){return this.minHeight;}
  setMinHeight(height){this.minHeight = height;}
  getMaxHeight(){return this.maxHeight;}
  setMaxHeight(height){this.maxHeight = height;}
  getMinWidth(){return this.minWidth;}
  setMinWidth(width){this.minWidth = width;}
  getMaxWidth(){return this.maxWidth;}
  setMaxWidth(width){this.maxWidth = width;}
  getMinDepth(){return this.minDepth;}
  setMinDepth(depth){this.minDepth = depth;}
  getMaxDepth(){return this.maxDepth;}
  setMaxDepth(depth){this.maxDepth = depth;}

  getRangeMinHeight(){return this.rangeMinHeight;}
  setRangeMinHeight(height){this.rangeMinHeight = height;}
  getRangeMaxHeight(){return this.rangeMaxHeight;}
  setRangeMaxHeight(height){this.rangeMaxHeight = height;}
  getRangeMinWidth(){return this.rangeMinWidth;}
  setRangeMinWidth(width){this.rangeMinWidth = width;}
  getRangeMaxWidth(){return this.rangeMaxWidth;}
  setRangeMaxWidth(width){this.rangeMaxWidth = width;}
  getRangeMinDepth(){return this.rangeMinDepth;}
  setRangeMinDepth(depth){this.rangeMinDepth = depth;}
  getRangeMaxDepth(){return this.rangeMaxDepth;}
  setRangeMaxDepth(depth){this.rangeMaxDepth = depth;}

  setBoundingBoxVisibility(visible){
      this.bounding3DBoxGroup.visible = visible;
  }

  setBounding2DBox(object, radian, initCamera){
    const cameraPosition = this.camera2d.position;
    const controlTarget = this.control2d.target;
    const cameraDistance = cameraPosition.distanceTo(controlTarget);

    const box = new THREE.Box3().setFromObject(object);

    const size = box.getSize(new THREE.Vector3());
    let center = box.getCenter(new THREE.Vector3());

    if(this.boxLineSegments) {
      this.scene2d.remove(this.boxLineSegments);
      this.boxLineSegments = null; 
    }

    if(this.axis==0){
      center.y = 0;

      let rotationMatrix = new THREE.Matrix4();
      rotationMatrix.makeRotationY(radian);
      let center4D = new THREE.Vector4(center.x, center.y, center.z, 1);
      center4D.applyMatrix4(rotationMatrix);
      let rotatedCenter = new THREE.Vector3(center4D.x, center4D.y, center4D.z);

      let cameraPos4D;
      if(initCamera){
        cameraPos4D = new THREE.Vector4(center.x, center.y + Math.max(size.x, size.y, size.z), center.z, 1);
      }
      else{
        cameraPos4D = new THREE.Vector4(center.x, center.y + cameraDistance, center.z, 1);
      }
      cameraPos4D.applyMatrix4(rotationMatrix);
      let rotatedCameraPos = new THREE.Vector3(cameraPos4D.x, cameraPos4D.y, cameraPos4D.z);

      const targetPosition = new THREE.Vector3(rotatedCenter.x, rotatedCenter.y, rotatedCenter.z);

      const xOffset = 0.00001 * Math.sin(radian);
      const zOffset = 0.00001 * Math.cos(radian);

      const newCameraPosition = new THREE.Vector3(
        targetPosition.x + xOffset, 
        rotatedCameraPos.y,  // y값은 회전에 영향을 받지 않음
        targetPosition.z + zOffset
      );

      this.camera2d.position.copy(newCameraPosition);
      this.control2d.target.copy(targetPosition);
      this.control2d.update();

      const boxGeometry = new THREE.BoxGeometry(size.x, 0, size.z);
      const edgesGeometry = new THREE.EdgesGeometry(boxGeometry);
      const boxLineMaterial = new THREE.LineBasicMaterial({color: 0x01fefa});
      this.boxLineSegments = new THREE.LineSegments(edgesGeometry, boxLineMaterial);
      this.boxLineSegments.rotation.y = radian;
      this.boxLineSegments.position.copy(rotatedCenter);
    } 
    else if(this.axis==1){
      center.x=0
      let rotationMatrix = new THREE.Matrix4();
      rotationMatrix.makeRotationY(radian);
      let center4D = new THREE.Vector4(center.x, center.y, center.z, 1);
      center4D.applyMatrix4(rotationMatrix);
      let rotatedCenter = new THREE.Vector3(center4D.x, center4D.y, center4D.z);

      let cameraPos4D;
      if(initCamera){
        cameraPos4D = new THREE.Vector4(center.x + Math.max(size.x, size.y, size.z), center.y, center.z, 1);
      }
      else{
        cameraPos4D = new THREE.Vector4(center.x + cameraDistance, center.y, center.z, 1);
      }
      cameraPos4D.applyMatrix4(rotationMatrix);
      let rotatedCameraPos = new THREE.Vector3(cameraPos4D.x, cameraPos4D.y, cameraPos4D.z);

      this.camera2d.position.set(rotatedCameraPos.x, rotatedCameraPos.y, rotatedCameraPos.z);
      this.control2d.target.set(rotatedCenter.x, rotatedCenter.y, rotatedCenter.z);
      
      this.control2d.update();    

      const boxGeometry = new THREE.BoxGeometry(0, size.y, size.z);
      const edgesGeometry = new THREE.EdgesGeometry(boxGeometry);
      const boxLineMaterial = new THREE.LineBasicMaterial({color: 0x01fefa});
      this.boxLineSegments = new THREE.LineSegments(edgesGeometry, boxLineMaterial);
      this.boxLineSegments.rotation.y = radian;
      this.boxLineSegments.position.copy(rotatedCenter);
    }
    else if(this.axis==2){
      center.z=0

      let rotationMatrix = new THREE.Matrix4();
      rotationMatrix.makeRotationY(radian);
      let center4D = new THREE.Vector4(center.x, center.y, center.z, 1);
      center4D.applyMatrix4(rotationMatrix);
      let rotatedCenter = new THREE.Vector3(center4D.x, center4D.y, center4D.z);

      let cameraPos4D;
      if(initCamera){
        cameraPos4D = new THREE.Vector4(center.x, center.y, center.z + Math.max(size.x, size.y, size.z), 1);
      }
      else{
        cameraPos4D = new THREE.Vector4(center.x, center.y, center.z + cameraDistance, 1);
      }
      cameraPos4D.applyMatrix4(rotationMatrix);
      let rotatedCameraPos = new THREE.Vector3(cameraPos4D.x, cameraPos4D.y, cameraPos4D.z);

      this.camera2d.position.set(rotatedCameraPos.x, rotatedCameraPos.y, rotatedCameraPos.z);
      this.control2d.target.set(rotatedCenter.x, rotatedCenter.y, rotatedCenter.z);
      this.control2d.update();      
      
      const boxGeometry = new THREE.BoxGeometry(size.x, size.y, 0);
      const edgesGeometry = new THREE.EdgesGeometry(boxGeometry);
      const boxLineMaterial = new THREE.LineBasicMaterial({color: 0x01fefa});
      this.boxLineSegments = new THREE.LineSegments(edgesGeometry, boxLineMaterial);
      this.boxLineSegments.rotation.y = radian;
      this.boxLineSegments.position.copy(rotatedCenter);
    }

    this.scene2d.add(this.boxLineSegments);
  }

    
  setBounding3DBox(object, initCamera){    
    const box = new THREE.Box3().setFromObject(object);

    this.minHeight = box.min.y;
    this.maxHeight = box.max.y;
    this.minWidth = box.min.x;
    this.maxWidth = box.max.x;
    this.minDepth = box.min.z;
    this.maxDepth = box.max.z;

    this.material3d.uniforms.minHeight.value = this.minHeight;
    this.material3d.uniforms.maxHeight.value = this.maxHeight;

    const boxHelper = new THREE.Box3Helper(box, 0x01fefa);
    boxHelper.raycast = () => {};
    this.bounding3DBoxGroup.add(boxHelper);

    if(initCamera){
      const size = box.getSize(new THREE.Vector3());
      let center = box.getCenter(new THREE.Vector3());

      this.camera3d.position.set(center.x, center.y + Math.max(size.x, size.z) / 2, center.z + Math.max(size.x, size.y, size.z));
      this.control3d.target.set(center.x, center.y, center.z); 
      this.control3d.update();
    }

    const step = 10;
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0x01fefa });

    for (let axis of ['x', 'y', 'z']) {
      let start = box.min[axis];
      let end = box.max[axis];
      for (let point = start; point <= end; point += step) {
        const points = [];
        const startPoint = new THREE.Vector3();
        const endPoint = new THREE.Vector3();

        startPoint[axis] = point;
        endPoint[axis] = point;
        
        if (axis === 'x') {
          startPoint['y'] = box.min['y'];
          startPoint['z'] = box.min['z'];
          endPoint['y'] = startPoint['y']; 
          endPoint['z'] = startPoint['z'] + 2;
          points.push(startPoint, endPoint);
          let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          let line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['y'] = box.min['y'];
          startPoint['z'] = box.max['z'];
          endPoint['y'] = startPoint['y'] + 2; 
          endPoint['z'] = startPoint['z'];
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['y'] = box.max['y'];
          startPoint['z'] = box.min['z'];
          endPoint['y'] = startPoint['y'] - 2; 
          endPoint['z'] = startPoint['z'] ;
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['y'] = box.max['y'];
          startPoint['z'] = box.max['z'];
          endPoint['y'] = startPoint['y']; 
          endPoint['z'] = startPoint['z'] - 2;
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);
        }
        else if (axis === 'y') {
          startPoint['x'] = box.min['x'];
          startPoint['z'] = box.min['z'];
          endPoint['x'] = startPoint['x'];
          endPoint['z'] = startPoint['z'] + 2; 
          points.push(startPoint, endPoint);
          let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          let line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);
          
          startPoint['x'] = box.min['x'];
          startPoint['z'] = box.max['z'];
          endPoint['x'] = startPoint['x'] + 2;
          endPoint['z'] = startPoint['z']; 
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['x'] = box.max['x'];
          startPoint['z'] = box.min['z'];
          endPoint['x'] = startPoint['x'] - 2;
          endPoint['z'] = startPoint['z']; 
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['x'] = box.max['x'];
          startPoint['z'] = box.max['z'];
          endPoint['x'] = startPoint['x'];
          endPoint['z'] = startPoint['z'] - 2; 		
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);			
        }
        else { // z축
          startPoint['x'] = box.min['x'];
          startPoint['y'] = box.min['y'];
          endPoint['x'] = startPoint['x'] + 2; 
          endPoint['y'] = startPoint['y'];
          points.push(startPoint, endPoint);
          let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          let line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['x'] = box.min['x'];
          startPoint['y'] = box.max['y'];
          endPoint['x'] = startPoint['x']; 
          endPoint['y'] = startPoint['y'] - 2;
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['x'] = box.max['x'];
          startPoint['y'] = box.min['y'];
          endPoint['x'] = startPoint['x']; 
          endPoint['y'] = startPoint['y'] + 2;
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);

          startPoint['x'] = box.max['x'];
          startPoint['y'] = box.max['y'];
          endPoint['x'] = startPoint['x'] - 2; 
          endPoint['y'] = startPoint['y'];
          points.push(startPoint, endPoint);
          lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          line = new THREE.Line(lineGeometry, lineMaterial);
          line.raycast = () => {};
          this.bounding3DBoxGroup.add(line);
        }
      }
    }

    const boxSize = new THREE.Vector3();
    box.getSize(boxSize);

    const boxCenter = new THREE.Vector3();
    box.getCenter(boxCenter);
    
    const planeGeometryHeight = new THREE.PlaneGeometry(boxSize.x, boxSize.z);
    const planeGeometryWidth = new THREE.PlaneGeometry(boxSize.y, boxSize.z);
    const planeGeometryDepth = new THREE.PlaneGeometry(boxSize.x, boxSize.y);
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: 0x015efe,
      transparent: true,
      opacity: 0.25,
      side: THREE.DoubleSide 
    });

    this.minHeightPlane = new THREE.Mesh(planeGeometryHeight, planeMaterial);
    this.minHeightPlane.position.x = boxCenter.x;
    this.minHeightPlane.position.z = boxCenter.z;
    this.rangeMinHeight  = 0;
    this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;		
    this.minHeightPlane.rotation.x = -Math.PI / 2;

    this.maxHeightPlane = new THREE.Mesh(planeGeometryHeight, planeMaterial);
    this.maxHeightPlane.position.x = boxCenter.x;
    this.maxHeightPlane.position.z = boxCenter.z;
    this.rangeMaxHeight  = this.maxHeight-this.minHeight;
    this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
    this.maxHeightPlane.rotation.x = -Math.PI / 2;

    this.minWidthPlane = new THREE.Mesh(planeGeometryWidth, planeMaterial);
    this.minWidthPlane.position.y = boxCenter.y;
    this.minWidthPlane.position.z = boxCenter.z;
    this.rangeMinWidth  = 0;
    this.minWidthPlane.position.x = this.minWidth + this.rangeMinWidth;
    this.minWidthPlane.rotation.y = -Math.PI / 2;
    this.minWidthPlane.rotation.z = -Math.PI / 2;

    this.maxWidthPlane = new THREE.Mesh(planeGeometryWidth, planeMaterial);
    this.maxWidthPlane.position.y = boxCenter.y;
    this.maxWidthPlane.position.z = boxCenter.z;
    this.rangeMaxWidth  = this.maxWidth-this.minWidth;
    this.maxWidthPlane.position.x = this.minWidth + this.rangeMaxWidth;
    this.maxWidthPlane.rotation.y = -Math.PI / 2;
    this.maxWidthPlane.rotation.z = -Math.PI / 2;

    this.minDepthPlane = new THREE.Mesh(planeGeometryDepth, planeMaterial);
    this.minDepthPlane.position.x = boxCenter.x;
    this.minDepthPlane.position.y = boxCenter.y;
    this.rangeMinDepth  = 0;
    this.minDepthPlane.position.z = this.maxDepth - this.rangeMinDepth;		
    
    this.maxDepthPlane = new THREE.Mesh(planeGeometryDepth, planeMaterial);
    this.maxDepthPlane.position.x = boxCenter.x;
    this.maxDepthPlane.position.y = boxCenter.y;
    this.rangeMaxDepth  = this.maxDepth-this.minDepth;
    this.maxDepthPlane.position.z = this.maxDepth - this.rangeMaxDepth;		

    this.bounding3DBoxGroup.add(this.minHeightPlane);
    this.bounding3DBoxGroup.add(this.maxHeightPlane);
    this.bounding3DBoxGroup.add(this.minWidthPlane);
    this.bounding3DBoxGroup.add(this.maxWidthPlane);
    this.bounding3DBoxGroup.add(this.minDepthPlane);
    this.bounding3DBoxGroup.add(this.maxDepthPlane);
  
    this.scene3d.add(this.bounding3DBoxGroup);

    this.minHeightPlane.raycast = () => {};
    this.maxHeightPlane.raycast = () => {};
    this.minWidthPlane.raycast = () => {};
    this.maxWidthPlane.raycast = () => {};
    this.minDepthPlane.raycast = () => {};
    this.maxDepthPlane.raycast = () => {};
  }

  calculateBoundingRectangle(geometry) {
    let minX = Infinity, maxX = -Infinity, minZ = Infinity, maxZ = -Infinity;

    // geometry.attributes.position.array는 Float32Array 형태로 모든 점의 x, y, z 좌표를 담고 있습니다.
    const positions = geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
      const y = positions[i + 1]; // y 좌표는 배열에서 i+1 위치에 있습니다.

      if (y >= this.rangeMinHeight && y <= this.rangeMaxHeight) {
        const x = positions[i]; // x 좌표
        const z = positions[i + 2]; // z 좌표

        // x와 z 좌표에 대한 최소 및 최대 값을 업데이트합니다.
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minZ = Math.min(minZ, z);
        maxZ = Math.max(maxZ, z);
      }
    }

    // 계산된 최소 및 최대 x, z 값이 바운딩 렉탱글의 코너를 정의합니다.
    if (minX === Infinity || maxX === -Infinity || minZ === Infinity || maxZ === -Infinity) {
      // 만약 주어진 y 값 범위 내에 포인트가 없다면, undefined를 반환합니다.
      return undefined;
    }
    else {
      return { minX, maxX, minZ, maxZ };
    }
  }

  redrawBoundingRectangle(geometry){
    const boundingRectangle = this.calculateBoundingRectangle(geometry);
    if (boundingRectangle) {
      this.bounding2DBoxGroup.remove(this.boxLineSegments);
      const width = boundingRectangle.maxX - boundingRectangle.minX;
      const height = boundingRectangle.maxZ - boundingRectangle.minZ; 
      const centerX = (boundingRectangle.maxX + boundingRectangle.minX) / 2;
      const centerZ = (boundingRectangle.maxZ + boundingRectangle.minZ) / 2;

      const boxGeometry = new THREE.BoxGeometry(width, 0, height);
      const edgesGeometry = new THREE.EdgesGeometry(boxGeometry);
      const boxLineMaterial = new THREE.LineBasicMaterial({color: 0x01fefa});
      this.boxLineSegments = new THREE.LineSegments(edgesGeometry, boxLineMaterial);

      this.boxLineSegments.position.set(centerX, 0, centerZ);
      this.bounding2DBoxGroup.add(this.boxLineSegments);
    } else {
      console.log('No points found in the specified y range.');
    }
  }

  resetMinMaxRange(){
    this.rangeMinHeight = 0;
    this.rangeMaxHeight = this.maxHeight - this.minHeight;

    this.rangeMinWidth = 0;
    this.rangeMaxWidth = this.maxWidth - this.minWidth;

    this.rangeMinDepth = 0;
    this.rangeMaxDepth = this.maxDepth - this.minDepth;
  }

  setAxis(id){
    this.axis = id;
    this.material2d.uniforms.axis.value = id;
    this.material3d.uniforms.axis.value = id;
    
    if(this.axis==0){
      this.camera2d.position.set(0, 100, 0); //xy
      this.material2d.uniforms.lowerHeight.value=this.minHeight + this.rangeMinHeight;
      this.material2d.uniforms.upperHeight.value=this.minHeight + this.rangeMaxHeight;
      this.material3d.uniforms.lowerHeight.value=this.minHeight + this.rangeMinHeight;
      this.material3d.uniforms.upperHeight.value=this.minHeight + this.rangeMaxHeight;
      this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;
      this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
    }
    else if(this.axis==1){
      this.camera2d.position.set(100, 0, 0); //xz
      this.material2d.uniforms.lowerHeight.value=this.minWidth + this.rangeMinWidth;
      this.material2d.uniforms.upperHeight.value=this.minWidth + this.rangeMaxWidth;
      this.material3d.uniforms.lowerHeight.value=this.minWidth + this.rangeMinWidth;
      this.material3d.uniforms.upperHeight.value=this.minWidth + this.rangeMaxWidth;
      this.minWidthPlane.position.x =  this.minWidth + this.rangeMinWidth;
      this.maxWidthPlane.position.x =  this.minWidth + this.rangeMaxWidth;
    }
    else if(this.axis==2){
      this.camera2d.position.set(0, 0, 100); //yz
      this.material2d.uniforms.lowerHeight.value=this.maxDepth - this.rangeMaxDepth;
      this.material2d.uniforms.upperHeight.value=this.maxDepth - this.rangeMinDepth;
      this.material3d.uniforms.lowerHeight.value=this.maxDepth - this.rangeMaxDepth;
      this.material3d.uniforms.upperHeight.value=this.maxDepth - this.rangeMinDepth;
      this.minDepthPlane.position.z =  this.maxDepth - this.rangeMinDepth;
      this.maxDepthPlane.position.z =  this.maxDepth - this.rangeMaxDepth;
    }
    this.setPlaneVisible(this.axis);
  }

  applyRange(range){      
    if(range.minHeight < 0) this.rangeMinHeight  = 0;
    else this.rangeMinHeight  = range.minHeight;
    if(range.maxHeight > this.maxHeight-this.minHeight) this.rangeMaxHeight  = this.maxHeight-this.minHeight;
    else this.rangeMaxHeight  = range.maxHeight;

    if(range.minWidth < 0) this.rangeMinWidth  = 0;
    else this.rangeMinWidth  = range.minWidth;
    if(range.maxWidth > this.maxWidth-this.minWidth) this.rangeMaxWidth  = this.maxWidth-this.minWidth;
    else this.rangeMaxWidth = range.maxWidth;

    if(range.minDepth < 0) this.rangeMinDepth  = 0;
    else this.rangeMinDepth  = range.minDepth;
    if(range.maxDepth > this.maxDepth-this.minDepth) this.rangeMaxDepth  = this.maxDepth-this.minDepth;
    else this.rangeMaxDepth = range.maxDepth;

    if(this.axis==0){
      this.material2d.uniforms.lowerHeight.value=this.minHeight + this.rangeMinHeight;
      this.material2d.uniforms.upperHeight.value=this.minHeight + this.rangeMaxHeight;
      this.material3d.uniforms.lowerHeight.value=this.minHeight + this.rangeMinHeight;
      this.material3d.uniforms.upperHeight.value=this.minHeight + this.rangeMaxHeight;
      this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;
      this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
    }
    else if(this.axis==1){
      this.material2d.uniforms.lowerHeight.value=this.minWidth + this.rangeMinWidth;
      this.material2d.uniforms.upperHeight.value=this.minWidth + this.rangeMaxWidth;
      this.material3d.uniforms.lowerHeight.value=this.minWidth + this.rangeMinWidth;
      this.material3d.uniforms.upperHeight.value=this.minWidth + this.rangeMaxWidth;
      this.minWidthPlane.position.x =  this.minWidth + this.rangeMinWidth;
      this.maxWidthPlane.position.x =  this.minWidth + this.rangeMaxWidth;
    }
    else if(this.axis==2){
      this.material2d.uniforms.lowerHeight.value=this.maxDepth - this.rangeMaxDepth;
      this.material2d.uniforms.upperHeight.value=this.maxDepth - this.rangeMinDepth;
      this.material3d.uniforms.lowerHeight.value=this.maxDepth - this.rangeMaxDepth;
      this.material3d.uniforms.upperHeight.value=this.maxDepth - this.rangeMinDepth;
      this.minDepthPlane.position.z =  this.maxDepth - this.rangeMaxDepth;
      this.maxDepthPlane.position.z =  this.maxDepth - this.rangeMinDepth;
    }

    this.setPlaneVisible(this.axis);
  }

  setPlaneVisible(axis){
    if(axis==0){
      // this.minHeightPlane.visible = true;
      // this.maxHeightPlane.visible = true;
      this.minHeightPlane.visible = false;
      this.maxHeightPlane.visible = false;
      this.minWidthPlane.visible = false;
      this.maxWidthPlane.visible = false;
      this.minDepthPlane.visible = false;
      this.maxDepthPlane.visible = false;
    }
    else if(axis==1){
      this.minHeightPlane.visible = false;
      this.maxHeightPlane.visible = false;
      // this.minWidthPlane.visible = true;
      // this.maxWidthPlane.visible = true;
      this.minWidthPlane.visible = false;
      this.maxWidthPlane.visible = false;
      this.minDepthPlane.visible = false;
      this.maxDepthPlane.visible = false;
    }
    else if(axis==2){
      this.minHeightPlane.visible = false;
      this.maxHeightPlane.visible = false;
      this.minWidthPlane.visible = false;
      this.maxWidthPlane.visible = false;
      // this.minDepthPlane.visible = true;
      // this.maxDepthPlane.visible = true;
      this.minDepthPlane.visible = false;
      this.maxDepthPlane.visible = false;
    }
    else{
      this.minHeightPlane.visible = false;
      this.maxHeightPlane.visible = false;
      this.minWidthPlane.visible = false;
      this.maxWidthPlane.visible = false;
      this.minDepthPlane.visible = false;
      this.maxDepthPlane.visible = false;
    }
  }

  removeBoundingBox(){
    while (this.bounding3DBoxGroup.children.length) {
      const child = this.bounding3DBoxGroup.children[0];
      this.bounding3DBoxGroup.remove(child);
    }		
  }
}