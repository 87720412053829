export const vertexShader2d = `  
  out vec4 vColor;
  uniform float lowerHeight;
  uniform float upperHeight;
  uniform mat4 customModelMatrix;
  uniform int axis;

  void main() {
    vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
    vec3 vWorldPosition = worldPosition.xyz;
    
    if(axis==1){
      if(vWorldPosition.x >= lowerHeight && vWorldPosition.x <= upperHeight){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,1.0);
      }
      else{
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.0);
      }
    }
    else if(axis==2){
      if(vWorldPosition.z >= lowerHeight && vWorldPosition.z <= upperHeight){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,1.0);
      }
      else{
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.0);
      }
    }
    else {
      if(vWorldPosition.y >= lowerHeight && vWorldPosition.y <= upperHeight){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,1.0);
      }
      else{
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.0);
      }
    }

    vec4 mvPosition;
    if(axis == 2) {
      mvPosition = modelViewMatrix *  vec4(worldPosition.x, worldPosition.y, -0.001, 1.0);
    }
    else if (axis == 1) {
      mvPosition = modelViewMatrix *  vec4(-0.001, worldPosition.y, worldPosition.z, 1.0);
    }
    else if (axis == 0) {
      mvPosition = modelViewMatrix *  vec4(worldPosition.x, -0.001, worldPosition.z, 1.0);
    }
    gl_Position = projectionMatrix * mvPosition;
  }
`;

export const vertexShader3d = `  
  out vec4 vColor;
  uniform float lowerHeight;
  uniform float upperHeight;
  uniform float minHeight;
  uniform float maxHeight;
  uniform int axis;
  uniform mat4 customModelMatrix;
  uniform bool colorMap;

  vec3 calculateColor(float intensity) {
    float value = 1.0 - intensity;
    value = clamp(value, 0.0, 1.0);
    float h = value * 5.0 + 1.0;
    float k = floor(h);
    float f = h - k;
    if (int(k) % 2 == 0) {
      f = 1.0 - f;
    }
    float n = 1.0 - f;
    if (k <= 1.0) {
      return vec3(n, 0.0, 1.0);
    }
    else if (k == 2.0) {
      return vec3(0.0, n, 1.0);
    }
    else if (k == 3.0) {
      return vec3(0.0, 1.0, n);
    }
    else if (k == 4.0) {
      return vec3(n, 1.0, 0.0);
    }
    else {
      return vec3(1.0, n, 0.0);
    }
  }
  
  void main() {
    
    vec3 colors[7];
    colors[0] = vec3(0.65, 0.4, 0.65); // 진한 파스텔 보라색
    colors[1] = vec3(0.65, 0.45, 0.65); // 진한 파스텔 남색
    colors[2] = vec3(0.4, 0.4, 0.9);   // 진한 파스텔 파란색
    colors[3] = vec3(0.4, 0.8, 0.4);   // 진한 파스텔 초록색
    colors[4] = vec3(0.8, 0.8, 0.4);   // 진한 파스텔 노란색
    colors[5] = vec3(0.8, 0.6, 0.4);   // 진한 파스텔 주황색
    colors[6] = vec3(0.8, 0.4, 0.4);   // 진한 파스텔 빨간색

    vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
    vec3 vWorldPosition = worldPosition.xyz;
      
    float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);

    float section = heightFactor * 6.0;
    int idx = int(section);
    float fract = section - float(idx);
    vec3 heightColor;
    if (idx < 6) {
      heightColor = mix(colors[idx], colors[idx + 1], fract);
    } else {
      heightColor = colors[6];
    }
    
    if(axis==1){
      if(vWorldPosition.x >= lowerHeight && vWorldPosition.x <= upperHeight){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.8);
      }
      else{
        gl_PointSize = 1.0;
        vec3 color = calculateColor(intensity / 255.0);
        if(colorMap) vColor = vec4(heightColor, 0.3);
        else vColor = vec4(color, 0.3);
      }
    }
    else if(axis==2){
      if(vWorldPosition.z >= lowerHeight && vWorldPosition.z <= upperHeight){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.8);
      }
      else{      
        gl_PointSize = 1.0;
        vec3 color = calculateColor(intensity / 255.0);
        if(colorMap) vColor = vec4(heightColor, 0.3);
        else vColor = vec4(color, 0.3);
      }
    }
    else{
      if(vWorldPosition.y >= lowerHeight && vWorldPosition.y <= upperHeight){     
        gl_PointSize = 2.0;
        vColor = vec4(1.0,1.0,1.0,0.8);
      }
      else{      
        gl_PointSize = 1.0;
        vec3 color = calculateColor(intensity / 255.0);
        if(colorMap) vColor = vec4(heightColor, 0.3);
        else vColor = vec4(color, 0.3);
      }
    }

    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

// Fragment Shader
export const fragmentShader = `
  in vec4 vColor;
  
  void main() {        
    gl_FragColor = vColor;
  }
`;