<template>
  <div class="report_hard_volume_wrap">
    <div class="report_hard_volume_title">서버 용량</div>
    <div class="report_hard_volume_gage_wrap">
      <div class="report_hard_volume_gage" :style="volumeStyle"></div>
    </div>
    <div class="report_hard_volume_text">{{totalVolume}}GB 중 {{remainVolume}}GB 사용 가능</div>
  </div>
</template>

<script>
export default {
  props: {
    volume: Object,
  },
  computed: {
    volumeStyle() {
      if (!this.volume || !this.volume.maxStorageSize) {
        return {
          width: "0%",
          backgroundColor: "rgb(172, 172, 172)",
        };
      }

      let percentage = this.volume.maxStorageSize ? Math.round(this.volume.currentUsage/this.volume.maxStorageSize*100) : 0;
      percentage = Math.min(percentage,100);
      let color = percentage<80? 'rgb(1, 254, 250)' : 'red';

      return {
        width: percentage + "%",
        backgroundColor: color,
      };
    },
    remainVolume(){
      let remainVolume = Math.round((this.volume.maxStorageSize - this.volume.currentUsage)/1073741824);
      remainVolume = Math.max(0, remainVolume);
      return remainVolume
    },
    totalVolume(){
      let totalVolume = Math.round(this.volume.maxStorageSize/1073741824);
      return totalVolume
    },
  }
}
</script>

<style lang="scss">
.report_hard_volume_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
}
.report_hard_volume_title {
  @include flexbox;
  width: 100%;
  height: 15px;
  color: $main_white;
  font-size: 15px;
  margin-bottom: 5px;
}
.report_hard_volume_gage_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 10px;
  border: 1px solid rgb(145, 145, 145);
  border-radius: 3px;
  background-color: rgb(172, 172, 172);
}
.report_hard_volume_gage {
  width: 0px;
  height: 8px;
  border-radius: 3px;
}
.report_hard_volume_text {
  @include flexbox;
  width: 100%;
  height: 15px;
  color: $main_white;
  font-size: 1.4rem;
  margin-top: 5px;
}
</style>
