export default class NetworkMapSummary{
    constructor(networMapSummary){
        this.id = null;
        this.inspectionNetworkMapId = null;
        this.type = null;
        this.count = null;
        this.quantity = null;
        this.priority = null;
        this.repair_method = null;
        this.createdDatetime = null;
        this.wrDatetime = null;        

        if(networMapSummary){
            this.id = networMapSummary.id;
            this.inspectionNetworkMapId = networMapSummary.inspectionNetworkMapId;
            this.type = networMapSummary.type;
            this.count = networMapSummary.count;
            this.quantity = networMapSummary.quantity;
            this.priority = networMapSummary.priority;
            this.repair_method = networMapSummary.repair_method;
            this.createdDatetime = networMapSummary.createdDatetime;
            this.wrDatetime = networMapSummary.wrDatetime;
        }
    }
}