/**
 * 앨범 배열에 Running 상태가 하나라도 들어가있으면 true
 * 아니면 false 반환.
 * @param {Array} arr
 * @returns boolean
 */

export const isRunningState = (arr) => {
  if (arr && arr !== null) {
    arr.map((item) => {
      switch (item.status) {
        case 'Running':
          return true;
        default:
          return false;
      }
    });
  }

  return;
};
