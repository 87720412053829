export default class PointCloudParams{
  constructor(pointCloudParams){
    this.mode = "intensity";
    this.downSampling = 1;
    this.pointSize = 1.0;
    this.opacity = 0.3;
    this.height = {
      min : 0,
      max : 100.0,
    }
    if(pointCloudParams){
      this.mode = pointCloudParams.mode;
      this.downSampling = pointCloudParams.downSampling;
      this.pointSize = pointCloudParams.pointSize;
      this.opacity = pointCloudParams.opacity;
      this.height = {
        min : pointCloudParams.height.min,
        max : pointCloudParams.height.max,
      } 
    }
  }
}