/**
 * 아이템 사이 사이 마다 쉽표를 넣어주는 함수.
 * 마지막 아이템 뒤에는 쉼표가 붙지 않는다.
 * @param {Array} arr
 */
export const formatArrayWithCommas = (arr) => {
  return arr.map((item, index) => {
    // 마지막 아이템에는 쉼표를 붙이지 않음
    if (index === arr.length - 1) {
      return item;
    } else {
      return item + ", ";
    }
  });
};
