import {
	Vector3, 
	TubeGeometry, 
	MeshBasicMaterial, 
	SphereGeometry,
	Mesh,
	LineCurve3
} from 'three';


export default class VolumeMeasure {
	constructor(scene, modelGroup) {
		this.scene = scene;
		this.modelGroup = modelGroup;
		this.volumeLinePoints  = [];
		this.volumeLines  = [];
		this.volumeLineCount = 0;  
	}

	increaseVolumeLineCount(){this.volumeLineCount++;}
	decreaseVolumeLineCount(){this.volumeLineCount--;}
	resetVolumeLineCount(){this.volumeLineCount=0;}
	getVolumeLineCount(){return this.volumeLineCount;}

	pushVolumeLinePoints(point){this.volumeLinePoints.push(point);}
	getVolumeLinePoint(index){return this.volumeLinePoints[index];}
	resetVolumeLinePoints(){this.volumeLinePoints = [];}
	getNumLinePoints(){return this.volumeLinePoints.length;}

	getVolumeLine(index){return this.volumeLines[index];}
	resetVolumeLines(){this.volumeLines = [];}
	getNumVolumeLines(){return this.volumeLines.length;}
	
	drawVolumeLine(startPoint, endPoint, save){        
		const path = new LineCurve3(
			new Vector3(startPoint.x, startPoint.y, startPoint.z),
			new Vector3(endPoint.x, endPoint.y, endPoint.z)
		);		
				
		const tubeRadius = 0.05; 		
		const tubeGeometry = new TubeGeometry(path, 64, tubeRadius, 10, false);
		const tubeMaterial = new MeshBasicMaterial({ color: 0xffffff});

		const sphereGeometry = new SphereGeometry(tubeRadius*2, 32, 32);
		const sphereMaterial = new MeshBasicMaterial({ color: 0xffffff });

		const tube = new Mesh(tubeGeometry, tubeMaterial);
		const startSphere = new Mesh(sphereGeometry, sphereMaterial);
		const endSphere = new Mesh(sphereGeometry, sphereMaterial);

		tube.raycast = () => {};
		startSphere.raycast = () => {};
		endSphere.raycast = () => {}; 

		startSphere.position.copy(startPoint);
		endSphere.position.copy(endPoint);

		if(this.volumeLines.length == this.volumeLineCount){
			this.scene.add(tube);
			this.scene.add(startSphere);
			this.scene.add(endSphere);

			this.volumeLines.push({tube: tube, startSphere: startSphere, endSphere: endSphere});
		}
		else{
			const lastLine = this.volumeLines[this.volumeLines.length - 1];
			this.scene.remove(lastLine.tube);
			lastLine.tube.geometry.dispose();
			lastLine.tube.material.dispose();
			this.scene.remove(lastLine.startSphere);
			lastLine.startSphere.geometry.dispose();
			lastLine.startSphere.material.dispose();
			this.scene.remove(lastLine.endSphere);
			lastLine.endSphere.geometry.dispose();
			lastLine.endSphere.material.dispose();

			if(save){				
				this.modelGroup.add(tube);
				this.modelGroup.add(startSphere);
				this.modelGroup.add(endSphere);
			}
			else{				
				this.scene.add(tube);
				this.scene.add(startSphere);
				this.scene.add(endSphere);
			}
			this.volumeLines[this.volumeLines.length - 1] = {tube: tube, startSphere: startSphere, endSphere: endSphere};
		}
	}

	removeDrawings(polyCroppingStart){
		for(let i=0; i<this.getNumVolumeLines(); i++){			
			const tempLine = this.getVolumeLine(i);
			if(i==this.getNumVolumeLines()-1){
				if(polyCroppingStart){
					this.scene.remove(tempLine.tube);
					this.scene.remove(tempLine.startSphere);
					this.scene.remove(tempLine.endSphere);
				}
				else{
					this.modelGroup.remove(tempLine.tube);
					this.modelGroup.remove(tempLine.startSphere);
					this.modelGroup.remove(tempLine.endSphere);	
				}
			}
			else{		
				this.modelGroup.remove(tempLine.tube);
				this.modelGroup.remove(tempLine.startSphere);
				this.modelGroup.remove(tempLine.endSphere);
			}
		
			tempLine.tube.geometry.dispose();
			tempLine.tube.material.dispose();
			tempLine.startSphere.geometry.dispose();
			tempLine.startSphere.material.dispose();
			tempLine.endSphere.geometry.dispose();
			tempLine.endSphere.material.dispose();
		}
		this.resetVolumeLines();
		this.resetVolumeLinePoints();
		this.resetVolumeLineCount();		
	}
}