<template>
  <div class="measure_info">
    <div class="measure_info_site_title">시설물</div>
    <div
      class="measure_info_site_content"
      v-if="selectedSite"
      :style="{ width: getWidth(selectedSite.name) }"
    >
      {{ selectedSite.name }}
    </div>
    <div class="measure_info_current_map_content">
      <div class="measure_info_current_map_name">선택된 맵</div>
      <div class="measure_info_current_map_container" v-if="selectedSite.map">
        {{ selectedSite.map.createdDatetime }}
      </div>
    </div>
    <div class="measure_info_button_wrap">
      <div
        class="measure_info_button button"
        @click="changeMethod('length')"
        :class="{ on: measureMethod == 'length' }"
      >
        <div class="measure_info_length_img"></div>
        <div class="measure_info_button_title">길이</div>
      </div>
      <div
        class="measure_info_button button"
        @click="changeMethod('area')"
        :class="{ on: measureMethod == 'area' }"
      >
        <div class="measure_info_area_img"></div>
        <div class="measure_info_button_title">면적</div>
      </div>
      <div
        class="measure_info_button button"
        @click="changeMethod('volume')"
        :class="{ on: measureMethod == 'volume' }"
      >
        <div class="measure_info_volume_img"></div>
        <div class="measure_info_button_title">부피</div>
      </div>
      <div
        class="measure_info_button button"
        @click="changeMethod('angle')"
        :class="{ on: measureMethod == 'angle' }"
      >
        <div class="measure_info_angle_img"></div>
        <div class="measure_info_button_title">각도</div>
      </div>
      <div class="measure_info_button button" @click="$emit('deleteMeasure')">
        <div class="measure_info_trash_img"></div>
        <div class="measure_info_button_title">삭제</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  emits: ["changeMethod", "deleteMeasure"],
  computed: {
    ...mapState(["user", "siteList", "selectedSite"]),
    getWidth() {
      return (siteName) => {
        if (siteName) {
          return siteName.length * 18 + 10 + "px";
        } else {
          return "70px";
        }
      };
    },
  },
  props: {
    measureMethod: String,
  },
  methods: {
    changeMethod(method) {
      this.$emit("changeMethod", method);
    },
  },
};
</script>

<style lang="scss">
.measure_info {
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 265px;
  margin: 15px 0px 15px 15px;
  padding: 20px;
  background: rgba(23, 23, 23, 0.85);
  border-radius: 10px;
}
.measure_info_site_title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: $main_white;
}
.measure_info_site_content {
  @include flexbox;
  width: 100%;
  height: 30px;
  color: $main_white;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.measure_info_current_map_content {
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.measure_info_current_map_name {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  color: $main_white;
  font-size: 1.6rem;
}
.measure_info_current_map_container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0px 10px 0px 10px;
}

.inspection_currnet_map {
  margin-left: 5px;
  color: $main_color;
  font-size: 1.6rem;
  font-weight: bold;
}
.measure_info_button_wrap {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.measure_info_button {
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 100%;
  font-size: 1.2rem;
  font-weight: bold;
}
.measure_info_button_title {
  @include flexbox;
  width: 100%;
  height: 20px;
}
.measure_info_length_img {
  width: 100%;
  height: calc(100% - 20px);
  background-image: url("@/../public/images/ruler.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button:hover .measure_info_length_img {
  background-image: url("@/../public/images/ruler_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button.on .measure_info_length_img {
  background-image: url("@/../public/images/ruler_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_area_img {
  width: 100%;
  height: calc(100% - 20px);
  background-image: url("@/../public/images/draw_square.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button:hover .measure_info_area_img {
  background-image: url("@/../public/images/draw_square_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button.on .measure_info_area_img {
  background-image: url("@/../public/images/draw_square_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_volume_img {
  width: 100%;
  height: calc(100% - 20px);
  background-image: url("@/../public/images/cube.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button:hover .measure_info_volume_img {
  background-image: url("@/../public/images/cube_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button.on {
  cursor: pointer;
}
.measure_info_button.on .measure_info_volume_img {
  background-image: url("@/../public/images/cube_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_angle_img {
  width: 100%;
  height: calc(100% - 20px);
  background-image: url("@/../public/images/angle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button:hover .measure_info_angle_img {
  background-image: url("@/../public/images/angle_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button.on .measure_info_angle_img {
  background-image: url("@/../public/images/angle_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_trash_img {
  width: 100%;
  height: calc(100% - 20px);
  background-image: url("@/../public/images/trash.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.measure_info_button:hover .measure_info_trash_img {
  background-image: url("@/../public/images/trash_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
</style>
