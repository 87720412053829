export default class Drone{
  constructor(drone){
    this.id = null;
    this.affiliationId = null;
    this.droneSerialNumber = null;
    this.droneStatus = null;
    this.port = null;
    if(drone){
      this.id = drone.id;
      this.affiliationId = drone.affiliationId;
      this.droneSerialNumber = drone.droneSerialNumber;
      this.droneStatus = drone.droneStatus;
      this.port = drone.port;
    }
  }
}