<template>
  <div class="report_editor_crack_info_wrap">
    <div class="report_editor_crack_info_header">
      <div class="report_editor_crack_info_x" @click="closeEditor"></div>
    </div>
    <div class="report_editor_crack_info_body">
      <div class="report_editor_crack_info_body_title">
        <div
          class="report_editor_crack_info_check"
          :class="{ active: checkAll }"
          @click="changeCheckAll"
        ></div>
        <div class="report_editor_crack_info_no">순번</div>
        <div class="report_editor_crack_info_type">종류</div>
        <div class="report_editor_crack_info_thickness">두께</div>
        <div class="report_editor_crack_info_length">길이</div>
        <div class="report_editor_crack_info_distance">거리</div>
      </div>
      <div class="report_editor_crack_info_conetent_wrap">
        <div
          class="report_editor_crack_info_conetent"
          v-for="(info, index) in infos"
          :key="index"
        >
          <div
            class="report_editor_crack_info_check"
            :class="{ active: checkArray[index] }"
            @click="clickCheckBox(index)"
          ></div>
          <div class="report_editor_crack_info_no">{{ index + 1 }}</div>
          <div class="report_editor_crack_info_type">
            {{ translateInfoType(info.type) }}
          </div>
          <div class="report_editor_crack_info_thickness">
            {{ getCrackWidth(info.crack_width, info.distance, info.type) }} mm
          </div>
          <div class="report_editor_crack_info_length">
            {{ getCrackLength(info.crack_length, info.distance) }} m
          </div>
          <div class="report_editor_crack_info_distance">
            {{ parseFloat(info.distance).toFixed(3) }} m
          </div>
        </div>
      </div>
    </div>
    <div
      class="report_editor_crack_info_button_wrap"
      v-if="selectedSegmentation"
    >
      <div
        class="report_editor_crack_info_button button"
        @click="analysisAdd"
        :class="{ on: drawState }"
      >
        {{ drawState ? "확인" : "추가" }}
      </div>
      <div class="report_editor_crack_info_button button" @click="analysisSave">
        저장
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
    "closeReportEditor",
    "changeDrawState",
    "changeAlert",
    "changeDrawModalState",
    "drawCrackAll",
    "drawCrack",
    "deleteCrackAll",
    "deleteCrack",
    "analysisSave",
    "openClassModal",
  ],
  props: {
    infos: Array,
    drawState: String,
    selectedCamera: Object,
    selectedSegmentation: Object,
  },
  watch: {
    infos: {
      handler(infos) {
        this.checkAll = false;
        if (infos) {
          this.checkArray = Array(infos.length).fill(false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      checkAll: false,
      checkArray: [],
    };
  },
  methods: {
    closeEditor() {
      this.$emit("closeReportEditor");
      if (this.drawState) {
        this.$emit("changeDrawState", null);
      }
    },
    translateInfoType(type) {
      switch (type) {
        case "crack":
          return "균열";
        case "efflorescence":
          return "백태";
        case "spalling":
          return "박리";
        case "rebar":
          return "철근노출";
        case "leakage":
          return "누수";
        case "net_crack":
          return "망상균열";
        case "etc":
          return "기타";
        default:
          return null;
      }
    },
    changeCheckAll() {
      if (this.drawState) {
        this.$emit(
          "changeAlert",
          "그리기 작업 중입니다. 그리기 작업을 마친 후 선택해주세요."
        );
      } else {
        this.checkAll = !this.checkAll;
        if (this.checkAll) {
          this.$emit("deleteCrackAll");
        } else {
          this.$emit("drawCrackAll");
        }
        this.checkArray = this.checkArray.map(() => this.checkAll);
      }
    },
    clickCheckBox(index) {
      if (this.drawState) {
        this.$emit(
          "changeAlert",
          "그리기 작업 중입니다. 그리기 작업을 마친 후 선택해주세요."
        );
      } else {
        this.checkArray[index] = !this.checkArray[index];
        if (this.checkArray[index]) {
          this.$emit("deleteCrack", index);
        } else {
          this.$emit("drawCrack", index);
        }
      }
    },
    getCrackWidth(crackWidth, distance, type) {
      let fovInRadians = this.selectedCamera.horizontalFov * (Math.PI / 180);
      let realLength = 2 * (distance * Math.tan(fovInRadians / 2));
      let metersPerPixel = realLength / this.selectedCamera.photoWidth;
      let calcCrackWidth = crackWidth * metersPerPixel * 1000;
      if (type == "crack") calcCrackWidth = calcCrackWidth / 2.5;
      return parseFloat(calcCrackWidth).toFixed(3);
    },
    getCrackLength(crackLength, distance) {
      let fovInRadians = this.selectedCamera.verticalFov * (Math.PI / 180);
      let realLength = 2 * (distance * Math.tan(fovInRadians / 2));
      let metersPerPixel = realLength / this.selectedCamera.photoHeight;
      let calcCrackLength = crackLength * metersPerPixel;
      return parseFloat(calcCrackLength).toFixed(2);
    },
    analysisAdd() {
      if (!this.drawState) {
        this.$emit("openClassModal");
      } else {
        this.$emit("changeDrawModalState", this.drawState);
        this.$emit("changeDrawState", null);
      }
    },
    analysisSave() {
      if (this.drawState) {
        this.$emit(
          "changeAlert",
          "그리기 작업 중입니다. 그리기 작업을 마친 후 선택해주세요."
        );
      } else {
        let checkedIndex = [];
        for (let i = 0; i < this.checkArray.length; i++) {
          if (this.checkArray[i]) {
            checkedIndex.push(i);
          }
        }
        this.checkAll = false;
        this.$emit("analysisSave", checkedIndex);
      }
    },
  },
};
</script>

<style lang="scss">
.report_editor_crack_info_wrap {
  display: flex;
  flex-direction: column;
  width: 520px;
  height: 100%;
  background: rgba(23, 23, 23, 0.3);
}
@media (max-width: 1300px) {
  .report_editor_left_wrap {
    width: 60%;
    height: 100%;
  }
  .report_editor_crack_info_wrap {
    width: 40%;
    height: 100%;
  }
}

.report_editor_crack_info_header {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  width: 100%;
  padding: 10px;
}
.report_editor_crack_info_x {
  width: 30px;
  height: 30px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
  cursor: pointer;
}
.report_editor_crack_info_body {
  width: 100%;
  height: calc(100% - 100px);
  padding: 0px 10px 0px 10px;
}
.report_editor_crack_info_body_title {
  @include flexbox(center, space-between, row);
  width: calc(100% - 3px);
  height: 30px;
  background: rgba(32, 32, 32, 0.85);
  border-radius: 5px;
  font-size: 1rem;
  color: gray;
  margin-bottom: 10px;
}
.report_editor_crack_info_check {
  margin: 0px 15px 0px 15px;
  width: 15px;
  height: 15px;
  border: 1px solid rgba(1, 250, 254, 0.2);
  border-radius: 3px;
  background: rgba(32, 32, 32, 0.85);
  cursor: pointer;
}
.report_editor_crack_info_check.active {
  background: rgb(1, 254, 250) url("@/../public/images/check.svg") no-repeat center;
  background-size: 12px 12px;
}
.report_editor_crack_info_no {
  @include flexbox;
  width: 10%;
  height: 100%;
}
.report_editor_crack_info_type {
  @include flexbox;
  width: calc((90% - 30px) / 4);
  height: 100%;
}
.report_editor_crack_info_thickness {
  @include flexbox;
  width: calc((90% - 30px) / 4);
  height: 100%;
}
.report_editor_crack_info_length {
  @include flexbox;
  width: calc((90% - 30px) / 4);
  height: 100%;
}
.report_editor_crack_info_distance {
  @include flexbox;
  width: calc((90% - 30px) / 4);
  height: 100%;
}

.report_editor_crack_info_conetent_wrap {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.report_editor_crack_info_conetent_wrap::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.report_editor_crack_info_conetent_wrap::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.report_editor_crack_info_conetent_wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}

.report_editor_crack_info_conetent {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 30px;
  font-size: 1rem;
  color: $main_white;
  flex-shrink: 0;
}
.report_editor_crack_info_button_wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 50px;
}

.report_editor_crack_info_button {
  @include flexbox;
  width: calc((100% - 40px) / 3);
  height: 30px;
  font-size: 1.2rem;
}
.report_editor_crack_info_button.on {
  cursor: pointer;
}
</style>
