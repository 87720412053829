/**
 * input의 value length가 limit보다 크면 알럿을 띄우고 글자수를 자른다.
 * @param {*} event
 * @param {number} limit
 * @param {*} store `store를 제어해서 alert 띄우기 때문에 ${this.$store}필요하다`
 */
export const checkInputLimit = (event, limit, store) => {
  if (event.target.value.length > limit) {
    store.commit('openAlert', `입력 값은 ${limit}자를 초과할 수 없습니다.`);
    event.target.value = event.target.value.substr(0, limit);
  }
};
