/**
 * 영어로된 권한을 한국어로 변환해주는 함수.
 * @param {String} role
 * @returns "관리자(admin)", "매니저(manager)", "일반(normal)"
 */
export const changeRoleToKorean = (role) => {
  if (role === "admin") {
    return "관리자";
  } else if (role === "manager") {
    return "매니저";
  } else {
    return "일반";
  }
};
