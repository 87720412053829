import {format} from 'date-fns'

export default class Map{
  constructor(map){
    this.id = null;
    this.facilityId = null;
    this.name = null; 
    this.url = null;
    this.createdDatetime = null;
    if(map){
      this.id = map.id;
      this.facilityId = map.facilityId;
      this.name = map.name; 
      this.url = map.url;
      this.createdDatetime = format(map.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    }
  }
}