/**
 * 한국어로된 권한을 영어로 변환해주는 함수.
 * @param {String} role
 * @returns "관리자(admin)", "매니저(manager)", "일반(normal)"
 */
export const changeRoleToEnglish = (role) => {
  if (role === "관리자") {
    return "admin";
  } else if (role === "매니저") {
    return "manager";
  } else {
    return "normal";
  }
};
