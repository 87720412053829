/**
 * 마우스로 동작하는 웹이 아니라 손(터치)을 사용해야하는 디바이스인지 아닌지를 체크한다.
 * @returns `Boolean`
 */
export const isTouchDevice = () => {
  return (
    "ontouchstart" in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    window.matchMedia("(pointer: coarse)").matches
  );
};
