<template>
  <div class="main korean">
    <MainHeaderVue :navigator="navigator" />
    <MainLoadingVue ref="mainLoading" />
    <div ref="pcd_viewer_3d" class="pcd_viewer_3d"></div>
    <div class="pc_measure_right_wrap"></div>
    <div ref="pcd_viewer_2d" class="pcd_viewer_2d" @mousemove="handleMouseMove" @mousedown="handleMouseDown">
      <div ref="magnifier" class="magnifier"></div>
    </div>
    <PCMeasureInfoVue :measureMethod = "measureMethod" @changeMethod="handleMesureMethod"
                        @deleteMeasure="deleteMeasure"></PCMeasureInfoVue>
    <PCMeasureRangeModalVue  :range="range" :rangeAxis="rangeAxis"  :rangeLimit="rangeLimit"
                              :rotateDeg="rotateDeg" @setRangeAxis="setRangeAxis" @setRange="setRange" @setRotateDeg="setRotateDeg"/>
  </div>
</template>

<script>
import { mapState } from "vuex";

import PCManager from '@/module/Measure_2D/PCManager_2D.js';
import API from "@/module/Communication/RESTAPI.js";

import MainHeaderVue from '../Common/MainHeader.vue';
import MainLoadingVue from '../Common/MainLoading.vue';
import PCMeasureInfoVue from './PCMeasureInfo.vue'
import PCMeasureRangeModalVue from './PCMeasureRangeModal.vue'

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Range from "@/model/Range.js";

export default {
  data() {
    return {
      navigator: "measure",
      measureMethod: null,
      range: new Range(),
      rangeLimit: new Range({
        minHeight: 0,
        maxHeight: 100,
        minWidth: 0,
        maxWidth: 100,
        minDepth: 0,
        maxDepth: 100,
      }),
      rangeAxis: 0,
      rotateDeg: { x: 0, y: 0, z: 0 },
    };
  },
  computed: {
    ...mapState(["user", "apiIp", "apiPort", "siteList", "selectedSite"]),
  },
  components: {
    MainHeaderVue,
    MainLoadingVue,
    PCMeasureInfoVue,
    PCMeasureRangeModalVue,
  },
  methods: {
    handleMouseMove(event) {
      if (this.measureMethod) this.loader.onMouseMove(event);
    },
    handleMouseDown(event) {
      event.preventDefault();
      this.loader.onMouseDown(event);
    },
    getSites() {
      if (!this.siteList) {
        this.api.getSites(this.user.id, this.getSitesCallback);
      } else {
        this.getSite();
      }
    },
    getSitesCallback(data) {
      let result = data.data.result;
      let siteList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          siteList.push(new Site(result[i]));
        }
      }
      this.$store.dispatch("setSiteList", siteList);
      let setSelectedSite = siteList.find(
        (site) => site.id == this.$route.params.siteId
      );
      this.$store.dispatch("setSelectedSite", setSelectedSite);
      this.getSite();
    },
    getSite() {
      if (
        !this.selectedSite ||
        this.selectedSite.id != this.$route.params.siteId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.siteId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedSite", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.siteId);
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch("setMapList", mapList);
        let map = mapList.find((map) => map.id == this.$route.params.mapId);
        this.$store.dispatch("updateMap", map);
        this.loadPCD(this.selectedSite.map.url);
      }
    },
    loadPCD(url) {
      this.loader = new PCManager(
        this.$refs.pcd_viewer_2d,
        this.$refs.pcd_viewer_3d,
        this.$refs.mainLoading,
        this.$refs.magnifier
      );
      this.loader.loadPCD(url).then(() => {
        this.loader.resetMinMaxRange();
        this.setRangeLimit();
        this.setResetRange();
      });
    },
    setRangeAxis(number) {
      this.rangeAxis = number;
      this.setResetRange();
      this.loader.setAxis(number);
    },
    handleMesureMethod(method) {
      this.loader.removeDrawings();
      if (this.measureMethod == method) {
        this.measureMethod = null;
      } else {
        this.measureMethod = method;
        switch (method) {
          case "length":
            this.loader.setLineMeasuring();
            break;
          case "area":
            this.loader.setAreaMeasuring();
            break;
          case "angle":
            this.loader.setAngleMeasuring();
            break;
          default:
            break;
        }
      }
    },
    setRangeLimit() {
      let heightRangeLimit = this.loader.getMinMaxHeight();
      let widthRangeLimit = this.loader.getMinMaxWidth();
      let depthRangeLimit = this.loader.getMinMaxDepth();

      this.rangeLimit.maxHeight = parseFloat(heightRangeLimit.max.toFixed(1));
      this.rangeLimit.minHeight = parseFloat(heightRangeLimit.min.toFixed(1));
      this.rangeLimit.maxWidth = parseFloat(widthRangeLimit.max.toFixed(1));
      this.rangeLimit.minWidth = parseFloat(widthRangeLimit.min.toFixed(1));
      this.rangeLimit.maxDepth = parseFloat(depthRangeLimit.max.toFixed(1));
      this.rangeLimit.minDepth = parseFloat(depthRangeLimit.min.toFixed(1));
    },
    setResetRange() {
      let heightRangeLimit = this.loader.getMinMaxHeight();
      let widthRangeLimit = this.loader.getMinMaxWidth();
      let depthRangeLimit = this.loader.getMinMaxDepth();
      this.setRange(
        "minHeight",
        ((heightRangeLimit.min + heightRangeLimit.max) / 2 - 1).toFixed(1)
      );
      this.setRange(
        "maxHeight",
        ((heightRangeLimit.min + heightRangeLimit.max) / 2 + 1).toFixed(1)
      );
      this.setRange(
        "minWidth",
        ((widthRangeLimit.min + widthRangeLimit.max) / 2 - 1).toFixed(1)
      );
      this.setRange(
        "maxWidth",
        ((widthRangeLimit.min + widthRangeLimit.max) / 2 + 1).toFixed(1)
      );
      this.setRange(
        "minDepth",
        ((depthRangeLimit.min + depthRangeLimit.max) / 2 - 1).toFixed(1)
      );
      this.setRange(
        "maxDepth",
        ((depthRangeLimit.min + depthRangeLimit.max) / 2 + 1).toFixed(1)
      );
    },
    setRange(property, value) {
      switch (property) {
        case "minHeight":
          this.range.minHeight = parseFloat(value);
          break;
        case "maxHeight":
          this.range.maxHeight = parseFloat(value);
          break;
        case "minWidth":
          this.range.minWidth = parseFloat(value);
          break;
        case "maxWidth":
          this.range.maxWidth = parseFloat(value);
          break;
        case "minDepth":
          this.range.minDepth = parseFloat(value);
          break;
        case "maxDepth":
          this.range.maxDepth = parseFloat(value);
          break;
        default:
          break;
      }
      this.loader.applyRange(this.range);
    },
    setRotateDeg(degree) {
      this.rotateDeg.z = parseFloat(degree);
      this.loader.rotateModel(degree);
      this.heightMinMax = this.loader.getMinMaxHeight();
      this.widthMinMax = this.loader.getMinMaxWidth();
      this.depthMinMax = this.loader.getMinMaxDepth();

      this.heightRangeMin =
        (this.heightMinMax.min + this.heightMinMax.max) / 2 - 1;
      this.heightRangeMax =
        (this.heightMinMax.min + this.heightMinMax.max) / 2 + 1;
      this.widthRangeMin =
        (this.widthMinMax.min + this.widthMinMax.max) / 2 - 1;
      this.widthRangeMax =
        (this.widthMinMax.min + this.widthMinMax.max) / 2 + 1;
      this.depthRangeMin =
        (this.depthMinMax.min + this.depthMinMax.max) / 2 - 1;
      this.depthRangeMax =
        (this.depthMinMax.min + this.depthMinMax.max) / 2 + 1;

      const range = {
        minHeight: this.heightRangeMin,
        maxHeight: this.heightRangeMax,
        minWidth: this.widthRangeMin,
        maxWidth: this.widthRangeMax,
        minDepth: this.depthRangeMin,
        maxDepth: this.depthRangeMax,
      };
      this.loader.applyRange(range);
    },
    deleteMeasure() {
      this.loader.deleteMeasurements();
    },
  },
  created() {
    this.api = new API(this.apiIp, this.apiPort);
  },
  mounted() {
    this.getSites();
  },
  beforeUnmount() {
    if (this.loader) {
      this.loader.dispose();
    }
  },
};
</script>

<style lang="scss">
.pcd_viewer_2d {
  position: absolute;
  top: 70px;
  right: 0px;
  width: 50%;
  height: calc(100% - 70px);
  overflow: hidden;
  border-left: 1px solid rgba(1, 250, 254, 0.5);
}
.pcd_viewer_3d {
  position: absolute;
  top: 70px;
  left: 0px;
  width: 50%;
  height: calc(100% - 70px);
  overflow: hidden;
}
.magnifier {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 2px rgba(1, 250, 254, 0.5);
  z-index: 10;
}
</style>
