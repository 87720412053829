export default class CaptureInfo{
  constructor(captureInfo){
    this.id = null;
    this.name = null;
    this.minHeight = null;
    this.maxHeight = null;
    this.minWidth = null; 
    this.maxWidth = null;
    this.minDepth = null;
    this.maxDepth = null;
    this.roll = null;
    this.pitch = null;
    this.yaw = null;
    this.vertical = null;
    this.roiPartId = null;

    if(captureInfo){
      this.id = captureInfo.id;
      this.name = captureInfo.name;
      this.minHeight = captureInfo.minHeight;
      this.maxHeight = captureInfo.maxHeight;
      this.minWidth = captureInfo.minWidth; 
      this.maxWidth = captureInfo.maxWidth;
      this.minDepth = captureInfo.minDepth;
      this.maxDepth = captureInfo.maxDepth;
      this.roll = captureInfo.roll;
      this.pitch = captureInfo.pitch;
      this.yaw = captureInfo.yaw;
      this.vertical = captureInfo.vertical;
      this.roiPartId = captureInfo.roiPartId;
    }
  }
}