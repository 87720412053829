<template>
  <MainAlertFrame>
    <div class="alert_container">
      <h2 v-html="alertMessage"></h2>
      <div class="button_box">
        <button @click="closeModal" class="default_bg_button" type="button">
          확인
        </button>
      </div>
    </div></MainAlertFrame
  >
</template>

<script>
import MainAlertFrame from "./MainAlertFrame.vue";

export default {
  name: "MainAlert",
  components: {
    MainAlertFrame,
  },
  props: {
    alertMessage: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("closeAlert");
    },
  },
};
</script>

<style scoped lang="scss">
.alert_container {
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  line-height: 1.5;
}

h2 {
  color: #ffffff;
  font-size: 1.6rem;
}

.button_box {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 8px 24px;
  }
}
</style>
