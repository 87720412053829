import * as THREE from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

export default class LineMeasure {
	constructor(scene, modelGroup, font) {
		this.scene = scene;
		this.modelGroup = modelGroup;
		this.lines = [];
		this.lineBoxes = [];
		this.lineCount = 0;
		this.font = font;
		this.startPoint = null;
		this.endPoint = null;
	}

	getLine(index){return this.lines[index];}
	resetLines(){this.lines = [];}
	getNumLines(){return this.lines.length;}

	getLineBox(index){return this.lineBoxes[index];}
	resetLineBoxes(){this.lineBoxes = [];}
	getNumLineBoxes(){return this.lineBoxes.length;}

	resetLineCount(){this.lineCount=0;}
	increaseLineCount(){this.lineCount++;}
	decreaseLineCount(){this.lineCount--;}
	getLineCount(){return this.lineCount;}

	setStartPoint(offsetPoint){this.startPoint = this.getStartEndPoint(offsetPoint);}
	setEndPoint(offsetPoint){this.endPoint = this.getStartEndPoint(offsetPoint);}

	getStartEndPoint(intersection){
		let point;
		if(this.lineCount>0){			
			for(let i=0;i<this.lineCount;i++){											
				const distanceToStart = intersection.distanceTo(this.lines[i].startPoint);
				const distanceToEnd = intersection.distanceTo(this.lines[i].endPoint);
				if(distanceToStart<0.5){					
					point = this.lines[i].startPoint;
					break;
				}
				else if(distanceToEnd<0.5){					
					point = this.lines[i].endPoint;
					break;
				}
				else point = intersection;
			}
		}
		else{			
			point = intersection;
		}

		return point;
	}

	drawMeasureLine(axis, radian,  save) {
		const path = new THREE.LineCurve3(
			new THREE.Vector3(this.startPoint.x, this.startPoint.y, this.startPoint.z),
			new THREE.Vector3(this.endPoint.x, this.endPoint.y, this.endPoint.z)
		);
				
		const tubeRadius = 0.05; 		
		const tubeGeometry = new THREE.TubeGeometry(path, 64, tubeRadius, 10, false);
		const tubeMaterial = new THREE.MeshBasicMaterial({ color: 0x80C497, transparent: true, opacity: 1 });

		const sphereGeometry = new THREE.SphereGeometry(tubeRadius*2, 32, 32);
		const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x80C497 });

		const tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
		const startSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
		const endSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

		tube.raycast = () => {};
		startSphere.raycast = () => {};
		endSphere.raycast = () => {};

		startSphere.position.copy(this.startPoint);
		endSphere.position.copy(this.endPoint);
				
		if(this.lines.length == this.lineCount){
			this.scene.add(tube);
			this.scene.add(startSphere);
			this.scene.add(endSphere);

			this.lines.push({tube: tube, startSphere: startSphere, endSphere: endSphere
											,startPoint: this.startPoint, endPoint: this.endPoint, axis: axis, radian: radian});			
		}
		else{			
			const lastLine = this.lines[this.lines.length - 1];
			this.scene.remove(lastLine.tube);
			lastLine.tube.geometry.dispose();
			lastLine.tube.material.dispose();
			this.scene.remove(lastLine.startSphere);
			lastLine.startSphere.geometry.dispose();
			lastLine.startSphere.material.dispose();
			this.scene.remove(lastLine.endSphere);
			lastLine.endSphere.geometry.dispose();
			lastLine.endSphere.material.dispose();

			let rotatedStartPoint = this.startPoint;
			let rotatedEndPoint = this.endPoint;

			if(save){
				const axis = new THREE.Vector3(0, 1, 0); // y축 기준
				const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, -radian);

				rotatedStartPoint = this.startPoint.clone().applyQuaternion(quaternion);
				rotatedEndPoint = this.endPoint.clone().applyQuaternion(quaternion);
				const rotatedPath = new THREE.LineCurve3(rotatedStartPoint, rotatedEndPoint);
				const rotatedTubeGeometry = new THREE.TubeGeometry(rotatedPath, 64, tubeRadius, 10, false);

				startSphere.position.copy(rotatedStartPoint);
				endSphere.position.copy(rotatedEndPoint);

				tube.geometry.dispose(); // 이전 Geometry 메모리 해제
				tube.geometry = rotatedTubeGeometry;

				this.modelGroup.add(tube);
				this.modelGroup.add(startSphere);
				this.modelGroup.add(endSphere);
			}
			else{
				this.scene.add(tube);
				this.scene.add(startSphere);
				this.scene.add(endSphere);
			}

			this.lines[this.lines.length - 1] = {tube: tube, startSphere: startSphere, endSphere: endSphere
																					,startPoint: rotatedStartPoint, endPoint: rotatedEndPoint, axis: axis, radian: radian};
		}
	}

	drawLineTextBox(axis, radian){
		const distance = this.startPoint.distanceTo(this.endPoint);					
		const roundedDistance = Math.round(distance * 10) / 10;

		if (this.font) {
			const textGeo = new TextGeometry(`${roundedDistance} m`, {
				font: this.font,
				size: 0.45,
				height: 0.15,
			});
			textGeo.computeBoundingBox();
			const textWidth = textGeo.boundingBox.max.x - textGeo.boundingBox.min.x;
			const textHeight = textGeo.boundingBox.max.y - textGeo.boundingBox.min.y;
	
			const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
			const textMeshFront = new THREE.Mesh(textGeo, textMaterial);    
			const textMeshBack = textMeshFront.clone();						

			const boxDepth = 0.2; 
			const textOffsetX = -textWidth / 2; 
			const textOffsetY = -textHeight / 2; 
			textMeshFront.position.set(textOffsetX, textOffsetY, boxDepth / 2);

			textMeshBack.position.set(textOffsetX, textOffsetY, -boxDepth / 2);
			textMeshBack.rotation.y = Math.PI;
			textMeshBack.position.x += textWidth;				

			const boxWidth = textWidth * 1.4; 
			const boxHeight = textHeight * 2.0;						
			const boxGeometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
			const boxMaterial = new THREE.MeshBasicMaterial({ color: 0x94D8AB });
			const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
			
			const midPoint = new THREE.Vector3().addVectors(this.startPoint, this.endPoint).multiplyScalar(0.5);
			const rotAxis = new THREE.Vector3(0, 1, 0); // 회전 축 (y축)
			const quaternion = new THREE.Quaternion().setFromAxisAngle(rotAxis, -radian);
			midPoint.applyQuaternion(quaternion);					
			boxMesh.position.copy(midPoint);
			
			boxMesh.position.y += boxHeight / 2;
			boxMesh.rotation.set(0, 0, 0);
			if(axis==0){
				boxMesh.rotation.x = -Math.PI / 2;
			}
			else if(axis==1){
				boxMesh.rotation.y = Math.PI / 2;
			}
			
			boxMesh.add(textMeshFront);
			boxMesh.add(textMeshBack);
					
			this.lineBoxes.push({boxMesh:boxMesh, checked: false});
			this.modelGroup.add(boxMesh);
		}
	}

	removeDrawings(){
		const lastLine = this.lines[this.lines.length - 1];
		this.scene.remove(lastLine.tube);
		lastLine.tube.geometry.dispose();
		lastLine.tube.material.dispose();
		this.scene.remove(lastLine.startSphere);
		lastLine.startSphere.geometry.dispose();
		lastLine.startSphere.material.dispose();
		this.scene.remove(lastLine.endSphere);
		lastLine.endSphere.geometry.dispose();
		lastLine.endSphere.material.dispose();
		this.lines.pop();
	}
    
	removeLineMeasure(){
		for(let i=this.lineBoxes.length-1;i>=0;i--){
			if(this.lineBoxes[i].checked){
				const tempBoxMesh = this.lineBoxes[i].boxMesh;
				this.modelGroup.remove(tempBoxMesh);
				tempBoxMesh.geometry.dispose();
				tempBoxMesh.material.dispose();
				this.lineBoxes.splice(i,1);

				const tempLine = this.lines[i];
				this.modelGroup.remove(tempLine.tube);
				tempLine.tube.geometry.dispose();
				tempLine.tube.material.dispose();
				this.modelGroup.remove(tempLine.startSphere);
				tempLine.startSphere.geometry.dispose();
				tempLine.startSphere.material.dispose();
				this.modelGroup.remove(tempLine.endSphere);
				tempLine.endSphere.geometry.dispose();
				tempLine.endSphere.material.dispose();
				this.lines.splice(i,1);
				this.decreaseLineCount();				
			}
		}
	}

	setVisibleMeasure(axis){
		for(let i=this.lines.length-1;i>=0;i--){
			const line = this.lines[i];
			const textBox = this.lineBoxes[i].boxMesh;
			if(line.axis == axis){
				line.tube.visible = true;
				line.startSphere.visible = true;
				line.endSphere.visible = true;
				textBox.visible= true;
			}
			else{
				line.tube.visible = false;
				line.startSphere.visible = false;
				line.endSphere.visible = false;
				textBox.visible= false;
			}
		}
	}

	setVisibleFalse(){
		for(let i=this.lines.length-1;i>=0;i--){
			const line = this.lines[i];
			const textBox = this.lineBoxes[i].boxMesh;
			line.tube.visible = false;
			line.startSphere.visible = false;
			line.endSphere.visible = false;
			textBox.visible= false;
		}
	}

	rotateLines(radian){
		if(this.lines.length==0) return;
		
		for(let i=this.lines.length-1;i>=0;i--){
			const axis = this.lines[i].axis;
			if(axis == 1 || axis==2){
				const tempBoxMesh = this.lineBoxes[i].boxMesh;
				this.modelGroup.remove(tempBoxMesh);
				tempBoxMesh.geometry.dispose();
				tempBoxMesh.material.dispose();
				this.lineBoxes.splice(i,1);

				const tempLine = this.lines[i];
				this.modelGroup.remove(tempLine.tube);
				tempLine.tube.geometry.dispose();
				tempLine.tube.material.dispose();
				this.modelGroup.remove(tempLine.startSphere);
				tempLine.startSphere.geometry.dispose();
				tempLine.startSphere.material.dispose();
				this.modelGroup.remove(tempLine.endSphere);
				tempLine.endSphere.geometry.dispose();
				tempLine.endSphere.material.dispose();
				this.lines.splice(i,1);
				this.decreaseLineCount();
			}
			else{
				const line = this.lines[i];
				const textBox = this.lineBoxes[i].boxMesh;
				line.tube.rotation.y = radian - line.radian;

				const rotAxis = new THREE.Vector3(0, 1, 0); // 회전 축 (y축)
				const quaternion = new THREE.Quaternion().setFromAxisAngle(rotAxis, radian - line.radian);

				let startPosition = new THREE.Vector3().copy(line.startPoint);
				startPosition.applyQuaternion(quaternion);					
				line.startSphere.position.copy(startPosition);

				let endPosition = new THREE.Vector3().copy(line.endPoint);
				endPosition.applyQuaternion(quaternion);		
				line.endSphere.position.copy(endPosition);

				const midPoint = new THREE.Vector3().addVectors(startPosition, endPosition).multiplyScalar(0.5);
				textBox.position.copy(midPoint);
			}
		}
	}
}