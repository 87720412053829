<template>
  <!-- <div class="home_map_gps_button" @click="gpsFocusing"></div> -->
  <div class="home_map_type_control" id="mapTypeControl">
    <div
      class="home_map_type_button map button"
      :class="{ on: isRoadMap }"
      @click="changeMapType('roadmap')"
    >
      일반
    </div>
    <div
      class="home_map_type_button sky button"
      :class="{ on: !isRoadMap }"
      @click="changeMapType('hybrid')"
    >
      위성
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isRoadMap: Boolean,
  },
  emits: ["changeMapType"],
  methods: {
    changeMapType(type) {
      this.$emit("changeMapType", type);
    },
    // gpsFocusing(){
    //   this.$emit('gpsFocusing');
    // }
  },
};
</script>

<style lang="scss">
.home_map_gps_button {
  position: absolute;
  z-index: 10;
  top: calc(100% / 12 + 20px);
  right: 150px;
  width: 30px;
  height: 30px;
  background: rgb(23, 23, 23) url("@/../public/images/tracking.svg") no-repeat center;
  background-size: 15px 15px;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  cursor: pointer;
}
.home_map_gps_button:hover {
  background: $main_color url("@/../public/images/tracking_hover.svg") no-repeat center;
  background-size: 15px 15px;
}
.home_map_type_control {
  position: absolute;
  z-index: 10;
  top: calc(100% / 12 + 20px);
  right: 10px;
  width: 130px;
  height: 30px;
  display: flex;
  background-color: rgb(23, 23, 23); /* 배경 색상 */
  border-radius: 5px; /* 모서리 둥글게 */
  box-shadow: $main_box_shadow;
  padding: 1px;
  font-size: 1.2rem;
}
.home_map_type_button {
  @include flexbox;
  height: 100%;
}
.map {
  width: calc(100% / 2 - 10px);
}
.sky {
  width: calc(100% / 2 + 10px);
}
</style>
