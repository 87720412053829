import {
	Vector3, 
	TubeGeometry, 
	MeshBasicMaterial, 
	SphereGeometry, 
	Mesh, 
	LineCurve3, 
	BufferGeometry, 
	DoubleSide, 
	BoxGeometry, 
	Float32BufferAttribute, 
	Quaternion
} from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

export default class AreaMeasure {
	constructor(scene, modelGroup, font) {
		this.scene = scene;
		this.modelGroup = modelGroup;
		this.areaLinePoints  = [];
		this.areaLines = [];
		this.polygons = [];
		this.areaBoxes = [];
		this.areaLineCount = 0;
		this.areaCount = 0;
		this.font = font;        
	}

	increaseAreaLineCount(){this.areaLineCount++;}
	decreaseAreaLineCount(){this.areaLineCount--;}
	resetAreaLineCount(){this.areaLineCount=0;}
	getAreaLineCount(){return this.areaLineCount;}

	increaseAreaCount(){this.areaCount++;}
	decreaseAreaCount(){this.areaCount--;}
	getAreaCount(){return this.areaCount;}

	pushAreaLinePoint(point){this.areaLinePoints.push(point);}
	getLinePoint(index){return this.areaLinePoints[index];}
	resetAreaLinePoints(){this.areaLinePoints = [];}
	getPointNum(){return this.areaLinePoints.length;}

	getAreaLine(index){return this.areaLines[index];}
	resetAreaLines(){this.areaLines = [];}
	getLineNum(){return this.areaLines.length;}

	getTextBoxes(){return this.areaBoxes;}
	getTextBox(index){return this.areaBoxes[index];}
	getTextNum(){return this.areaBoxes.length;}

	getPolygon(index){return this.polygons[index];}
	getPolygonNum(){return this.polygons.length;}

	pushPolygon(){this.polygons.push({areaLinePoints:this.areaLinePoints, areaLines:this.areaLines, polygonMesh:null, area:null})}

	

	drawAreaLine(startPoint, endPoint, radian, save){
		const path = new LineCurve3(
			new Vector3(startPoint.x, startPoint.y, startPoint.z),
			new Vector3(endPoint.x, endPoint.y, endPoint.z)
		);
			
		const tubeRadius = 0.05; 		
		const tubeGeometry = new TubeGeometry(path, 64, tubeRadius, 10, false);
		const tubeMaterial = new MeshBasicMaterial({ color: 0xffffff});

		const sphereGeometry = new SphereGeometry(tubeRadius*2, 32, 32);
		const sphereMaterial = new MeshBasicMaterial({ color: 0xffffff });

		const tube = new Mesh(tubeGeometry, tubeMaterial);
		const startSphere = new Mesh(sphereGeometry, sphereMaterial);
		const endSphere = new Mesh(sphereGeometry, sphereMaterial);

		tube.raycast = () => {};
		startSphere.raycast = () => {};
		endSphere.raycast = () => {}; 

		startSphere.position.copy(startPoint);
		endSphere.position.copy(endPoint);

		if(this.areaLines.length == this.areaLineCount){
			this.scene.add(tube);
			this.scene.add(startSphere);
			this.scene.add(endSphere);

			this.areaLines.push({tube: tube, startSphere: startSphere, endSphere: endSphere});
		}
		else{
			const lastLine = this.areaLines[this.areaLines.length - 1];
			this.scene.remove(lastLine.tube);
			lastLine.tube.geometry.dispose();
			lastLine.tube.material.dispose();
			this.scene.remove(lastLine.startSphere);
			lastLine.startSphere.geometry.dispose();
			lastLine.startSphere.material.dispose();
			this.scene.remove(lastLine.endSphere);
			lastLine.endSphere.geometry.dispose();
			lastLine.endSphere.material.dispose();

			if(save){		
				const axis = new Vector3(0, 1, 0); // y축 기준
				const quaternion = new Quaternion().setFromAxisAngle(axis, -radian);

				const rotatedStartPoint = startPoint.clone().applyQuaternion(quaternion);
				const rotatedEndPoint = endPoint.clone().applyQuaternion(quaternion);
				const rotatedPath = new LineCurve3(rotatedStartPoint, rotatedEndPoint);
				const rotatedTubeGeometry = new TubeGeometry(rotatedPath, 64, tubeRadius, 10, false);

				startSphere.position.copy(rotatedStartPoint);
				endSphere.position.copy(rotatedEndPoint);

				tube.geometry.dispose(); // 이전 Geometry 메모리 해제
				tube.geometry = rotatedTubeGeometry;

				this.modelGroup.add(tube);
				this.modelGroup.add(startSphere);
				this.modelGroup.add(endSphere);
			}
			else{				
				this.scene.add(tube);
				this.scene.add(startSphere);
				this.scene.add(endSphere);
			}
			this.areaLines[this.areaLines.length - 1] = {tube: tube, startSphere: startSphere, endSphere: endSphere};
		}
	}

	drawAreaPolygon(radian){		
		const points = this.polygons[this.areaCount].areaLinePoints;

		const vertices = [];
		const axis = new Vector3(0, 1, 0);
		const quaternion = new Quaternion().setFromAxisAngle(axis, -radian)
		for (let i = 0; i < points.length; i++) {	
			const rotatedPoint = points[i].clone().applyQuaternion(quaternion);
			vertices.push(rotatedPoint.x, rotatedPoint.y, rotatedPoint.z);
		}

		const rotatedInitPoint = points[0].clone().applyQuaternion(quaternion);
		vertices.push(rotatedInitPoint.x, rotatedInitPoint.y, rotatedInitPoint.z);	
		
		const geometry = new BufferGeometry();
		geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

		const indices = [];
		for (let i = 1; i < vertices.length - 1; i++) {
			indices.push(0, i, i + 1);
		}
		
		this.polygons[this.areaCount].area = this.calculatePolygonArea(vertices, indices);
		
		geometry.setIndex(indices);

		const material = new MeshBasicMaterial({
			color: 0x00ff00,
			side: DoubleSide,
			transparent : true,
			opacity : 0.5,
		});
		
		const polygon = new Mesh(geometry, material);
		this.polygons[this.areaCount].polygonMesh = polygon;
		this.modelGroup.add(polygon);
		// this.scene.add(polygon);
	}

	drawAreaTextBox(radian, camera){
		const center = new Vector3(0, 0, 0);
		const points = this.polygons[this.areaCount].areaLinePoints;	

		const rotAxis = new Vector3(0, 1, 0);
		const quaternion = new Quaternion().setFromAxisAngle(rotAxis, -radian)

		points.forEach(point => {
			const rotatedPoint = point.clone().applyQuaternion(quaternion);
			center.add(rotatedPoint);
		});

		center.divideScalar(points.length);

		const roundedArea = Math.round(this.polygons[this.areaCount].area * 10) / 10;

		if (this.font) {
			const textGeo = new TextGeometry(`${roundedArea} m²`, {
				font: this.font,
				size: 0.3,
				depth: 0.1,
			});
			textGeo.computeBoundingBox();
			const textWidth = textGeo.boundingBox.max.x - textGeo.boundingBox.min.x;
			const textHeight = textGeo.boundingBox.max.y - textGeo.boundingBox.min.y;
	
			const textMaterial = new MeshBasicMaterial({ color: 0x000000, side: DoubleSide });						
			const textMeshFront = new Mesh(textGeo, textMaterial);    
			const textMeshBack = textMeshFront.clone();						

			const boxDepth = 0.2; 
			const textOffsetX = -textWidth / 2; 
			const textOffsetY = -textHeight / 2; 
			textMeshFront.position.set(textOffsetX, textOffsetY, boxDepth / 2);

			textMeshBack.position.set(textOffsetX, textOffsetY, -boxDepth / 2); // 먼저 중앙으로 이동						
			textMeshBack.rotation.y = Math.PI; // Y축을 기준으로 180도 회전		
			textMeshBack.position.x += textWidth;				

			const boxWidth = textWidth * 1.4; 
			const boxHeight = textHeight * 2.0;						
			const boxGeometry = new BoxGeometry(boxWidth, boxHeight, boxDepth);
			const boxMaterial = new MeshBasicMaterial({ color: 0xffffff }); // 하얀색 재질
			const boxMesh = new Mesh(boxGeometry, boxMaterial);
			boxMesh.position.copy(center);	

			boxMesh.lookAt(camera.position);

			boxMesh.add(textMeshFront);
			boxMesh.add(textMeshBack);

			this.areaBoxes.push({boxMesh:boxMesh, checked: false});
			this.modelGroup.add(boxMesh);
			// this.scene.add(boxMesh);
		}
	}

	calculatePolygonArea(vertices, indices) {
		let area = 0;
	
		for (let i = 0; i < indices.length; i += 3) {
			const indexA = indices[i] * 3;
			const indexB = indices[i + 1] * 3;
			const indexC = indices[i + 2] * 3;
	
			const A = new Vector3(vertices[indexA], vertices[indexA + 1], vertices[indexA + 2]);
			const B = new Vector3(vertices[indexB], vertices[indexB + 1], vertices[indexB + 2]);
			const C = new Vector3(vertices[indexC], vertices[indexC + 1], vertices[indexC + 2]);
	
			const AB = new Vector3().subVectors(B, A);
			const AC = new Vector3().subVectors(C, A);
	
			const cross = new Vector3().crossVectors(AB, AC);
			area += cross.length() / 2;
		}
	
		return area;
	}

	calculatePolygonAreaEarcut(vertices, indices) {
		let totalArea = 0;
		
		for (let i = 0; i < indices.length; i += 3) {
			const aIndex = indices[i] * 3;
			const bIndex = indices[i + 1] * 3;
			const cIndex = indices[i + 2] * 3;

			const a = new Vector3(vertices[aIndex], vertices[aIndex + 1], vertices[aIndex + 2]);
			const b = new Vector3(vertices[bIndex], vertices[bIndex + 1], vertices[bIndex + 2]);
			const c = new Vector3(vertices[cIndex], vertices[cIndex + 1], vertices[cIndex + 2]);
			
			const ab = a.distanceTo(b);
			const bc = b.distanceTo(c);
			const ca = c.distanceTo(a);
			
			const s = (ab + bc + ca) / 2;
			const area = Math.sqrt(s * (s - ab) * (s - bc) * (s - ca));

			totalArea += area;
		}

		return totalArea;
	}
	
	removeDrawings(){
		for(let i=0; i<this.areaLines.length; i++){
			const tempLine = this.areaLines[i];
			if(i==this.areaLines.length-1){
				this.scene.remove(tempLine.tube);
				this.scene.remove(tempLine.startSphere);
				this.scene.remove(tempLine.endSphere);
			}
			else{
				this.modelGroup.remove(tempLine.tube);
				this.modelGroup.remove(tempLine.startSphere);
				this.modelGroup.remove(tempLine.endSphere);
			}

			tempLine.tube.geometry.dispose();
			tempLine.tube.material.dispose();
			tempLine.startSphere.geometry.dispose();
			tempLine.startSphere.material.dispose();
			tempLine.endSphere.geometry.dispose();
			tempLine.endSphere.material.dispose();
		}
		this.areaLines = [];
		this.areaLinePoints=[];
		this.areaLineCount=0;
	}

	removeAreaMeasures(){
		for(let i=this.areaBoxes.length-1;i>=0;i--){
			if(this.areaBoxes[i].checked){
				const tempBoxMesh = this.areaBoxes[i].boxMesh;
				this.modelGroup.remove(tempBoxMesh);
				tempBoxMesh.geometry.dispose();
				tempBoxMesh.material.dispose();
				this.areaBoxes.splice(i,1);				

				const tempPolygon = this.polygons[i];
				const tempPolyMesh = tempPolygon.polygonMesh;
				this.modelGroup.remove(tempPolyMesh);
				tempPolyMesh.geometry.dispose();
				tempPolyMesh.material.dispose();

				for(let j=0; j<tempPolygon.areaLines.length; j++){
					const tempLine = tempPolygon.areaLines[j];
					this.modelGroup.remove(tempLine.tube);
					tempLine.tube.geometry.dispose();
					tempLine.tube.material.dispose();
					this.modelGroup.remove(tempLine.startSphere);
					tempLine.startSphere.geometry.dispose();
					tempLine.startSphere.material.dispose();
					this.modelGroup.remove(tempLine.endSphere);
					tempLine.endSphere.geometry.dispose();
					tempLine.endSphere.material.dispose();
				}
				this.polygons.splice(i,1);
				this.decreaseAreaCount();				
			}
		}
	}
}