<template>
  <div class="default_modal_wrapper">
    <div class="default_modal_dim" @click="closeAlert"></div>
    <div class="default_modal_container">
      <div class="default_modal_box">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainAlertFrame",
  methods: {
    closeAlert() {
      this.$store.commit("closeAlert");
    },
  },
};
</script>

<style scoped lang="scss">
.default_modal_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  @include flexbox;
  z-index: $zIndex_dim;
}

.default_modal_dim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  @include flexbox;
  background: rgba(0, 0, 0, 0.8);
  z-index: $zIndex_dim;
}

.default_modal_container {
  z-index: $zIndex_modal;
}

.default_modal_box {
  border-radius: 6px;
  padding: 40px 40px 35px;
  background: $main_black;
  color: $main_white;
}
</style>
