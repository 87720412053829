<template>
  <div class="mapping_status_modal" v-if="statusModalState">
    <div class="mapping_status_modal_header">
      <div class="mapping_status_modal_header_title">데이터 로그</div>
      <div
        class="mapping_status_modal_header_x"
        @click="$emit('changeStatusModalState', false)"
      ></div>
    </div>
    <div class="mapping_status_modal_body">
      <div
        class="mapping_status_modal_content"
        v-for="(msg, msgIndex) in msgs"
        :key="msgIndex"
        :style="{ color: getColor(msg.severity) }"
      >
        {{ getTitle(msg.severity) }} {{ msg.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["changeStatusModalState"],
  props: {
    statusModalState: Boolean,
    msgs: Array,
  },
  methods: {
    getColor(severity) {
      if (severity >= 0 && severity <= 3) {
        return "red";
      } else if (severity >= 4 && severity <= 5) {
        return "yellow";
      } else if (severity >= 6 && severity <= 7) {
        return "green";
      }
    },
    getTitle(severity) {
      const title = {
        0: "[EMERGENCY]",
        1: "[ALERT]",
        2: "[CRITICAL]",
        3: "[ERROR]",
        4: "[WARNING]",
        5: "[NOTICE]",
        6: "[INFO]",
        7: "[DEBUG]",
      };
      return title[severity] || "";
    },
  },
};
</script>

<style lang="scss">
.mapping_status_modal {
  position: absolute;
  top: 15%;
  left: 25%;
  width: 50%;
  height: 70%;
  background: rgba(23, 23, 23, 0.85);
  box-shadow: $main_box_shadow;
  z-index: 20;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
.mapping_status_modal_header {
  position: relative;
  @include flexbox;
  width: 100%;
  height: 50px;
  background: rgba(32, 32, 32, 0.85);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.mapping_status_modal_header_title {
  @include flexbox;
  width: 100px;
  font-size: 1.8rem;
  color: $main_white;
}
.mapping_status_modal_header_x {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50px;
  height: 100%;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 12px 12px;
  cursor: pointer;
}
.mapping_status_modal_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
}
.mapping_status_modal_body::-webkit-scrollbar {
  width: 5px;
}
/* 스크롤바 트랙 스타일 */
.mapping_status_modal_body::-webkit-scrollbar-track {
  background: transparent;
}
/* 스크롤바 손잡이 스타일 */
.mapping_status_modal_body::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.mapping_status_modal_body::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.mapping_status_modal_content {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  font-size: 1.2rem;
}
</style>
