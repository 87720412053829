<template>
  <div class="mapping_left_side">
    <div
      class="mapping_left_side_title"
      v-if="selectedSite"
      :style="{ width: getWidth(selectedSite.name) }"
    >
      {{ selectedSite.name }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedSite"]),
    getWidth() {
      return (siteName) => {
        if (siteName) {
          return siteName.length * 18 + 10 + "px";
        } else {
          return "70px";
        }
      };
    },
  },
  props: {
    site: Object,
  },
};
</script>

<style lang="scss">
.mapping_left_side {
  position: fixed;
  left: 0px;
  top: 70px;
  width: calc(100% / 12 * 3);
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  background: $main_white;
  background: rgba(23, 23, 23, 0.6);
  padding: 20px;
  margin: 15px 0px 15px 15px;
  box-shadow: $main_box_shadow;
  max-width: 300px;
  border-radius: 10px;
  z-index: 10;
}
.mapping_left_side_title {
  @include flexbox;
  gap: 10px;
  width: 100%;
  height: 30px;
  color: $main_white;
  font-size: 1.8rem;
  font-weight: 700;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
