<template>
  <div
    class="measure_crop button"
    :class="{ on: isXYZClicked }"
    @click="clickXYZ(true)"
  ></div>
  <div
    class="measure_polygon button"
    :class="{ on: !isXYZClicked }"
    @click="clickXYZ(false)"
  ></div>
</template>

<script>
export default {
  emits: ["changeXYZ"],
  props: {
    isXYZClicked: Boolean,
  },
  methods: {
    clickXYZ(boolean) {
      this.$emit("changeXYZ", boolean);
    },
  },
};
</script>

<style lang="scss">
.measure_crop {
  position: fixed;
  top: 85px;
  right: 60px;
  width: 40px;
  height: 40px;
  background-image: url("@/../public/images/cube_solid.svg");
  background-size: 20px 20px;
}
.measure_crop:hover {
  background-image: url("@/../public/images/cube_solid_hover.svg");
  background-size: 20px 20px;
}
.measure_crop.on {
  background-image: url("@/../public/images/cube_solid_hover.svg");
  background-size: 20px 20px;
}
.measure_polygon {
  position: fixed;
  top: 85px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-image: url("@/../public/images/polygon.svg");
  background-size: 20px 20px;
}
.measure_polygon:hover {
  background-image: url("@/../public/images/polygon_hover.svg");
  background-size: 20px 20px;
}
.measure_polygon.on {
  background-image: url("@/../public/images/polygon_hover.svg");
  background-size: 20px 20px;
}
</style>
