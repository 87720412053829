export const REGION_ARRAY = [
  { name: "서울", isChecked: false },
  { name: "부산", isChecked: false },
  { name: "인천", isChecked: false },
  { name: "대구", isChecked: false },
  { name: "대전", isChecked: false },
  { name: "광주", isChecked: false },
  { name: "울산", isChecked: false },
  { name: "세종", isChecked: false },
  { name: "경기", isChecked: false },
  { name: "충북", isChecked: false },
  { name: "충남", isChecked: false },
  { name: "전남", isChecked: false },
  { name: "경북", isChecked: false },
  { name: "경남", isChecked: false },
  { name: "강원", isChecked: false },
  { name: "전북", isChecked: false },
  { name: "제주", isChecked: false },
];

export const TYPE_ARRAY = [
  { name: "교량", isChecked: false },
  { name: "건물", isChecked: false },
  { name: "사면", isChecked: false },
  { name: "터널", isChecked: false },
  { name: "기타", isChecked: false },
];
