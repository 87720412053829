import * as THREE from 'three';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh'; // npm install three-mesh-bvh

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import fontJSON from 'three/examples/fonts/helvetiker_regular.typeface.json';

import LineMeasure from './LineMeasure';
import AreaMeasure from './AreaMeasure';
import AngleMeasure from './AngleMeasure';

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

// THREE.Cache.enabled = true;

const vertexShader = `
  varying float vIntensity;
  varying vec3 vColor;

  void main() {
    gl_PointSize = 1.0;

    #ifdef USE_INTENSITY
      vIntensity = intensity;
    #else
      vColor = color;
    #endif

    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

// Fragment Shader
const fragmentShader = `
  varying float vIntensity;
  varying vec3 vColor;

  vec3 calculateColor(float intensity) {
    float value = 1.0 - intensity;
    value = clamp(value, 0.0, 1.0);
    float h = value * 5.0 + 1.0;
    float k = floor(h);
    float f = h - k;
    if (int(k) % 2 == 0) {
      f = 1.0 - f;
    }
    float n = 1.0 - f;
    if (k <= 1.0) {
      return vec3(n, 0.0, 1.0);
    } else if (k == 2.0) {
      return vec3(0.0, n, 1.0);
    } else if (k == 3.0) {
      return vec3(0.0, 1.0, n);
    } else if (k == 4.0) {
      return vec3(n, 1.0, 0.0);
    } else {
      return vec3(1.0, n, 0.0);
    }
  }

  void main() {
    #ifdef USE_INTENSITY
      vec3 color = calculateColor(vIntensity / 255.0);
      gl_FragColor = vec4(color, 0.5);
    #else
      gl_FragColor = vec4(vColor, 0.5);
    #endif
  }
`;

export default class PCManager{
  constructor(containerElement3D, loading) {        
    this.container3d = containerElement3D;
    this.loading = loading;
    this.boundingBoxGroup = new THREE.Group();
    const loader = new FontLoader();
		this.font = loader.parse(fontJSON);

    this.radian = 0;

    const fps = 15;
    this.interval = 1000/fps;

    this.init();
  }

    ////////////// Initialize (start) //////////////
  init() {
    this.scene3d = new THREE.Scene();

    this.renderer3d = new THREE.WebGLRenderer({ preserveDrawingBuffer: true });
    this.renderer3d.setSize(this.container3d.offsetWidth, this.container3d.offsetHeight);
    this.renderer3d.sortObjects = true;
    this.container3d.appendChild(this.renderer3d.domElement);

    this.modelGroup3D = new THREE.Group();

    this.initView();

    // if(this.magnifierEl){
    //   this.magnifierEl.style.display = 'none';
    //   this.initMagnifierCanvas();
    // }

    this.initLineParams();
		this.initAreaParams();
		this.initAngleParams();		

    window.addEventListener('resize', this.onWindowResize.bind(this), false);

    this.material3d = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent :true,
      vertexColors: true,
    });

    this.render();
  }

  initView() {   
    this.camera3d = new THREE.PerspectiveCamera(75, this.container3d.offsetWidth / this.container3d.offsetHeight, 1, 1000);
    this.controls3d = new OrbitControls(this.camera3d, this.renderer3d.domElement);
    this.camera3d.position.set(0, 5, 50);
    this.controls3d.update();

    let ambientLight_3d = new THREE.AmbientLight(0xffffff); 
    this.scene3d.add(ambientLight_3d);
  }

  onWindowResize() {
    if(this.camera3d){
      this.camera3d.aspect = this.container3d.offsetWidth / this.container3d.offsetHeight;
      this.camera3d.updateProjectionMatrix();
      this.renderer3d.setSize(this.container3d.offsetWidth, this.container3d.offsetHeight);
    }
  }

  render(){
    let now,delta;
    let then = Date.now();
    const frame= () => {
      this.animationFrameId = requestAnimationFrame(frame);
      now = Date.now();
      delta = now - then;
      if(delta < this.interval) return

      for(let i=0; i<this.lineMeasure.getNumLineBoxes(); i++){
				const lineBox = this.lineMeasure.getLineBox(i);
				lineBox.boxMesh.lookAt(this.camera3d.position);
				this.updateBoxSizeRelativeToCamera(lineBox.boxMesh, this.camera3d);
			}
			for(let i=0; i<this.areaMeasure.getTextNum(); i++){
				const textBox = this.areaMeasure.getTextBox(i);
				textBox.boxMesh.lookAt(this.camera3d.position);
				this.updateBoxSizeRelativeToCamera(textBox.boxMesh, this.camera3d);
			}
			for(let i=0; i<this.angleMeasure.getNumAngleBoxes(); i++){
				const angleBox = this.angleMeasure.getAngleBox(i);
				angleBox.boxMesh.lookAt(this.camera3d.position);
				this.updateBoxSizeRelativeToCamera(angleBox.boxMesh, this.camera3d);
			}		

			for(let i=0; i<this.lineMeasure.getNumLines();i++){
				const tempLine = this.lineMeasure.getLine(i);
				this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera3d);
				this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera3d);
			}

			for(let i=0; i<this.areaMeasure.getLineNum();i++){
				const tempLine = this.areaMeasure.getAreaLine(i);
				this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera3d);
				this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera3d);
			}

			for(let i=0; i<this.areaMeasure.getPolygonNum();i++){
				const tempPolygon = this.areaMeasure.getPolygon(i);
				for(let j=0; j<tempPolygon.areaLines.length;j++){				
					this.updateBoxSizeRelativeToCamera(tempPolygon.areaLines[j].startSphere, this.camera3d);
					this.updateBoxSizeRelativeToCamera(tempPolygon.areaLines[j].endSphere, this.camera3d);
				}
			}

			for(let i=0; i<this.angleMeasure.getNumAngleLines();i++){
				const tempLine = this.angleMeasure.getAngleLine(i);
				this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera3d);
				this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera3d);
			}

			for(let i=0; i<this.angleMeasure.getNumAngles();i++){
				const tempAngles = this.angleMeasure.getAngle(i);
				for(let j=0; j<tempAngles.angleLines.length;j++){				
					this.updateBoxSizeRelativeToCamera(tempAngles.angleLines[j].startSphere, this.camera3d);
					this.updateBoxSizeRelativeToCamera(tempAngles.angleLines[j].endSphere, this.camera3d);
				}
			}

      this.renderer3d.render(this.scene3d,this.camera3d);
      then = now - (delta%this.interval);
    }
    this.animationFrameId = requestAnimationFrame(frame);
  }

  loadPCD(pcdFile) {
    return new Promise((resolve, reject) => {
      this.loading.startLoadingAnimation();
      const loader = new PCDLoader();
      loader.load(pcdFile, (points) => {
        
        const geometry = points.geometry;
        geometry.rotateX(-Math.PI / 2);

        this.boundingBox = new THREE.Box3().setFromObject(points);
        let boxHelper = new THREE.Box3Helper(this.boundingBox, 0x01fefa);
        this.boundingBoxGroup.add(boxHelper);

        this.material3d.onBeforeCompile = (shader) => {
          shader.vertexShader = '#define USE_INTENSITY\nattribute float intensity;\n' + vertexShader;
          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            'vIntensity = intensity;\n#include <begin_vertex>'
          );
          shader.fragmentShader = '#define USE_INTENSITY\n' + fragmentShader;
        };

        const pointCloud3D = new THREE.Points(geometry, this.material3d);
        this.modelGroup3D.add(pointCloud3D);

        const step = 10;
        const lineMaterial = new THREE.LineBasicMaterial({ color: 0x01fefa });

        for (let axis of ['x', 'y', 'z']) {
          let start = this.boundingBox.min[axis];
          let end = this.boundingBox.max[axis];
          for (let point = start; point <= end; point += step) {
            const points = [];
            const startPoint = new THREE.Vector3();
            const endPoint = new THREE.Vector3();

            startPoint[axis] = point;
            endPoint[axis] = point;
            
            if (axis === 'x') {
              startPoint['y'] = this.boundingBox.min['y'];
              startPoint['z'] = this.boundingBox.min['z'];
              endPoint['y'] = startPoint['y']; 
              endPoint['z'] = startPoint['z'] + 2;
              points.push(startPoint, endPoint);
              let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              let line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['y'] = this.boundingBox.min['y'];
              startPoint['z'] = this.boundingBox.max['z'];
              endPoint['y'] = startPoint['y'] + 2; 
              endPoint['z'] = startPoint['z'];
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['y'] = this.boundingBox.max['y'];
              startPoint['z'] = this.boundingBox.min['z'];
              endPoint['y'] = startPoint['y'] - 2; 
              endPoint['z'] = startPoint['z'] ;
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['y'] = this.boundingBox.max['y'];
              startPoint['z'] = this.boundingBox.max['z'];
              endPoint['y'] = startPoint['y']; 
              endPoint['z'] = startPoint['z'] - 2;
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

            }
            else if (axis === 'y') {
              startPoint['x'] = this.boundingBox.min['x'];
              startPoint['z'] = this.boundingBox.min['z'];
              endPoint['x'] = startPoint['x'];
              endPoint['z'] = startPoint['z'] + 2; 
              points.push(startPoint, endPoint);
              let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              let line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);
              
              startPoint['x'] = this.boundingBox.min['x'];
              startPoint['z'] = this.boundingBox.max['z'];
              endPoint['x'] = startPoint['x'] + 2;
              endPoint['z'] = startPoint['z']; 
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['x'] = this.boundingBox.max['x'];
              startPoint['z'] = this.boundingBox.min['z'];
              endPoint['x'] = startPoint['x'] - 2;
              endPoint['z'] = startPoint['z']; 
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['x'] = this.boundingBox.max['x'];
              startPoint['z'] = this.boundingBox.max['z'];
              endPoint['x'] = startPoint['x'];
              endPoint['z'] = startPoint['z'] - 2; 		
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);			

            }
            else { // z축
              startPoint['x'] = this.boundingBox.min['x'];
              startPoint['y'] = this.boundingBox.min['y'];
              endPoint['x'] = startPoint['x'] + 2; 
              endPoint['y'] = startPoint['y'];
              points.push(startPoint, endPoint);
              let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              let line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['x'] = this.boundingBox.min['x'];
              startPoint['y'] = this.boundingBox.max['y'];
              endPoint['x'] = startPoint['x']; 
              endPoint['y'] = startPoint['y'] - 2;
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['x'] = this.boundingBox.max['x'];
              startPoint['y'] = this.boundingBox.min['y'];
              endPoint['x'] = startPoint['x']; 
              endPoint['y'] = startPoint['y'] + 2;
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);

              startPoint['x'] = this.boundingBox.max['x'];
              startPoint['y'] = this.boundingBox.max['y'];
              endPoint['x'] = startPoint['x'] - 2; 
              endPoint['y'] = startPoint['y'];
              points.push(startPoint, endPoint);
              lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
              line = new THREE.Line(lineGeometry, lineMaterial);
              line.raycast = () => {};
              this.boundingBoxGroup.add(line);
            }
          }
        }
        this.scene3d.add(this.modelGroup3D);
        this.scene3d.add(this.boundingBoxGroup);

        this.loading.stopLoadingAnimation();
        resolve();
      },
      (xhr)=>{
        this.loading.updateLoadingText(xhr.timeStamp);
        if(xhr.lengthComputable){
          let progress = xhr.loaded/xhr.total*100;
          this.loading.$refs.progress_bar.value = progress;
        }
        else{
          this.loading.$refs.progress_bar.style.display = 'none'
        }
      },
      (error) => {
          reject(error); 
      });
    });  
  }

  updateBoxSizeRelativeToCamera(mesh, camera) {		
		const distance = mesh.position.distanceTo(camera.position);
		const scale = this.calculateScaleBasedOnDistance(distance);
		mesh.scale.set(scale, scale, scale);
	}

  calculateScaleBasedOnDistance(distance) {
		const baseDistance = 30;
		const baseScale = 1;
		return baseScale * (distance / baseDistance);
	}

  // hideMagnifier(){
	// 	this.magnifierEl.style.display = 'none';
	// 	this.controls3d.enabled = true;
	// }
	// showMagnifier(){
	// 	this.magnifierEl.style.display = 'block';
	// 	this.controls3d.enabled = false;
	// }

  initLineParams(){
		this.measureLineStart = false;
		this.lineMeasure = new LineMeasure(this.scene3d, this.modelGroup3D, this.font);
	}

	initAreaParams(){
		this.measureAreaStart = false;		
		this.areaMeasure = new AreaMeasure(this.scene3d, this.modelGroup3D, this.font);
	}

	initAngleParams(){
		this.measureAngleStart = false;
		this.angleMeasure = new AngleMeasure(this.scene3d, this.modelGroup3D, this.font);
	}

  setMeasureMethod(method){
    this.measureMethod = method;
    // this.showMagnifier();
  }

  ////////////// Clear Scene (start) //////////////
  removeObjectFromScene(scene, object) {      
    if (object.isMesh || object.isPoints) {
      if (object.geometry) {
        object.geometry.dispose();
      }
      if (object.material) {
        if (Array.isArray(object.material)) {
          object.material.forEach(material => material.dispose());
        }
        else {
          object.material.dispose();
        }
      }
    }
      
    scene.remove(object);
      
    if(object.children){
      while (object.children.length > 0) {
        this.removeObjectFromScene(object, object.children[0]);
      }
    }
  }

  dispose() {      
    cancelAnimationFrame(this.animationFrameId);      
    if(this.scene3d){
      while (this.scene3d.children.length > 0) {
        const object = this.scene3d.children[0];
        this.removeObjectFromScene(this.scene3d, object);
      }
      this.scene3d = null;
    }   
    if(this.renderer3d){
      this.renderer3d.dispose();
      this.renderer3d = null;
    }
    if(this.controls3d){
      this.controls3d.dispose();
      this.controls3d = null;
    }
    window.removeEventListener('resize', this.onWindowResize.bind(this));        
  }

  // initMagnifierCanvas() {
	// 	this.magnifierCanvas = document.createElement('canvas');
	// 	this.magnifierCanvas.width = 150;
	// 	this.magnifierCanvas.height = 150;
	// 	this.magnifierCanvas.style.borderRadius = '50%'; 
	// 	this.magnifierCanvas.style.position = 'absolute';
	// 	this.magnifierEl.appendChild(this.magnifierCanvas);
	// }
  onMouseMove(event){
		// if(this.shiftKeyDown) return;
    let rect = this.renderer3d.domElement.getBoundingClientRect();
  
    // const mouseX = event.clientX - rect.left;
    // const mouseY = event.clientY - rect.top;

    // this.renderMagnifierImage(mouseX, mouseY);
    // this.magnifierEl.style.left = `${event.clientX - this.magnifierCanvas.width / 2 - 80}px`;
    // this.magnifierEl.style.top = `${event.clientY - this.magnifierCanvas.height - 50}px`;

    // if(this.measureLineStart || this.measureAreaStart || this.measureAngleStart || this.polyCroppingStart){
    const mouse = new THREE.Vector2(((event.clientX - rect.left) / rect.width) * 2 - 1,-((event.clientY - rect.top) / rect.height) * 2 + 1);
    
    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = 0.05;
    raycaster.setFromCamera(mouse, this.camera3d);

    const intersections = raycaster.intersectObjects(this.scene3d.children);

    if (intersections.length > 0){
      const intersection = intersections[0];

      const intensities = intersection.object.geometry.attributes.intensity;

      if(this.preIntersection && this.preIntersection !== intersection){
        intensities.setX(this.preIntersection.index, this.originalIntensity);
        intensities.needsUpdate = true;
      }
      this.originalIntensity = intensities.getX(intersection.index);

      intensities.setX(intersection.index, 255);
      intensities.needsUpdate = true;

      this.preIntersection = intersection;

    //     if(this.measureLineStart){
    //       this.lineMeasure.setEndPoint(intersection);
    //       this.lineMeasure.drawMeasureLine(this.radian, false);
    //     }
    //     else if(this.measureAreaStart)
    //       this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(this.areaMeasure.getPointNum()-1), intersection, this.radian, false);
    //     else if(this.measureAngleStart)
    //       this.angleMeasure.drawAngleLine(this.angleMeasure.getAngleLinePoint(this.angleMeasure.getNumAngleLinePoints()-1), intersection, this.radian, false);
    //   }
    }		
	}

	onMouseDown(event){	
		if(this.shiftKeyDown) return;
		
		if (event.button === 0){
			let rect = this.renderer3d.domElement.getBoundingClientRect();

			const mouse = new THREE.Vector2(
				((event.clientX - rect.left) / rect.width) * 2 - 1,
				-((event.clientY - rect.top) / rect.height) * 2 + 1
			);

			const raycaster = new THREE.Raycaster();
			raycaster.params.Points.threshold = 0.05;
			raycaster.setFromCamera(mouse, this.camera3d);

			const intersections = raycaster.intersectObjects(this.scene3d.children);
			let boxSelected = this.selectBox(intersections);
			if(this.measureMethod == null || boxSelected) return;
			if (intersections.length > 0) {
				const intersection = intersections[0];

				switch(this.measureMethod){
					case "length":
						if (!this.measureLineStart) {  
							this.lineMeasure.setStartPoint(intersection.point);
							this.measureLineStart = true;
						} 
						else {          
							this.lineMeasure.setEndPoint(intersection.point);
							this.measureLineStart = false;						
							this.lineMeasure.drawMeasureLine(this.radian, true);
							this.lineMeasure.drawLineTextBox(this.radian, this.camera3d);
							this.lineMeasure.increaseLineCount();
						}
						break;
					case "area":
						if(!this.measureAreaStart){
							this.areaMeasure.pushAreaLinePoint(intersection.point);
							this.measureAreaStart = true;						
						}
						else{						
							if(this.areaMeasure.getPointNum() > 2){
								const distanceToStart = intersection.point.distanceTo(this.areaMeasure.getLinePoint(0));
								if(distanceToStart>0.5){
									this.areaMeasure.pushAreaLinePoint(intersection.point);
									const pointsNum = this.areaMeasure.getPointNum();
									this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-2), this.areaMeasure.getLinePoint(pointsNum-1), this.radian, true);
									this.areaMeasure.increaseAreaLineCount();
								}
								else{								
									const pointsNum = this.areaMeasure.getPointNum();
									this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-1), this.areaMeasure.getLinePoint(0), this.radian, true);
									this.areaMeasure.pushPolygon();
									this.areaMeasure.drawAreaPolygon(this.radian);
									this.areaMeasure.drawAreaTextBox(this.radian, this.camera3d);
									
									this.areaMeasure.resetAreaLineCount();
									this.areaMeasure.resetAreaLinePoints();
									this.areaMeasure.resetAreaLines();
									this.areaMeasure.increaseAreaCount();								
									this.measureAreaStart = false;
								}
							}
							else{
								this.areaMeasure.pushAreaLinePoint(intersection.point);
								const pointsNum = this.areaMeasure.getPointNum();
								this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-2), this.areaMeasure.getLinePoint(pointsNum-1), this.radian, true);
								this.areaMeasure.increaseAreaLineCount();							
							}
						}
						break;				
					case "angle":
						if(!this.measureAngleStart){
							this.angleMeasure.pushAngleLinePoint(intersection.point);
							this.measureAngleStart = true;
						}
						else{
							this.angleMeasure.pushAngleLinePoint(intersection.point);
							const pointsNum = this.angleMeasure.getNumAngleLinePoints();
							this.angleMeasure.drawAngleLine(this.angleMeasure.getAngleLinePoint(pointsNum-2), this.angleMeasure.getAngleLinePoint(pointsNum-1), this.radian, true);
							this.angleMeasure.increaseAngleLineCount();
	
							if(this.angleMeasure.getAngleLineCount()==2){
								this.angleMeasure.pushAngle();							
								this.angleMeasure.drawAngleCurve(this.radian, this.camera3d);
								this.angleMeasure.resetAngleLineCount();
								this.angleMeasure.resetAngleLines();
								this.angleMeasure.resetAngleLinePoints();
								this.angleMeasure.increaseAngleCount();
								this.measureAngleStart = false;
							}
						}
						break;
					default:
						break;
				}
			}
		}
	}
  // renderMagnifierImage(mouseX, mouseY) {			
	// 	const magnification = 2; 
	// 	const readWidth = this.magnifierCanvas.width / magnification;
	// 	const readHeight = this.magnifierCanvas.height / magnification;
	// 	const pixels = new Uint8Array(readWidth * readHeight * 4);

	// 	const startX = mouseX - (readWidth / 2);
	// 	const startY = (this.renderer3d.domElement.height - mouseY) - (readHeight / 2);

	// 	const tempCanvas = document.createElement('canvas');
	// 	tempCanvas.width = readWidth;
	// 	tempCanvas.height = readHeight;
	// 	const tempCtx = tempCanvas.getContext('2d');
		
	// 	const gl = this.renderer3d.getContext();
	// 	const format = gl.RGBA;
	// 	const type = gl.UNSIGNED_BYTE;		

	// 	gl.readPixels(startX, startY, readWidth, readHeight, format, type, pixels);
		
	// 	const imageData = new ImageData(new Uint8ClampedArray(pixels), readWidth, readHeight);
	// 	tempCtx.putImageData(imageData, 0, 0);			
		
	// 	const ctx = this.magnifierCanvas.getContext('2d');
	// 	ctx.clearRect(0, 0, this.magnifierCanvas.width, this.magnifierCanvas.height);
	// 	ctx.save();
	// 	ctx.scale(1, -1);
	// 	ctx.drawImage(tempCanvas, 0, -this.magnifierCanvas.height, this.magnifierCanvas.width, this.magnifierCanvas.height);
	// 	ctx.restore();

	// 	const centerSquareSize = 16;
	// 	let centerX = (this.magnifierCanvas.width - centerSquareSize) / 2;
	// 	let centerY = (this.magnifierCanvas.height - centerSquareSize) / 2;

	// 	ctx.strokeStyle = 'red'; 
	// 	ctx.lineWidth = 1; 
	// 	ctx.strokeRect(centerX, centerY, centerSquareSize, centerSquareSize); 

	// 	const lineLength = 60; 		
	// 	centerX = this.magnifierCanvas.width / 2;
	// 	centerY = this.magnifierCanvas.height / 2;

	// 	ctx.beginPath(); 
	// 	ctx.moveTo(centerX - lineLength, centerY); 
	// 	ctx.lineTo(centerX + lineLength, centerY); 
	// 	ctx.strokeStyle = 'red';
	// 	ctx.lineWidth = 1;
	// 	ctx.stroke();		

	// 	ctx.beginPath(); 
	// 	ctx.moveTo(centerX, centerY - lineLength); 
	// 	ctx.lineTo(centerX, centerY + lineLength); 
	// 	ctx.strokeStyle = 'blue';
	// 	ctx.stroke();

	// 	const circleRadius = 2; 
	// 	centerX = this.magnifierCanvas.width / 2;
	// 	centerY = this.magnifierCanvas.height / 2;

	// 	ctx.beginPath();
	// 	ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
	// 	ctx.fillStyle = 'white'; 
	// 	ctx.fill();

	// 	ctx.beginPath();
	// 	ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
	// 	ctx.fillStyle = 'red';
	// 	ctx.lineWidth = 1;
	// 	ctx.stroke();
	// }

  removeDrawings(){		
		switch(this.measureMethod){
			case "length":
				if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();	
				break;
			case "area":
				this.removeDrawingPolygon();
				break;
			case "angle":
				this.removeDrawingAngleLine();
				break;
			default:
				break;
		}
		// this.hideMagnifier();
		this.measureMethod = null;
	}

	removeDrawingLine(){
		this.lineMeasure.removeDrawings();
		this.measureLineStart = false;
	}

	removeDrawingPolygon(){		
		this.areaMeasure.removeDrawings();
		this.measureAreaStart=false;
	}

	removeDrawingAngleLine(){
		this.angleMeasure.removeDrawings();
		this.measureAngleStart=false;
	}

  deleteMeasurements(){
		if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();
		this.lineMeasure.removeLineMeasure();
		
		this.removeDrawingPolygon();
		this.areaMeasure.removeAreaMeasures();

		this.removeDrawingAngleLine();
		this.angleMeasure.removeAngleMeasures();
	}

	selectBox(intersections){
		let boxSelected = false;
		for (let i = 0; i < intersections.length; i++) {
			for(let j = 0; j < this.lineMeasure.getNumLineBoxes(); j++){
				const lineBox = this.lineMeasure.getLineBox(j);
				if (intersections[i].object === lineBox.boxMesh) {					
					if(lineBox.checked) {							
						intersections[i].object.material.color.set(0xFFFFFF);
						lineBox.checked = false;
					} 
					else{							
						intersections[i].object.material.color.set(0x00FFFF);
						lineBox.checked = true;
					}
					if(this.measureLineStart) this.removeDrawingLine();
					if(this.measureAreaStart) this.removeDrawingPolygon();
					if(this.measureAngleStart) this.removeDrawingAngleLine();
					boxSelected = true;
					break; 
				}	
			}
			if (boxSelected) break; 		
		}
		if(boxSelected) return boxSelected;

		const areaBoxes = this.areaMeasure.getTextBoxes();
		for (let i = 0; i < intersections.length; i++) {
			for(let j = 0; j < areaBoxes.length; j++){
				if (intersections[i].object === areaBoxes[j].boxMesh) {					
					if(areaBoxes[j].checked) {							
						intersections[i].object.material.color.set(0xFFFFFF);
						areaBoxes[j].checked = false;
					} 
					else{							
						intersections[i].object.material.color.set(0x00FFFF);
						areaBoxes[j].checked = true;
					}
					if(this.measureLineStart) this.removeDrawingLine();
					if(this.measureAreaStart) this.removeDrawingPolygon();
					if(this.measureAngleStart) this.removeDrawingAngleLine();
					boxSelected = true;
					break; 
				}	
			}
			if (boxSelected) break;
		}
		if(boxSelected) return boxSelected;

		for (let i = 0; i < intersections.length; i++) {
			for(let j = 0; j < this.angleMeasure.getNumAngleBoxes(); j++){
				const angleBox = this.angleMeasure.getAngleBox(j);
				if (intersections[i].object === angleBox.boxMesh) {
					if(angleBox.checked) {							
						intersections[i].object.material.color.set(0xFFFFFF);
						angleBox.checked = false;
					} 
					else{							
						intersections[i].object.material.color.set(0x00FFFF);
						angleBox.checked = true;
					}
					if(this.measureLineStart) this.removeDrawingLine();
					if(this.measureAreaStart) this.removeDrawingPolygon();
					if(this.measureAngleStart) this.removeDrawingAngleLine();
					boxSelected = true;
					break; 
				}	
			}
			if (boxSelected) break;
		}
		return boxSelected;
	}
  getShiftKey(keyDown){
		if(this.measureMethod != null){
      if(keyDown) this.controls3d.enabled = true;
      else this.controls3d.enabled = false;	
		}
		this.shiftKeyDown = keyDown;		
	}
  findClosestIntersection(raycaster, objects) {
    let closestIntersection = null;
    let closestDistance = Infinity;

    console.log(raycaster, objects);
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const intersection = raycaster.intersectObject(object, false)[0];  // 첫 번째 교차만 확인

      if (intersection && intersection.distance < closestDistance) {
        closestIntersection = intersection;
        closestDistance = intersection.distance;
        
        if (closestDistance < 0.01) {
            break;
        }
      }
    }

    return closestIntersection;
}
}