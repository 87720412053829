export default class Photo{
  constructor(photo){
    this.id = null;
    this.x = null;
    this.y = null;
    this.z = null;
    this.roll = null;
    this.pitch = null;
    this.yaw = null;
    this.photo = {
      id : null,
      albumId : null,
      cameraId : null,
      albumPhotoPath : null,
      albumPhotoThumbnailsPath : null,
      albumalbumPhotoResizedPathhotoPath : null
    }
    if(photo){
      this.id = photo.id;
      this.x = photo.x;
      this.y = photo.y;
      this.z = photo.z;
      this.roll = photo.roll;
      this.pitch = photo.pitch;
      this.yaw = photo.yaw;
      this.photo = {
        id : photo.photo.id,
        albumId : photo.photo.albumId,
        cameraId : photo.photo.cameraId,
        albumPhotoPath : photo.photo.albumPhotoPath,
        albumPhotoThumbnailsPath : photo.photo.albumPhotoThumbnailsPath,
        albumalbumPhotoResizedPathhotoPath : photo.photo.albumPhotoResizedPath
      }
    }
  }
}