<template>
  <component :is="$route.meta.layout || 'main'">
    <div id="user_page">
      <section class="title_area">
        <h2>
          <img
            src="@/../public/images/users_gear_regular_main.svg"
            alt="사용자 관리 아이콘"
          />
          <span>사용자 관리</span>
        </h2>
        <h3>사용자를 직접 생성하고 수정하며 관리할 수 있습니다.</h3>
        <DefaultSearchInput
          :placeHolder="'사용자 이름을 검색하세요.'"
          :hasRemoveButton="true"
          @handleKeywordSearch="handleKeywordSearch($event)"
          @handleRemoveInputValue="handleRemoveInputValue"
        />
      </section>
      <UserListManager
        :total="filteredTotal"
        @handleOpenUserAddForm="handleOpenUserAddForm"
      >
        <template v-if="isReady">
          <template v-if="filteredUserListArray.length > 0">
            <UserList
              :listType="deviceType === 'mobile' ? 'card' : 'table'"
              :userList="filteredUserListArray"
              :currentPage="currentPage"
              @handleOpenUserEditForm="handleOpenUserEditForm($event)"
            />
            <Pagination
              class="pagination_margin"
              :totalCount="filteredTotal"
              @handleClickPaginationNumber="handleClickPaginationNumber($event)"
              :initialPageOnMount="currentPage"
              :itemsPerPage="itemsPerPage"
            />
          </template>
          <template v-else><NoResult /></template>
        </template>
        <template v-else>
          <div class="empty_result_wrap">
            <EmptyResult>아직 등록된 사용자가 없습니다.</EmptyResult>
          </div>
        </template>
      </UserListManager>
      <CustomModalFrame v-if="isModalOpen" @closeModal="handleCloseCustomFrame">
        <UserAddFormContent
          v-if="modalName === 'add'"
          :uploadedImageUrl="uploadedImageUrl"
          @handleCloseCustomFrame="handleCloseCustomFrame"
          @handleUploadFile="handleUploadFile($event)"
          @handleAddUser="handleAddUser($event)"
          :userListArray="userListArray"
        />
        <UserEditFormContent
          :editMode="'userInfo'"
          :uploadedImageUrl="uploadedImageUrl"
          :selectedUserInfo="selectedUserInfo"
          :userListArray="userListArray"
          :isDeleted="isDeleted"
          v-if="modalName === 'edit'"
          @handleCloseCustomFrame="handleCloseCustomFrame"
          @handleUploadFile="handleUploadFile($event)"
          @handleRemoveUser="handleRemoveUser($event)"
          @handleEditUser="handleEditUser($event)"
          @handleRemoveProfileImage="handleRemoveProfileImage"
        />
      </CustomModalFrame>
    </div>
  </component>
</template>
<script>
import * as RESTAPI from "@/shared/communication/RESTAPI";

import DefaultSearchInput from "@/component/input/DefaultSearchInput.vue";
import UserList from "./component/UserList.vue";
import UserListManager from "./component/UserListManager.vue";
import CustomModalFrame from "@/component/modal/custom/CustomModalFrame.vue";
import UserAddFormContent from "./component/UserAddFormContent.vue";
import UserEditFormContent from "./component/UserEditFormContent.vue";
import Pagination from "@/component/pagination/Pagination.vue";
import { paginateArray } from "@/shared/util/paginateArray";
import NoResult from "@/component/no-result/NoSearchResult.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import EmptyResult from "@/component/no-result/EmptyResult.vue";

export default {
  name: "UserPage",
  components: {
    DefaultSearchInput,
    UserList,
    UserListManager,
    CustomModalFrame,
    UserAddFormContent,
    UserEditFormContent,
    Pagination,
    NoResult,
    EmptyResult,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["deviceType"]),
  },
  data() {
    return {
      isReady: false,
      userListArray: [],
      filteredUserListArray: [],
      isModalOpen: false,
      modalName: "",
      selectedUserInfo: null,
      selectedPositionValue: null,
      itemsPerPage: 6,
      currentPage: 1,
      searchKeyword: "",
      total: 0,
      filteredTotal: 0,
      uploadedImageUrl: null,
      uploadedImageFile: null,
      isDeleted: false,
    };
  },
  methods: {
    /**
     * 유저 리스트 가져오기
     */
    async setUserList() {
      this.$store.commit("setIsLoading", true);

      const data = await RESTAPI.getUserList(this.user.affiliation.id);

      try {
        if (data.success) {
          this.isReady = true;

          // 배열 최신순 부터 나올 수 있도록 정렬
          const reversedArray = data.result;

          // 배열에서 본인은 삭제
          const newDeleteMeArray = reversedArray.filter(
            (item) => item.id !== this.user.id
          );

          this.userListArray = newDeleteMeArray;

          this.total = newDeleteMeArray.length;
          this.filteredTotal = newDeleteMeArray.length;

          this.filteredUserListArray = paginateArray(
            newDeleteMeArray,
            this.itemsPerPage
          );

          return;
        }
        this.isReady = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 리스트 초기화 할 때 사용하는 함수 (배열 새로 할당 및 페이지네이션 부여 / 모달 창 닫기 해야할 때 사용한다.)
     */
    updateUserList() {
      this.filteredUserListArray = paginateArray(
        this.userListArray,
        this.itemsPerPage
      );

      this.handleCloseCustomFrame();
      this.total = this.userListArray.length;
      this.filteredTotal = this.userListArray.length;
    },
    /**
     * 사용자 추가 모달 열기 기능
     */
    async handleOpenUserAddForm() {
      this.isModalOpen = true;
      this.modalName = "add";
    },
    /**
     * 사용자 수정 모달 열기 기능
     */
    async handleOpenUserEditForm(userInfo) {
      this.isModalOpen = true;
      this.modalName = "edit";
      this.selectedUserInfo = userInfo;
      this.$store.commit("setSelectedUserInfo", userInfo);
    },
    /**
     * 모달 닫기
     */
    handleCloseCustomFrame() {
      this.isModalOpen = false;
      this.modalName = "";
      this.$store.commit("setSelectedUserInfo", null);

      this.uploadedImageUrl = null;
      this.uploadedImageFile = null;
      this.isDeleted = false;
    },
    /**
     * 사용자 검색 기능
     */
    handleKeywordSearch(keyword) {
      this.searchKeyword = keyword;
      this.handleFilterArray();
    },
    handleFilterArray() {
      this.currentPage = 1;
      const keyword = this.searchKeyword
        ? this.searchKeyword.toLowerCase()
        : ""; // 검색어가 빈 문자열이면 빈 문자열 유지

      // 원본 데이터를 사용하여 필터링 (this.facilities)
      const filteredArray = this.userListArray.filter((user) => {
        // 조건이 null이거나 빈 문자열이면 해당 조건을 무시
        const matchesName = keyword
          ? user.fullName && user.fullName.toLowerCase().includes(keyword)
          : true; // keyword가 비어있으면 무시

        return matchesName;
      });

      this.filteredTotal = filteredArray.length;
      // 필터된 배열을 잘라서 this.filteredFacilities에 넣어줌
      this.filteredUserListArray = paginateArray(
        filteredArray,
        this.itemsPerPage
      );
    },
    /**
     * 프로필 이미지 파일 업로드
     */
    async handleUploadFile(file) {
      let image = window.URL.createObjectURL(file);
      this.uploadedImageFile = file;
      this.uploadedImageUrl = image;
      this.isDeleted = false;
    },
    /**
     * 유저 추가 기능
     */
    async handleAddUser(userInfo) {
      this.$store.commit("setIsLoading", true);
      // FormData 생성
      // const formData = new FormData();

      // // userInfo 객체를 문자열로 변환하여 request에 추가
      // formData.append(
      //   "request",
      //   new Blob([JSON.stringify(userInfo)], { type: "application/json" })
      // );

      // // 이미지 파일이 있으면 추가, 없으면 null 추가

      // if (this.uploadedImageFile) {
      //   formData.append("userProfile", this.uploadedImageFile);
      // } else {
      //   formData.append("userProfile", null);
      // }

      try {
        const userData = await RESTAPI.postUser(userInfo);

        if (userData.status === 409) {
          return this.$store.commit(
            "openAlert",
            `이미 존재하는 아이디입니다. 다시 설정해 주세요.`
          );
        }

        if (userData.status === 403) {
          return this.$store.commit(
            "openAlert",
            `관리자 권한이 있는 계정만 사용자를 추가할 수 있습니다.`
          );
        }

        if (userData.status === 400) {
          return this.$store.commit(
            "openAlert",
            `에러 발생 : ${userData.data.error}<br>새로고침하거나 다시 시도해 주세요.`
          );
        }

        if (userData.success) {
          /**
           * ? : 이미지 파일이 존재한다면 formData로 전송
           */

          const formData = new FormData();

          if (this.uploadedImageFile) {
            // 프로필 이미지 업로드하면 file object
            formData.append("userProfile", this.uploadedImageFile);
          } else if (this.isDeleted) {
            // 프로필 이미지 삭제하면 빈 값
            formData.append(
              "userProfile",
              new File([], "", { type: "text/plain" })
            );
          }

          // FormData 확인용 로그
          // for (let pair of formData.entries()) {
          //   console.log(`${pair[0]}:`, pair[1]);
          // }

          const thumbnailData = await RESTAPI.postUserThumbnail(
            userData.result.id,
            formData
          );

          let newUserData = userData.result;
          newUserData.profileImageUrl = thumbnailData.result.profileImageUrl;

          this.userListArray = [newUserData, ...this.userListArray];
          this.updateUserList();
        }
      } catch (error) {
        console.log("postUser error");
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 삭제
     */
    async handleRemoveUser(selectedUserInfo) {
      this.selectedUserInfo = selectedUserInfo;

      this.$store.commit("setIsLoading", true);
      try {
        const data = await RESTAPI.deleteUser(
          this.user.id,
          this.selectedUserInfo.id
        );

        if (data.status === 400) {
          return this.$store.commit(
            "openAlert",
            `에러 발생 : ${data.data.error}<br>새로고침하거나 다시 시도해 주세요.`
          );
        }

        if (data.success) {
          this.handleCloseCustomFrame();

          const removeUser = this.userListArray.find(
            (user) => user.id === this.selectedUserInfo.id
          );

          if (removeUser) {
            const newUsers = this.userListArray.filter(
              (user) => user.id !== this.selectedUserInfo.id
            );

            this.userListArray = newUsers;
            this.updateUserList();
            this.currentPage = 1;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 수정
     */
    async handleEditUser(newUserInfo) {
      this.$store.commit("setIsLoading", true);

      try {
        /**
         * ? : 이미지 파일을 제외한 데이터 전송
         */
        const editData = await RESTAPI.patchUser(
          this.selectedUserInfo.id,
          newUserInfo
        );

        if (editData.success) {
          // 조건에 맞는 객체의 인덱스 찾기
          const editUserIndex = this.userListArray.findIndex(
            (user) => user.id === editData.result.id
          );

          // 인덱스가 존재하면 해당 객체를 삭제하고 data로 대체
          if (editUserIndex !== -1) {
            this.userListArray.splice(editUserIndex, 1, editData.result);

            this.filteredUserListArray = paginateArray(
              this.userListArray,
              this.itemsPerPage
            );

            this.total = this.userListArray.length;
            this.filteredTotal = this.userListArray.length;
          }
          this.handleCloseCustomFrame();
        }

        if (editData.status === 415) {
          return this.$store.commit(
            "openAlert",
            `에러 발생 : ${editData.data.error}<br>새로고침하거나 다시 시도해 주세요.`
          );
        }
        /**
         * ? : 이미지 파일이 존재한다면 formData로 전송
         */

        if (this.uploadedImageFile || this.isDeleted) {
          const formData = new FormData();

          if (this.uploadedImageFile) {
            // 프로필 이미지 업로드하면 file object
            formData.append("userProfile", this.uploadedImageFile);
          } else if (this.isDeleted) {
            // 프로필 이미지 삭제하면 빈 값
            formData.append(
              "userProfile",
              new File([], "", { type: "text/plain" })
            );
          }

          // FormData 확인용 로그
          for (let pair of formData.entries()) {
            console.log(`${pair[0]}:`, pair[1]);
          }

          const thumbnailData = await RESTAPI.postUserThumbnail(
            this.selectedUserInfo.id,
            formData
          );

          console.log("thumbnail data");
          console.log(thumbnailData);
        }
      } catch (error) {
        console.log("수정 실패");
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 프로필 이미지 삭제
     */
    handleRemoveProfileImage() {
      this.uploadedImageUrl = null;
      this.uploadedImageFile = "";
      this.isDeleted = true;
    },
    /**
     * 페이지 네이션 숫자 클릭 동작
     */
    handleClickPaginationNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
    /**
     * 검색 인풋 value 값 삭제하거나 없을 때 동작
     */
    ...mapActions(["updateDeviceType"]),
    handleRemoveInputValue() {
      this.updateUserList();
    },
    /**
     * 윈도우 너비에 따른 디바이스 사이즈 디텍하기ㄴ
     */
    handleResize() {
      this.updateDeviceType();
    },
  },
  mounted() {
    this.setUserList();
    this.handleResize(); // 초기 화면 사이즈 체크
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
#user_page {
  padding-top: 71px;
  background: $main_black;
  min-height: 100vh;

  @include mobile {
    padding-top: 28px;
  }

  .empty_result_wrap {
    padding: 100px 0 0;
  }

  h2 {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 12px;
    @include flexbox(center, flex-start, row);
    gap: 12px;

    @include tablet {
      font-size: 2.8rem;
      margin-bottom: 8px;
    }

    @include mobile {
      font-size: 2.4rem;
    }

    img {
      font-size: 0;
      width: 34px;
      height: 34px;
      object-fit: contain;

      @include tablet {
        width: 30px;
        height: 30px;
      }
    }
  }

  h3 {
    font-size: 2.4rem;
    margin-bottom: 60px;

    @include tablet {
      font-size: 2rem;
      margin-bottom: 45px;
    }

    @include mobile {
      font-size: 1.6rem;
      margin-bottom: 25px;
    }
  }

  section {
    @include maxInner(1280px);
    color: $main_white;
  }

  .title_area {
    margin-top: 60px;
  }

  .pagination_margin {
    margin-top: 50px;
  }
}
</style>
