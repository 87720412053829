<template>
  <div class="route_modal_wrap">
    <h2 v-if="emptyMapUrlCount === 0">작업에 앞서, 매핑 생성이 필요해요.</h2>
    <h2 v-else>어떤 작업이 필요하세요?</h2>
    <DefaultSelectBox
      v-if="emptyMapUrlCount > 0"
      :className="'full focus'"
      :selectedName="selectedMapName"
      :defaultName="reversedMaps[0].createdDatetime"
      :isOptionOpen="isMapOpen"
      :optionTag="'최신'"
      :needOptionTag="selectedMapName === reversedMaps[0].createdDatetime"
      :optionTagClassName="'state_badge blue'"
      @update:isOptionOpen="isMapOpen = $event"
      @controlOptionToggle="mapOptionToggle"
      :style="'margin-bottom: 18px'"
    >
      <ul>
        <template v-if="reversedMaps[0].url !== ''">
          <li
            v-for="(map, index) in reversedMaps"
            :key="map.id"
            @click="mapOptionClick(map)"
            :class="
              map.createdDatetime === this.selectedMapName ? 'active' : ''
            "
          >
            <p>{{ map.createdDatetime }}</p>
            <div v-if="index === 0" class="state_badge blue">최신</div>
          </li></template
        >
      </ul>
    </DefaultSelectBox>
    <div class="route_list">
      <button type="button" @click="mappingRoute">매핑</button>
      <!-- <button type="button" @click="newMapping" v-else>새로운 맵 생성</button> -->
      <button
        type="button"
        :disabled="emptyMapUrlCount === 0"
        @click="$emit('handleRouteInspection')"
      >
        인스펙션
      </button>
      <button
        type="button"
        :disabled="emptyMapUrlCount === 0"
        @click="$emit('handleRouteReport')"
      >
        레포트
      </button>
    </div>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { filterEmptyMapUrl } from "@/shared/util/filterEmptyMapUrl";
import { mapState } from "vuex";

export default {
  name: "RouteModalContent",
  emits: [
    "handleMappingRoute",
    "handleSaveMapInfo",
    "handleRouteInspection",
    "handleRouteReport",
  ],
  computed: {
    ...mapState(["selectedFacility"]),
    reversedMaps() {
      // 원본 배열 수정하지 않고 거꾸로 출력
      if (this.selectedFacility?.maps) {
        const reversedArray = this.selectedFacility.maps.slice().reverse();

        const newArray = reversedArray.filter((map) => map.url !== "");
        this.$store.commit("setSelectedFacilityMap", newArray[0]);
        return newArray;
      } else {
        return null;
      }
    },
  },
  components: {
    DefaultSelectBox,
  },
  data() {
    return {
      isMapOpen: false,
      selectedMapName: null,
      emptyMapUrlCount: null,
    };
  },
  methods: {
    filterEmptyMapUrl,
    /**
     * 맵 옵션 토글
     */
    mapOptionToggle() {
      this.isMapOpen = !this.isMapOpen;
    },
    /**
     * 맵 옵션 클릭 동작
     */
    mapOptionClick(selectedMap) {
      this.selectedMapName = selectedMap.createdDatetime;
      this.$emit("handleSaveMapInfo", selectedMap);
      this.isMapOpen = false;
    },
    mappingRoute() {
      let mappingType;
      if (this.selectedFacility.maps === null) {
        mappingType = "first";
      } else if (this.selectedFacility.maps[0].url === "") {
        mappingType = "exist";
      } else {
        mappingType = "new";
      }
      this.$emit("handleMappingRoute", mappingType);
    },
  },
  mounted() {
    this.emptyMapUrlCount = filterEmptyMapUrl(
      this.selectedFacility?.maps
    ).length;
    if (this.reversedMaps?.length > 0) {
      this.selectedMapName = this.reversedMaps[0].createdDatetime;
    }
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 2.4rem;
  margin-bottom: 30px;

  @include mobile {
    font-size: 1.8rem;
  }
}

.route_modal_wrap {
  padding: 30px;

  @include mobile {
    max-width: 400px;
    min-width: 300px;
    width: 100%;
  }
}

.route_list {
  @include flexbox(center, center, row);
  gap: 12px;

  @include mobile {
    @include flexbox(flex-start, flex-start, column);
  }

  button {
    @include flexbox;
    width: 200px;
    padding: 80px 0;
    font-size: 3rem;
    font-weight: 500;
    font-size: 1.8rem;
    color: $main_white;
    border: 1px solid $black_252530;
    transition: 0.2s;

    @include mobile {
      width: 100%;
      padding: 30px 0;
      font-size: 1.6rem;
    }

    &:hover {
      color: $main_color;
      border: 1px solid $main_color;
    }

    &:disabled {
      color: $black_353542;

      &:hover {
        color: $black_353542;
        border: 1px solid $black_252530;
      }
    }
  }
}
</style>
