/**
 * 버튼 클릭시 previousSibling에 위치한 input을 찾아 type을 토글시켜준다.
 * @param {*} event
 * @returns 'password' | 'text'
 */

export const changeSiblingInputType = (event) => {
  let targetInput = event.target.previousSibling;

  if (targetInput.type === 'text') {
    return (targetInput.type = 'password');
  }
  return (targetInput.type = 'text');
};
