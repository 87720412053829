<template>
  <div id="droneLog">
    <div class="search_area">
      <DefaultSelectBox
        class="drone_select_box"
        :className="`focus`"
        :isOptionOpen="isDroneIdOptionOpen"
        :defaultName="`드론 아이디`"
        :selectedName="
          clickedDroneOptionValue
            ? clickedDroneOptionValue?.droneSerialNumber
            : null
        "
        @update:isOptionOpen="isDroneIdOptionOpen = $event"
        @controlOptionToggle="controlOptionToggle"
      >
        <ul>
          <li
            v-for="drone in droneIdList"
            :key="drone.id"
            @click="handleClickOption(drone)"
            :class="
              drone.droneSerialNumber ===
              this.clickedDroneOptionValue?.droneSerialNumber
                ? 'active'
                : ''
            "
          >
            <p>{{ drone.droneSerialNumber }}</p>
          </li>
        </ul>
      </DefaultSelectBox>
      <DefaultSearchInput
        ref="searchEl"
        :placeHolder="'날짜를 검색하세요.'"
        @handleKeywordSearch="handleKeywordSearch($event)"
      />
    </div>

    <div
      class="filter_result_group"
      v-if="searchKeyword || clickedDroneOptionValue"
    >
      <ClearButton @handleClear="handleClearFilter" />
    </div>
    <template v-if="filteredDroneLogList?.length > 0">
      <ul class="log_list">
        <li
          v-for="log in filteredDroneLogList[this.currentPage - 1]"
          :key="log.id"
        >
          <div class="info_group">
            <div class="file_icon"></div>
            <div class="info_box">
              <p>{{ extractOnlyDateFromFilePath(log.logUrl) }}.log</p>
              <div class="small_info_box">
                <span>드론 아이디</span>
                <strong>{{ log.droneSerialNumber }}</strong>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="default_border_hover_button"
            @click="handleClickDownload(log)"
          >
            다운로드
          </button>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="no_result_area">
        <NoSearchResult />
      </div>
    </template>
    <Pagination
      :totalCount="filteredTotal"
      @handleClickPaginationNumber="handleClickPaginationNumber($event)"
      :initialPageOnMount="currentPage"
      :itemsPerPage="itemsPerPage"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

import * as RESTAPI from "@/shared/communication/RESTAPI";
import Drone from "@/model/Drone.js";

import DefaultSearchInput from "../input/DefaultSearchInput.vue";
import DefaultSelectBox from "../select/DefaultSelectBox.vue";
import { DRONE_LOG } from "./mock";
import { paginateArray } from "@/shared/util/paginateArray";
import Pagination from "../pagination/Pagination.vue";
import ClearButton from "../button/ClearButton.vue";
import NoSearchResult from "../no-result/NoSearchResult.vue";
import { extractOnlyDateFromFilePath } from "@/shared/util/extractOnlyDateFromFilePath";

export default {
  name: "DroneLogDownloadContent",
  computed: {
    ...mapState(["user"]),
  },
  components: {
    DefaultSearchInput,
    DefaultSelectBox,
    Pagination,
    NoSearchResult,
    ClearButton,
  },
  data() {
    return {
      DRONE_LOG,
      isDroneIdOptionOpen: false,
      clickedDroneOptionValue: null,
      droneIdList: [],
      droneLogList: null,
      filteredDroneLogList: null,
      searchKeyword: "",
      itemsPerPage: 5,
      currentPage: 1,
      total: 0,
      filteredTotal: 0,
    };
  },
  methods: {
    extractOnlyDateFromFilePath,
    /**
     * 비행 로그 뽑기
     */
    async setDroneSystemLogList() {
      try {
        const dataLog = await RESTAPI.getDroneSystemLogList(this.user.id);
        if (dataLog.data.success) {
          let result = dataLog.data.result;

          // 혹시 url이 없는 아이템이 있다면 제외하고 새로운 배열 반환한다.
          const filterEmptyUrl = result.filter((log) => log.logUrl !== "");

          this.droneLogList = filterEmptyUrl;
          this.filteredDroneLogList = paginateArray(
            filterEmptyUrl,
            this.itemsPerPage
          );

          this.total = filterEmptyUrl.length;
          this.filteredTotal = filterEmptyUrl.length;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlOptionToggle() {
      this.isDroneIdOptionOpen = !this.isDroneIdOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickOption(value) {
      this.currentPage = 1;
      this.isDroneIdOptionOpen = false;
      this.clickedDroneOptionValue = value;
      this.handleFilterArray();
    },
    /**
     * 드론 아이디 받기
     */
    async getDroneIdList() {
      try {
        const DroneIdData = await RESTAPI.getDrones(this.user.affiliation.id);
        const droneIdList = DroneIdData.data.result;
        for (let i = 0; i < droneIdList.length; i++) {
          this.droneIdList.push(new Drone(droneIdList[i]));
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 드론 리스트 초기화 할 때 사용하는 함수 (배열 새로 할당 및 페이지네이션 부여 / 모달 창 닫기 해야할 때 사용한다.)
     */
    updateDroneLogList() {
      this.filteredDroneLogList = paginateArray(
        this.droneLogList,
        this.itemsPerPage
      );

      this.total = this.droneLogList.length;
      this.filteredTotal = this.droneLogList.length;
    },
    /**
     * 비행 기록 파일 검색 기능
     */
    handleKeywordSearch(keyword) {
      this.searchKeyword = keyword;
      this.handleFilterArray();
    },
    handleFilterArray() {
      this.currentPage = 1;
      const keyword = this.searchKeyword
        ? this.searchKeyword.toLowerCase()
        : ""; // 검색어가 빈 문자열이면 빈 문자열 유지

      // 드론 아이디가 선택된 값이 있을 때 ? droneSerialNumber 반환 : null
      const droneId = this.clickedDroneOptionValue
        ? this.clickedDroneOptionValue.droneSerialNumber
        : null; // typeName이 null일 수 있음

      // 원본 데이터를 사용하여 필터링
      const filteredArray = this.droneLogList.filter((drone) => {
        // 조건이 null이거나 빈 문자열이면 해당 조건을 무시
        const matchesName = keyword
          ? extractOnlyDateFromFilePath(drone.logUrl)
              .toLowerCase()
              .includes(keyword)
          : true; // keyword가 비어있으면 무시

        // droneId가 없으면 항상 true를 반환하도록해서 검색되지 않도록 하였음
        const matchesDroneId = droneId
          ? drone.droneSerialNumber === droneId
          : true;

        return matchesName && matchesDroneId;
      });

      console.log(filteredArray);

      this.filteredTotal = filteredArray.length;
      // 필터된 배열을 잘라서 this.filteredFacilities에 넣어줌
      this.filteredDroneLogList = paginateArray(
        filteredArray,
        this.itemsPerPage
      );
    },
    /**
     * 필터 초기화 버튼
     */
    handleClearFilter() {
      this.searchKeyword = "";
      this.clickedDroneOptionValue = null;

      this.updateDroneLogList();

      const searchArea = this.$refs.searchEl.$el;
      const specificInput = searchArea.querySelector("#searchInput");
      if (specificInput) {
        specificInput.value = "";
      }
    },
    /**
     * 비행 로그 다운로드 기능
     */
    handleClickDownload(log) {
      let fileUrl = log.logUrl;
      let link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "log");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * 페이지 네이션 숫자 클릭 동작
     */
    handleClickPaginationNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  mounted() {
    this.getDroneIdList();
    this.setDroneSystemLogList();
  },
};
</script>
<style lang="scss" scoped>
#droneLog {
  margin-top: 20px;

  .default_border_hover_button {
    padding: 8px 17px;
  }

  .search_area {
    @include flexbox(flex-start, flex-start, row);
    gap: 10px;
    margin-bottom: 18px;

    .drone_select_box {
      min-width: 98px;
    }
  }

  .filter_result_group {
    @include flexbox(center, flex-end, row);
    margin-bottom: 12px;

    .clear_button {
      @include flexbox;
      gap: 4px;
      color: $main_white;

      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("@/../public/images/arrow_rotate_white.svg") no-repeat
          center;
        background-size: contain;
      }
    }
  }

  .no_result_area {
    min-height: 338px;
  }

  .log_list {
    @include flexbox(center, flex-start, column);
    gap: 12px;
    margin-bottom: 20px;
    min-height: 376px;

    li {
      @include flexbox(center, space-between, row);
      padding: 14px 18px;
      background: $black_26262d;
      border-radius: 10px;
      width: 100%;

      @include mobile {
        padding: 12px 18px;
      }

      .info_group {
        @include flexbox(center, space-between, row);
        gap: 12px;

        .file_icon {
          width: 20px;
          height: 20px;
          background: url("@/../public/images/file_line_white.svg") no-repeat
            center;
          background-size: contain;
        }

        p {
          font-size: 1.4rem;
          margin-bottom: 4px;
        }

        .small_info_box {
          @include flexbox(center, flex-start, row);
          gap: 4px;

          span {
            font-size: 1.2rem;
            /* color: $gray_6e6e82; */
            color: $gray_9fa6b2;
          }

          strong {
            font-size: 1.2rem;
            color: $gray_9fa6b2;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
