<template>
  <div
    class="pcd_measure_height_range_contatiner"
    :style="{ height: rangeAxis === 3 ? '80px' : '120px' }"
  >
    <div class="pcd_measure_range_item">
      <div
        class="pcd_measure_axis_radio_button"
        :class="{ on: rangeAxis == 1 }"
        @click="setRangeAxis(1)"
      ></div>
      <div class="pcd_measure_axis_radio_discript">X</div>
      <div
        class="pcd_measure_axis_radio_button"
        :class="{ on: rangeAxis == 2 }"
        @click="setRangeAxis(2)"
      ></div>
      <div class="pcd_measure_axis_radio_discript">Y</div>
      <div
        class="pcd_measure_axis_radio_button"
        :class="{ on: rangeAxis == 0 }"
        @click="setRangeAxis(0)"
      ></div>
      <div class="pcd_measure_axis_radio_discript">Z</div>
      <div
        class="pcd_measure_axis_radio_button"
        :class="{ on: rangeAxis == 3 }"
        @click="setRangeAxis(3)"
      ></div>
      <div class="pcd_measure_axis_radio_discript">Rot</div>
    </div>
    <div v-if="rangeAxis != 3" class="pcd_measure_range_item">
      <div class="pcd_measure_range_title">Min</div>
      <div
        class="pcd_measure_range_image_up button"
        @click="clickMinUp()"
      ></div>
      <div
        class="pcd_measure_range_image_down button"
        @click="clickMinDown()"
      ></div>
      <input
        v-if="rangeAxis == 0"
        type="range"
        step="0.1"
        :min="rangeLimit.minHeight"
        :max="rangeLimit.maxHeight"
        :value="range.minHeight"
        @input="updateHeightRangeMin"
      />
      <input
        v-else-if="rangeAxis == 1"
        type="range"
        step="0.1"
        :min="rangeLimit.minWidth"
        :max="rangeLimit.maxWidth"
        :value="range.minWidth"
        @input="updateWidthRangeMin"
      />
      <input
        v-else-if="rangeAxis == 2"
        type="range"
        step="0.1"
        :min="rangeLimit.minDepth"
        :max="rangeLimit.maxDepth"
        :value="range.minDepth"
        @input="updateDepthRangeMin"
      />
      <input
        v-if="rangeAxis == 0"
        type="text"
        :placeholder="range.minHeight"
        :value="range.minHeight"
        class="measure_range_input input"
        @blur="setMinRange"
      />
      <input
        v-else-if="rangeAxis == 1"
        type="text"
        :placeholder="range.minWidth"
        :value="range.minWidth"
        class="measure_range_input input"
        @blur="setMinRange"
      />
      <input
        v-else-if="rangeAxis == 2"
        type="text"
        :placeholder="range.minDepth"
        :value="range.minDepth"
        class="measure_range_input input"
        @blur="setMinRange"
      />
    </div>
    <div
      v-if="rangeAxis != 3"
      class="pcd_measure_range_item pcd_measure_range_last"
    >
      <div class="pcd_measure_range_title">Max</div>
      <div
        class="pcd_measure_range_image_up button"
        @click="clickMaxUp()"
      ></div>
      <div
        class="pcd_measure_range_image_down button"
        @click="clickMaxDown()"
      ></div>
      <input
        v-if="rangeAxis == 0"
        type="range"
        step="0.1"
        :min="rangeLimit.minHeight"
        :max="rangeLimit.maxHeight"
        :value="range.maxHeight"
        @input="updateHeightRangeMax"
      />
      <input
        v-else-if="rangeAxis == 1"
        type="range"
        step="0.1"
        :min="rangeLimit.minWidth"
        :max="rangeLimit.maxWidth"
        :value="range.maxWidth"
        @input="updateWidthRangeMax"
      />
      <input
        v-else-if="rangeAxis == 2"
        type="range"
        step="0.1"
        :min="rangeLimit.minDepth"
        :max="rangeLimit.maxDepth"
        :value="range.maxDepth"
        @input="updateDepthRangeMax"
      />
      <input
        v-if="rangeAxis == 0"
        type="text"
        :placeholder="range.maxHeight"
        :value="range.maxHeight"
        class="measure_range_input input"
        @blur="setMaxRange"
      />
      <input
        v-else-if="rangeAxis == 1"
        type="text"
        :placeholder="range.maxWidth"
        :value="range.maxWidth"
        class="measure_range_input input"
        @blur="setMaxRange"
      />
      <input
        v-else-if="rangeAxis == 2"
        type="text"
        :placeholder="range.maxDepth"
        :value="range.maxDepth"
        class="measure_range_input input"
        @blur="setMaxRange"
      />
    </div>
    <div v-if="rangeAxis == 3" class="range_item range_last">
      <div class="range_title">Yaw</div>
      <div class="rotate_image button" @click="rotateClicked('z', -1)"></div>
      <div
        class="rotate_image rotate_right button"
        @click="rotateClicked('z', 1)"
      ></div>
      <input
        type="range"
        :min="0"
        :max="360"
        :value="rotateDeg.z"
        @input="updateRotRangeValue"
      />
      <input
        type="text"
        :placeholder="rotateDeg.z"
        :value="rotateDeg.z"
        class="range_input input"
        @blur="setRotateDeg"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    range: Object,
    rangeAxis: Number,
    rangeLimit: Object,
    rotateDeg: Object,
  },
  emits: ["setRangeAxis", "setRange", "setRotateDeg"],
  methods: {
    setRangeAxis(number) {
      if (this.rangeAxis != number) {
        this.$emit("setRangeAxis", number);
      }
    },
    clickMinUp() {
      switch (this.rangeAxis) {
        case 0:
          if (this.range.minHeight <= this.range.maxHeight - 1)
            this.$emit("setRange", "minHeight", this.range.minHeight + 1);
          break;
        case 1:
          if (this.range.minWidth <= this.range.maxWidth - 1)
            this.$emit("setRange", "minWidth", this.range.minWidth + 1);
          break;
        case 2:
          if (this.range.minDepth <= this.range.maxDepth - 1)
            this.$emit("setRange", "minDepth", this.range.minDepth + 1);
          break;
        default:
          break;
      }
    },
    clickMinDown() {
      switch (this.rangeAxis) {
        case 0:
          if (this.range.minHeight - 1 >= 0)
            this.$emit("setRange", "minHeight", this.range.minHeight - 1);
          break;
        case 1:
          if (this.range.minWidth - 1 >= 0)
            this.$emit("setRange", "minWidth", this.range.minWidth - 1);
          break;
        case 2:
          if (this.range.minDepth - 1 >= 0)
            this.$emit("setRange", "minDepth", this.range.minDepth - 1);
          break;
        default:
          break;
      }
    },
    clickMaxUp() {
      if (this.rangeAxis == 0) {
        if (this.range.maxHeight <= this.rangeLimit.maxHeight - 1)
          this.$emit("setRange", "maxHeight", this.range.maxHeight + 1);
      } else if (this.rangeAxis == 1) {
        if (this.range.maxWidth <= this.rangeLimit.maxWidth - 1)
          this.$emit("setRange", "maxWidth", this.range.maxWidth + 1);
      } else if (this.rangeAxis == 2) {
        if (this.range.maxDepth <= this.rangeLimit.maxDepth - 1)
          this.$emit("setRange", "maxDepth", this.range.maxDepth + 1);
      }
    },
    clickMaxDown() {
      if (this.rangeAxis == 0) {
        if (this.range.maxHeight - 1 >= this.range.minHeight)
          this.$emit("setRange", "maxHeight", this.range.maxHeight - 1);
      } else if (this.rangeAxis == 1) {
        if (this.range.maxWidth - 1 >= this.range.minWidth)
          this.$emit("setRange", "maxWidth", this.range.maxWidth - 1);
      } else if (this.rangeAxis == 2) {
        if (this.range.maxDepth - 1 >= this.range.minDepth)
          this.$emit("setRange", "maxDepth", this.range.maxDepth - 1);
      }
    },
    updateHeightRangeMin(event) {
      if (event.target.value > this.range.maxHeight) {
        event.target.value = this.range.maxHeight;
      }
      this.$emit("setRange", "minHeight", event.target.value);
    },
    updateHeightRangeMax(event) {
      if (this.range.minHeight > event.target.value) {
        event.target.value = this.range.minHeight;
      }
      this.$emit("setRange", "maxHeight", event.target.value);
    },
    updateWidthRangeMin(event) {
      if (event.target.value > this.range.maxWidth) {
        event.target.value = this.range.maxWidth;
      }
      this.$emit("setRange", "minWidth", event.target.value);
    },
    updateWidthRangeMax(event) {
      if (this.range.minWidth > event.target.value) {
        event.target.value = this.range.minWidth;
      }
      this.$emit("setRange", "maxWidth", event.target.value);
    },
    updateDepthRangeMin(event) {
      if (event.target.value > this.range.maxDepth) {
        event.target.value = this.range.maxDepth;
      }
      this.$emit("setRange", "minDepth", event.target.value);
    },
    updateDepthRangeMax(event) {
      if (this.range.minDepth > event.target.value) {
        event.target.value = this.range.minDepth;
      }
      this.$emit("setRange", "maxDepth", event.target.value);
    },
    setMaxRange(event) {
      if (event.target.value) {
        event.target.value = parseFloat(event.target.value).toFixed(1);
        if (this.rangeAxis == 0) {
          if (parseFloat(event.target.value) < this.range.minHeight) {
            event.target.value = this.range.minHeight;
          }
          if (parseFloat(event.target.value) > this.rangeLimit.maxHeight) {
            event.target.value = this.rangeLimit.maxHeight;
          }
          this.$emit("setRange", "maxHeight", parseFloat(event.target.value));
        } else if (this.rangeAxis == 1) {
          if (parseFloat(event.target.value) < this.range.minWidth) {
            event.target.value = this.range.minWidth;
          }
          if (parseFloat(event.target.value) > this.rangeLimit.maxWidth) {
            event.target.value = this.rangeLimit.maxWidth;
          }
          this.$emit("setRange", "maxWidth", parseFloat(event.target.value));
        } else if (this.rangeAxis == 2) {
          if (parseFloat(event.target.value) < this.range.minDepth) {
            event.target.value = this.range.minDepth;
          }
          if (parseFloat(event.target.value) > this.rangeLimit.maxDepth) {
            event.target.value = this.rangeLimit.maxDepth;
          }
          this.$emit("setRange", "maxDepth", parseFloat(event.target.value));
        }
      }
    },
    setMinRange(event) {
      if (event.target.value) {
        event.target.value = parseFloat(event.target.value).toFixed(1);
        if (this.rangeAxis == 0) {
          if (parseFloat(event.target.value) > this.range.maxHeight) {
            event.target.value = this.range.maxHeight;
          }
          this.$emit("setRange", "minHeight", parseFloat(event.target.value));
        } else if (this.rangeAxis == 1) {
          if (parseFloat(event.target.value) > this.range.maxWidth) {
            event.target.value = this.range.maxWidth;
          }
          this.$emit("setRange", "minWidth", parseFloat(event.target.value));
        } else if (this.rangeAxis == 2) {
          if (parseFloat(event.target.value) > this.range.maxDepth) {
            event.target.value = this.range.maxDepth;
          }
          this.$emit("setRange", "minDepth", parseFloat(event.target.value));
        }
      }
    },
    rotateClicked(axis, dDeg) {
      let tempRotateDeg;
      switch (axis) {
        case "x":
          tempRotateDeg = this.rotateDeg.x + dDeg;
          break;
        case "y":
          tempRotateDeg = this.rotateDeg.y + dDeg;
          break;
        case "z":
          tempRotateDeg = this.rotateDeg.z + dDeg;
          break;
        default:
          break;
      }
      if (tempRotateDeg < 0) tempRotateDeg = 0;
      if (tempRotateDeg > 360) tempRotateDeg = 360;
      this.$emit("setRotateDeg", tempRotateDeg);
    },
    setRotateDeg(event) {
      if (event.target.value) {
        let tempRotateDeg = parseFloat(event.target.value).toFixed(1);
        if (tempRotateDeg < 0) tempRotateDeg = 0;
        if (tempRotateDeg > 360) tempRotateDeg = 360;
        this.$emit("setRotateDeg", tempRotateDeg);
      }
    },
    updateRotRangeValue(event) {
      this.$emit("setRotateDeg", event.target.value);
    },
  },
  created() {},
};
</script>

<style lang="scss">
.pcd_measure_height_range_contatiner {
  position: fixed;
  bottom: 10px;
  left: calc(25% - 160px);
  height: 120px;
  width: 320px;
  background-color: rgba(27, 27, 27, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
}
.pcd_measure_range_item {
  position: relative;
  height: 40px;
  width: 270px;
  border-bottom: 1px solid rgba(1, 250, 254, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $main_white;
}
.pcd_measure_range_last {
  border-bottom: none;
}
.pcd_measure_axis_radio_button {
  display: flex;
  align-items: center;
  height: 10px;
  width: 10px;
  background-image: url("@/../public/images/circleButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  cursor: pointer;
}
.pcd_measure_axis_radio_button.on {
  background-image: url("@/../public/images/radio_button_selected.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}
.pcd_measure_axis_radio_discript {
  @include flexbox;
  height: 40px;
  width: 40px;
  background-color: transparent;
  font-size: 1.2rem;
  padding-left: 5px;
  padding-right: 5px;
}
.pcd_measure_volume_button {
  position: absolute;
  top: 5px;
  right: 0px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/cube.svg");
  background-size: 20px 20px;
}
.pcd_measure_volume_button:hover {
  background-image: url("@/../public/images/cube_hover.svg");
  background-size: 20px 20px;
}
.pcd_measure_range_title {
  height: 40px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1rem;
  color: $main_white;
}
.pcd_measure_range_image_up {
  height: 25px;
  width: 25px;
  margin: 0px 5px 0px 5px;
  background-image: url("@/../public/images/arrowUp.svg");
  background-size: 15px 15px;
}
.pcd_measure_range_image_up:hover {
  background-image: url("@/../public/images/arrowUp_hover.svg");
  background-size: 15px 15px;
}
.pcd_measure_range_image_down {
  height: 25px;
  width: 25px;
  margin: 0px 5px 0px 0px;
  background-image: url("@/../public/images/arrowDown.svg");
  background-size: 15px 15px;
}
.pcd_measure_range_image_down:hover {
  background-image: url("@/../public/images/arrowDown_hover.svg");
  background-size: 15px 15px;
}
.pcd_measure_range_input {
  display: flex;
  align-items: center;
  height: 30px;
  width: 40px;
  margin-left: 5px;
  font-size: 1.2rem;
  text-align: center;
  padding-left: 0px;
}

.pcd_measure_rotate_image {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-image: url("@/../public/images/rotate_right.svg");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 5px;
}

.pcd_measure_rotate_image:hover {
  background-image: url("@/../public/images/rotate_right_hover.svg");
}

.pcd_measure_range_item .pcd_measure_rotate_image.measure_rotate_right {
  background-image: url("@/../public/images/rotate_left.svg");
  margin-right: 5px;
}

.pcd_measure_range_item .pcd_measure_rotate_image.measure_rotate_right:hover {
  background-image: url("@/../public/images/rotate_left_hover.svg");
}
</style>
