import {	
	AnimationMixer,
	Box3,
	Cache,		
	AmbientLight,
	DirectionalLight,
    // HemisphereLight,
	LoadingManager,
	PerspectiveCamera,
	PMREMGenerator,
	Scene,
	Vector2,
	Vector3,
	Group,
	WebGLRenderer,	
	LinearToneMapping,
	ClampToEdgeWrapping,
	LinearFilter,
	Raycaster,	
	Mesh,	
	BufferGeometry,	
	MathUtils,
	OrthographicCamera
} from 'three';

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh'; // npm install three-mesh-bvh  -- 고속 레이케스팅(3D 객체의 바운딩 볼륨 계층(Bounding Volume Hierarchy, BVH)을 계산하고 관리하는 데 사용)

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import fontJSON from 'three/examples/fonts/helvetiker_regular.typeface.json';

import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

import * as repaintModel from "./Measure/UpdateModelColor"
import LineMeasure from './Measure/LineMeasure';
import AreaMeasure from './Measure/AreaMeasure';
import VolumeMeasure from './Measure/VolumeMeasure';
import AngleMeasure from './Measure/AngleMeasure';
import BoundingRange from './Measure/BoundingRange';

const MANAGER = new LoadingManager();

const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(
	`/draco/`,
);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(
	`/basis/`,
);

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;		//--해당 지오메트리의 BVH를 계산
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;		//--BVH를 메모리에서 해제
Mesh.prototype.raycast = acceleratedRaycast;		//--표준 레이캐스팅 절차 대신 BVH를 사용하여 최적화된 레이캐스팅을 수행

Cache.enabled = true;

export default class ModelLoader {
	constructor(el, captrueImgFunc, loading, magnifierEl) {
		this.el = el;
		this.magnifierEl = magnifierEl;
		this.captureImgFunc = captrueImgFunc;
		this.loading = loading;
		this.measureMethod = null;
				
		this.mixer = null;
		this.clips = [];
		this.gui = null;

		this.acceleratedRaycasts = [];

		const loader = new FontLoader();
		this.font = loader.parse(fontJSON);

		this.shiftKeyDown = false;		
		this.modelObject = null;
		this.prevTime = 0;

		this.radian = 0;
		this.saveImage = false;
		this.captureDegree = 0;
		this.verticalCaptureImages = [null, null, null, null];
		this.horizontalCaptureImages = [null, null, null, null];

		this.initModelColor();

		this.initRender();
		this.initCamera();
		this.initLights();
		this.initRangeParams();

    if(this.magnifierEl){
      this.magnifierEl.style.display = 'none';
      this.initMagnifierCanvas();
    }

		this.initLineParams();
		this.initAreaParams();
		this.initAngleParams();		
		this.initVolumeCropParams();

		this.isPattern = false;
		this.verticalCapture = false;

		window.addEventListener('resize', this.resize.bind(this), false);
		this.render();
	}

	////////////// Initialize (start) //////////////
	initRender(){
		this.scene = new Scene();

		this.renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true  });		
		this.renderer.setSize(this.el.clientWidth, this.el.clientHeight);
		this.renderer.toneMapping = LinearToneMapping; // --하이 다이내믹 레인지(HDR) 이미지를 로우 다이내믹 레인지(LDR) 디스플레이에서 적절하게 표현하기 위해 사용

		this.pmremGenerator = new PMREMGenerator(this.renderer); // --다양한 해상도의 미리 필터링된 환경 맵을 생성
		this.pmremGenerator.compileEquirectangularShader();	//--이퀴렉탱귤러(전체적인 구 형태를 직사각형에 매핑한) 환경 맵을 처리하기 위한 쉐이더를 컴파일합니다. 이 작업은 환경 맵을 렌더링하기 전에 필요한 준비 단계입니다.
		this.neutralEnvironment = this.pmremGenerator.fromScene(new RoomEnvironment()).texture; // --여기서는 RoomEnvironment를 이용해 장면을 생성하고, PMREMGenerator를 사용하여 이 장면의 환경 맵을 생성합니다. 생성된 환경 맵은 텍스처로 저장되어 추후 재사용될 수 있습니다. 이 환경 텍스처는 장면 내의 물체들에게 보다 실제감 있는 반사 및 광원 효과를 제공합니다.
		this.el.appendChild(this.renderer.domElement);

		this.modelGroup = new Group();

		this.isRendering = false;
	}

	initCamera(){
		const fov = 60;
		const aspect = this.el.clientWidth / this.el.clientHeight;

		this.camera = new PerspectiveCamera(fov, aspect, 0.01, 1000);
		this.camera.position.set(0,5,50);
		let centerPos = new Vector3(0,0,0);
		this.camera.lookAt(centerPos);

		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.target.copy(new Vector3(0,0,0));
		this.controls.screenSpacePanning = true;
		this.controls.update();
	}

	initLights(){
		const ambientLight = new AmbientLight(0xFFFFFF);
		ambientLight.intensity = 1;
		this.scene.add(ambientLight);

		const directions = [
			{ x: 1, y: 0, z: 0 },
			{ x: -1, y: 0, z: 0 },
			{ x: 0, y: 1, z: 0 },
			{ x: 0, y: -1, z: 0 },
			{ x: 0, y: 0, z: 1 },
			{ x: 0, y: 0, z: -1 }
		];

		directions.forEach((dir) => {
			const light = new DirectionalLight(0xffffff, 2.0);
			light.position.set(dir.x, dir.y, dir.z);
			this.scene.add(light);
		});
	}

	initLineParams(){
		this.measureLineStart = false;
		this.lineMeasure = new LineMeasure(this.scene, this.modelGroup, this.font);
	}

	initAreaParams(){
		this.measureAreaStart = false;		
		this.areaMeasure = new AreaMeasure(this.scene, this.modelGroup, this.font);
	}

	initAngleParams(){
		this.measureAngleStart = false;
		this.angleMeasure = new AngleMeasure(this.scene, this.modelGroup, this.font);
	}

	initVolumeCropParams(){
		this.isXYZVolumeCrop = true;
		this.volumeCropVertices = [];
		this.polyCroppingStart = false;		
		this.volumeMeasure = new VolumeMeasure(this.scene, this.modelGroup);
	}

	initModelColor(){
		this.originalMaterial = null;
		this.isHeightBaseColor = false;		
	}

	initMagnifierCanvas() {
		this.magnifierCanvas = document.createElement('canvas');
		this.magnifierCanvas.width = 150;
		this.magnifierCanvas.height = 150;
		this.magnifierCanvas.style.borderRadius = '50%'; 
		this.magnifierCanvas.style.position = 'absolute';
		this.magnifierEl.appendChild(this.magnifierCanvas);
	}

	initRangeParams(){
		this.boundingRange = new BoundingRange(this.scene, this.modelGroup);
	}
	////////////// Initialize (end) //////////////

	updateBoxSizeRelativeToCamera(mesh, camera) {		
		const distance = mesh.position.distanceTo(camera.position);
		const scale = this.calculateScaleBasedOnDistance(distance);
		mesh.scale.set(scale, scale, scale);
	}
	
	calculateScaleBasedOnDistance(distance) {
		const baseDistance = 30;
		const baseScale = 1;
		return baseScale * (distance / baseDistance);
	}

	render(){
		let now,delta;
		let then = Date.now();
		const frame= () => {
			this.animationFrameId = requestAnimationFrame(frame);
			now = Date.now();
			delta = now - then;
			if(delta < this.interval) return

			for(let i=0; i<this.lineMeasure.getNumLineBoxes(); i++){
				const lineBox = this.lineMeasure.getLineBox(i);
				lineBox.boxMesh.lookAt(this.camera.position);
				this.updateBoxSizeRelativeToCamera(lineBox.boxMesh, this.camera);
			}
			for(let i=0; i<this.areaMeasure.getTextNum(); i++){
				const textBox = this.areaMeasure.getTextBox(i);
				textBox.boxMesh.lookAt(this.camera.position);
				this.updateBoxSizeRelativeToCamera(textBox.boxMesh, this.camera);
			}
			for(let i=0; i<this.angleMeasure.getNumAngleBoxes(); i++){
				const angleBox = this.angleMeasure.getAngleBox(i);
				angleBox.boxMesh.lookAt(this.camera.position);
				this.updateBoxSizeRelativeToCamera(angleBox.boxMesh, this.camera);
			}		

			for(let i=0; i<this.lineMeasure.getNumLines();i++){
				const tempLine = this.lineMeasure.getLine(i);
				this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera);
				this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera);
			}

			for(let i=0; i<this.areaMeasure.getLineNum();i++){
				const tempLine = this.areaMeasure.getAreaLine(i);
				this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera);
				this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera);
			}

			for(let i=0; i<this.areaMeasure.getPolygonNum();i++){
				const tempPolygon = this.areaMeasure.getPolygon(i);
				for(let j=0; j<tempPolygon.areaLines.length;j++){				
					this.updateBoxSizeRelativeToCamera(tempPolygon.areaLines[j].startSphere, this.camera);
					this.updateBoxSizeRelativeToCamera(tempPolygon.areaLines[j].endSphere, this.camera);
				}
			}

			for(let i=0; i<this.angleMeasure.getNumAngleLines();i++){
				const tempLine = this.angleMeasure.getAngleLine(i);
				this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera);
				this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera);
			}

			for(let i=0; i<this.angleMeasure.getNumAngles();i++){
				const tempAngles = this.angleMeasure.getAngle(i);
				for(let j=0; j<tempAngles.angleLines.length;j++){				
					this.updateBoxSizeRelativeToCamera(tempAngles.angleLines[j].startSphere, this.camera);
					this.updateBoxSizeRelativeToCamera(tempAngles.angleLines[j].endSphere, this.camera);
				}
			}
			this.renderer.render(this.scene, this.camera);

			if(this.saveImage){
				if(this.verticalCapture){
					if(this.captureDegree==0) this.verticalCaptureImages[0] = this.renderer.domElement.toDataURL('image/png');
					else if(this.captureDegree==90) this.verticalCaptureImages[1] = this.renderer.domElement.toDataURL('image/png');
					else if(this.captureDegree==180) this.verticalCaptureImages[2] = this.renderer.domElement.toDataURL('image/png');
					else if(this.captureDegree==270) this.verticalCaptureImages[3] = this.renderer.domElement.toDataURL('image/png');
				}
				else{
					if(this.captureDegree==0) this.horizontalCaptureImages[0] = this.renderer.domElement.toDataURL('image/png');
					else if(this.captureDegree==90) this.horizontalCaptureImages[1] = this.renderer.domElement.toDataURL('image/png');
					else if(this.captureDegree==180) this.horizontalCaptureImages[2] = this.renderer.domElement.toDataURL('image/png');
					else if(this.captureDegree==270) this.horizontalCaptureImages[3] = this.renderer.domElement.toDataURL('image/png');
				}
				this.saveImage = false;

				this.setBoundingBoxVisibility(true);
				this.initCamera();
				this.resize();
			}
			then = now - (delta%this.interval);
		}
		this.animationFrameId = requestAnimationFrame(frame);
	}

	resize() {
		this.camera.aspect = this.el.offsetWidth / this.el.offsetHeight;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(this.el.offsetWidth, this.el.offsetHeight);
	}

	load(path, loadModelCallback) {	
		return new Promise((resolve, reject) => {
			this.loading.startLoadingAnimation();
			const loader = new GLTFLoader(MANAGER)
				.setCrossOrigin('anonymous')
				.setDRACOLoader(DRACO_LOADER)
				.setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer))
				.setMeshoptDecoder(MeshoptDecoder);			
			loader.load(path,
				(gltf) => {					
					this.modelObject = gltf.scene;
					const clips = gltf.animations;

					this.setContent(this.modelObject, clips);
					loadModelCallback();
					this.loading.stopLoadingAnimation();
					resolve(gltf);
				},
				(xhr)=>{
					this.loading.updateLoadingText(xhr.timeStamp);
					if(xhr.lengthComputable){
						let progress = xhr.loaded/xhr.total*100;
						this.loading.$refs.progress_bar.value = progress;
					}
					else{
						this.loading.$refs.progress_bar.style.display = 'none'
					}
				},
				(error) =>{
					this.loading.stopLoadingAnimation();
					reject(error);
				}				
			);
		});
	}

	setContent(object, clips) {
		this.modelClear();				
		object.traverse((child) => {  			
			if(child.isMesh && child.material) { 	
				child.userData.originalMaterial = child.material.clone();
				child.material.transparent = true;
				child.material.opacity = 0.9;
				if (child.material.map) {
					child.material.map.wrapS = ClampToEdgeWrapping;
					child.material.map.wrapT = ClampToEdgeWrapping;
					child.material.map.minFilter = LinearFilter;
					child.material.map.magFilter = LinearFilter;
					child.material.map.generateMipmaps = false;
				}
			}
		});	
		object.traverse((child) => {
			if (child.isMesh) {												
				child.geometry.computeBoundsTree(); // BVH 계산 활성화				
			}
		});		
		object.updateMatrixWorld(); 
	
		const box = new Box3().setFromObject(object);
		const size = box.getSize(new Vector3()).length();
		this.modelCenter = box.getCenter(new Vector3());

		this.controls.reset();

		object.position.x += object.position.x - this.modelCenter.x;
		object.position.y += object.position.y - this.modelCenter.y;
		object.position.z += object.position.z - this.modelCenter.z;
		this.controls.maxDistance = size * 10;
		this.camera.near = size / 100;
		this.camera.far = size * 100;
		this.camera.updateProjectionMatrix();

		this.camera.position.copy(this.modelCenter);
		this.camera.position.x += size / 2.0;
		this.camera.position.y += size / 1.0;
		this.camera.position.z += size / 2.0;
		this.camera.lookAt(this.modelCenter);
				
		this.controls.saveState();
		this.modelGroup.add(object);
		this.scene.add(this.modelGroup);
		this.controls.update();
		
		this.boundingRange.setBoundingBox(object, 0);

		this.setClips(clips);					
	}

	setClips(clips) {
		this.mixer = new AnimationMixer(this.modelObject);
		clips.forEach((clip) => {
			this.mixer.clipAction(clip).play();
		});		
	}
	
	modelClear() {
		if (!this.modelObject) return;
		this.scene.remove(this.modelObject);
		this.modelObject.traverse((node) => {
			if (!node.geometry) return;
			node.geometry.dispose();
		});
		traverseMaterials(this.modelObject, (material) => {
			for (const key in material) {
				if (key !== 'envMap' && material[key] && material[key].isTexture) {
					material[key].dispose();
				}
			}
		});
	}

	clear(){
		cancelAnimationFrame(this.animationFrameId);

		window.removeEventListener('resize', this.resize.bind(this));

		this.modelClear();
		if (this.mixer) this.mixer.uncacheRoot(this.modelObject);
		if (this.renderer) this.renderer.dispose();
		if (this.scene) {
			while (this.scene.children.length) {
				const obj = this.scene.children[0];
				if (obj.geometry) obj.geometry.dispose();
				if (obj.material) {
					if (Array.isArray(obj.material)) {
					obj.material.forEach(material => material.dispose());
					}
					else {
					obj.material.dispose();
					}
				}
				this.scene.remove(obj);
			}
		}
	}

	getShiftKey(keyDown){
		if(this.measureMethod != null){
			if(this.measureMethod == 'volume'){
				if(!this.isXYZVolumeCrop){
					if(keyDown) this.controls.enabled = true;
					else this.controls.enabled = false;
				}
			}
			else{
				if(keyDown) this.controls.enabled = true;
				else this.controls.enabled = false;	
			}
		}
		this.shiftKeyDown = keyDown;		
	}

	////////////// Set Measuring (start) //////////////
	hideMagnifier(){
		this.magnifierEl.style.display = 'none';
		this.controls.enabled = true;
	}
	showMagnifier(){
		this.magnifierEl.style.display = 'block';
		this.controls.enabled = false;
	}
	setLineMeasuring(){
		this.measureMethod = "length";
		this.showMagnifier();
		this.boundingRange.setBoundingBoxVisibility(false);
		this.volumeCropVertices = [];
	}

	setAreaMeasuring(){				
		this.measureMethod = "area";
		this.showMagnifier();
		this.boundingRange.setBoundingBoxVisibility(false);
		this.volumeCropVertices = [];
	}

	setVolumeMeasuring(){
		this.measureMethod = "volume";
		if(!this.isXYZVolumeCrop){
			this.showMagnifier();
		}
		else{
			this.hideMagnifier();
		}
		this.volumeCropVertices = [];
	}

	setAngleMeasuring(){		
		this.measureMethod = "angle";
		this.showMagnifier();		
		this.boundingRange.setBoundingBoxVisibility(false);
		this.volumeCropVertices = [];
	}

	setPattern(isPattern){
		this.isPattern = isPattern;
	}

	////////////// Set Measuring (end) //////////////


	////////////// Set/Get BoundingBox params (start) //////////////
	setBoundingBoxVisibility(visible) {
		this.boundingRange.setBoundingBoxVisibility(visible);
	}

	getMinMaxHeight(){
		const minHeight = this.boundingRange.getMinHeight();
		const maxHeight = this.boundingRange.getMaxHeight();
		return {min: 0, max: maxHeight - minHeight, boxMin : minHeight};
	}

	getMinMaxWidth(){
		const minWidth = this.boundingRange.getMinWidth();
		const maxWidth = this.boundingRange.getMaxWidth();
		return {min: 0, max: maxWidth - minWidth, boxMin : minWidth};
	}

	getMinMaxDepth(){
		const minDepth = this.boundingRange.getMinDepth();
		const maxDepth = this.boundingRange.getMaxDepth();
		return {min: 0, max: maxDepth - minDepth, boxMax : maxDepth};
	}

	getHeightRange(){
		const rangeMinHeight = this.boundingRange.getRangeMinHeight();
		const rangeMaxHeight = this.boundingRange.getRangeMaxHeight();
		return {min: rangeMinHeight, max: rangeMaxHeight};
	}

	getWidthRange(){
		const rangeMinWidth = this.boundingRange.getRangeMinWidth();
		const rangeMaxWidth = this.boundingRange.getRangeMaxWidth();
		return {min: rangeMinWidth, max: rangeMaxWidth};
	}

	getDepthRange(){
		const rangeMinDepth = this.boundingRange.getRangeMinDepth();
		const rangeMaxDepth = this.boundingRange.getRangeMaxDepth();
		return {min: rangeMinDepth, max: rangeMaxDepth};
	}

	resetMinMaxRange(){
		this.boundingRange.resetMinMaxRange();
	}
	
	setPlaneVisible(axis){
		this.boundingRange.setPlaneVisible(axis);
	}
	////////////// Set/Get BoundingBox params (end) //////////////

	////////////// Model Color (start) //////////////
	applyHeightBaseColor(){
		const minHeight = this.boundingRange.getMinHeight();
		const maxHeight = this.boundingRange.getMaxHeight();
		repaintModel.repaintHeightBaseColor(this.modelObject, minHeight, maxHeight);
	}

	applyOriginalColor(){
		this.modelObject.traverse((child) => {
			if (child.isMesh && child.material) {				
				child.material = child.userData.originalMaterial.clone(); 
				child.material.transparent = true;
				child.material.opacity = 0.9;
				child.renderOder = 1;
				child.material.needsUpdate = true;
			}
		});
	}

	applyVolumeColor(range) {
		this.isXYZVolumeCrop = true;
		this.boundingRange.setVolumeColorRange(range);

		repaintModel.repaintVolumeColor(this.modelObject, 
																		this.boundingRange.getMinHeight(), this.boundingRange.getRangeMinHeight(), this.boundingRange.getRangeMaxHeight(),
																		this.boundingRange.getMinWidth(), this.boundingRange.getRangeMinWidth(), this.boundingRange.getRangeMaxWidth(),
																		this.boundingRange.getMaxDepth(), this.boundingRange.getRangeMinDepth(), this.boundingRange.getRangeMaxDepth());
	}

	applyCropedColor(range) {
		this.isXYZVolumeCrop = true;
		this.boundingRange.setCropedColorRange(range);

		repaintModel.repaintCroppedColor(this.modelObject, 
			this.boundingRange.getMinHeight(), this.boundingRange.getRangeMinHeight(), this.boundingRange.getRangeMaxHeight(),
			this.boundingRange.getMinWidth(), this.boundingRange.getRangeMinWidth(), this.boundingRange.getRangeMaxWidth(),
			this.boundingRange.getMaxDepth(), this.boundingRange.getRangeMinDepth(), this.boundingRange.getRangeMaxDepth());
	}

	applyVolumePolygonColor(range) {
		this.isXYZVolumeCrop = false;
		this.boundingRange.setPolygonColorRange(range);

		let verticesArray, vertexCount;
		if(this.volumeCropVertices.length<3){			
			const tempVertices = [{x: 0.0, z: 0.0}];
			verticesArray = new Float32Array(2);
			tempVertices.forEach((vertex, index) => {
				verticesArray[index * 2] = vertex.x; // X 좌표
				verticesArray[index * 2 + 1] = vertex.z; // Z 좌표 (Y 대신 Z 사용)
			});
			vertexCount = 1;
		}
		else{			
			verticesArray = new Float32Array(this.volumeCropVertices.length * 2);
			this.volumeCropVertices.forEach((vertex, index) => {
				verticesArray[index * 2] = vertex.x; // X 좌표
				verticesArray[index * 2 + 1] = vertex.z; // Z 좌표 (Y 대신 Z 사용)
			});
			vertexCount = this.volumeCropVertices.length ;
		}
		
		repaintModel.repaintVolumePolygonColor(this.modelObject, 
			this.boundingRange.getMinHeight(), 
			this.boundingRange.getRangeMinHeight(), 
			this.boundingRange.getRangeMaxHeight(), 
			verticesArray, 
			vertexCount);
	}

	applyCropPolygonColor(range) {
		this.isXYZVolumeCrop = false;
		this.boundingRange.setPolygonColorRange(range);
		
		let verticesArray, vertexCount;
		if(this.volumeCropVertices.length<3){
			const tempVertices = [{x: 0.0, z: 0.0}];
			verticesArray = new Float32Array(2);
			tempVertices.forEach((vertex, index) => {
				verticesArray[index * 2] = vertex.x; // X 좌표
				verticesArray[index * 2 + 1] = vertex.z; // Z 좌표 (Y 대신 Z 사용)
			});
			vertexCount = 1;
		}
		else{
			verticesArray = new Float32Array(this.volumeCropVertices.length * 2);
			this.volumeCropVertices.forEach((vertex, index) => {
				verticesArray[index * 2] = vertex.x; // X 좌표
				verticesArray[index * 2 + 1] = vertex.z; // Z 좌표 (Y 대신 Z 사용)
			});
			vertexCount = this.volumeCropVertices.length ;
		}

		repaintModel.repaintCropPolygonColor(this.modelObject, 
			this.boundingRange.getMinHeight(), 
			this.boundingRange.getRangeMinHeight(), 
			this.boundingRange.getRangeMaxHeight(), 
			verticesArray, 
			vertexCount);
	}
	////////////// Model Color (end) //////////////

	////////////// Mouse Event (start) //////////////
	onMouseMove(event){
		if(this.shiftKeyDown) return;
    let rect = this.renderer.domElement.getBoundingClientRect();
  
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    this.renderMagnifierImage(mouseX, mouseY);
    this.magnifierEl.style.left = `${event.clientX - this.magnifierCanvas.width / 2 - 80}px`;
    this.magnifierEl.style.top = `${event.clientY - this.magnifierCanvas.height - 50}px`;

    if(this.measureLineStart || this.measureAreaStart || this.measureAngleStart || this.polyCroppingStart){
      const mouse = new Vector2(((event.clientX - rect.left) / rect.width) * 2 - 1,-((event.clientY - rect.top) / rect.height) * 2 + 1);

      const raycaster = new Raycaster();
      raycaster.near = this.camera.near;
      raycaster.far = 1000;
      raycaster.linePrecision = 0.1;
      raycaster.params.Points.threshold = 0.1;
      raycaster.setFromCamera(mouse, this.camera);

      const intersections = raycaster.intersectObjects(this.scene.children, true);

      if (intersections.length > 0){
        const intersection = intersections[0];
        if(!intersection.face) return;
        const normal = intersection.face.normal.clone();
        const offset = 0.1;
        const offsetPoint = intersection.point.clone().add(normal.multiplyScalar(offset));

        if(this.measureLineStart){
          this.lineMeasure.setEndPoint(offsetPoint);
          this.lineMeasure.drawMeasureLine(this.radian, false);
        }
        else if(this.measureAreaStart)
          this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(this.areaMeasure.getPointNum()-1), offsetPoint, this.radian, false);
        else if(this.measureAngleStart)
          this.angleMeasure.drawAngleLine(this.angleMeasure.getAngleLinePoint(this.angleMeasure.getNumAngleLinePoints()-1), offsetPoint, this.radian, false);
        else if(this.polyCroppingStart)
          this.volumeMeasure.drawVolumeLine(this.volumeMeasure.getVolumeLinePoint(this.volumeMeasure.getNumLinePoints() -1), offsetPoint, false);
      }
    }		
	}

	onMouseDown(event){	
		if(this.shiftKeyDown) return;
		
		if (event.button === 0){
			let rect = this.renderer.domElement.getBoundingClientRect();

			const mouse = new Vector2(
				((event.clientX - rect.left) / rect.width) * 2 - 1,
				-((event.clientY - rect.top) / rect.height) * 2 + 1
			);

			const raycaster = new Raycaster();
			raycaster.near = this.camera.near;
			raycaster.far = 1000;
			raycaster.linePrecision = 0.1;
			raycaster.params.Points.threshold = 0.1;
			raycaster.setFromCamera(mouse, this.camera);

			const intersections = raycaster.intersectObjects(this.scene.children, true);
			let boxSelected = this.selectBox(intersections);

			if(this.measureMethod == null || boxSelected) return;
			if (intersections.length > 0) {
				const intersection = intersections[0];
				if(!intersection.normal) return;
				const normal = intersection.face.normal.clone(); 
				const offset = 0;
				const offsetPoint = intersection.point.clone().add(normal.multiplyScalar(offset));

				switch(this.measureMethod){
					case "length":
						if (!this.measureLineStart) {  
							this.lineMeasure.setStartPoint(offsetPoint);
							this.measureLineStart = true;
						} 
						else {          
							this.lineMeasure.setEndPoint(offsetPoint);
							this.measureLineStart = false;						
							this.lineMeasure.drawMeasureLine(this.radian, true);
							this.lineMeasure.drawLineTextBox(this.radian, this.camera);
							this.lineMeasure.increaseLineCount();
						}
						break;
					case "area":
						if(!this.measureAreaStart){
							this.areaMeasure.pushAreaLinePoint(offsetPoint);
							this.measureAreaStart = true;						
						}
						else{						
							if(this.areaMeasure.getPointNum() > 2){
								const distanceToStart = offsetPoint.distanceTo(this.areaMeasure.getLinePoint(0));
								if(distanceToStart>0.5){
									this.areaMeasure.pushAreaLinePoint(offsetPoint);
									const pointsNum = this.areaMeasure.getPointNum();
									this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-2), this.areaMeasure.getLinePoint(pointsNum-1), this.radian, true);
									this.areaMeasure.increaseAreaLineCount();
								}
								else{								
									const pointsNum = this.areaMeasure.getPointNum();
									this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-1), this.areaMeasure.getLinePoint(0), this.radian, true);
									this.areaMeasure.pushPolygon();
									this.areaMeasure.drawAreaPolygon(this.radian);
									this.areaMeasure.drawAreaTextBox(this.radian, this.camera);
									
									this.areaMeasure.resetAreaLineCount();
									this.areaMeasure.resetAreaLinePoints();
									this.areaMeasure.resetAreaLines();
									this.areaMeasure.increaseAreaCount();								
									this.measureAreaStart = false;
								}
							}
							else{
								this.areaMeasure.pushAreaLinePoint(offsetPoint);
								const pointsNum = this.areaMeasure.getPointNum();
								this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-2), this.areaMeasure.getLinePoint(pointsNum-1), this.radian, true);
								this.areaMeasure.increaseAreaLineCount();							
							}
						}
						break;				
					case "volume":
						if(!this.isXYZVolumeCrop){
							if(!this.polyCroppingStart){						
								this.removeDrawingCropLine();
								this.volumeMeasure.pushVolumeLinePoints(offsetPoint);
								this.polyCroppingStart = true;
							}
							else{
								if(this.volumeMeasure.getNumLinePoints() > 2){
									const distanceToStart = offsetPoint.distanceTo(this.volumeMeasure.getVolumeLinePoint(0));
									if(distanceToStart>0.5){
										this.volumeMeasure.pushVolumeLinePoints(offsetPoint);
										const pointsNum = this.volumeMeasure.getNumLinePoints();
										this.volumeMeasure.drawVolumeLine(this.volumeMeasure.getVolumeLinePoint(pointsNum-2), this.volumeMeasure.getVolumeLinePoint(pointsNum-1), true);
										this.volumeMeasure.increaseVolumeLineCount();
									}
									else{
										const pointsNum = this.volumeMeasure.getNumLinePoints();
										this.volumeMeasure.drawVolumeLine(this.volumeMeasure.getVolumeLinePoint(pointsNum-1), this.volumeMeasure.getVolumeLinePoint(0), true);
										this.polyCroppingStart = false;
										for(let i=0;i<this.volumeMeasure.getNumLinePoints();i++){
											const linePoint = this.volumeMeasure.getVolumeLinePoint(i);
											this.volumeCropVertices.push({x:linePoint.x, z: linePoint.z});
										}
										const linePoint = this.volumeMeasure.getVolumeLinePoint(0);
										this.volumeCropVertices.push({x:linePoint.x, z: linePoint.z});
										const range ={minHeight: this.boundingRange.getRangeMinHeight(), maxHeight:this.boundingRange.getRangeMaxHeight()};
										this.applyVolumePolygonColor(range);				
									}
								}
								else{
									this.volumeMeasure.pushVolumeLinePoints(offsetPoint);
									const pointsNum = this.volumeMeasure.getNumLinePoints();
									this.volumeMeasure.drawVolumeLine(this.volumeMeasure.getVolumeLinePoint(pointsNum-2), this.volumeMeasure.getVolumeLinePoint(pointsNum-1), true);
									this.volumeMeasure.increaseVolumeLineCount();
								}
							}
						}
						break;
					case "angle":
						if(!this.measureAngleStart){
							this.angleMeasure.pushAngleLinePoint(offsetPoint);
							this.measureAngleStart = true;
						}
						else{
							this.angleMeasure.pushAngleLinePoint(offsetPoint);
							const pointsNum = this.angleMeasure.getNumAngleLinePoints();
							this.angleMeasure.drawAngleLine(this.angleMeasure.getAngleLinePoint(pointsNum-2), this.angleMeasure.getAngleLinePoint(pointsNum-1), this.radian, true);
							this.angleMeasure.increaseAngleLineCount();
	
							if(this.angleMeasure.getAngleLineCount()==2){
								this.angleMeasure.pushAngle();							
								this.angleMeasure.drawAngleCurve(this.radian, this.camera);
								this.angleMeasure.resetAngleLineCount();
								this.angleMeasure.resetAngleLines();
								this.angleMeasure.resetAngleLinePoints();
								this.angleMeasure.increaseAngleCount();
								this.measureAngleStart = false;
							}
						}
						break;
					default:
						break;
				}
			}
		}
	}
	////////////// Mouse Event (end) //////////////

	////////////// Magnifier //////////////
	renderMagnifierImage(mouseX, mouseY) {			
		const magnification = 2; 
		const readWidth = this.magnifierCanvas.width / magnification;
		const readHeight = this.magnifierCanvas.height / magnification;
		const pixels = new Uint8Array(readWidth * readHeight * 4);

		const startX = mouseX - (readWidth / 2);
		const startY = (this.renderer.domElement.height - mouseY) - (readHeight / 2);

		const tempCanvas = document.createElement('canvas');
		tempCanvas.width = readWidth;
		tempCanvas.height = readHeight;
		const tempCtx = tempCanvas.getContext('2d');
		
		const gl = this.renderer.getContext();
		const format = gl.RGBA;
		const type = gl.UNSIGNED_BYTE;		

		gl.readPixels(startX, startY, readWidth, readHeight, format, type, pixels);
		
		const imageData = new ImageData(new Uint8ClampedArray(pixels), readWidth, readHeight);
		tempCtx.putImageData(imageData, 0, 0);			
		
		const ctx = this.magnifierCanvas.getContext('2d');
		ctx.clearRect(0, 0, this.magnifierCanvas.width, this.magnifierCanvas.height);
		ctx.save();
		ctx.scale(1, -1);
		ctx.drawImage(tempCanvas, 0, -this.magnifierCanvas.height, this.magnifierCanvas.width, this.magnifierCanvas.height);
		ctx.restore();

		const centerSquareSize = 16;
		let centerX = (this.magnifierCanvas.width - centerSquareSize) / 2;
		let centerY = (this.magnifierCanvas.height - centerSquareSize) / 2;

		ctx.strokeStyle = 'red'; 
		ctx.lineWidth = 1; 
		ctx.strokeRect(centerX, centerY, centerSquareSize, centerSquareSize); 

		const lineLength = 60; 		
		centerX = this.magnifierCanvas.width / 2;
		centerY = this.magnifierCanvas.height / 2;

		ctx.beginPath(); 
		ctx.moveTo(centerX - lineLength, centerY); 
		ctx.lineTo(centerX + lineLength, centerY); 
		ctx.strokeStyle = 'red';
		ctx.lineWidth = 1;
		ctx.stroke();		

		ctx.beginPath(); 
		ctx.moveTo(centerX, centerY - lineLength); 
		ctx.lineTo(centerX, centerY + lineLength); 
		ctx.strokeStyle = 'blue';
		ctx.stroke();

		const circleRadius = 2; 
		centerX = this.magnifierCanvas.width / 2;
		centerY = this.magnifierCanvas.height / 2;

		ctx.beginPath();
		ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
		ctx.fillStyle = 'white'; 
		ctx.fill();

		ctx.beginPath();
		ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
		ctx.fillStyle = 'red';
		ctx.lineWidth = 1;
		ctx.stroke();
	}

	////////////// remove drawings (start) //////////////
	removeDrawings(){		
		switch(this.measureMethod){
			case "length":
				if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();	
				break;
			case "area":
				this.removeDrawingPolygon();
				break;
			case "volume":
				this.removeDrawingCropLine();
				break;
			case "angle":
				this.removeDrawingAngleLine();
				break;
			default:
				break;
		}
		this.hideMagnifier();
		this.measureMethod = null;
	}

	removeDrawingLine(){
		this.lineMeasure.removeDrawings();
		this.measureLineStart = false;
	}

	removeDrawingPolygon(){		
		this.areaMeasure.removeDrawings();
		this.measureAreaStart=false;
	}

	removeDrawingAngleLine(){
		this.angleMeasure.removeDrawings();
		this.measureAngleStart=false;
	}

	removeDrawingCropLine(){		
		this.volumeMeasure.removeDrawings(this.polyCroppingStart);
		this.polyCroppingStart=false;
	}
	////////////// remove drawings (end) //////////////

	
	////////////// Delete Measurement //////////////
	deleteMeasurements(){
		if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();
		this.lineMeasure.removeLineMeasure();
		
		this.removeDrawingPolygon();
		this.areaMeasure.removeAreaMeasures();

		this.removeDrawingAngleLine();
		this.angleMeasure.removeAngleMeasures();
	}

	selectBox(intersections){
		let boxSelected = false;
		for (let i = 0; i < intersections.length; i++) {
			for(let j = 0; j < this.lineMeasure.getNumLineBoxes(); j++){
				const lineBox = this.lineMeasure.getLineBox(j);
				if (intersections[i].object === lineBox.boxMesh) {					
					if(lineBox.checked) {							
						intersections[i].object.material.color.set(0xFFFFFF);
						lineBox.checked = false;
					} 
					else{							
						intersections[i].object.material.color.set(0x00FFFF);
						lineBox.checked = true;
					}
					if(this.measureLineStart) this.removeDrawingLine();
					if(this.measureAreaStart) this.removeDrawingPolygon();
					if(this.measureAngleStart) this.removeDrawingAngleLine();
					boxSelected = true;
					break; 
				}	
			}
			if (boxSelected) break; 		
		}
		if(boxSelected) return boxSelected;

		const areaBoxes = this.areaMeasure.getTextBoxes();
		for (let i = 0; i < intersections.length; i++) {
			for(let j = 0; j < areaBoxes.length; j++){
				if (intersections[i].object === areaBoxes[j].boxMesh) {					
					if(areaBoxes[j].checked) {							
						intersections[i].object.material.color.set(0xFFFFFF);
						areaBoxes[j].checked = false;
					} 
					else{							
						intersections[i].object.material.color.set(0x00FFFF);
						areaBoxes[j].checked = true;
					}
					if(this.measureLineStart) this.removeDrawingLine();
					if(this.measureAreaStart) this.removeDrawingPolygon();
					if(this.measureAngleStart) this.removeDrawingAngleLine();
					boxSelected = true;
					break; 
				}	
			}
			if (boxSelected) break;
		}
		if(boxSelected) return boxSelected;

		for (let i = 0; i < intersections.length; i++) {
			for(let j = 0; j < this.angleMeasure.getNumAngleBoxes(); j++){
				const angleBox = this.angleMeasure.getAngleBox(j);
				if (intersections[i].object === angleBox.boxMesh) {
					if(angleBox.checked) {							
						intersections[i].object.material.color.set(0xFFFFFF);
						angleBox.checked = false;
					} 
					else{							
						intersections[i].object.material.color.set(0x00FFFF);
						angleBox.checked = true;
					}
					if(this.measureLineStart) this.removeDrawingLine();
					if(this.measureAreaStart) this.removeDrawingPolygon();
					if(this.measureAngleStart) this.removeDrawingAngleLine();
					boxSelected = true;
					break; 
				}	
			}
			if (boxSelected) break;
		}
		return boxSelected;
	}

	rotateModel(axis, deg, axisIdx){		
		this.radian = MathUtils.degToRad(parseFloat(deg));
		switch(axis){
			case 'x':
				this.modelGroup.rotation.x = this.radian;
				break;
			case 'y':
				this.modelGroup.rotation.y = this.radian;		
				break;
			case 'z':
				this.modelGroup.rotation.z = this.radian;
				break;
			default:
				break;
		}
		this.boundingRange.removeBoundingBox();
		this.boundingRange.setBoundingBox(this.modelGroup, axisIdx);
		this.boundingRange.setBoundingBoxVisibility(true);
	}

	// measureVolume(){
	// 	console.log("Complete the code afer setting up the server (back-end)")
	// }

	// async cutModel(){
	// 	console.log("Complete the code afer setting up the server (back-end)")
	// }

	async captureModel(verticalCapture){
		this.verticalCapture = verticalCapture;

		this.captureDegree = 0;
		if(this.verticalCapture) await this.setupCameraAndVerticalView(this.captureDegree);
		else await this.setupCameraAndHorizontalView(this.captureDegree);
		await sleep(1000);
		this.captureImgFunc(this.captureDegree);

		this.captureDegree = 90;
		if(this.verticalCapture) await this.setupCameraAndVerticalView(this.captureDegree);
		else await this.setupCameraAndHorizontalView(this.captureDegree);
		await sleep(1000);
		this.captureImgFunc(this.captureDegree);

		this.captureDegree = 180;
		if(this.verticalCapture) await this.setupCameraAndVerticalView(this.captureDegree);
		else await this.setupCameraAndHorizontalView(this.captureDegree);
		await sleep(1000);
		this.captureImgFunc(this.captureDegree);

		this.captureDegree = 270;
		if(this.verticalCapture) await this.setupCameraAndVerticalView(this.captureDegree);
		else await this.setupCameraAndHorizontalView(this.captureDegree);
		await sleep(1000);
		this.captureImgFunc(this.captureDegree);
	}

	setupCameraAndHorizontalView(deg){
		// Bounding Box 계산
		const minHeight = this.boundingRange.getMinHeight();
		const minWidth = this.boundingRange.getMinWidth();
		const maxDepth = this.boundingRange.getMaxDepth();
		const rangeMinHeight = this.boundingRange.getRangeMinHeight()
		const rangeMaxHeight = this.boundingRange.getRangeMaxHeight();
		const rangeMinWidth = this.boundingRange.getRangeMinWidth();
		const rangeMaxWidth = this.boundingRange.getRangeMaxWidth();
		const rangeMinDepth = this.boundingRange.getRangeMinDepth();
		const rangeMaxDepth = this.boundingRange.getRangeMaxDepth();


		const min = new Vector3(minWidth + rangeMinWidth, minHeight + rangeMinHeight, maxDepth - rangeMaxDepth);
		const max = new Vector3(minWidth + rangeMaxWidth, minHeight + rangeMaxHeight, maxDepth - rangeMinDepth);

		const bbox = new Box3(min, max);
		const bboxSize = new Vector3();
		bbox.getSize(bboxSize);

		
		const center = bbox.getCenter(new Vector3());

		const distance = 100;
		const radian = MathUtils.degToRad(parseFloat(deg));
		
		const cameraX = center.x;
		const cameraY = center.y + distance * Math.sin(-radian);
		const cameraZ = center.z + distance * Math.cos(-radian);

		const pixelsPerMeter = 100;

		let halfWidth = bboxSize.x*1.1/2;
		// let halfWidthPixel = (bboxSize.x * pixelsPerMeter) * 1.1 / 2;

		let halfHeight;
		if(deg == 0 || deg == 180) {
			halfHeight = bboxSize.y*1.1/2;
			// halfHeightPixel = (bboxSize.y * pixelsPerMeter) * 1.1 / 2;
		}
		else if(deg == 90 || deg == 270) {
			halfHeight = bboxSize.z*1.1/2;
			// halfHeightPixel = (bboxSize.z * 1.1 * pixelsPerMeter) / 2;
		}

		this.camera = new OrthographicCamera(
			-halfWidth, // left
			halfWidth, // right
			halfHeight, // top
			-halfHeight, // bottom
			1, // near
			distance + bboxSize.x * pixelsPerMeter
		);

		this.camera.position.set(cameraX, cameraY, cameraZ);
		this.camera.lookAt(center);


		let rendererWidth, rendererHeight;
		if(halfWidth>halfHeight){
			rendererWidth = 1920;
			rendererHeight = (halfHeight/halfWidth)*1920;
		}
		else{
			rendererHeight = 1920;
			rendererWidth = (halfWidth/halfHeight)*1920;
		}

		this.renderer.setSize(rendererWidth, rendererHeight);

		this.setBoundingBoxVisibility(false);
		this.saveImage = true;
	}

	setupCameraAndVerticalView(deg){
		// Bounding Box 계산
		const minHeight = this.boundingRange.getMinHeight();
		const minWidth = this.boundingRange.getMinWidth();
		const maxDepth = this.boundingRange.getMaxDepth();
		const rangeMinHeight = this.boundingRange.getRangeMinHeight()
		const rangeMaxHeight = this.boundingRange.getRangeMaxHeight();
		const rangeMinWidth = this.boundingRange.getRangeMinWidth();
		const rangeMaxWidth = this.boundingRange.getRangeMaxWidth();
		const rangeMinDepth = this.boundingRange.getRangeMinDepth();
		const rangeMaxDepth = this.boundingRange.getRangeMaxDepth();


		const min = new Vector3(minWidth + rangeMinWidth, minHeight + rangeMinHeight, maxDepth - rangeMaxDepth);
		const max = new Vector3(minWidth + rangeMaxWidth, minHeight + rangeMaxHeight, maxDepth - rangeMinDepth);

		const bbox = new Box3(min, max);
		const bboxSize = new Vector3();
		bbox.getSize(bboxSize);

		
		const center = bbox.getCenter(new Vector3());

		const distance = 100;
		const yawRadians = MathUtils.degToRad(parseFloat(deg));
		const cameraX = center.x + distance * Math.cos(-yawRadians);
		const cameraY = center.y;
		const cameraZ = center.z + distance * Math.sin(-yawRadians);

		const pixelsPerMeter = 100;
		let halfWidth;
		if(deg == 0 || deg == 180) {
			halfWidth = bboxSize.z*1.1/2;
			// halfWidthPixel = (bboxSize.z * pixelsPerMeter) * 1.1 / 2;
		}
		else if(deg == 90 || deg == 270) {
			halfWidth = bboxSize.x*1.1/2;
			// halfWidthPixel = (bboxSize.x * pixelsPerMeter) * 1.1 / 2;
		}
		else return;
		let halfHeight = bboxSize.y*1.1/2;
		// let halfHeightPixel = (bboxSize.y * pixelsPerMeter) * 1.1 / 2;

		this.camera = new OrthographicCamera(
		-halfWidth, // left
		halfWidth, // right
		halfHeight, // top
		-halfHeight, // bottom
		1, // near
		distance + bboxSize.z * pixelsPerMeter
		);

		this.camera.position.set(cameraX, cameraY, cameraZ);
		this.camera.lookAt(center);

		let rendererWidth, rendererHeight;
		if(halfWidth>halfHeight){
			rendererWidth = 1920;
			rendererHeight = (halfHeight/halfWidth)*1920;
		}
		else{
			rendererHeight = 1920;
			rendererWidth = (halfWidth/halfHeight)*1920;
		}

		this.renderer.setSize(rendererWidth, rendererHeight);

		this.setBoundingBoxVisibility(false);
		this.saveImage = true;
	}
	
	downloadImage(imageData, filename = 'image.png') {
		// a 태그 생성
		const link = document.createElement('a');
		
		// 이미지 데이터 URL 설정
		link.href = imageData;
		
		// 다운로드될 파일명 설정
		link.download = filename;
		
		// a 태그를 문서에 추가 (화면에는 보이지 않음)
		document.body.appendChild(link);
		
		// 링크 클릭 시뮬레이션으로 다운로드 시작
		link.click();
		
		// 더 이상 필요하지 않으므로 링크 제거
		document.body.removeChild(link);
	}

	getCaptureImage(deg){
		if(this.verticalCapture){
			if(deg == 0) return this.verticalCaptureImages[0];
			else if(deg == 90) return this.verticalCaptureImages[1];
			else if(deg == 180) return this.verticalCaptureImages[2];
			else if(deg == 270) return this.verticalCaptureImages[3];
		}
		else{
			if(deg == 0) return this.horizontalCaptureImages[0];
			else if(deg == 90) return this.horizontalCaptureImages[1];
			else if(deg == 180) return this.horizontalCaptureImages[2];
			else if(deg == 270) return this.horizontalCaptureImages[3];
		}
	}
}

function traverseMaterials(object, callback) {
	object.traverse((node) => {
		if (!node.geometry) return;
		const materials = Array.isArray(node.material) ? node.material : [node.material];
		materials.forEach(callback);
	});
}

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
  

	// if (intersections.length > 0) {			
	// 	const intersection = intersections[0];
	// 	const point = intersection.point;			
	// 	const normalMatrix = new Matrix3().getNormalMatrix(intersection.object.matrixWorld);
	// 	const normal = intersection.face.normal.clone().applyMatrix3(normalMatrix).normalize();

	// 	this.circleMesh.position.copy(point);
	// 	this.circleMesh.lookAt(intersection.point.clone().add(normal));
	// 	this.circleMesh.visible = false;
	// 	this.circleMesh.updateMatrixWorld(true);

	// } else {
	// 	this.circleMesh.visible = false;
	// }