<template>
  <div v-if="alert.state" class="alert">
    <div class="alert_content input">
      <div class="alert_text">{{ alert.text }}</div>
      <div class="alert_button_wrap">
        <div class="alert_ok button" @click="clickOk">확인</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeAlert"],
  props: {
    alert: Object,
  },
  methods: {
    clickOk() {
      this.$emit("closeAlert");
    },
  },
};
</script>

<style lang="scss">
.alert {
  position: fixed;
  left: 0px;
  top: 0px;
  @include flexbox;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.7);
  z-index: 100;
}
.alert_content {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100px;
  padding: 10px;
}
.alert_text {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50px;
  font-size: 1.4rem;
  overflow: hidden;
}
.alert_button_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
}
.alert_ok {
  @include flexbox;
  width: 50px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
}
</style>
