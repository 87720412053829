export default class DroneData{
  constructor(){
    this.mode = '-';
    this.state = {
      arming : false,
      takeOff : false,
      mission : false
    };
    this.gps = {
      satellites : '-',
      eph : '-.-'
    };
    this.battery = '--';
    this.lte = null;
    this.rc = null;
    this.speed = '-';
    this.flightTime = '--:--:--';
    this.msg = [];
    this.pose = {
      x : '-',
      y : '-',
      z : '-',
      lon : '-',
      lat : '-',
      alt : '-',
      roll : '-',
      pitch : '-',
      yaw : '-',
    }
    this.sensorState = {
      lidar : null,
      imu : null,
      encoder : null
    }
  }
}