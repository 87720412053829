import * as THREE from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

export default class AngleMeasure {
	constructor(scene, modelGroup, font) {
		this.scene = scene;
		this.modelGroup = modelGroup;
		this.angleLinePoints = [];
		this.angleLines = [];
		this.angles = [];
		this.angleBoxes = [];
		this.angleCount = 0;
		this.angleLineCount = 0;
		this.font = font;
	}

	increaseAngleLineCount(){this.angleLineCount++;}
	decreaseAngleLineCount(){this.angleLineCount--;}
	resetAngleLineCount(){this.angleLineCount=0;}
	getAngleLineCount(){return this.angleLineCount;}

	increaseAngleCount(){this.angleCount++;}
	decreaseAngleCount(){this.angleCount--;}
	resetAngleCount(){this.angleCount=0;}
	getAngleCount(){return this.angleCount;}

	pushAngleLinePoint(point){this.angleLinePoints.push(point);}
	getAngleLinePoint(index){return this.angleLinePoints[index];}
	resetAngleLinePoints(){this.angleLinePoints = [];}
	getNumAngleLinePoints(){return this.angleLinePoints.length;}

	getAngleLine(index){return this.angleLines[index];}
	resetAngleLines(){this.angleLines = [];}
	getNumAngleLines(){return this.angleLines.length;}

	pushAngle(axis, radian){
		this.angles.push({angleLinePoints:this.angleLinePoints, angleLines:this.angleLines, degreeMesh:null, axis: axis, radian:radian});
	}
	getAngle(index){return this.angles[index];}
	resetAngles(){this.angles=[];}
	getNumAngles(){return this.angles.length;}

	getAngleBox(index){return this.angleBoxes[index];}
	resetAngleBoxes(){this.angleBoxes = [];}
	getNumAngleBoxes(){return this.angleBoxes.length;}

	drawAngleLine(startPoint, endPoint, radian, save){
		const path = new THREE.LineCurve3(
			new THREE.Vector3(startPoint.x, startPoint.y, startPoint.z),
			new THREE.Vector3(endPoint.x, endPoint.y, endPoint.z)
		);
				
		const tubeRadius = 0.1; 		
		const tubeGeometry = new THREE.TubeGeometry(path, 64, tubeRadius, 10, false);
		const tubeMaterial = new THREE.MeshBasicMaterial({ color: 0x80C497});

		const sphereGeometry = new THREE.SphereGeometry(tubeRadius*2, 32, 32);
		const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x80C497 });

		const tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
		const startSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
		const endSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

		tube.raycast = () => {};
		startSphere.raycast = () => {};
		endSphere.raycast = () => {}; 

		startSphere.position.copy(startPoint);
		endSphere.position.copy(endPoint);

		if(this.angleLines.length == this.angleLineCount){
			this.scene.add(tube);
			this.scene.add(startSphere);
			this.scene.add(endSphere);

			this.angleLines.push({tube: tube, startSphere: startSphere, endSphere: endSphere,
														startPoint: startPoint, endPoint: endPoint, radian:radian});
		}
		else{
			const lastLine = this.angleLines[this.angleLines.length - 1];
			this.scene.remove(lastLine.tube);
			lastLine.tube.geometry.dispose();
			lastLine.tube.material.dispose();
			this.scene.remove(lastLine.startSphere);
			lastLine.startSphere.geometry.dispose();
			lastLine.startSphere.material.dispose();
			this.scene.remove(lastLine.endSphere);
			lastLine.endSphere.geometry.dispose();
			lastLine.endSphere.material.dispose();

			let rotatedStartPoint = startPoint;
			let rotatedEndPoint = endPoint;

			if(save){
				const axis = new THREE.Vector3(0, 1, 0);
				const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, -radian);

				rotatedStartPoint = startPoint.clone().applyQuaternion(quaternion);
				rotatedEndPoint = endPoint.clone().applyQuaternion(quaternion);
				const rotatedPath = new THREE.LineCurve3(rotatedStartPoint, rotatedEndPoint);
				const rotatedTubeGeometry = new THREE.TubeGeometry(rotatedPath, 64, tubeRadius, 10, false);

				startSphere.position.copy(rotatedStartPoint);
				endSphere.position.copy(rotatedEndPoint);

				tube.geometry.dispose(); // 이전 Geometry 메모리 해제
				tube.geometry = rotatedTubeGeometry;

				this.modelGroup.add(tube);
				this.modelGroup.add(startSphere);
				this.modelGroup.add(endSphere);
			}
			else{
				this.scene.add(tube);
				this.scene.add(startSphere);
				this.scene.add(endSphere);
			}
			this.angleLines[this.angleLines.length - 1] = {tube: tube, startSphere: startSphere, endSphere: endSphere
																										,startPoint: rotatedStartPoint, endPoint: rotatedEndPoint, radian:radian};
		}
	}

	drawAngleCurve(axis, radian){
		const rotAxis = new THREE.Vector3(0, 1, 0);
		const quaternion = new THREE.Quaternion().setFromAxisAngle(rotAxis, -radian)

		const point1 = this.angleLinePoints[0].clone().applyQuaternion(quaternion);
		const point2 = this.angleLinePoints[1].clone().applyQuaternion(quaternion);
		const point3 = this.angleLinePoints[2].clone().applyQuaternion(quaternion);
		
		// 벡터 정의
		const vectorA = new THREE.Vector3().subVectors(point1, point2).normalize();
		const vectorB = new THREE.Vector3().subVectors(point3, point2).normalize();
		
		// vectorA와 vectorB 사이의 각도 계산
		const angle = vectorA.angleTo(vectorB);
		const crossProduct = new THREE.Vector3().crossVectors(vectorA, vectorB);
		const direction = Math.sign(crossProduct.dot(point2.clone().normalize())); // 호의 그리기 방향 결정
		
		// 호를 구성하는 점들 계산
		const points = [];
		const radius = 2; // 호의 반지름
		const segments = 50; // 세분화 정도
				
		for (let i = 0; i <= segments; i++) {
			const t = direction > 0 ? i / segments : 1 - (i / segments);
			const interpolatedAngle = angle * t;
		
			// 회전 축 (vectorA와 vectorB의 외적)
			const arcAxis = new THREE.Vector3().crossVectors(vectorA, vectorB).normalize();
		
			// startPoint에서 회전을 적용하여 점 계산
			const rotatedVector = vectorA.clone().applyAxisAngle(arcAxis, interpolatedAngle);
			const point = rotatedVector.multiplyScalar(radius).add(point2);
			points.push(point);			
		}
		
		// BufferGeometry와 Line을 사용하여 호 생성
		const geometry = new THREE.BufferGeometry().setFromPoints(points);
		const material = new THREE.LineBasicMaterial({ color: 0x00ff00 });
		const arcLine = new THREE.Line(geometry, material);
		
		// 씬에 호 추가
		this.modelGroup.add(arcLine);
		this.angles[this.angleCount].degreeMesh = arcLine;

		// vectorA와 vectorB의 평균 벡터 계산
		const averageVector = new THREE.Vector3().addVectors(vectorA, vectorB).normalize();

		// 호의 중심점에서 평균 벡터 방향으로 오프셋을 주어 텍스트 박스 위치 계산
		const offsetDistance = 10.0; // 호에서 텍스트 박스까지의 거리
		const textBoxPosition = point2.clone().add(averageVector.multiplyScalar(offsetDistance));

		// 각도 값을 계산하여 텍스트 박스에 전달
		const angleInDegrees = (vectorA.angleTo(vectorB) * 180.0) / Math.PI;
		this.drawAngleTextBox(angleInDegrees, textBoxPosition, axis);
	}

	drawAngleTextBox(degree, position, axis){
		if (this.font) {
			const textGeo = new TextGeometry(`${degree.toFixed(1)} °`, {
				font: this.font,
				size: 0.45,
				height: 0.15,
			});
			textGeo.computeBoundingBox();
			const textWidth = textGeo.boundingBox.max.x - textGeo.boundingBox.min.x;
			const textHeight = textGeo.boundingBox.max.y - textGeo.boundingBox.min.y;
	
			const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });						
			const textMeshFront = new THREE.Mesh(textGeo, textMaterial);    
			const textMeshBack = textMeshFront.clone();						

			const boxDepth = 0.2; 
			const textOffsetX = -textWidth / 2; 
			const textOffsetY = -textHeight / 2; 
			textMeshFront.position.set(textOffsetX, textOffsetY, boxDepth / 2);

			textMeshBack.position.set(textOffsetX, textOffsetY, -boxDepth / 2);
			textMeshBack.rotation.y = Math.PI;
			textMeshBack.position.x += textWidth;				

			const boxWidth = textWidth * 1.4; 
			const boxHeight = textHeight * 2.0;						
			const boxGeometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
			const boxMaterial = new THREE.MeshBasicMaterial({ color: 0x94D8AB });
			const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
			boxMesh.position.copy(position);	

			boxMesh.rotation.set(0, 0, 0);
			if(axis==0){
				boxMesh.rotation.x = -Math.PI / 2;
			}
			else if(axis==1){
				boxMesh.rotation.y = Math.PI / 2;
			}
			// boxMesh.lookAt(camera.position);

			boxMesh.add(textMeshFront);
			boxMesh.add(textMeshBack);

			this.angleBoxes.push({boxMesh:boxMesh, checked: false});
			this.modelGroup.add(boxMesh);			
		}
	}

	removeDrawings(){
		for(let i=0; i<this.angleLines.length; i++){
			const tempLine = this.angleLines[i];
			if(i==this.angleLines.length-1){
				this.scene.remove(tempLine.tube);
				this.scene.remove(tempLine.startSphere);
				this.scene.remove(tempLine.endSphere);
			}
			else{
				this.modelGroup.remove(tempLine.tube);
				this.modelGroup.remove(tempLine.startSphere);
				this.modelGroup.remove(tempLine.endSphere);
			}

			tempLine.tube.geometry.dispose();
			tempLine.tube.material.dispose();
			tempLine.startSphere.geometry.dispose();
			tempLine.startSphere.material.dispose();
			tempLine.endSphere.geometry.dispose();
			tempLine.endSphere.material.dispose();
		}
		this.angleLines = [];
		this.angleLinePoints=[];
		this.angleLineCount=0;
	}

	removeAngleMeasures(){
		for(let i=this.angleBoxes.length-1;i>=0;i--){
			if(this.angleBoxes[i].checked){
				const tempBoxMesh = this.angleBoxes[i].boxMesh;
				this.modelGroup.remove(tempBoxMesh);
				tempBoxMesh.geometry.dispose();
				tempBoxMesh.material.dispose();
				this.angleBoxes.splice(i,1);				

				const tempAngle = this.angles[i];
				const tempDegreeMesh = tempAngle.degreeMesh;
				this.modelGroup.remove(tempDegreeMesh);
				tempDegreeMesh.geometry.dispose();
				tempDegreeMesh.material.dispose();
				for(let j=0; j<tempAngle.angleLines.length; j++){
					const tempLine = tempAngle.angleLines[j];
					this.modelGroup.remove(tempLine.tube);
					tempLine.tube.geometry.dispose();
					tempLine.tube.material.dispose();
					this.modelGroup.remove(tempLine.startSphere);
					tempLine.startSphere.geometry.dispose();
					tempLine.startSphere.material.dispose();
					this.modelGroup.remove(tempLine.endSphere);
					tempLine.endSphere.geometry.dispose();
					tempLine.endSphere.material.dispose();
				}
				this.angles.splice(i,1);

				this.decreaseAngleCount();
			}
		}
	}


	setVisibleMeasure(axis){
		for(let i=this.angles.length-1;i>=0;i--){
			const angle = this.angles[i];            
			const textBox = this.angleBoxes[i].boxMesh;
			if(angle.axis == axis){
				for(let j=0; j<angle.angleLines.length; j++){
					const line = angle.angleLines[j];
					line.tube.visible = true;
					line.startSphere.visible = true;
					line.endSphere.visible = true;
				}
				textBox.visible= true;
			}
			else{
				for(let j=0; j<angle.angleLines.length; j++){
					const line = angle.angleLines[j];
					line.tube.visible = false;
					line.startSphere.visible = false;
					line.endSphere.visible = false;
				}
				textBox.visible= false;
			}
		}
	}

	setVisibleFalse(){
		for(let i=this.angles.length-1;i>=0;i--){
			const angle = this.angles[i];            
			const textBox = this.angleBoxes[i].boxMesh;
			for(let j=0; j<angle.angleLines.length; j++){
				const line = angle.angleLines[j];
				line.tube.visible = false;
				line.startSphere.visible = false;
				line.endSphere.visible = false;
			}
			textBox.visible= false;
		}
	}

	rotateAngles(radian){
		if(this.angles.length==0) return;
	
		for(let i=this.angles.length-1;i>=0;i--){
			const axis = this.angles[i].axis;
			if(axis == 1 || axis==2){
				const tempBoxMesh = this.angleBoxes[i].boxMesh;
				this.modelGroup.remove(tempBoxMesh);
				tempBoxMesh.geometry.dispose();
				tempBoxMesh.material.dispose();
				this.angleBoxes.splice(i,1);				

				const tempAngle = this.angles[i];
				const tempDegreeMesh = tempAngle.degreeMesh;
				this.modelGroup.remove(tempDegreeMesh);
				tempDegreeMesh.geometry.dispose();
				tempDegreeMesh.material.dispose();
				for(let j=0; j<tempAngle.angleLines.length; j++){
					const tempLine = tempAngle.angleLines[j];
					this.modelGroup.remove(tempLine.tube);
					tempLine.tube.geometry.dispose();
					tempLine.tube.material.dispose();
					this.modelGroup.remove(tempLine.startSphere);
					tempLine.startSphere.geometry.dispose();
					tempLine.startSphere.material.dispose();
					this.modelGroup.remove(tempLine.endSphere);
					tempLine.endSphere.geometry.dispose();
					tempLine.endSphere.material.dispose();
				}
				this.angles.splice(i,1);

				this.decreaseAngleCount();	
			}
			else{
				const angle = this.angles[i];
				const degreeMesh = angle.degreeMesh;
				degreeMesh.rotation.y = radian - angle.radian;
				const textBox = this.angleBoxes[i].boxMesh;

				const rotAxis = new THREE.Vector3(0, 1, 0); 
				let quaternion = new THREE.Quaternion().setFromAxisAngle(rotAxis, radian - angle.radian);

				for(let j=0; j<angle.angleLines.length; j++){
					const line = angle.angleLines[j];
					line.tube.rotation.y = radian - line.radian;

					let startPosition = new THREE.Vector3().copy(line.startPoint);
					startPosition.applyQuaternion(quaternion);					
					line.startSphere.position.copy(startPosition);

					let endPosition = new THREE.Vector3().copy(line.endPoint);
					endPosition.applyQuaternion(quaternion);		
					line.endSphere.position.copy(endPosition);
				}

				quaternion = new THREE.Quaternion().setFromAxisAngle(rotAxis, radian - angle.radian*2);

				const point1 = angle.angleLinePoints[0].clone().applyQuaternion(quaternion);
				const point2 = angle.angleLinePoints[1].clone().applyQuaternion(quaternion);
				const point3 = angle.angleLinePoints[2].clone().applyQuaternion(quaternion);
		
				const vectorA = new THREE.Vector3().subVectors(point1, point2).normalize();
				const vectorB = new THREE.Vector3().subVectors(point3, point2).normalize();

				const averageVector = new THREE.Vector3().addVectors(vectorA, vectorB).normalize();
				const offsetDistance = 10.0; 
				const textBoxPosition = point2.clone().add(averageVector.multiplyScalar(offsetDistance));
		
				textBox.position.copy(textBoxPosition);
			}
		}
	}
}