<template>
  <section class="sec_state_area">
    <h2
      v-if="
        facilities.length <= 0 ||
        facilities === null ||
        facilities === undefined
      "
    >
      <strong>{{ user.fullName }}</strong
      >님, 환영합니다.
    </h2>
    <h2 v-else>
      <strong>{{ user.fullName }}</strong
      >님, 반갑습니다.
    </h2>
    <h3>현재 {{ totalFacility }}개의 시설물이 등록되어 있어요.</h3>
    <ul class="state_box_list">
      <li
        class="state_box"
        v-for="(facilityType, index) in facilityTypeCounts"
        :key="facilityType.type + index"
      >
        <h5>{{ facilityType.type }}</h5>
        <p>{{ facilityType.count }}개</p>
      </li>
    </ul>
  </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SummaryList",
  computed: {
    ...mapState(["user", "facilityTypeCounts"]),
  },
  props: {
    totalFacility: {
      type: Number,
      required: true,
    },
    facilities: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-weight: 700;
  font-size: 3.8rem;
  margin-bottom: 14px;
  margin-top: 60px;

  @include mobile {
    font-size: 2.8rem;
    margin-bottom: 10px;
    margin-top: 16px;
  }

  strong {
    color: $main_color;
  }
}

h3 {
  font-weight: 400;
  font-size: 2.4rem;
  margin-bottom: 40px;

  @include mobile {
    font-size: 2rem;
    margin-bottom: 20px;
  }
}

.state_box_list {
  @include flexbox;
  gap: 20px;

  @include mobile {
    @include flexbox(center, flex-start, column);
    gap: 12px;
  }

  .state_box {
    width: calc(100% - 20px);
    @include borderCardBox;
    @include flexbox(center, center, column);

    @include mobile {
      @include flexbox(center, space-between, row);
      @include borderCardBox($padding: 18px 20px);
      width: 100%;
    }

    h5 {
      font-weight: 400;
      font-size: 1.6rem;
      color: $gray_9fa6b2;
      margin-bottom: 14px;

      @include mobile {
        margin-bottom: 0;
        font-size: 1.4rem;
      }
    }

    p {
      font-size: 3rem;
      font-weight: 700;
      color: $main_white;

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }
}
</style>
