export default class Shape {
    constructor(group_num, shape, from_wall, interval) {
        this.group_num = group_num;
        if(shape=='circle') this.shape='Circle';
        else if(shape=='rectangle') this.shape='Rectangle';
        else if(shape=='underside') this.shape='Underside';
        else if(shape=='line') this.shape='Line';
        else this.shape = shape;
        
        this.from_wall = from_wall;
        this.interval = interval;

        this.circle_rotate = 0;
        this.rect_rotate = 0;
        this.checked = false;
    }

    setHeighParams(height_interval, lower_height, upper_height){
        this.height_interval = height_interval;
        this.lower_height = lower_height;
        this.upper_height = upper_height;
    }

    setLineInfo(line_auto, line_direction){
        this.line_auto = line_auto;
        this.line_direction = line_direction;
        this.circle_direction = 'inward';
        this.circle_start_angle = 0;
        this.circle_coeffs = [];     
        this.rect_direction = 'inward';
        this.rect_coeffs = []; 
        this.bottom_auto = false;
        this.bottom_direction = 'long';
        this.camera_pitch_interval = 0;
    }

    setCircleInfo(circle_direction, circle_start_angle, circle_coeffs){
        this.line_auto = false;
        this.line_direction = "up";
        this.circle_direction = circle_direction;
        this.circle_start_angle = circle_start_angle;
        this.circle_coeffs = circle_coeffs;
        this.rect_direction = 'inward';
        this.rect_coeffs = [];
        this.bottom_auto = false;
        this.bottom_direction = 'long';
        this.camera_pitch_interval = 0;
    }

    setRectInfo(rect_direction, rect_coeffs){
        this.line_auto = false;
        this.line_direction = "up";
        this.circle_direction = 'inward';
        this.circle_start_angle = 0;
        this.circle_coeffs = [];
        this.rect_direction = rect_direction;
        this.rect_coeffs = rect_coeffs;
        this.bottom_auto = false;
        this.bottom_direction = 'long';
        this.camera_pitch_interval = 0;
    }

    setBottomInfo(bottom_auto, bottom_direction){
        this.line_auto = false;
        this.line_direction = "up";
        this.circle_direction = 'inward';
        this.circle_start_angle = 0;
        this.circle_coeffs = [];
        this.rect_direction = 'inward';
        this.rect_coeffs = [];
        this.bottom_auto = bottom_auto;
        this.bottom_direction = bottom_direction;
        this.camera_pitch_interval = 0;
    }

    setAbutment(camera_pitch_interval){
        this.line_auto = false;
        this.line_direction = "up";
        this.circle_direction = 'inward';
        this.circle_start_angle = 0;
        this.circle_coeffs = [];
        this.rect_direction = 'inward';
        this.rect_coeffs = [];
        this.bottom_auto = false;
        this.bottom_direction = 'long';
        this.camera_pitch_interval = camera_pitch_interval;
    }

    setGroupNum(group_num){this.group_num=group_num;}
    setShape(shape){this.shape=shape;}
    setChecked(checked){this.checked=checked;}
    setFromWall(from_wall){this.from_wall=from_wall;}

    setInterval(interval){this.interval=interval;}
    setHeightInterval(height_interval){this.height_interval=height_interval;}
    setLowerHeight(lower_height){this.lower_height=lower_height;}
    setUpperHeight(upper_height){this.upper_height=upper_height;}

    setLineAuto(line_auto){this.line_auto=line_auto;}
    setLineDirection(line_direction){this.line_direction=line_direction;}

    setCircleInward(circle_direction){this.circle_direction=circle_direction;}
    setCircleStartAngle(circle_start_angle){this.circle_start_angle=circle_start_angle;}
    setCircleCoeffs(circle_coeffs){this.circle_coeffs=circle_coeffs;}
    setCircleRotate(circle_rotate){this.circle_rotate=circle_rotate;}

    setRectInward(rect_direction){this.rect_direction=rect_direction;}
    setRectCoeffs(rect_coeffs){this.rect_coeffs=rect_coeffs;}
    setRectRotate(rect_rotate){this.rect_rotate=rect_rotate;}

    setBottomAuto(bottom_auto){this.bottom_auto=bottom_auto;}
    setBottomDirection(bottom_direction){this.bottom_direction=bottom_direction;}
     
    setCameraPitchInterval(camera_pitch_interval){this.camera_pitch_interval = camera_pitch_interval;}

    getGroupNum(){return this.group_num;}
    getShape(){return this.shape;}
    getChecked(){return this.checked;}
    getFromWall(){return this.from_wall;}
    getInterval(){return this.interval;}

    getHeightInterval(){return this.height_interval;}
    getLowerHeight(){return this.lower_height;}
    getUpperHeight(){return this.upper_height;}

    getLineAuto(){return this.line_auto;}
    getLineDirection(){return this.line_direction;}

    getCircleInward(){return this.circle_direction;}
    getCircleStartAngle(){return this.circle_start_angle;}
    getCircleCoeffs(){return this.circle_coeffs;}
    getCircleRotate(){return this.circle_rotate;}

    getRectInward(){return this.rect_direction;}
    getRectCoeffs(){return this.rect_coeffs;}
    getRectRotate(){return this.rect_rotate;}

    getBottomAuto(){return this.bottom_auto;}
    getBottomDirection(){return this.bottom_direction;}

    getCameraPitchInterval(){return this.camera_pitch_interval;}

    rotateCircle(rot){
        this.circle_rotate += rot;
        return this.circle_rotate;
    }
    rotateRect(rot){
        this.rect_rotate += rot;
        if(this.rect_rotate===4) this.rect_rotate = 0;
        return this.rect_rotate;
    }
}