<template>
  <div v-if="isMissionListModalVisible" class="inspection_mission_modal">
    <div class="inspection_mission_modal_header">미션 목록</div>
    <div
      class="inspection_mission_modal_header_x"
      @click="$emit('closeModal')"
    ></div>
    <div class="inspection_mission_modal_content_wrap">
      <div
        class="inspection_mission_modal_content button"
        v-for="(mission, index) in filteredMissionList"
        :key="index"
        @click="loadingMission(mission)"
      >
        <div class="inspection_mission_modal_content_name">
          {{ mission.name }}
        </div>
        <div class="inspection_mission_modal_content_regdate">
          {{ mission.createdDatetime }}
        </div>
        <div
          class="inspection_mission_modal_content_x"
          @click.stop="deleteMission(mission)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./InspectionMissionListModal.scss";

export default {
  props: {
    isMissionListModalVisible: Boolean,
    filteredMissionList: Array,
  },
  emits: ["closeModal", "loadingMission", "deleteMission"],
  methods: {
    loadingMission(mission) {
      this.$emit("loadingMission", mission);
    },
    deleteMission(mission) {
      if (confirm("미션을 삭제하시겠습니까?")) {
        this.$emit("deleteMission", mission);
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
