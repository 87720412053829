export default class Range{
  constructor(range){
    this.minHeight = null;
    this.maxHeight = null;
    this.minWidth = null; 
    this.maxWidth = null;
    this.minDepth = null;
    this.maxDepth = null;
    this.boxMinHeight = null;
    this.boxMinWidth = null;
    this.boxMaxDepth = null;

    if(range){
      this.minHeight = range.minHeight;
      this.maxHeight = range.maxHeight;
      this.minWidth = range.minWidth; 
      this.maxWidth = range.maxWidth;
      this.minDepth = range.minDepth;
      this.maxDepth = range.maxDepth;
      this.boxMinHeight = range.boxMinHeight;
      this.boxMinWidth = range.boxMinWidth;
      this.boxMaxDepth = range.boxMaxDepth;
    }
  }
}