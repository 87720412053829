import {format} from 'date-fns'

export default class Detection{
  constructor(detection){
    this.id = null;
    this.albumOtherRecognitionId = null;
    this.albumId = null;
    this.photoId = null;
    this.otherRecognitionType = null;
    this.urisOfOthers = null;
    this.otherCount = null;
    this.createdDatetime = null;

    if(detection){
      this.id = detection.id;
      this.albumOtherRecognitionId = detection.albumOtherRecognitionId;
      this.albumId = detection.albumId;
      this.photoId = detection.photoId;
      this.otherRecognitionType = detection.otherRecognitionType;
      this.urisOfOthers = detection.urisOfOthers;
      this.otherCount = detection.otherCount;
      this.createdDatetime = format(detection.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    }
  }
}