<template>
  <div class="mapping_pointcloud_info">
    <div class="mapping_pointcloud_info_header">포인트 클라우드 정보</div>
    <div class="mapping_pointcloud_info_content">
      <div class="mapping_pointcloud_info_content_title">포인트 개수</div>
      <div class="mapping_pointcloud_info_content_value">
        {{ pointCloudInfo.pointNumber }}
      </div>
    </div>
    <div class="mapping_pointcloud_info_content">
      <div class="mapping_pointcloud_info_content_title">매핑 영역</div>
      <div class="mapping_pointcloud_info_content_value">
        {{ pointCloudInfo.xArea.toFixed(2) }}m *
        {{ pointCloudInfo.yArea.toFixed(2) }}m
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pointCloudInfo: Object,
  },
};
</script>

<style lang="scss">
.mapping_pointcloud_info {
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: calc(100% / 4);
  height: calc(100% / 6);
  background: $main_white;
  border-radius: 10px;
  background: rgba(23, 23, 23, 0.6);
  box-shadow: $main_box_shadow;
  padding: 15px 10px 20px 30px;
  display: flex;
  flex-direction: column;
  max-width: 330px;
  z-index: 10;
}
.mapping_pointcloud_info_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40%;
  color: $main_color;
  font-size: 1.8rem;
  padding-bottom: 10px;
}
.mapping_pointcloud_info_content {
  display: flex;
  width: 100%;
  height: 30%;
}
.mapping_pointcloud_info_content_title {
  display: flex;
  align-items: center;
  width: 45%;
  height: 100%;
  color: $main_white;
  font-size: 1.4rem;
}
.mapping_pointcloud_info_content_value {
  @include flexbox;
  width: 55%;
  height: 100%;
  color: $main_white;
  font-size: 1.4rem;
}
</style>
