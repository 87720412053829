<template>
  <div class="report_left_layout" v-if="!reportDetails">
    <div class="report_site_title">시설물</div>
    <div
      class="report_site"
      v-if="selectedSite"
      :style="{ width: getWidth(selectedSite.name) }"
    >
      {{ selectedSite.name }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedSite"]),
    getWidth() {
      return (siteName) => {
        if (siteName) {
          return siteName.length * 18 + 10 + "px";
        } else {
          return "70px";
        }
      };
    },
  },
  props: {
    reportDetails: Boolean,
  },
};
</script>

<style lang="scss">
.report_left_layout {
  position: fixed;
  top: 70px;
  left: 0px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  /* backdrop-filter: blur(1px); */
  width: calc(100% / 4);
  height: calc(100% - 100px);
  max-width: 310px;
  margin: 15px 0px 15px 15px;
  padding: 20px 10px 20px 20px;
  z-index: 5;
}
.report_site_title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: $main_white;
}
.report_site {
  @include flexbox;
  width: 100%;
  height: 30px;
  color: $main_white;
  font-size: 1.7rem;
  font-weight: bold;
  margin: 10px 0px 10px 0px;
}
</style>
