<template>
  <div class="mapping_left_side_command">
    <div class="mapping_left_side_command_header">명령</div>
    <div class="mapping_left_side_command_button_wrap">
      <!-- <div class="mapping_left_side_command_button" id="arm" @click="clickArm">
        <div class="mapping_left_side_command_button_title">{{droneData.state.arming? '시동 끄기' : '시동 켜기'}}</div>
        <div class="mapping_left_side_command_button_img" :class="{'on':droneData.state.arming}"></div>
      </div>
      <div
        class="mapping_left_side_command_button"
        id="take_off"
        @click="clickTakeOff"
      >
        <div class="mapping_left_side_command_button_title">
          {{ droneData.state.takeOff ? "착륙" : "이륙" }}
        </div>
        <div
          class="mapping_left_side_command_button_img"
          :class="{ on: droneData.state.takeOff }"
        ></div>
      </div>
      <div
        class="mapping_left_side_command_button"
        id="auto"
        @click="clickMode"
      >
        <div class="mapping_left_side_command_button_title">
          {{ droneData.mode == "GUIDED" ? "수동" : "자동" }}
        </div>
        <div class="mapping_left_side_command_button_img"></div>
      </div> -->
      <div
        class="mapping_left_side_command_button"
        id="return"
        @click="clickRTL"
      >
        <div class="mapping_left_side_command_button_title">복귀</div>
        <div class="mapping_left_side_command_button_img"></div>
      </div>
      <div
        class="mapping_left_side_command_button"
        id="reboot"
        @click="clickReboot"
      >
        <div class="mapping_left_side_command_button_title">재부팅</div>
        <div class="mapping_left_side_command_button_img"></div>
      </div>
    </div>
    <!-- <div class="mapping_left_side_reboot button" @click="clickReboot">재부팅</div> -->
  </div>
</template>

<script>
export default {
  // emits : ['clickArm','clickTakeOff','clickMode','clickRTL','clickReboot'],
  emits: ["clickRTL", "clickReboot"],
  props: {
    droneData: Object,
  },
  methods: {
    // clickArm(){
    //   this.$emit('clickArm');
    // },
    // clickTakeOff(){
    //   this.$emit('clickTakeOff');
    // },
    // clickMode(){
    //   this.$emit('clickMode');
    // },
    clickRTL() {
      this.$emit("clickRTL");
    },
    clickReboot() {
      this.$emit("clickReboot");
    },
  },
};
</script>

<style lang="scss">
.mapping_left_side_command {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110px;
}
.mapping_left_side_command_header {
  width: 100%;
  height: 30px;
  font-size: 1.4rem;
  font-weight: 700;
  color: $main_white;
}
.mapping_left_side_command_button_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  height: 80px;
}
.mapping_left_side_command_button {
  flex-shrink: 0;
  width: calc(50% - 5px);
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
.mapping_left_side_command_button_title {
  @include flexbox;
  width: 100%;
  height: 20px;
  font-size: 1.2rem;
  color: $main_white;
}
.mapping_left_side_command_button_img {
  width: 100%;
  height: 60px;
}
/* #arm .mapping_left_side_command_button_img{
  background: url('@/../public/images/disarm.png') no-repeat center;
  background-size: 30px 30px;
}
#arm .mapping_left_side_command_button_img:hover {
  background: url("@/../public/images/arm.png") no-repeat center;
  background-size: 30px 30px;
}
#arm .mapping_left_side_command_button_img.on {
  background: url("@/../public/images/arm.png") no-repeat center;
  background-size: 30px 30px;
}
#arm .mapping_left_side_command_button_img.on:hover {
  background: url("@/../public/images/disarm.png") no-repeat center;
  background-size: 30px 30px;
}
#take_off .mapping_left_side_command_button_img {
  background: url("@/../public/images/land.png") no-repeat center;
  background-size: 30px 30px;
}
#take_off .mapping_left_side_command_button_img:hover {
  background: url("@/../public/images/take_off.png") no-repeat center;
  background-size: 30px 30px;
}
#take_off .mapping_left_side_command_button_img.on {
  background: url("@/../public/images/take_off.png") no-repeat center;
  background-size: 30px 30px;
}
#take_off .mapping_left_side_command_button_img.on:hover {
  background: url("@/../public/images/land.png") no-repeat center;
  background-size: 30px 30px;
}
#auto .mapping_left_side_command_button_img {
  background: url("@/../public/images/auto.svg") no-repeat center;
  background-size: 30px 30px;
} */
#return .mapping_left_side_command_button_img{
  background: url('@/../public/images/return.png') no-repeat center;
  background-size: 30px 30px;
}
#reboot .mapping_left_side_command_button_img{
  background: url('@/../public/images/reboot.svg') no-repeat center;
  background-size: 30px 30px;
}
/* .mapping_left_side_reboot{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  font-size: 1.2rem;
} */
</style>
