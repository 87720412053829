/**
 * 010으로 시작하는 숫자를 핸드폰 번호처럼 만들어줌 (하이픈 나오도록)
 * 파라미터는 String 타입임!!!!
 * 그리고 무조건 010으로 시작해야 하이픈이 들어감
 * @param {String} phoneNumber
 */
export const makePhoneNumber = (phoneNumber) => {
  // 전화번호가 010으로 시작하는지 확인
  if (phoneNumber.startsWith("010")) {
    // 전화번호 형식 변환 (010-xxxx-xxxx)
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      7
    )}-${phoneNumber.slice(7)}`;
  } else {
    // 010으로 시작하지 않는 경우 원래 번호 반환
    return phoneNumber;
  }
};
