import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import axios from "axios";

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App);

const user = JSON.parse(sessionStorage.getItem("user"));
if (user) {
  store.dispatch("login", user);
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user.id) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

app.use(router);
app.use(store);
app.config.globalProperties.$axios = axios;
app.use(VueAwesomePaginate);
app.mount("#app");
