export default class CameraMission{
  constructor(cameraMission){
    this.id = null; 
    this.impId = null; 
    this.impWpsId = null;
    this.gimbalPitch = null;
    this.shouldCapture = null;
    if(cameraMission){
      this.id = cameraMission.id; 
      this.impId = cameraMission.impId; 
      this.impWpsId = cameraMission.impWpsId;
      this.gimbalPitch = cameraMission.gimbalPitch;
      this.shouldCapture = cameraMission.shouldCapture;
    }
  }
}