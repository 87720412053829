<template>
  <div v-if="isVisible" class="inspection_setting_modal_overlay">
    <div class="inspection_setting_title">설정</div>
    <div class="inspection_setting_container">
      <div class="inspection_setting_contents_titles_conatiner">
        <div
          class="inspection_setting_name button"
          :class="{ on: settingTab == 1 }"
          @click="changeSettingTab(1)"
        >
          드론 연결
        </div>
        <div
          class="inspection_setting_name button"
          :class="{ on: settingTab == 2 }"
          @click="changeSettingTab(2)"
        >
          점검 파라미터
        </div>
        <div
          class="inspection_setting_name button"
          :class="{ on: settingTab == 3 }"
          @click="changeSettingTab(3)"
        >
          페일세이프
        </div>
        <div
          class="inspection_setting_name button"
          :class="{ on: settingTab == 4 }"
          @click="changeSettingTab(4)"
        >
          포인트클라우드
        </div>
      </div>

      <div v-if="settingTab == 1" class="inspection_setting_contents_container">
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">모듈 종류</div>
          <div class="inspection_setting_type_select_wrapper" @click="typeSelectionClicked()">
            <div class="inspection_setting_selected_type"> {{ selectedDroneType.name }} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_delete button" 
               v-if=selectedDroneType.name
               @click="setDroneType()"></div>
        </div>
        <div v-if="isTypeSelection && droneTypeList"
             class="inspection_setting_type_select_modal"
             :style="{ height: getSelectionTypeHeight}">
          <div v-for="(droneType, typeIndex) in droneTypeList"
               :key="typeIndex"
               class="inspection_setting_type_selection_item"
               @click="setDroneType(typeIndex)">
            {{ droneType.name }}
          </div>
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">{{selectedDroneType.name == 'Anafi'? '드론 #1' : '모듈 #1'}}</div>
          <div class="inspection_setting_type_select_wrapper" @click="droneSelectionClicked(1)">
            <div class="inspection_setting_selected_type"> {{selectedDrone[0].droneSerialNumber}} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_unlink button" v-if="selectedDrone[0].id"
               @click="setDrone(0)"></div>
        </div>
        <div v-if="isDroneSelection == 1 && droneList"
             class="inspection_setting_type_select_modal" id="inspection_module_1"
             :style="{ height: getSelectionDroneHeight}">
          <div v-for="(drone, droneIndex) in droneList"
               :key="droneIndex"
               class="inspection_setting_type_selection_item"
               @click="setDrone(0,droneIndex)">
            {{ drone.droneSerialNumber }}
          </div>
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">{{selectedDroneType.name == 'Anafi'? '드론 #2' : '모듈 #2'}}</div>
          <div class="inspection_setting_type_select_wrapper" @click="droneSelectionClicked(2)">
            <div class="inspection_setting_selected_type"> {{selectedDrone[1].droneSerialNumber}} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_unlink button" v-if="selectedDrone[1].id"
               @click="setDrone(1)"></div>
        </div>
        <div v-if="isDroneSelection == 2 && droneList"
             class="inspection_setting_type_select_modal" id="inspection_module_2"
             :style="{ height: getSelectionDroneHeight}">
          <div v-for="(drone, droneIndex) in droneList"
               :key="droneIndex"
               class="inspection_setting_type_selection_item"
               @click="setDrone(1,droneIndex)">
            {{ drone.droneSerialNumber }}
          </div>
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">{{selectedDroneType.name == 'Anafi'? '드론 #3' : '모듈 #3'}}</div>
          <div class="inspection_setting_type_select_wrapper" @click="droneSelectionClicked(3)">
            <div class="inspection_setting_selected_type"> {{selectedDrone[2].droneSerialNumber}} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_unlink button" v-if="selectedDrone[2].id"
               @click="setDrone(2)"></div>
        </div>
        <div v-if="isDroneSelection == 3 && droneList"
             class="inspection_setting_type_select_modal" id="inspection_module_3"
             :style="{ height: getSelectionDroneHeight}">
          <div v-for="(drone, droneIndex) in droneList"
               :key="droneIndex"
               class="inspection_setting_type_selection_item"
               @click="setDrone(2,droneIndex)">
            {{ drone.droneSerialNumber }}
          </div>
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">{{selectedDroneType.name == 'Anafi'? '드론 #4' : '모듈 #4'}}</div>
          <div class="inspection_setting_type_select_wrapper" @click="droneSelectionClicked(4)">
            <div class="inspection_setting_selected_type"> {{selectedDrone[3].droneSerialNumber}} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_unlink button" v-if="selectedDrone[3].id"
               @click="setDrone(3)"></div>
        </div>
        <div v-if="isDroneSelection == 4 && droneList"
             class="inspection_setting_type_select_modal" id="inspection_module_4"
             :style="{ height: getSelectionDroneHeight}">
          <div v-for="(drone, droneIndex) in droneList"
               :key="droneIndex"
               class="inspection_setting_type_selection_item"
               @click="setDrone(3,droneIndex)">
            {{ drone.droneSerialNumber }}
          </div>
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">{{selectedDroneType.name == 'Anafi'? '드론 #5' : '모듈 #5'}}</div>
          <div class="inspection_setting_type_select_wrapper" @click="droneSelectionClicked(5)">
            <div class="inspection_setting_selected_type"> {{selectedDrone[4].droneSerialNumber}} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_unlink button" v-if="selectedDrone[4].id"
               @click="setDrone(4)"></div>
        </div>
        <div v-if="isDroneSelection == 5 && droneList"
             class="inspection_setting_type_select_modal" id="inspection_module_5"
             :style="{ height: getSelectionDroneHeight}">
          <div v-for="(drone, droneIndex) in droneList"
               :key="droneIndex"
               class="inspection_setting_type_selection_item"
               @click="setDrone(4,droneIndex)">
            {{ drone.droneSerialNumber }}
          </div>
        </div>
        <div class="inspection_setting_content_1" v-if="selectedDroneType.name == 'Anafi'">
          <div class="inspection_setting_content_name">모듈 아이디</div>
          <div class="inspection_setting_type_select_wrapper" @click="droneSelectionClicked(6)">
            <div class="inspection_setting_selected_type"> {{selectedModule.droneSerialNumber}} </div>
            <div class="inspection_setting_selected_type_image"></div>
          </div>
          <div class="inspection_setting_selected_unlink button" v-if="selectedModule.id"
               @click="setModule()"></div>
        </div>
        <div v-if="isDroneSelection == 6 && droneList"
             class="inspection_setting_type_select_modal" id="inspection_module_6"
             :style="{ height: getSelectionDroneHeight}">
          <div v-for="(drone, droneIndex) in droneList"
               :key="droneIndex"
               class="inspection_setting_type_selection_item"
               @click="setModule(droneIndex)">
            {{ drone.droneSerialNumber }}
          </div>
        </div>
      </div>
      <div v-if="settingTab == 2" class="inspection_setting_contents_container">
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">이륙 높이</div>
          <input
            :placeholder="droneParams.takeoffHeight"
            class="inspection_setting_input param"
            ref="inputTakeOff"
            @blur="setTakeoff"
            @input="validateNumber($event, 3, true)"
          />
          <div class="inspection_setting_unit">m</div>
        </div>
        <div class="inspection_setting_content_3">
          <div class="inspection_setting_content_name">드론 크기</div>
          <div class="inspection_setting_multi_content_wrapper">
            <div class="inspection_setting_content_wrapper">
              <div class="inspection_setting_discript">가로</div>
              <input
                type="number"
                :placeholder="selectedDroneType.dimensionX"
                class="inspection_setting_input param"
                ref="inputDimensionX"
                @blur="setParams"
                @input="validateNumber($event, 4, true)"
              />
              <div class="inspection_setting_unit">mm</div>
            </div>
            <div class="inspection_setting_content_wrapper">
              <div class="inspection_setting_discript">세로</div>
              <input
                type="number"
                :placeholder="selectedDroneType.dimensionY"
                class="inspection_setting_input param"
                ref="inputDimensionY"
                @blur="setParams"
                @input="validateNumber($event, 4, true)"
              />
              <div class="inspection_setting_unit">mm</div>
            </div>
            <div class="inspection_setting_content_wrapper">
              <div class="inspection_setting_discript">높이</div>
              <input
                type="number"
                :placeholder="selectedDroneType.dimensionZ"
                class="inspection_setting_input param"
                ref="inputDimensionZ"
                @blur="setParams"
                @input="validateNumber($event, 4, true)"
              />
              <div class="inspection_setting_unit">mm</div>
            </div>
          </div>
        </div>
        <!-- <div class="inspection_setting_content_1">
                <div class="inspection_setting_content_name"> 대기 시간 </div>
                <input :placeholder="droneParams.waitingTime" class="inspection_setting_input param" ref="inputWaitingTime" @blur="setWaitingTime" @input="validateNumber($event,5,true)">
                <div class="inspection_setting_unit"> sec </div>
            </div> -->
      </div>
      <div v-if="settingTab == 3" class="inspection_setting_contents_container">
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">배터리 설정</div>
          <!-- <div class="inspection_setting_multi_content_wrapper">  -->
          <!-- <div class="inspection_setting_content_wrapper"> 
                      <div class="inspection_setting_discript"> 최솟값 </div>
                      <input :placeholder="selectedDroneType.minVoltage" ref="inputMinVoltage" class="inspection_setting_input param" @blur="setParams" @input="validateNumber($event,5,true)">
                      <div class="inspection_setting_unit"> v </div>
                  </div>
                  <div class="inspection_setting_content_wrapper"> 
                      <div class="inspection_setting_discript"> 최댓값 </div>
                      <input :placeholder="selectedDroneType.maxVoltage" ref="inputMaxVoltage" class="inspection_setting_input param"  @blur="setParams" @input="validateNumber($event,5,true)">
                      <div class="inspection_setting_unit"> v </div>
                  </div> -->
          <div class="inspection_setting_content_1">
            <div class="inspection_setting_discript">페일세이프</div>
            <input
              :placeholder="droneParams.batteryFailSafe"
              ref="inputBatteryFailSafe"
              class="inspection_setting_input param"
              @blur="setBatteryFailSafe"
            />
            <div class="inspection_setting_unit">%</div>
          </div>
          <!-- </div> -->
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">타임 아웃</div>
          <input
            :placeholder="droneParams.connectionLost"
            class="inspection_setting_input param"
            ref="inputConnectionLost"
            @blur="setConnectionLost"
            @input="validateNumber($event, 5, true)"
          />
          <div class="inspection_setting_unit">sec</div>
        </div>
      </div>
      <div v-if="settingTab == 4" class="inspection_setting_contents_container">
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">포인트 크기</div>
          <input
            type="number"
            :placeholder="pointCloudParams.pointSize"
            class="inspection_setting_input param"
            v-model="inputPointSize"
            @blur="blurPointSize"
          />
        </div>
        <div class="inspection_setting_content_1">
          <div class="inspection_setting_content_name">포인트 투명도</div>
          <input
            type="number"
            :placeholder="pointCloudParams.opacity"
            class="inspection_setting_input param"
            v-model="inputPointOpacity"
            @blur="blurPointOpacity"
          />
        </div>
        <div class="inspection_setting_content_3">
          <div class="inspection_setting_content_name">포인트 색상</div>
          <div class="inspection_setting_multi_content_wrapper">
            <div class="inspection_setting_content_wrapper">
              <div
                class="inspection_setting_radio_button radio"
                :class="{ on: pointCloudParams.mode == 'intensity' }"
                @click="clickPointCloudMode('intensity')"
              ></div>
              <div class="inspection_setting_discript">반사율</div>
              <div
                class="inspection_setting_radio_button radio"
                :style="{ marginLeft: '20px' }"
                :class="{ on: pointCloudParams.mode == 'height' }"
                @click="clickPointCloudMode('height')"
              ></div>
              <div class="inspection_setting_discript">높이</div>
            </div>
            <div class="inspection_setting_content_wrapper" v-if="pointCloudParams.mode == 'height'">
              <div class="inspection_setting_discript">최솟값</div>
              <input
                type="number"
                class="inspection_setting_input param"
                :placeholder="pointCloudParams.height.min"
                v-model="inputHeight.min"
                @blur="blurHeight('min')"
              />
              <div
                class="inspection_setting_unit"
              >
                m
              </div>
            </div>
            <div class="inspection_setting_content_wrapper" v-if="pointCloudParams.mode == 'height'">
              <div class="inspection_setting_discript">최댓값</div>
              <input
                type="number"
                class="inspection_setting_input param"
                :placeholder="pointCloudParams.height.max"
                v-model="inputHeight.max"
                @blur="blurHeight('max')"
              />
              <div
                class="inspection_setting_unit"
              >
                m
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isVisible: Boolean,
    droneList: Array,
    selectedDrone : Array,
    selectedModule: Object,
    droneTypeList: Array,
    selectedDroneType: Object,
    droneParams: Object,
    pointCloudParams: Object,
  },
  computed: {
    ...mapState(["api"]),
    getSelectionTypeHeight(){
      if(this.droneTypeList.length == 0){
        return '0px'
      }
      else{
        return this.droneTypeList.length * 25 + 'px'
      }
    },
    getSelectionDroneHeight(){
      if(this.droneList.length == 0){
        return '0px'
      }
      else{
        return this.droneList.length * 25 + 'px'
      }
    },
  },
  emits: [
    "droneChanged",
    "droneTypeChanged",
    "moduleChanged",
    "takeoffChanged",
    "waitingTimeChanged",
    "batteryFailSafeChanged",
    "connectionLostChanged",
    "changePointCloudParam",
    "getDroneTypes",
    "changeAlert",
    "closeAlert",
  ],
  data() {
    return {
      settingTab: 1,
      isTypeSelection: false,
      isDroneSelection : null,
      inputPointSize: null,
      inputPointOpacity: null,
      inputHeight : {
        min : null,
        max : null,
      }
    };
  },
  methods: {
    checkDroneIdLimit(event, limit) {
      if (event.target.value.length > limit) {
        event.target.value = null; // 값 자르기
        this.$emit("changeAlert", `입력 값은 ${limit}자를 초과할 수 없습니다.`);
        event.target.blur();
      }
    },
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        event.target.value = null;
        this.$emit("changeAlert", `입력 값은 ${limit}자를 초과할 수 없습니다.`);
        event.target.blur();
      }
    },
    validateNumber(event, limit, UsingCheckLimit) {
      let regex = /[^0-9.]/g;
      if (regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(regex, "");
      }
      if (UsingCheckLimit) {
        this.checkInputLimit(event, limit);
      }
    },
    changeSettingTab(number) {
      this.settingTab = number;
    },
    typeSelectionClicked() {
      this.isTypeSelection = !this.isTypeSelection;
      if(this.isTypeSelection){
        this.isDroneSelection = null;
      }
    },
    setDroneType(index) {
      this.isTypeSelection = false;
      this.$emit("droneTypeChanged", this.droneTypeList[index]);
    },
    droneSelectionClicked(index) {
      if(this.isDroneSelection == index){
        this.isDroneSelection = null;
      }
      else{
        this.isDroneSelection = index;
        this.isTypeSelection = false;
      }
    },
    setDrone(num, index) {
      this.isDroneSelection = null;
      if(index != undefined){
        this.$emit("droneChanged", num, this.droneList[index]);
      }
      else{
        this.$emit("droneChanged", num);
      }
    },
    setModule(index) {
      this.isDroneSelection = null;
      if(index != undefined){
        this.$emit("moduleChanged", this.droneList[index]);
      }
      else{
        this.$emit("moduleChanged");
      }
    },
    setTakeoff() {
      if (
        this.droneParams.takeoffHeight != this.$refs.inputTakeOff.value &&
        this.$refs.inputTakeOff.value
      ) {
        this.$emit("takeoffChanged", this.$refs.inputTakeOff.value);
      }
      this.$refs.inputTakeOff.value = null;
    },
    setParams() {
      this.api.putDroneType(this.putDroneTypeCallback, this.selectedDroneType.id, {
        name: this.selectedDroneType.name,
        // minVoltage : this.$refs.inputMinVoltage.value? this.$refs.inputMinVoltage.value : this.selectedDroneType.minVoltage,
        // maxVoltage : this.$refs.inputMaxVoltage.value? this.$refs.inputMaxVoltage.value : this.selectedDroneType.maxVoltage,
        minVoltage: this.selectedDroneType.minVoltage,
        maxVoltage: this.selectedDroneType.maxVoltage,
        dimensionX: this.$refs.inputDimensionX.value
          ? parseFloat(this.$refs.inputDimensionX.value)
          : this.selectedDroneType.dimensionX,
        dimensionY: this.$refs.inputDimensionY.value
          ? parseFloat(this.$refs.inputDimensionY.value)
          : this.selectedDroneType.dimensionY,
        dimensionZ: this.$refs.inputDimensionZ.value
          ? parseFloat(this.$refs.inputDimensionZ.value)
          : this.selectedDroneType.dimensionZ,
      });
    },
    putDroneTypeCallback() {
      // this.$refs.inputMinVoltage.value = null;
      // this.$refs.inputMaxVoltage.value = null;
      this.$refs.inputDimensionX.value = null;
      this.$refs.inputDimensionY.value = null;
      this.$refs.inputDimensionZ.value = null;
      this.$emit("getDroneTypes");
    },
    setWaitingTime() {
      if (
        this.droneParams.waitingTime != this.$refs.inputWaitingTime.value &&
        this.$refs.inputWaitingTime.value
      ) {
        this.$emit("waitingTimeChanged", this.$refs.inputWaitingTime.value);
      }
      this.$refs.inputWaitingTime.value = null;
    },
    setBatteryFailSafe() {
      if (
        this.droneParams.batteryFailSafe != this.$refs.inputBatteryFailSafe.value &&
        this.$refs.inputBatteryFailSafe.value
      ) {
        if (
          this.$refs.inputBatteryFailSafe.value >= 25 &&
          this.$refs.inputBatteryFailSafe.value <= 80
        ) {
          this.$emit(
            "batteryFailSafeChanged",
            this.$refs.inputBatteryFailSafe.value
          );
        } else {
          this.$emit("changeAlert", "25~80 사이의 값만 입력이 가능합니다.");
        }
      }
      this.$refs.inputBatteryFailSafe.value = null;
    },
    setConnectionLost() {
      if (
        this.droneParams.connectionLost !=
          this.$refs.inputConnectionLost.value &&
        this.$refs.inputConnectionLost.value
      ) {
        this.$emit(
          "connectionLostChanged",
          this.$refs.inputConnectionLost.value
        );
      }
      this.$refs.inputConnectionLost.value = null;
    },
    clickPointCloudMode(mode) {
      this.$emit("changePointCloudParam", "mode", mode);
    },
    blurPointSize() {
      if (
        this.pointCloudParams.pointSize != this.inputPointSize &&
        this.inputPointSize != undefined
      ) {
        if (this.inputPointSize <= 10 && this.inputPointSize >= 1) {
          this.$emit("changePointCloudParam", "pointSize", this.inputPointSize);
        } else {
          this.$emit("changeAlert", " 1~10 사이의 값만 입력이 가능합니다.");
        }
      }
      this.inputPointSize = null;
    },
    blurPointOpacity() {
      if (
        this.pointCloudParams.opacity != this.inputPointOpacity &&
        this.inputPointOpacity != undefined
      ) {
        if (this.inputPointOpacity <= 1 && this.inputPointOpacity >= 0) {
          this.$emit(
            "changePointCloudParam",
            "opacity",
            this.inputPointOpacity
          );
        } else {
          this.$emit("changeAlert", " 0~1사이의 값만 입력이 가능합니다.");
        }
      }
      this.inputPointOpacity = null;
    },
    blurHeight(type){
      switch (type){
        case 'min':
          if(this.inputHeight.min != this.pointCloudParams.height.min && this.inputHeight.min != undefined){
            if(this.inputHeight.min >= this.pointCloudParams.height.max){
              this.$emit("changeAlert", " 입력하신 최솟값이 최댓값보다 같거나 큽니다. 더 낮은 숫자를 입력해주세요.");
            }
            else{
              this.$emit("changePointCloudParam", "heightMin", this.inputHeight.min);
            }
          }
          this.inputHeight.min = null;
          break;
        case 'max':
          if(this.inputHeight.max != this.pointCloudParams.height.max && this.inputHeight.max != undefined){
            if(this.inputHeight.max <= this.pointCloudParams.height.min){
              this.$emit("changeAlert", " 입력하신 최댓값이 최솟값보다 같거나 작습니다. 더 큰 숫자를 입력해주세요.");
            }
            else{
              this.$emit("changePointCloudParam", "heightMax", this.inputHeight.max);
            }
          }
          this.inputHeight.max = null;
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style lang="scss">
.inspection_setting_modal_overlay {
  position: fixed;
  top: 20%;
  left: 35%;
  width: 30%;
  height: 60%;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspection_setting_title {
  @include flexbox;
  color: $main_white;
  font-size: 2rem;
  background-color: rgba(32, 32, 32, 0.85);
  width: 100%;
  height: 10%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.inspection_setting_container {
  display: flex;
  flex-direction: row;
  height: 90%;
  width: 100%;
}

.inspection_setting_contents_titles_conatiner {
  display: flex;
  flex-direction: column;
  width: calc(30% - 5px);
  height: calc(100% - 10px);
  margin-left: 5px;
  margin-top: 5px;
  background-color: rgba(32, 32, 32, 0.85);
  border-radius: 2px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.inspection_setting_name {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  height: 35px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 1.2rem;
}

.inspection_setting_contents_container {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: calc(100% - 10px);
  margin-top: 5px;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.inspection_setting_content_1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 35px;
  background-color: transparent;
  margin-bottom: 10px;
}

.inspection_setting_content_2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  background-color: transparent;
  margin-bottom: 10px;
}

.inspection_setting_content_3 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 125px;
  background-color: transparent;
  margin-bottom: 10px;
}

.inspection_setting_multi_content_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 130px);
  background-color: transparent;
}

.inspection_setting_content_wrapper {
  display: flex;
  align-items: center;
  flex-direction: bottom;
  height: calc(100% / 3);
  width: 100%;
}

.inspection_setting_content_name {
  display: flex;
  align-items: center;
  height: 35px;
  width: 100px;
  padding-left: 10px;
  color: $main_white;
  font-size: 1.2rem;
}

.inspection_setting_input {
  display: flex;
  align-items: center;
  height: 25px;
  width: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(23, 23, 23);
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  color: $main_white;
  font-size: 1.2rem;
  padding-left: 10px;
  border: none;
}

.inspection_setting_input::placeholder {
  color: gray;
  font-size: 1.2rem;
}
.param {
  width: 50px;
  text-align: center;
}
.inspection_setting_discript {
  display: flex;
  align-items: center;
  height: 25px;
  width: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  color: $main_white;
  font-size: 1.2rem;
  padding-right: 10px;
}

.inspection_setting_unit {
  display: flex;
  align-items: center;
  height: 25px;
  width: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  color: $main_white;
  font-size: 1.2rem;
  padding-left: 10px;
}

.inspection_setting_radio_button {
  @include flexbox;
  height: 100%;
  width: 25px;
  margin-right: 5px;
}
.inspection_setting_radio_button:last-of-type {
  margin-left: 20px;
}

.inspection_setting_type_select_wrapper {
  display: flex;
  align-items: center;
  height: 25px;
  width: 100px;
  background-color: rgb(23, 23, 23);
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  padding-left: 10px;
  cursor: pointer;
}

.inspection_setting_selected_type {
  color: $main_white;
  font-size: 1.2rem;
}

.inspection_setting_selected_type_image {
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: 5px;
  background-image: url("@/../public/images/chevronDown.svg");
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: transparent; */
}

.inspection_setting_type_select_wrapper:hover .inspection_setting_selected_type_image{
  background-image: url("@/../public/images/chevronDown_hover.svg");
}
.inspection_setting_selected_delete{
  margin-left: 10px;
  width: 25px;
  height: 25px;
  background-image: url("@/../public/images/trash.svg");
  background-size: 15px 15px;
}
.inspection_setting_selected_delete:hover{
  background-image: url("@/../public/images/trash_hover.svg");
  background-size: 15px 15px;
}
.inspection_setting_selected_unlink{
  margin-left: 10px;
  width: 25px;
  height: 25px;
  background-image: url("@/../public/images/unlink.svg");
  background-size: 15px 15px;
}
.inspection_setting_selected_unlink:hover{
  background-image: url("@/../public/images/unlink_hover.svg");
  background-size: 15px 15px;
}
.inspection_setting_type_select_modal {
  position: absolute;
  left: 100px;
  top: 50px;
  width: 100px;
  height: 0px;
  padding: 0px 10px 0px 10px;
  color: white;
  background-color: rgb(23, 23, 23);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
}
#inspection_module_1{
  top: 95px;
}
#inspection_module_2{
  top : 140px
}
#inspection_module_3{
  top: 185px;
}
#inspection_module_4{
  top : 230px
}
#inspection_module_5{
  top : 275px
}
#inspection_module_6{
  top : 320px
}
.inspection_setting_type_selection_item {
  height: 25px;
  width: 100%;
  border-bottom: 1px solid rgba(1, 250, 254, 0.5);
  display: flex;
  cursor: pointer;
  align-items: center;
  color: $main_white;
  font-size: 1.2rem;
}

.inspection_setting_type_selection_item:hover {
  color: rgba(1, 250, 254, 0.5);
}
.inspection_setting_type_selection_item:last-of-type {
  border-bottom: none;
}
</style>
