<template>
  <MainAlertVue :alert="alert" @closeAlert="closeAlert" />
  <div class="main_fixed main korean">
    <div class="mapping_main_window" ref="mapping_cloud_viewer"></div>
    <MainImportantMessage
      v-if="importantMessage"
      :importantMessage="importantMessage"
    />
    <MainHeaderVue :navigator="navigator" />
    <MappingLeftVue>
      <MappingPointCloudControlVue
        @clickClear="clearPointCloud"
        @clickSave="savePointCloud"
      />
      <MappingStatusVue
        @changeSettingModalState="handleSettingModalState"
        @changeStatusModalState="handleStatusModalState"
        :droneData="droneData"
      />
      <!-- <MappingCommandVue @clickArm="handleArm" @clickTakeOff="handleTakeOff" @clickMode="handleMode"
                         @clickRTL="handleRTL" @clickReboot="handleReboot" :droneData="droneData"/> -->
      <MappingCommandVue
        @clickRTL="handleRTL"
        @clickReboot="handleReboot"
        :droneData="droneData"
      />
    </MappingLeftVue>
    <MappingPointCloudInfoVue :pointCloudInfo="pointCloudInfo" />
    <!-- <MappingMissionSummaryVue v-if="false" @seleteMission="seleteMission" @changeMissionModalState="handleMissionModalState" @missionStart="missionStart"
                              :missionList="missionList" :selectedMission="selectedMission" :waypointList="waypointList"/> -->
    <!-- <MappingMissionModalVue ref="mission_modal" @changeMissionModalState="handleMissionModalState"
                            @seleteMission="seleteMission" @saveMission="saveMission" @deleteMission="deleteMission"
                            @saveWaypoint="saveWaypoint" @deleteWaypoint="deleteWaypoint" @updateWaypoints='updateWaypoints'
                            :missionModalState="missionModalState" :missionList="missionList" :selectedMission="selectedMission"
                            :waypointList="waypointList" @changeAlert="changeAlert"/> -->
    <MappingSettingModalVue
      v-if="settingModalState"
      @changeSettingModalState="handleSettingModalState"
    >
      <MappingSettingDroneVue
        v-if="settingTab == 1"
        @changeSettingTab="handleSettingTab"
        @selectDrone="selectDrone"
        @selectDroneType="selectDroneType"
        :droneList="droneList"
        :selectedDrone="selectedDrone"
        :droneTypeList="droneTypeList"
        :selectedDroneType="selectedDroneType"
      />
      <!-- <MappingSettingMapVue v-else-if="settingTab==2" @changeSettingTab="handleSettingTab" @changeMapMode="handleMapMode"/> -->
      <MappingSettingPointCloudVue
        v-else-if="settingTab == 3"
        @changeSettingTab="handleSettingTab"
        @changeAlert="changeAlert"
        @changePointCloudParam="handlePointCloudParam"
        :pointCloudParams="pointCloudParams"
      />
    </MappingSettingModalVue>
    <MappingStatusModalVue
      @changeStatusModalState="handleStatusModalState"
      :statusModalState="statusModalState"
      :msgs="droneData.msg"
    />
    <!-- <div :class="mapClass" ref="mapping_map">
    </div>
    <div class="mapping_sub_window_footer">
      <div
        class="mapping_sub_window_footer_icon button"
        @click="handleMainMode"
      ></div>
      <div class="mapping_sub_window_footer_text">메인 화면 전환</div>
    </div> -->
  </div>
</template>

<script>
// import { formatISO } from 'date-fns'

// import PCReportViewer from '@/module/PointCloud/PointCloudViewReport.js'
import PCMappingVievwer from "@/module/PointCloud/PointCloudViewMapping.js";
// import GoogleAPI from "@/module/Google/GoogleAPI.js"
import PCDSocket from "@/module/Communication/PCDSocket.js";
import DroneControl from "@/module/Drone/DroneControl";
import DroneMonitor from "@/module/Drone/DroneMonitor.js";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Drone from "@/model/Drone.js";
import DroneType from "@/model/DroneType.js";
import DroneData from "@/model/MappingDroneData.js";
// import DroneParams from "@/model/DroneParams.js"
// import MappingMission from "@/model/MappingMission.js"
// import MappingWaypoint from "@/model/MappingWaypoint.js"
import PointCloudParams from "@/model/PointCloudParams.js";
import Alert from "@/model/Alert";

import MainHeaderVue from "../Common/MainHeader.vue";
import MainImportantMessage from "../Common/MainImportantMessage.vue";
import MappingLeftVue from "./MappingLeft.vue";
import MappingPointCloudControlVue from "./MappingPointCloudControl.vue";
import MappingStatusVue from "./MappingStatus.vue";
import MappingCommandVue from "./MappingCommand.vue";
import MappingPointCloudInfoVue from "./MappingPointCloudInfo.vue";
// import MappingMissionSummaryVue from './MappingMissionSummary.vue'
// import MappingMissionModalVue from './MappingMissionModal.vue'
import MappingSettingModalVue from "./MappingSettingModal.vue";
import MappingSettingDroneVue from "./MappingSettingDrone.vue";
// import MappingSettingMapVue from './MappingSettingMap.vue'
import MappingSettingPointCloudVue from "./MappingSettingPointCloud.vue";
import MappingStatusModalVue from "./MappingStatusModal.vue";
import MainAlertVue from "../Common/MainAlert.vue";

import { mapState } from "vuex";
import { markRaw } from "vue";

export default {
  components: {
    MainHeaderVue,
    MainImportantMessage,
    MappingLeftVue,
    MappingPointCloudControlVue,
    MappingStatusVue,
    MappingCommandVue,
    MappingPointCloudInfoVue,
    // MappingMissionSummaryVue,
    // MappingMissionModalVue,
    MappingSettingModalVue,
    MappingSettingDroneVue,
    // MappingSettingMapVue,
    MappingSettingPointCloudVue,
    MappingStatusModalVue,
    MainAlertVue,
  },
  data() {
    return {
      navigator: "Mapping",
      importantMessage: null,
      pointCloudParams: new PointCloudParams(),
      pointCloudInfo: { pointNumber: 0, xArea: 0, yArea: 0 },
      // udpPort: null,
      // mainMode : 'pointcloud',
      missionModalState: false,
      settingModalState: false,
      settingTab: 1,
      statusModalState: false,
      droneData: new DroneData(),
      droneList: [],
      selectedDrone: new Drone(),
      droneTypeList: [],
      selectedDroneType: new DroneType(),
      // droneParams : new DroneParams(),
      // missionList : [],
      // selectedMission : new MappingMission({name : '새 미션',createdDatetime:formatISO(new Date())}),
      // waypointList : [],
      alert: new Alert(),
    };
  },
  computed: {
    ...mapState(["user", "api", "selectedSite", "siteList"]),
    // viewerClass() {
    //   return this.mainMode=='pointcloud'? 'mapping_main_window' : 'mapping_sub_window' ;
    // },
    // mapClass() {
    //   return this.mainMode=='map'? 'mapping_main_window' : 'mapping_sub_window';
    // },
  },
  watch: {
    settingModalState: {
      handler(settingModalState) {
        if (settingModalState) {
          this.statusModalState = false;
        }
      },
    },
    missionModalState: {
      handler(missionModalState) {
        if (missionModalState) {
          this.statusModalState = false;
        }
      },
    },
  },
  methods: {
    closeAlert() {
      this.alert = new Alert();
    },
    changeAlert(text) {
      this.alert = new Alert({ state: true, text: text });
    },
    //############################### HANDLE ###############################
    // handleMainMode() {
    //   if(this.mainMode == 'pointcloud'){
    //     this.mainMode = 'map';
    //   }else{
    //     this.mainMode = 'pointcloud';
    //   }
    //   this.$nextTick(()=>{
    //     if (this.cloudViewer) {
    //       this.cloudViewer.onWindowResize();
    //     }
    //   })
    // },
    // handleMissionModalState(boolean){
    //   this.missionModalState = boolean;
    // },
    // missionStart() {
    //   if (this.droneControl) {
    //     if (this.selectedMission.id) {
    //     } else {
    //       this.changeAlert("실행할 미션을 선택해주세요.");
    //     }
    //   } else {
    //     this.changeAlert("설정에서 드론 아이디를 설정해주세요.");
    //   }
    // },
    handleSettingModalState(boolean) {
      this.settingModalState = boolean;
    },
    handleStatusModalState() {
      this.statusModalState = !this.statusModalState;
    },
    selectDroneType(droneType) {
      if (droneType) {
        this.selectedDroneType = droneType;
      } else {
        this.selectDrone();
        this.selectedDroneType = new DroneType();
      }
    },
    selectDrone(drone) {
      if(this.selectedDroneType.id){
        this.checkDroneStatus(drone);
      } else {
        this.changeAlert("모듈 종류를 먼저 선택해주세요.");
        this.selectedDrone = new Drone();
      }
    },
    checkDroneStatus(drone){
      if(drone){
        this.api.getDrone(this.checkDroneStatusCallback, drone.id);
      }
      else{
        this.selectedDrone = new Drone();
        this.connectDrone();  
      }
    },
    checkDroneStatusCallback(data){
      let result = data.data.result;
      if(result.droneStatus == 'disConnecting'){
        this.selectedDrone = new Drone(result);
      }
      else{
        this.changeAlert("이미 사용중인 아이디입니다. 아이디를 확인해주세요.");
        this.selectedDrone = new Drone();
      }
      this.connectDrone();
    },
    connectDrone() {
      if (this.droneMonitor) {
        this.api.putDrone(this.droneMonitor.module.id,"disConnecting");
        this.droneMonitor.closeMqtt();
        this.droneMonitor = null;
        this.droneControl = null;
        this.cloudViewer.deleteDroneModel();
        this.droneData = new DroneData();
        if (this.pcdSocket) this.pcdSocket.closeSocket();
        this.pcdSocket = null;
      }
      if (this.selectedDrone.droneSerialNumber) {
        this.droneMonitor = new DroneMonitor(
          this.selectedDrone
        );
        this.api.putDrone(this.droneMonitor.module.id,"connecting");
        this.droneMonitor.subSiriusState(this.siriusStateCallback);
        this.droneMonitor.subSiriusError(this.siriusErrorCallback);
        this.droneMonitor.subDataState(this.dataStateCallback);
        this.droneMonitor.subDataError(this.dataErrorCallback);
        this.droneMonitor.subSlamWarning(this.slamWarningCallback);
        this.droneMonitor.subGlobalPose(this.globalPoseCallback);
        this.droneMonitor.subSlamState(this.slamStateCallback);
        this.droneMonitor.subState(this.stateCallback);
        this.droneMonitor.subBattery(this.batteryCallback);
        this.droneMonitor.subStatusText(this.statusTextCallback);
        this.droneMonitor.subSensorState(this.sensorStateCallback);
        this.droneMonitor.subGroundSpeed(this.groundSpeedCallback);
        this.droneMonitor.subGpsMode(this.gpsModeCallback);
        this.droneMonitor.subGpsStatus(this.gpsStatusCallback);
        this.droneMonitor.subRc(this.rcCallback);
        this.droneMonitor.subLte(this.lteCallback);
        // this.droneMonitor.subParams(this.paramsCallback);
        this.droneMonitor.subMapSaveComplete(this.mapSaveCompleteCallback);
        this.droneMonitor.readMsg();

        this.droneControl = new DroneControl(
          this.droneMonitor.mqttClient,
          this.selectedDrone
        );
        // this.droneControl.params();

        this.cloudViewer.createDroneModel();
        this.pcdSocket = new PCDSocket(this.selectedDrone.port);
        this.pcdSocket.pointCloudData(this.pointCloudDataCallback);
        this.pcdSocket.pointCloudPose(this.pointCloudPoseCallback);
      }
    },
    // handleGpsMode(boolean) {
    //   if (this.droneControl) {
    //     this.droneControl.gpsMode(boolean);
    //   } else {
    //     this.changeAlert("설정에서 드론 아이디를 설정해주세요.");
    //   }
    // },
    // handleMapMode(mapType){
    //   this.googleMap.setMapType(mapType.toLowerCase());
    //   this.$refs.mission_modal.changeMissionMapMode(mapType);
    // },
    seleteMission(mission) {
      this.selectedMission = mission;
      this.readWaypoints();
    },
    handlePointCloudParam(type, value) {
      switch (type) {
        case "mode":
          if (value == "intensity") {
            this.pointCloudParams.mode = "intensity";
            this.cloudViewer.material.uniforms.colorMap.value = false;
          } else if (value == "height") {
            this.pointCloudParams.mode = "height";
            this.cloudViewer.material.uniforms.colorMap.value = true;
          }
          break;
        case "downSampling":
          this.pointCloudParams.downSampling = value;
          break;
        case "pointSize":
          this.pointCloudParams.pointSize = value;
          this.cloudViewer.material.uniforms.size.value = value;
          break;
        case "opacity":
          this.pointCloudParams.opacity = value;
          this.cloudViewer.material.uniforms.opacity.value = value;
          break;
        case "heightMin":
          this.pointCloudParams.height.min = value;
          this.cloudViewer.material.uniforms.minHeight.value = value;
          break;
        case "heightMax":
          this.pointCloudParams.height.max = value;
          this.cloudViewer.material.uniforms.maxHeight.value = value;
          break;
        default:
          break;
      }
    },
    clearPointCloud() {
      this.cloudViewer.positionsArray = [];
      this.cloudViewer.scene.clear();
      this.cloudViewer.init();

      if (this.droneControl) {
        this.cloudViewer.createDroneModel();
      }
      this.pointCloudInfo.pointNumber = 0;
      this.pointCloudInfo.xArea = 0;
      this.pointCloudInfo.yArea = 0;
    },
    savePointCloud() {
      if (this.droneControl) {
        if (!this.droneData.state.arming) {
          this.droneControl.pointCloudSave();
          this.handleImportantMessage("맵을 저장 중입니다.");
        } else {
          this.changeAlert(
            "현재 드론의 시동이 걸려 있습니다. 안전한 맵 저장을 위해 먼저 시동을 끄고 다시 시도해 주세요."
          );
        }
      } else {
        this.changeAlert("설정에서 모듈 종류 및 아이디를 설정해주세요.");
      }
    },
    // handleArm() {
    //   if (this.droneControl) {
    //     if (this.droneData.state.arming) {
    //       this.droneControl.disArm();
    //     } else {
    //       this.droneControl.arm();
    //     }
    //   } else {
    //     this.changeAlert("설정에서 드론 아이디를 설정해주세요.");
    //   }
    // },
    // handleTakeOff() {
    //   if (this.droneControl) {
    //     if (this.droneData.state.takeOff) {
    //       this.droneControl.land();
    //     } else {
    //       let alt = prompt("고도를 입력해주세요.");
    //       let alert;
    //       if (alt || alt == "") {
    //         alert = this.droneControl.takeOff(alt);
    //       }
    //       if (alert) {
    //         this.changeAlert(alert);
    //       }
    //     }
    //   } else {
    //     this.changeAlert("설정에서 드론 아이디를 설정해주세요.");
    //   }
    // },
    // handleMode() {
    //   if (this.droneControl) {
    //     if (this.droneData.mode == "GUIDED") {
    //       this.droneControl.setModeLoiter();
    //     } else {
    //       this.droneControl.setModeGuide();
    //     }
    //   } else {
    //     this.changeAlert("설정에서 드론 아이디를 설정해주세요.");
    //   }
    // },
    handleRTL() {
      if (this.droneControl) {
        if (this.droneData.state.takeOff) {
          if (this.droneData.mode != "RTL") this.droneControl.returnHome();
          else this.changeAlert("드론이 이미 RTL모드 입니다.");
        } else {
          this.changeAlert(
            "드론이 비행 중이 아닙니다. RTL 모드를 사용할 수 없습니다."
          );
        }
      } else {
        this.changeAlert("설정에서 모듈 종류 및 아이디를 설정해주세요.");
      }
    },
    handleReboot() {
      if (this.droneControl) {
        if (!this.droneData.state.arming) {
          if (confirm("기체를 재부팅하시겠습니까?")) this.droneControl.reboot();
          else return false;
        } else {
          this.changeAlert(
            "현재 드론의 시동이 걸려 있습니다. 안전한 재부팅을 위해 먼저 시동을 끄고 다시 시도해 주세요."
          );
        }
      } else {
        this.changeAlert("설정에서 모듈 종류 및 아이디를 설정해주세요.");
      }
    },
    handleImportantMessage(message) {
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = message;
    },
    handleSettingTab(tabNumber) {
      this.settingTab = tabNumber;
    },
    // handleDroneParam(title, value) {
    //   if (this.droneControl) {
    //     switch (title) {
    //       case "maxSpeed":
    //         this.droneControl.maxSpeed(value);
    //         break;
    //       case "maxYawSpeed":
    //         this.droneControl.maxYawSpeed(value);
    //         break;
    // case 'obstacleDistance':
    //   this.droneControl.obstacleDistance(value);
    //   break;
    // case 'waypointSpeed':
    //   this.droneControl.waypointSpeed(value);
    //   break;
    // case 'waypointYawSpeed':
    //   this.droneControl.waypointYawSpeed(value);
    //   break;
    //   default:
    //     break;
    // }
    // this.droneControl.params();
    // } else {
    //   this.changeAlert("설정에서 드론 아이디를 설정해주세요.");
    // }
    // },
    //############################### UDP CALLBACK ###############################
    pointCloudDataCallback(data) {
      this.pointCloudSamplingCount++;
      if (
        this.pointCloudSamplingCount % this.pointCloudParams.downSampling ==
        0
      ) {
        this.cloudViewer.createPointsShader(
          this.createPointsShaderCallback,
          data
        );
        this.pointCloudSamplingCount = 0;
      }
    },
    pointCloudPoseCallback(data) {
      this.cloudViewer.drawLine(data.p.x, data.p.z, -data.p.y);
    },
    createPointsShaderCallback(point) {
      this.pointCloudInfo.pointNumber +=
        point.geometry.attributes.position.count;
      this.pointCloudInfo.xArea = this.cloudViewer.maxX - this.cloudViewer.minX;
      this.pointCloudInfo.yArea = this.cloudViewer.maxY - this.cloudViewer.minY;
    },
    //############################### MQTT CALLBACK ###############################
    siriusStateCallback(data) {
      if (data.state == "REQUESTING") {
        this.droneControl.initialize(this.selectedDroneType.name, "mapping");
        this.droneControl.ftpUploadInfo(this.ftpUploadInfo);
      } else if (data.state == "INITIALIZATION") {
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `프로그램이 실행 중입니다.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "STANBY") {
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `프로그램 실행이 완료되었습니다. 작동 상태를 최종 점검하세요.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      }
    },
    siriusErrorCallback(data) {
      this.droneData.msg.push({ severity: 0, text: data.message });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    dataStateCallback(data){
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    dataErrorCallback(data){
      this.droneData.msg.push({ severity: 3, text: data.message });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    slamWarningCallback(data){
      this.droneData.msg.push({ severity: 4, text: data.message });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    globalPoseCallback(data) {
      this.droneData.pose.lat = data.latitude.toFixed(5);
      this.droneData.pose.lon = data.longitude.toFixed(5);
      this.droneData.pose.alt = data.altitude.toFixed(2);
    },
    slamStateCallback(data) {
      this.droneData.pose.x = data.slam_position_x.toFixed(3);
      this.droneData.pose.y = data.slam_position_y.toFixed(3);
      this.droneData.pose.roll =
        (Math.atan2(
          2 *
            (data.slam_orientation_w * data.slam_orientation_x +
              data.slam_orientation_y * data.slam_orientation_z),
          1 -
            2 *
              (data.slam_orientation_x * data.slam_orientation_x +
                data.slam_orientation_y * data.slam_orientation_y)
        ) *
          180) /
        Math.PI;
      this.droneData.pose.pitch =
        (Math.asin(
          Math.max(
            -1.0,
            Math.min(
              1.0,
              2 *
                (data.slam_orientation_w * data.slam_orientation_y -
                  data.slam_orientation_z * data.slam_orientation_x)
            )
          )
        ) *
          180) /
        Math.PI;
      this.droneData.pose.yaw =
        (Math.atan2(
          2 *
            (data.slam_orientation_w * data.slam_orientation_z +
              data.slam_orientation_x * data.slam_orientation_y),
          1 -
            2 *
              (data.slam_orientation_y * data.slam_orientation_y +
                data.slam_orientation_z * data.slam_orientation_z)
        ) *
          180) /
        Math.PI;

      if (this.cloudViewer.droneModel) {
        this.cloudViewer.droneModel.position.set(
          data.slam_position_x,
          data.slam_position_z,
          -data.slam_position_y
        );
        this.cloudViewer.droneModel.rotation.order = "YXZ";
        this.cloudViewer.droneModel.rotation.set(
          (-this.droneData.pose.pitch * Math.PI) / 180,
          (this.droneData.pose.yaw * Math.PI) / 180 - Math.PI / 2,
          0
        );
      }
    },
    stateCallback(data) {
      this.droneData.mode = data.mode;
      this.droneData.state.arming = Boolean(data.arming);
      if (this.droneData.state.arming) {
        if (!this.timeCheck) {
          this.armingTime = new Date();
          this.timeCheck = true;
        } else {
          let nowTime = new Date();
          let differenceInMilliseconds = nowTime - this.armingTime;
          let hours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60);
          let minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
          let seconds = Math.floor((differenceInMilliseconds / 1000) % 60);
          hours = String(hours).padStart(2, "0");
          minutes = String(minutes).padStart(2, "0");
          seconds = String(seconds).padStart(2, "0");

          this.droneData.flightTime = `${hours}:${minutes}:${seconds}`;
        }
      } else {
        this.timeCheck = false;
      }
      if (data.system_status == 3) {
        this.droneData.state.takeOff = false;
      } else if (data.system_status == 4) {
        this.droneData.state.takeOff = true;
      }
      //data.connected;
    },
    batteryCallback(data) {
      // if (this.selectDroneType.minVoltage &&this.selectDroneType.maxVoltage){
      //   this.droneData.battery =
      //     ((data.voltage-this.selectDroneType.minVoltage)/
      //     (this.selectDroneType.maxVoltage-this.selectDroneType.minVoltage)*
      //     100).toFixed(0);
      // }
      if (data.battery_percentage) {
        this.droneData.battery = data.battery_percentage.toFixed(0);
      }
    },
    statusTextCallback(data) {
      this.droneData.msg.push({ severity: data.severity, text: data.text });
    },
    sensorStateCallback(data) {
      this.droneData.sensorState.lidar = Boolean(data.lidar_connection);
      this.droneData.sensorState.imu = Boolean(data.imu_connection);
      this.droneData.sensorState.encoder = Boolean(data.encoder_connection);
    },
    groundSpeedCallback(data) {
      this.droneData.speed = data.ground_speed.toFixed(2);
    },
    // gpsModeCallback(data) {
    //   this.droneParams.gpsMode = Boolean(data.gps_mode);
    // },
    gpsStatusCallback(data) {
      if (data.fix_type != "0") {
        this.droneData.gps.satellites = data.satellites;
        this.droneData.gps.eph = (data.eph / 100).toFixed(1);
      } else {
        this.droneData.gps.satellites = "-";
        this.droneData.gps.eph = "-.-";
      }
    },
    rcCallback(data) {
      this.droneData.rc = Boolean(data.rc_connection);
    },
    lteCallback(data) {
      let rsrp;
      let rssi;
      let rsrq;

      if (data.RSRP <= -100) rsrp = 0;
      else if (-100 < data.RSRP && data.RSRP <= -90) rsrp = 2;
      else if (-90 < data.RSRP && data.RSRP <= -80) rsrp = 3;
      else if (-80 < data.RSRP) rsrp = 4;

      if (data.RSRQ <= -20) rssi = 0;
      else if (-20 < data.RSRQ && data.RSRQ <= -15) rssi = 2;
      else if (-15 < data.RSRQ && data.RSRQ <= -10) rssi = 3;
      else if (-10 < data.RSRQ) rssi = 4;

      if (-data.RSSI <= -95) rsrq = 0;
      else if (-95 < -data.RSSI && -data.RSSI <= -85) rsrq = 1;
      else if (-85 < -data.RSSI && -data.RSSI <= -75) rsrq = 2;
      else if (-75 < -data.RSSI && -data.RSSI <= -65) rsrq = 3;
      else if (-65 < -data.RSSI) rsrq = 4;

      this.droneData.lte = Math.min(rsrp, rsrq, rssi); //0,1,2,3,4
    },
    // paramsCallback(data) {
    //   this.droneParams.maxSpeed = data.max_speed;
    //   this.droneParams.maxYawSpeed = data.max_yaw_speed;
    //   this.droneParams.obstacleDistance = data.obstacle_distance;
    //   this.droneParams.waypointSpeed = data.waypoint_speed;
    //   this.droneParams.waypointYawSpeed = data.waypoint_yaw_speed;
    // },
    mapSaveCompleteCallback() {
      this.droneData.msg.push({ severity: 6, text: 'Map save Complete'});
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `맵 저장이 완료되었습니다.`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
      this.api.postFtpUpload(this.selectedSite.id, this.$route.params.mapId);
    },
    //################################## API ##################################
    getSites() {
      if (!this.siteList) {
        this.api.getSites(this.user.id, this.getSitesCallback);
      } else {
        this.getSite();
      }
    },
    getSitesCallback(data) {
      let result = data.data.result;
      let siteList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          siteList.push(new Site(result[i]));
        }
      }
      this.$store.dispatch("setSiteList", siteList);
      let setSelectedSite = siteList.find(
        (site) => site.id == this.$route.params.siteId
      );
      this.$store.dispatch("setSelectedSite", setSelectedSite);
      this.getSite();
    },
    getSite() {
      if (
        !this.selectedSite ||
        this.selectedSite.id != this.$route.params.siteId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.siteId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedSite", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.siteId);
    },
    getMapsCallback(data) {
      this.selectedSite.mapList = [];
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch("setMapList", mapList);
      }
    },
    // saveMission(missionName){
    //   this.api.postMappingMission(this.createWaypoints, this.$route.params.siteId,  this.$route.params.mapId, missionName, formatISO(new Date()));
    // },
    // deleteMission(mapId, missionId){
    //   if(missionId == this.selectedMission.id){
    //     this.selectedMission = new MappingMission({name:'새 미션',facilityMapId:this.$route.params.mapId,createdDatetime:formatISO(new Date())});
    //   }
    //   this.api.deleteMappingMission(this.readMission, this.$route.params.siteId, mapId, missionId);
    // },
    // readMission(){
    //   this.missionList = [];
    //   this.missionList.push(new MappingMission({name:'새 미션',facilityMapId:this.$route.params.mapId,createdDatetime:formatISO(new Date())}));
    //   this.mappingMissionCount = 0;
    //   for(let i=0; i<this.selectedSite.mapList.length; i++){
    //     this.api.getMappingMissions(this.getMappingMissionsCallback,this.$route.params.siteId,this.selectedSite.mapList[i].id);
    //   }
    // },
    // getMappingMissionsCallback(data){
    //   this.mappingMissionCount++;
    //   let result = data.data.result;
    //   if(result){
    //     for(let i=0; i<result.length; i++){
    //     this.missionList.push(new MappingMission(result[i]));
    //     }
    //   }
    //   if(this.mappingMissionCount == this.selectedSite.mapList.length){
    //     if(this.selectedMission.name != '새 미션'){
    //       this.selectedMission = this.missionList.find((mission)=> mission.id == this.selectedMission.id);
    //     }
    //     else{
    //       this.selectedMission = new MappingMission({name:'새 미션',facilityMapId:this.$route.params.mapId,createdDatetime:formatISO(new Date())});
    //     }
    //     this.readWaypoints();
    //   }
    // },
    // saveWaypoint(newWaypoint){
    //   this.waypointList = [...this.waypointList, newWaypoint];
    //   this.updateMarker();
    //   nextTick(()=>{
    //     this.$refs.mission_modal.updateMarker();
    //   })
    // },
    // deleteWaypoint(waypointIndex){
    //   this.waypointList.splice(waypointIndex, 1);
    //   this.waypointList.forEach((item, index)=>{
    //     item.no = index + 1;
    //   });
    //   this.updateMarker();
    //   nextTick(()=>{
    //     this.$refs.mission_modal.updateMarker();
    //   })
    // },
    // createWaypoints(data){
    //   for(let i=0; i<this.waypointList.length; i++){
    //     this.waypointList[i].mappingMissionId = data.data.result.id;
    //   }
    //   this.api.postMappingWaypoints(this.readMission,this.$route.params.siteId, data.data.result.facilityMapId, data.data.result.id, this.waypointList);
    // },
    // updateWaypoints(){
    //   this.api.putMappingWaypoints(this.readMission,this.$route.params.siteId, this.selectedMission.facilityMapId, this.selectedMission.id, this.waypointList);
    // },
    // readWaypoints(){
    //   this.waypointList = [];
    //   if(this.selectedMission.name != "새 미션"){
    //     this.api.getMappingWaypoints(this.getMappingWaypointsCallback,this.$route.params.siteId,this.selectedMission.facilityMapId,this.selectedMission.id);
    //   }
    //   else{
    //     this.updateMarker();
    //     nextTick(()=>{
    //       this.$refs.mission_modal.updateMarker();
    //     })
    //   }
    // },
    // getMappingWaypointsCallback(data){
    //   let result = data.data.result;
    //   if(result){
    //     for(let i=0; i<result.length; i++){
    //      this.waypointList.push(new MappingWaypoint(result[i]));
    //     }
    //   }
    //   this.updateMarker();
    //   nextTick(()=>{
    //     this.$refs.mission_modal.updateMarker();
    //   })
    // },
    // updateMarker(){
    //   this.googleMap.clearMarkers();
    //   for(let i=0; i<this.waypointList.length; i++){
    //     this.googleMap.addMarker({lat : parseFloat(this.waypointList[i].lat), lng : parseFloat(this.waypointList[i].lon)}, false, true);
    //   }
    // },
    // updateDroneType(body){
    //   this.api.putDroneType(this.readDroneTypes,this.selectedDroneType.id, body)
    // },
    getDrones() {
      this.api.getDrones(this.getDronesCallback, this.user);
    },
    getDronesCallback(data) {
      let result = data.data.result;
      this.droneList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneList.push(new Drone(result[i]));
      }
    },
    getDroneTypes() {
      this.api.getDronesType(this.getDronesTypeCallback);
    },
    getDronesTypeCallback(data) {
      let result = data.data.result;
      this.droneTypeList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneTypeList.push(new DroneType(result[i]));
      }
    },
    ftpCredentialsCallback(data) {
      console.log(data);
      this.ftpUploadInfo = JSON.stringify(data.data.result);
    },
    cleanUp() {
      if (this.droneMonitor) {
        this.api.putDrone(this.droneMonitor.module.id,"disConnecting");
        this.droneMonitor.closeMqtt();
        this.droneMonitor = null;
      }
      if (this.droneControl) this.droneControl = null;
      if (this.pcdSocket) this.pcdSocket.closeSocket();
    },
  },
  created() {
    this.importantMessageTimer;
    this.pointCloudSamplingCount = 0;
    this.timeCheck = false;
    this.armingTime = 0;
  },
  mounted() {
    this.cloudViewer = markRaw(
      new PCMappingVievwer(
        this.$refs.mapping_cloud_viewer,
        this.pointCloudParams
      )
    );
    // this.googleMap = markRaw(new GoogleAPI(this.$refs.mapping_map, false, false));
    this.api.putUser({"taskStatus" : "mapping"}, this.user.id);
    this.getSites();
    this.getDroneTypes();
    this.getDrones();
    // this.readDroneTypes();
    this.api.ftpCredentials(
      this.ftpCredentialsCallback,
      this.$route.params.siteId,
      this.$route.params.mapId
    );
    window.addEventListener("beforeunload",()=>{
      for (let i = 0; i < 5; i++) {
        if (this.droneMonitor) this.api.putDrone(this.droneMonitor.module.id,"disConnecting");
      }
    })
  },
  beforeUnmount() {
    this.cleanUp();
  },
};
</script>

<style lang="scss">
.main {
  background: #000;
}
.mapping_main_window {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.mapping_sub_window {
  position: absolute;
  right: 15px;
  top: 85px;
  width: calc(100% / 11 * 3);
  height: calc(100% / 8 * 3);
  background: rgba(23, 23, 23, 0.85);
  box-shadow: $main_box_shadow;
  border-radius: 10px;
  z-index: 10;
  max-width: 370px;
  max-height: 370px;
}
/* .mapping_sub_window_footer{
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 90px;
  width: calc(100% / 11 * 3);
  height: 30px;
  padding: 10px 0px 0px 10px;
  z-index: 11;
  max-width: 370px;
}
.mapping_sub_window_footer_icon {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/toggle.svg");
  background-size: 15px 15px;
}
.mapping_sub_window_footer_icon:hover {
  background-image: url("@/../public/images/toggle_hover.svg");
  background-size: 15px 15px;
}
.mapping_sub_window_footer_text {
  padding-left: 5px;
  color:white;
  font-size: 15px;
} */
</style>
