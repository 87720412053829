<template>
  <div class="mapping_setting_background"></div>
  <div class="mapping_setting">
    <div class="mapping_setting_header">
      <div class="mapping_setting_header_title">설정</div>
      <div class="mapping_setting_header_x" @click="clickClose"></div>
    </div>
    <div class="mapping_setting_body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import "./MappingSettingModal.scss";

export default {
  emits: ["changeSettingModalState"],
  methods: {
    clickClose() {
      this.$emit("changeSettingModalState", false);
    },
  },
};
</script>

<style lang="scss"></style>
