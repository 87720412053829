import * as THREE from 'three';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh'; // npm install three-mesh-bvh

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import fontJSON from 'three/examples/fonts/helvetiker_regular.typeface.json';

import LineMeasure from './LineMeasure';
import AreaMeasure from './AreaMeasure';
import AngleMeasure from './AngleMeasure';
import * as Shader from './Shader';
import BoundingRange from './BoundingRange';

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

// THREE.Cache.enabled = true;

export default class PCManager{
  constructor(containerElement2D, containerElement3D, loading, magnifierEl) {        
    this.container2d = containerElement2D;
    this.container3d = containerElement3D;
    this.loading = loading;
    this.magnifierEl = magnifierEl;
    this.magnifierEl.style.display = 'none';
    this.measureMethod = null;

    const fps = 15;
    this.interval = 1000/fps;
    const loader = new FontLoader();
    this.font = loader.parse(fontJSON);

    this.init();

    this.axis = 0;
    this.radian = 0;
    this.boundingRange = new BoundingRange(this.scene2d, this.scene3d
                                          ,this.material2d, this.material3d
                                          ,this.camera2d, this.controls2d
                                          ,this.camera3d, this.controls3d);
  }

    ////////////// Initialize (start) //////////////
  init() {
    this.scene2d = new THREE.Scene();
    this.scene3d = new THREE.Scene();

    // 렌더러 설정        
    this.renderer2d = new THREE.WebGLRenderer({ preserveDrawingBuffer: true });
    this.renderer2d.setSize(this.container2d.offsetWidth, this.container2d.offsetHeight);
    this.renderer2d.sortObjects = true;
    this.container2d.appendChild(this.renderer2d.domElement);

    this.renderer3d = new THREE.WebGLRenderer({ preserveDrawingBuffer: true });
    this.renderer3d.setSize(this.container3d.offsetWidth, this.container3d.offsetHeight);
    this.renderer3d.sortObjects = true;
    this.container3d.appendChild(this.renderer3d.domElement);

    this.modelGroup2D = new THREE.Group();
    this.modelGroup3D = new THREE.Group();

    this.initView();
    this.initMagnifierCanvas();
    this.initLineParams();
    this.initAreaParams();
    this.initAngleParams();

    window.addEventListener('resize', this.onWindowResize.bind(this), false);

    const modelMatrix = new THREE.Matrix4();

    this.uniforms2D = {
      lowerHeight: {value:0},
      upperHeight: {value:1},
      axis: {value:0},
      customModelMatrix: {value: modelMatrix},
    };

    this.uniforms3D = {
      lowerHeight: {value:0},
      upperHeight: {value:1},
      minHeight: {value:0},
      maxHeight: {value:100},
      axis: {value:0},
      customModelMatrix: {value: modelMatrix},
      colorMap: { type: 'b', value: false },
    };

    this.material2d = new THREE.ShaderMaterial({
      vertexShader: Shader.vertexShader2d,
      fragmentShader: Shader.fragmentShader,
      transparent :true,
      vertexColors: true, 
      uniforms: this.uniforms2D
    });

    this.material3d = new THREE.ShaderMaterial({
      vertexShader: Shader.vertexShader3d,
      fragmentShader: Shader.fragmentShader,
      transparent :true,
      vertexColors: true,
      uniforms: this.uniforms3D
    });

    this.render();
  }

  initView() {   
    this.camera2d = new THREE.PerspectiveCamera(75, this.container2d.offsetWidth / this.container2d.offsetHeight, 1, 1000);
    this.camera2d.position.set(0, 100, 0); //xy      
    this.camera2d.lookAt(new THREE.Vector3(0, 0, 0));
    this.controls2d = new OrbitControls(this.camera2d, this.renderer2d.domElement);
    this.controls2d.enableRotate = false;
    this.controls2d.screenSpacePanning = true; 
    this.controls2d.update();

    this.camera3d = new THREE.PerspectiveCamera(75, this.container3d.offsetWidth / this.container3d.offsetHeight, 1, 1000);
    this.controls3d = new OrbitControls(this.camera3d, this.renderer3d.domElement);
    this.camera3d.position.set(0, 5, 50);
    this.controls3d.update();

    let ambientLight_2d = new THREE.AmbientLight(0xffffff); 
    this.scene2d.add(ambientLight_2d);

    let ambientLight_3d = new THREE.AmbientLight(0xffffff); 
    this.scene3d.add(ambientLight_3d);
  }
    
  initMagnifierCanvas(){
    this.magnifierCanvas = document.createElement('canvas');
    this.magnifierCanvas.width = 150;
    this.magnifierCanvas.height = 150;
    this.magnifierCanvas.style.borderRadius = '50%'; 
    this.magnifierCanvas.style.position = 'absolute';
    this.magnifierEl.appendChild(this.magnifierCanvas);
  }

  initLineParams(){
    this.measureLineStart = false;
    this.lineMeasure = new LineMeasure(this.scene2d, this.modelGroup2D, this.font);
  }

  initAreaParams(){
    this.measureAreaStart = false;		
    this.areaMeasure = new AreaMeasure(this.scene2d, this.modelGroup2D, this.font);
  }

  initAngleParams(){
    this.measureAngleStart = false;
    this.angleMeasure = new AngleMeasure(this.scene2d, this.modelGroup2D, this.font);
  }
  ////////////// Initialize (end) //////////////

  ////////////// Rendering and Animation (start) //////////////
  animate() {
    this.animationFrameId = requestAnimationFrame(this.animate.bind(this));
    this.renderer2d.render(this.scene2d, this.camera2d);
    this.renderer3d.render(this.scene3d, this.camera3d);
  }

  onWindowResize() {
    if(this.camera2d){
      this.camera2d.aspect = this.container2d.offsetWidth / this.container2d.offsetHeight;
      this.camera2d.updateProjectionMatrix();
      this.renderer2d.setSize(this.container2d.offsetWidth, this.container2d.offsetHeight);
    }
    if(this.camera3d){
      this.camera3d.aspect = this.container3d.offsetWidth / this.container3d.offsetHeight;
      this.camera3d.updateProjectionMatrix();
      this.renderer3d.setSize(this.container3d.offsetWidth, this.container3d.offsetHeight);
    }
  }

  updateBoxSizeRelativeToCamera(mesh, camera) {		
    const distance = mesh.position.distanceTo(camera.position);
    const scale = this.calculateScaleBasedOnDistance(distance);
    mesh.scale.set(scale, scale, scale);
  }
    
  calculateScaleBasedOnDistance(distance) {
    const baseDistance = 30;
    const baseScale = 1;
    return baseScale * (distance / baseDistance);
  }

  render(){
    let now,delta;
    let then = Date.now();
    const frame= () => {
      this.animationFrameId = requestAnimationFrame(frame);
      now = Date.now();
      delta = now - then;
      if(delta < this.interval) return

      for(let i=0; i<this.lineMeasure.getNumLineBoxes(); i++){
        const lineBox = this.lineMeasure.getLineBox(i);
        const line = this.lineMeasure.getLine(i);            
        lineBox.boxMesh.rotation.set(0, 0, 0);
        if(line.axis==0){              
          lineBox.boxMesh.rotation.x = -Math.PI / 2;
        }
        else if(line.axis==1){
          lineBox.boxMesh.rotation.y = Math.PI / 2;
        }
        this.updateBoxSizeRelativeToCamera(lineBox.boxMesh, this.camera2d);
      }
      for(let i=0; i<this.lineMeasure.getNumLines();i++){
        const tempLine = this.lineMeasure.getLine(i);
        this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera2d);
        this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera2d);
      }

      for(let i=0; i<this.areaMeasure.getTextNum(); i++){
        const areaBox = this.areaMeasure.getTextBox(i);
        const polygon = this.areaMeasure.getPolygon(i);
        areaBox.boxMesh.rotation.set(0, 0, 0);
        if(polygon.axis==0){              
          areaBox.boxMesh.rotation.x = -Math.PI / 2;
        }
        else if(polygon.axis==1){
          areaBox.boxMesh.rotation.y = Math.PI / 2;
        }
        // areaBox.boxMesh.lookAt(this.camera2d.position);
        this.updateBoxSizeRelativeToCamera(areaBox.boxMesh, this.camera2d);
      }
      for(let i=0; i<this.areaMeasure.getLineNum();i++){
        const tempLine = this.areaMeasure.getAreaLine(i);
        this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera2d);
        this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera2d);
      }
      for(let i=0; i<this.areaMeasure.getPolygonNum();i++){
        const tempPolygon = this.areaMeasure.getPolygon(i);
        for(let j=0; j<tempPolygon.areaLines.length;j++){				
          this.updateBoxSizeRelativeToCamera(tempPolygon.areaLines[j].startSphere, this.camera2d);
          this.updateBoxSizeRelativeToCamera(tempPolygon.areaLines[j].endSphere, this.camera2d);
        }
      }

      for(let i=0; i<this.angleMeasure.getNumAngleBoxes(); i++){
        const angleBox = this.angleMeasure.getAngleBox(i);
        const angle = this.angleMeasure.getAngle(i);
        angleBox.boxMesh.rotation.set(0,0,0);
        if(angle.axis==0){              
          angleBox.boxMesh.rotation.x = -Math.PI / 2;
        }
        else if(angle.axis==1){
          angleBox.boxMesh.rotation.y = Math.PI / 2;
        }
        this.updateBoxSizeRelativeToCamera(angleBox.boxMesh, this.camera2d);
      }
      for(let i=0; i<this.angleMeasure.getNumAngleLines();i++){
        const tempLine = this.angleMeasure.getAngleLine(i);
        this.updateBoxSizeRelativeToCamera(tempLine.startSphere, this.camera2d);
        this.updateBoxSizeRelativeToCamera(tempLine.endSphere, this.camera2d);
      }
      for(let i=0; i<this.angleMeasure.getNumAngles();i++){
        const tempAngles = this.angleMeasure.getAngle(i);
        for(let j=0; j<tempAngles.angleLines.length;j++){				
          this.updateBoxSizeRelativeToCamera(tempAngles.angleLines[j].startSphere, this.camera2d);
          this.updateBoxSizeRelativeToCamera(tempAngles.angleLines[j].endSphere, this.camera2d);
        }
      }

      this.renderer3d.render(this.scene3d,this.camera3d);
      this.renderer2d.render(this.scene2d,this.camera2d);
      then = now - (delta%this.interval);
    }
    this.animationFrameId = requestAnimationFrame(frame);
  }
    ////////////// Rendering and Animation (end) //////////////

  loadPCD(pcdFile) {
    return new Promise((resolve, reject) => {
      this.loading.startLoadingAnimation();
      const loader = new PCDLoader();
      loader.load(pcdFile, (points) => {
        const geometry = points.geometry;
        // geometry.computeBoundsTree(); 
        geometry.rotateX(-Math.PI / 2);
        geometry.computeBoundingBox();  // 바운딩 박스 계산


        const applyIntensityToShader = (material) => {
          const originalOnBeforeCompile = material.onBeforeCompile;
          material.onBeforeCompile = (shader) => {
            shader.vertexShader = 'attribute float intensity;\n' + shader.vertexShader;
            shader.vertexShader = shader.vertexShader.replace(
              '#include <begin_vertex>',
              'vIntensity = intensity;\n#include <begin_vertex>'
            );
                        
            if (originalOnBeforeCompile) {
              originalOnBeforeCompile(shader);
            }
          };
        };
              
        applyIntensityToShader(this.material2d);
        const pointCloud2D = new THREE.Points(geometry, this.material2d);
        this.modelGroup2D.add(pointCloud2D);
        this.scene2d.add(this.modelGroup2D);

        applyIntensityToShader(this.material3d);
        const pointCloud3D = new THREE.Points(geometry, this.material3d);
        this.modelGroup3D.add(pointCloud3D);
        this.scene3d.add(this.modelGroup3D);

        this.modelGroup2D.updateMatrixWorld(true);
        this.modelGroup3D.updateMatrixWorld(true);

        this.boundingRange.setBounding3DBox(this.modelGroup3D, true);
        this.boundingRange.setBounding2DBox(this.modelGroup2D, 0, true);
        this.loading.stopLoadingAnimation();
        resolve();
      },
      (xhr)=>{
        this.loading.updateLoadingText(xhr.timeStamp);
        if(xhr.lengthComputable){
          let progress = xhr.loaded/xhr.total*100;
          this.loading.$refs.progress_bar.value = progress;
        }
        else{
          this.loading.$refs.progress_bar.style.display = 'none'
        }
      },
      (error) => {
          reject(error); 
      });
    });  
  }


  ////////////// Clear Scene (start) //////////////
  removeObjectFromScene(scene, object) {      
    if (object.isMesh || object.isPoints) {
      if (object.geometry) {
        object.geometry.dispose();
      }
      if (object.material) {
        if (Array.isArray(object.material)) {
          object.material.forEach(material => material.dispose());
        }
        else {
          object.material.dispose();
        }
      }
    }
      
    scene.remove(object);
      
    if(object.children){
      while (object.children.length > 0) {
        this.removeObjectFromScene(object, object.children[0]);
      }
    }
  }

  dispose() {      
    cancelAnimationFrame(this.animationFrameId);      

    if(this.scene2d){
      while (this.scene2d.children.length > 0) {
        const object = this.scene2d.children[0];
        this.removeObjectFromScene(this.scene2d, object);
      }
      this.scene2d = null;
    }
    
    if(this.scene3d){
      while (this.scene3d.children.length > 0) {
        const object = this.scene3d.children[0];
        this.removeObjectFromScene(this.scene3d, object);
      }
      this.scene3d = null;
    }   
    if(this.renderer2d){
      this.renderer2d.dispose();
      this.renderer2d = null;
    }
    if(this.renderer3d){
      this.renderer3d.dispose();
      this.renderer3d = null;
    }
        
    if(this.controls2d){
      this.controls2d.dispose();
      this.controls2d = null;
    }
    if(this.controls3d){
      this.controls3d.dispose();
      this.controls3d = null;
    }
    window.removeEventListener('resize', this.onWindowResize.bind(this));        
  }
  ////////////// Clear Scene (end) //////////////

  ////////////// Mouse Event (start) //////////////
  onMouseMove(event){
    let rect = this.renderer2d.domElement.getBoundingClientRect();
    
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    this.renderMagnifierImage(mouseX, mouseY);
    this.magnifierEl.style.left = `${mouseX- this.magnifierCanvas.width / 2}px`;
    this.magnifierEl.style.top = `${event.clientY - this.magnifierCanvas.height - 50}px`;
    if(this.axis ==3) return;

    if(this.measureLineStart || this.measureAreaStart || this.measureAngleStart || this.polyCroppingStart){
      const mouse = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();        
      raycaster.setFromCamera(mouse, this.camera2d);
      const plane = this.setAxisPlane();
      let intersection = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersection);

      if (intersection){
        if(this.measureLineStart){
          this.lineMeasure.setEndPoint(intersection);
          this.lineMeasure.drawMeasureLine(this.axis, false);
        }
        else if(this.measureAreaStart){
          this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(this.areaMeasure.getPointNum()-1), intersection, this.radian, false);
        }
        else if(this.measureAngleStart){
          this.angleMeasure.drawAngleLine(this.angleMeasure.getAngleLinePoint(this.angleMeasure.getNumAngleLinePoints()-1), intersection, this.radian, false);
        }
      }
    }
  }


  onMouseDown(event){	
    if (event.button === 0){
      let rect = this.renderer2d.domElement.getBoundingClientRect();

      const mouse = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.near = this.camera2d.near;
      raycaster.far = 1000;
      raycaster.linePrecision = 0.1;
      raycaster.params.Points.threshold = 0.1;        

      raycaster.setFromCamera(mouse, this.camera2d);
      const meshObjects = this.findAllMeshes(this.scene2d);
      const intersections = raycaster.intersectObjects(meshObjects, true);
      const boxSelected = this.selectBox(intersections);

      if(this.axis == 3 || boxSelected) return;

      raycaster.setFromCamera(mouse, this.camera2d);
      const plane = this.setAxisPlane();
      const intersection = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersection);

      switch(this.measureMethod){
        case "length":
          if (intersection) this.lineClicked(intersection);
          break;
        case "area":
          if (intersection) this.areaClicked(intersection);
          break;
        case "angle":
          if (intersection) this.angleClicked(intersection);
          break;
        default:
          break;
      }
    }
  }

  lineClicked(intersection){
    if (!this.measureLineStart) {  
      this.lineMeasure.setStartPoint(intersection);
      this.measureLineStart = true;
    } 
    else {          
      this.lineMeasure.setEndPoint(intersection);
      this.measureLineStart = false;						
      this.lineMeasure.drawMeasureLine(this.axis, this.radian, true);
      this.lineMeasure.drawLineTextBox(this.axis, this.radian);
      this.lineMeasure.increaseLineCount();
    }
  }

  areaClicked(intersection){
    if(!this.measureAreaStart){
      this.areaMeasure.pushAreaLinePoint(intersection);
      this.measureAreaStart = true;						
    }
    else{	
      if(this.areaMeasure.getPointNum() > 2){
        const distanceToStart = intersection.distanceTo(this.areaMeasure.getLinePoint(0));
        if(distanceToStart>0.5){
          this.areaMeasure.pushAreaLinePoint(intersection);
          const pointsNum = this.areaMeasure.getPointNum();
          this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-2), this.areaMeasure.getLinePoint(pointsNum-1), this.radian, true);
          this.areaMeasure.increaseAreaLineCount();
        }
        else{
          const pointsNum = this.areaMeasure.getPointNum();
          this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-1), this.areaMeasure.getLinePoint(0), this.radian, true);
          this.areaMeasure.pushPolygon(this.axis, this.radian);
          this.areaMeasure.drawAreaPolygon(this.radian);
          this.areaMeasure.drawAreaTextBox(this.axis, this.radian);
          
          this.areaMeasure.resetAreaLineCount();
          this.areaMeasure.resetAreaLinePoints();
          this.areaMeasure.resetAreaLines();
          this.areaMeasure.increaseAreaCount();								
          this.measureAreaStart = false;
        }
      }
      else{
        this.areaMeasure.pushAreaLinePoint(intersection);
        const pointsNum = this.areaMeasure.getPointNum();
        this.areaMeasure.drawAreaLine(this.areaMeasure.getLinePoint(pointsNum-2), this.areaMeasure.getLinePoint(pointsNum-1), this.radian, true);
        this.areaMeasure.increaseAreaLineCount();
      }
    }
  }

  angleClicked(intersection){
    if(!this.measureAngleStart){
      this.angleMeasure.pushAngleLinePoint(intersection);
      this.measureAngleStart = true;
    }
    else{
      this.angleMeasure.pushAngleLinePoint(intersection);
      const pointsNum = this.angleMeasure.getNumAngleLinePoints();
      this.angleMeasure.drawAngleLine(this.angleMeasure.getAngleLinePoint(pointsNum-2), this.angleMeasure.getAngleLinePoint(pointsNum-1), this.radian, true);
      this.angleMeasure.increaseAngleLineCount();

      if(this.angleMeasure.getAngleLineCount()==2){
        this.angleMeasure.pushAngle(this.axis, this.radian);							
        this.angleMeasure.drawAngleCurve(this.axis, this.radian);
        this.angleMeasure.resetAngleLineCount();
        this.angleMeasure.resetAngleLines();
        this.angleMeasure.resetAngleLinePoints();
        this.angleMeasure.increaseAngleCount();
        this.measureAngleStart = false;
      }
    }
  }

  setAxisPlane(){
    let plane;
    if(this.axis==0) plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
    else if(this.axis==1) {
      const basePlane = new THREE.Plane(new THREE.Vector3(1, 0, 0), 0);
      const quaternion = new THREE.Quaternion();
      quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), this.radian);
      const rotatedNormal = basePlane.normal.clone().applyQuaternion(quaternion);
      plane = new THREE.Plane(rotatedNormal, basePlane.constant);
    }
    else if(this.axis==2) {
      const basePlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
      const quaternion = new THREE.Quaternion();
      quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), this.radian);
      const rotatedNormal = basePlane.normal.clone().applyQuaternion(quaternion);
      plane = new THREE.Plane(rotatedNormal, basePlane.constant);
    }
    else plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
    return plane;
  }

  findAllMeshes(scene, meshes = []) {
    scene.children.forEach(child => {
      if (child instanceof THREE.Mesh) {
        meshes.push(child);
      }
      else if (child.children.length) {
        this.findAllMeshes(child, meshes);
      }
    });
    return meshes;
  }

  selectBox(intersections){
    let boxSelected = false;
    for (let i = 0; i < intersections.length; i++) {
      for(let j = 0; j < this.lineMeasure.getNumLineBoxes(); j++){
        const lineBox = this.lineMeasure.getLineBox(j);
        if (intersections[i].object === lineBox.boxMesh) {					
          if(lineBox.checked) {							
            intersections[i].object.material.color.set(0x94D8AB);
            lineBox.checked = false;
          } 
          else{							
            intersections[i].object.material.color.set(0x00FFFF);
            lineBox.checked = true;
          }
          if(this.measureLineStart) this.removeDrawingLine();
          if(this.measureAreaStart) this.removeDrawingPolygon();
          if(this.measureAngleStart) this.removeDrawingAngleLine();
          boxSelected = true;
          break; 
        }	
      }
      if (boxSelected) break; 		
    }
    if(boxSelected) return boxSelected;

    const areaBoxes = this.areaMeasure.getTextBoxes();
    for (let i = 0; i < intersections.length; i++) {
      for(let j = 0; j < areaBoxes.length; j++){
        if (intersections[i].object === areaBoxes[j].boxMesh) {					
          if(areaBoxes[j].checked) {							
            intersections[i].object.material.color.set(0x94D8AB);
            areaBoxes[j].checked = false;
          } 
          else{							
            intersections[i].object.material.color.set(0x00FFFF);
            areaBoxes[j].checked = true;
          }
          if(this.measureLineStart) this.removeDrawingLine();
          if(this.measureAreaStart) this.removeDrawingPolygon();
          if(this.measureAngleStart) this.removeDrawingAngleLine();
          boxSelected = true;
          break; 
        }	
      }
      if (boxSelected) break;
    }
    if(boxSelected) return boxSelected;

    for (let i = 0; i < intersections.length; i++) {
      for(let j = 0; j < this.angleMeasure.getNumAngleBoxes(); j++){
        const angleBox = this.angleMeasure.getAngleBox(j);
        if (intersections[i].object === angleBox.boxMesh) {
          if(angleBox.checked) {							
            intersections[i].object.material.color.set(0x94D8AB);
            angleBox.checked = false;
          } 
          else{							
            intersections[i].object.material.color.set(0x00FFFF);
            angleBox.checked = true;
          }
          if(this.measureLineStart) this.removeDrawingLine();
          if(this.measureAreaStart) this.removeDrawingPolygon();
          if(this.measureAngleStart) this.removeDrawingAngleLine();
          boxSelected = true;
          break; 
        }	
      }
      if (boxSelected) break;
    }
    return boxSelected;
  }
  ////////////// Mouse Event (end) //////////////

  ////////////// Magnifier //////////////
  renderMagnifierImage(mouseX, mouseY) {			
    const magnification = 2; 
    const readWidth = this.magnifierCanvas.width / magnification;
    const readHeight = this.magnifierCanvas.height / magnification;
    const pixels = new Uint8Array(readWidth * readHeight * 4);

    const startX = mouseX - (readWidth / 2);
    const startY = (this.renderer2d.domElement.height - mouseY) - (readHeight / 2);

    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = readWidth;
    tempCanvas.height = readHeight;
    const tempCtx = tempCanvas.getContext('2d');
    
    const gl = this.renderer2d.getContext();
    const format = gl.RGBA;
    const type = gl.UNSIGNED_BYTE;		

    gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
    gl.readPixels(startX, startY, readWidth, readHeight, format, type, pixels);
    
    const imageData = new ImageData(new Uint8ClampedArray(pixels), readWidth, readHeight);
    tempCtx.putImageData(imageData, 0, 0);			
    
    const ctx = this.magnifierCanvas.getContext('2d');
    ctx.clearRect(0, 0, this.magnifierCanvas.width, this.magnifierCanvas.height);
    ctx.save();
    ctx.scale(1, -1);
    ctx.drawImage(tempCanvas, 0, -this.magnifierCanvas.height, this.magnifierCanvas.width, this.magnifierCanvas.height);
    ctx.restore();

    const centerSquareSize = 16;
    let centerX = (this.magnifierCanvas.width - centerSquareSize) / 2;
    let centerY = (this.magnifierCanvas.height - centerSquareSize) / 2;

    ctx.strokeStyle = 'red'; 
    ctx.lineWidth = 1; 
    ctx.strokeRect(centerX, centerY, centerSquareSize, centerSquareSize); 

    const lineLength = 60; 		
    centerX = this.magnifierCanvas.width / 2;
    centerY = this.magnifierCanvas.height / 2;

    ctx.beginPath(); 
    ctx.moveTo(centerX - lineLength, centerY); 
    ctx.lineTo(centerX + lineLength, centerY); 
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 1;
    ctx.stroke();		

    ctx.beginPath(); 
    ctx.moveTo(centerX, centerY - lineLength); 
    ctx.lineTo(centerX, centerY + lineLength); 
    ctx.strokeStyle = 'blue';
    ctx.stroke();

    const circleRadius = 2; 
    centerX = this.magnifierCanvas.width / 2;
    centerY = this.magnifierCanvas.height / 2;

    ctx.beginPath();
    ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
    ctx.fillStyle = 'white'; 
    ctx.fill();

    ctx.beginPath();
    ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
    ctx.fillStyle = 'red';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

    ////////////// Set Measuring (start) //////////////
  hideMagnifier(){
    this.magnifierEl.style.display = 'none';
  }
  showMagnifier(){
    this.magnifierEl.style.display = 'block';
  }
  setLineMeasuring(){
    this.measureMethod = "length";
    this.showMagnifier();
  }

  setAreaMeasuring(){				
    this.measureMethod = "area";
		this.showMagnifier();
  }

  setAngleMeasuring(){		
		this.measureMethod = "angle";
		this.showMagnifier();		
  }
  
  ////////////// Set Measuring (end) //////////////


  ////////////// Set Axis and min/max (start) //////////////
  setAxis(number){
    this.axis = number;
    if(number==0 || number==1 || number==2){
      this.boundingRange.setAxis(number);
      this.boundingRange.setBounding2DBox(this.modelGroup2D, this.radian, true);
        
      this.lineMeasure.setVisibleMeasure(number);
      this.areaMeasure.setVisibleMeasure(number);
      this.angleMeasure.setVisibleMeasure(number);

      this.controls2d.update();
    }

    if(this.measureMethod == "length"){
      if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();        
    }

    if(this.measureMethod == "area"){
      this.removeDrawingPolygon();
      this.removeDrawingAngleLine();
    }
  }

  getMinMaxHeight(){
    const minHeight = this.boundingRange.getMinHeight();
    const maxHeight = this.boundingRange.getMaxHeight();
    return {min: 0, max: maxHeight - minHeight};
  }
  
  getMinMaxWidth(){
    const minWidth = this.boundingRange.getMinWidth();
    const maxWidth = this.boundingRange.getMaxWidth();
    return {min: 0, max: maxWidth - minWidth};
  }
  
  getMinMaxDepth(){
    const minDepth = this.boundingRange.getMinDepth();
    const maxDepth = this.boundingRange.getMaxDepth();
    return {min: 0, max: maxDepth - minDepth};
  }
  
  getHeightRange(){
    const rangeMinHeight = this.boundingRange.getRangeMinHeight();
    const rangeMaxHeight = this.boundingRange.getRangeMaxHeight();
    return {min: rangeMinHeight, max: rangeMaxHeight};
  }

  getWidthRange(){
    const rangeMinWidth = this.boundingRange.getRangeMinWidth();
    const rangeMaxWidth = this.boundingRange.getRangeMaxWidth();
    return {min: rangeMinWidth, max: rangeMaxWidth};
  }

  getDepthRange(){
    const rangeMinDepth = this.boundingRange.getRangeMinDepth();
    const rangeMaxDepth = this.boundingRange.getRangeMaxDepth();
    return {min: rangeMinDepth, max: rangeMaxDepth};
  }

  resetMinMaxRange(){
    this.boundingRange.resetMinMaxRange();
  }
  
  applyRange(range){      
    this.boundingRange.applyRange(range);
    // this.boundingRange.redrawBoundingRectangle(this.pcGeometry)
  }
  ////////////// Set Axis and min/max (end) //////////////

  rotateModel(deg){
    this.rotDeg = deg;
    this.radian = THREE.MathUtils.degToRad(parseFloat(deg));
    this.modelGroup3D.rotation.y = this.radian;
    this.modelGroup2D.rotation.y = this.radian;
          
    const worldMatrix2D = this.modelGroup2D.matrixWorld;
    const worldMatrix3D = this.modelGroup3D.matrixWorld;     
    
    this.material2d.uniforms.customModelMatrix.value = worldMatrix2D;
    this.material3d.uniforms.customModelMatrix.value = worldMatrix3D;
    
    this.boundingRange.setBounding2DBox(this.modelGroup2D, this.radian, false);
    this.boundingRange.removeBoundingBox();
    this.boundingRange.setBounding3DBox(this.modelGroup3D, false);

    this.lineMeasure.rotateLines(this.radian);
    this.areaMeasure.rotateAreas(this.radian);
    this.angleMeasure.rotateAngles(this.radian);
  }

    ////////////// remove drawings (start) //////////////
  removeDrawings(){	
    switch(this.measureMethod){
			case "length":
				if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();
				break;
			case "area":
				this.removeDrawingPolygon();
				break;
			case "angle":
				this.removeDrawingAngleLine();
				break;
			default:
				break;
		}
		this.hideMagnifier();
		this.measureMethod = null;
  }

  removeDrawingLine(){
    this.lineMeasure.removeDrawings();
    this.measureLineStart = false;
  }

  removeDrawingPolygon(){		
    this.areaMeasure.removeDrawings();
    this.measureAreaStart=false;
  }

  removeDrawingAngleLine(){
    this.angleMeasure.removeDrawings();
    this.measureAngleStart=false;
  }

  ////////////// remove drawings (end) //////////////

  setHeightColor(isHeightMap){
    this.material3d.uniforms.colorMap.value = isHeightMap;
  }

  deleteMeasurements(){
    if(this.lineMeasure.getNumLineBoxes()<this.lineMeasure.getNumLines()) this.removeDrawingLine();
    this.lineMeasure.removeLineMeasure();
    
    this.removeDrawingPolygon();
    this.areaMeasure.removeAreaMeasures();

    this.removeDrawingAngleLine();
    this.angleMeasure.removeAngleMeasures();
  }
}