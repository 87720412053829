import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

class GoogleAPI {
  constructor(element, callback, newMarkerCallback) {
    this.loader = new Loader({
      apiKey: "AIzaSyBg5pc7A1i9EtPm4p8n_NBGTywNicmB7sk",
      version: "weekly",
    });
    this.map;
    // this.marker_img = require('@/assets/flag.png');
    this.marker_img = require('@/../public/images/map_marker.svg');
    this.marker = null;
    this.newMarkerCallback = newMarkerCallback;
    this.markers = [];
    this.markerClusterer;
    this.initMap(element, callback);
  }
  initMap(element, callback) {
    this.loader.load().then(async () => {
      this.map = new google.maps.Map(element, {
        center: { lat: 38.03375, lng: 128.02025 },
        zoom: 8,
        disableDefaultUI: true, //기본 Map UI 비활성화
        gestureHandling: "cooperative", //Pad에서 조작 가능
        mapTypeId: "hybrid",
        styles: [
          {
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
      });
      this.setPolyLine();

      if (typeof callback == "function") {
        callback();
      }
    });
  }
  changeMapCenter(latLng) {
    let newCenter = new google.maps.LatLng(latLng);
    this.map.setCenter(newCenter);
  }
  setClickEvent() {
    this.clickListener = this.map.addListener("click", this.addNewMarker);
  }
  deleteClickEvent() {
    this.clearNewMarker();
    if (this.clickListener) {
      google.maps.event.removeListener(this.clickListener);
      this.clickListener = null;
    }
  }
  setPolyLine() {
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      scale: 2,
    };
    this.polyLine = new google.maps.Polyline({
      strokeColor: "#01FEFA",
      strokeOpacity: 0,
      strokeWeight: 1,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "20px",
        },
      ],
    });
    this.polyLine.setMap(this.map);
  }
  addMarker(latLng, markerCickEvent, usePolyLine) {
    let marker = new google.maps.Marker({
      map: this.map,
      position: latLng,
      // animation: google.maps.Animation.DROP,
      icon: {
        url: this.marker_img,
        scaledSize: new google.maps.Size(30, 40),
      },
    });
    if (markerCickEvent) {
      marker.addListener("click", markerCickEvent);
    }
    this.markers.push(marker);
    if (usePolyLine) {
      let polyLinePath = this.polyLine.getPath();
      polyLinePath.push(marker.getPosition());
    }
    this.setMarkerClustere();
  }
  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
    if (this.polyLine) {
      this.polyLine.getPath().clear();
    }
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }
  }
  addNewMarker = (eventOrPosition) => {
    let position = eventOrPosition.latLng || eventOrPosition;

    this.clearNewMarker();

    this.marker = new google.maps.Marker({
      map: this.map,
      position: position,
      icon: {
        url: this.marker_img,
        scaledSize: new google.maps.Size(30, 40),
      },
    });

    if (this.newMarkerCallback) {
      this.newMarkerCallback();
    }

    this.marker.addListener("click", () => {
      this.marker.setMap(null);
      this.marker = null;
      if (this.newMarkerCallback) {
        this.newMarkerCallback();
      }
    });
  };
  clearNewMarker() {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }
  setMarkerClustere() {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
      this.markerClusterer.addMarkers(this.markers);
    } else {
      this.markerClusterer = new MarkerClusterer({
        markers: this.markers,
        map: this.map,
      });
    }
  }
  setMapType(mapType) {
    this.map.setMapTypeId(mapType);
  }
}

export default GoogleAPI;
