<template>
  <header class="with_nav">
    <div class="header_wrap">
      <div class="left_area">
        <h1>
          <router-link :to="'/'">
            <img src="@/../public/images/header-logo.png" alt="메인 홈으로"
          /></router-link>
        </h1>
        <nav>
          <div class="navigator_mapping nav_button" v-if="navigator != 'Home'">
            <button
              type="button"
              class="navigator_title"
              @click="mappingRouter"
              :class="{ 'on ': navigator == 'Mapping' }"
            >
              매핑
            </button>
          </div>
          <div
            class="navigator_inspection nav_button"
            v-if="navigator != 'Home'"
          >
            <button
              type="button"
              class="navigator_title"
              @click="inspectionRouter"
              :class="{ 'on ': navigator == 'Inspection' }"
            >
              인스펙션
            </button>
          </div>
          <div class="navigator_report nav_button" v-if="navigator != 'Home'">
            <button
              type="button"
              class="navigator_title"
              @click="reportRouter"
              :class="{ 'on ': navigator == 'Report' }"
            >
              레포트
            </button>
          </div>
        </nav>
      </div>
      <!-- 로그아웃/사용자 관리/내 정보 수정 컴포넌트 -->
      <HeaderUserBox />
    </div>
  </header>
</template>

<script>
import HeaderUserBox from "@/component/layout/component/HeaderUserBox.vue";
import { addOutsideClickHandler } from "@/shared/util/handleOutsideClickHandler";
import { formatISO } from "date-fns";

import { mapState } from "vuex";

export default {
  components: {
    HeaderUserBox,
  },
  computed: {
    ...mapState(["user", "selectedSite", "selectedFacilityMap", "api"]),
  },
  props: {
    navigator: String,
  },
  data() {
    return {
      isModalOpen: false,
      modalName: null,
      uploadedImageUrl: null,
      uploadedImageFile: null,
    };
  },
  methods: {
    reportRouter() {
      // const mapsArray = this.selectedSite?.maps;
      if (this.selectedSite) {
        this.$router.push({
          name: "report",
          params: { siteId: this.selectedSite.id },
        });

        return;
      }

      const isConfirmed = confirm(
        `시설을 다시 선택해 주세요. 메인으로 이동합니다.`
      );

      if (isConfirmed) {
        this.$router.push("/");
      }
    },
    mappingRouter() {
      if (this.selectedSite) {
        if (this.selectedSite.mapList) {
          let emptyMap = this.selectedSite.mapList.find((map) => map.url == "");
          if (emptyMap) {
            this.$router.push({
              name: "mapping",
              params: { siteId: this.selectedSite.id, mapId: emptyMap.id },
            });
          } else {
            this.api.postMap(
              this.mappingRouterCallback,
              this.selectedSite.id,
              formatISO(new Date())
            );
          }
        } else {
          this.api.postMap(
            this.mappingRouterCallback,
            this.selectedSite.id,
            formatISO(new Date())
          );
        }
      } else {
        alert(
          "선택된 시설물이 없습니다. 시설물 목록에서 시설물을 선택해주세요."
        );
      }
    },
    mappingRouterCallback(data) {
      let result = data.data.result;
      this.$router.push({
        name: "mapping",
        params: { siteId: this.selectedSite.id, mapId: result.id },
      });
    },
    inspectionRouter() {
      if (this.selectedFacilityMap) {
        this.$router.push({
          name: "inspection",
          params: {
            siteId: this.selectedSite.id,
            mapId: this.selectedFacilityMap.id,
          },
        });

        return;
      }

      const isConfirmed = confirm(
        `맵을 다시 선택해 주세요. 메인으로 이동합니다.`
      );

      if (isConfirmed) {
        this.$router.push("/");
      }
    },
    homeRouter() {
      this.$router.push({ name: "home" });
    },
  },
  mounted() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    this.clickHandlerRemover = addOutsideClickHandler(
      this.$refs.headerMenuButton,
      () => {
        this.isMenuShow = false;
      }
    );
  },
  beforeUnmount() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    if (this.clickHandlerRemover) {
      this.clickHandlerRemover();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/component/header";

.navigator_home {
  position: relative;
  width: 25px;
  height: 30px;
}
.navigator_title {
  @include flexbox;
  position: relative;
  width: 80px;
  height: 30px;
  font-size: 1.4rem;
  border: 1px solid $gray_a1a1a1;
  border-radius: 4px;
  color: $gray_a1a1a1;
  transition: 0.1s;

  &.on {
    color: $main_black;
    background: $main_color;
    border: 1px solid $main_color;
    font-weight: 500;
  }

  &:hover {
    color: $main_black;
    background: $main_color;
    border: 1px solid $main_color;
    font-weight: 500;
  }
}
</style>
