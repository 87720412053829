export default class NetworkMap{
    constructor(networMap){
        this.id = null;
        this.elevationSetId = null;
        this.elevationId = null;
        this.inspectionNetworkMapUrl = null;
        this.createdDatetime = null;
        this.wrDatetime = null;        

        if(networMap){
            this.id = networMap.id;
            this.elevationSetId = networMap.elevationSetId;
            this.elevationId = networMap.elevationId;
            this.inspectionNetworkMapUrl = networMap.inspectionNetworkMapUrl;
            this.createdDatetime = networMap.createdDatetime;
            this.wrDatetime = networMap.wrDatetime;
        }
    }
}