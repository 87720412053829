import {
	Group, 
	Box3, 
	Box3Helper, 
	Vector3, 
	BufferGeometry, 
	Line, 
	LineBasicMaterial, 
	PlaneGeometry, 
	MeshBasicMaterial,
	Mesh,
	DoubleSide
} from 'three'

export default class BoundingRange {
	constructor(scene, modelGroup) {
		this.scene = scene;
		this.modelGroup = modelGroup;
		this.boundingBoxGroup = new Group();

		this.minHeight = Infinity;
		this.maxHeight = -Infinity;
		this.minWidth = Infinity;
		this.maxWidth = -Infinity;
		this.minDepth = Infinity;
		this.maxDepth = -Infinity;

		this.minHeightPlane = null;
		this.maxHeightPlane = null;
		this.minWidthPlane = null;
		this.maxWidthPlane = null;
		this.minDepthPlane = null;
		this.maxDepthPlane = null;

		this.rangeMinHeight = null;
		this.rangeMaxHeight = null;
		this.rangeMinWidth = null;
		this.rangeMaxWidth = null;
		this.rangeMinDepth = null;
		this.rangeMaxDepth = null;
	}

	getMinHeight(){return this.minHeight;}
	setMinHeight(height){this.minHeight = height;}
	getMaxHeight(){return this.maxHeight;}
	setMaxHeight(height){this.maxHeight = height;}
	getMinWidth(){return this.minWidth;}
	setMinWidth(width){this.minWidth = width;}
	getMaxWidth(){return this.maxWidth;}
	setMaxWidth(width){this.maxWidth = width;}
	getMinDepth(){return this.minDepth;}
	setMinDepth(depth){this.minDepth = depth;}
	getMaxDepth(){return this.maxDepth;}
	setMaxDepth(depth){this.maxDepth = depth;}

	getRangeMinHeight(){return this.rangeMinHeight;}
	setRangeMinHeight(height){this.rangeMinHeight = height;}
	getRangeMaxHeight(){return this.rangeMaxHeight;}
	setRangeMaxHeight(height){this.rangeMaxHeight = height;}
	getRangeMinWidth(){return this.rangeMinWidth;}
	setRangeMinWidth(width){this.rangeMinWidth = width;}
	getRangeMaxWidth(){return this.rangeMaxWidth;}
	setRangeMaxWidth(width){this.rangeMaxWidth = width;}
	getRangeMinDepth(){return this.rangeMinDepth;}
	setRangeMinDepth(depth){this.rangeMinDepth = depth;}
	getRangeMaxDepth(){return this.rangeMaxDepth;}
	setRangeMaxDepth(depth){this.rangeMaxDepth = depth;}

	setBoundingBoxVisibility(visible){
		this.boundingBoxGroup.visible = visible;
	}

	resetMinMaxRange(){
		this.rangeMinHeight = 0;
		this.rangeMaxHeight = this.maxHeight - this.minHeight;

		this.rangeMinWidth = 0;
		this.rangeMaxWidth = this.maxWidth - this.minWidth;

		this.rangeMinDepth = 0;
		this.rangeMaxDepth = this.maxDepth - this.minDepth;
	}

	setPlaneVisible(axis){
		if(axis==0){
			this.minHeightPlane.visible = true;
			this.maxHeightPlane.visible = true;
			this.minWidthPlane.visible = false;
			this.maxWidthPlane.visible = false;
			this.minDepthPlane.visible = false;
			this.maxDepthPlane.visible = false;
		}
		else if(axis==1){
			this.minHeightPlane.visible = false;
			this.maxHeightPlane.visible = false;
			this.minWidthPlane.visible = true;
			this.maxWidthPlane.visible = true;
			this.minDepthPlane.visible = false;
			this.maxDepthPlane.visible = false;
		}
		else if(axis==2){
			this.minHeightPlane.visible = false;
			this.maxHeightPlane.visible = false;
			this.minWidthPlane.visible = false;
			this.maxWidthPlane.visible = false;
			this.minDepthPlane.visible = true;
			this.maxDepthPlane.visible = true;
		}
		else if(axis==3){
			this.minHeightPlane.visible = false;
			this.maxHeightPlane.visible = false;
			this.minWidthPlane.visible = false;
			this.maxWidthPlane.visible = false;
			this.minDepthPlane.visible = false;
			this.maxDepthPlane.visible = false;
		}
	}

	setVolumeColorRange(range){
		if(range.minHeight < 0) this.rangeMinHeight  = 0;
		else this.rangeMinHeight  = range.minHeight;

		if(range.maxHeight > this.maxHeight-this.minHeight) this.rangeMaxHeight  = this.maxHeight-this.minHeight;
		else this.rangeMaxHeight  = range.maxHeight;

		if(range.minWidth < 0) this.rangeMinWidth  = 0;
		else this.rangeMinWidth  = range.minWidth;

		if(range.maxWidth > this.maxWidth-this.minWidth) this.rangeMaxWidth  = this.maxWidth-this.minWidth;
		else this.rangeMaxWidth = range.maxWidth;

		if(range.minDepth < 0) this.rangeMinDepth  = 0;
		else this.rangeMinDepth  = range.minDepth;

		if(range.maxDepth > this.maxDepth-this.minDepth) this.rangeMaxDepth  = this.maxDepth-this.minDepth;
		else this.rangeMaxDepth = range.maxDepth;

		this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;
		this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
		this.minWidthPlane.position.x =  this.minWidth + this.rangeMinWidth;
		this.maxWidthPlane.position.x =  this.minWidth + this.rangeMaxWidth;
		this.minDepthPlane.position.z =  this.maxDepth - this.rangeMinDepth;
		this.maxDepthPlane.position.z =  this.maxDepth - this.rangeMaxDepth;
	}

	setCropedColorRange(range){
		if(range.minHeight < 0) this.rangeMinHeight  = 0;
		else this.rangeMinHeight  = range.minHeight;

		if(range.maxHeight > this.maxHeight-this.minHeight) this.rangeMaxHeight  = this.maxHeight-this.minHeight;
		else this.rangeMaxHeight  = range.maxHeight;

		if(range.minWidth < 0) this.rangeMinWidth  = 0;
		else this.rangeMinWidth  = range.minWidth;

		if(range.maxWidth > this.maxWidth-this.minWidth) this.rangeMaxWidth  = this.maxWidth-this.minWidth;
		else this.rangeMaxWidth = range.maxWidth;

		if(range.minDepth < 0) this.rangeMinDepth  = 0;
		else this.rangeMinDepth  = range.minDepth;

		if(range.maxDepth > this.maxDepth-this.minDepth) this.rangeMaxDepth  = this.maxDepth-this.minDepth;
		else this.rangeMaxDepth = range.maxDepth;

		this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;
		this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
		this.minWidthPlane.position.x =  this.minWidth + this.rangeMinWidth;
		this.maxWidthPlane.position.x =  this.minWidth + this.rangeMaxWidth;
		this.minDepthPlane.position.z =  this.maxDepth - this.rangeMaxDepth;
		this.maxDepthPlane.position.z =  this.maxDepth - this.rangeMinDepth;
	}

	setPolygonColorRange(range){
		if(range.minHeight < 0) this.rangeMinHeight  = 0;
		else this.rangeMinHeight  = range.minHeight;
		if(range.maxHeight > this.maxHeight-this.minHeight) this.rangeMaxHeight  = this.maxHeight-this.minHeight;
		else this.rangeMaxHeight  = range.maxHeight;

		this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;
		this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
	}

	setBoundingBox(object, axisIdx){
		const box = new Box3().setFromObject(object);
		this.minHeight = box.min.y;
		this.maxHeight = box.max.y;
		this.minWidth = box.min.x;
		this.maxWidth = box.max.x;
		this.minDepth = box.min.z;
		this.maxDepth = box.max.z;

		const boxHelper = new Box3Helper(box, 0x01fefa);
		boxHelper.raycast = () => {};
		this.boundingBoxGroup.add(boxHelper);

		const step = 10;
		const lineMaterial = new LineBasicMaterial({ color: 0x01fefa });

		for (let axis of ['x', 'y', 'z']) {
			let start = box.min[axis];
			let end = box.max[axis];
			for (let point = start; point <= end; point += step) {
				const points = [];
				const startPoint = new Vector3();
				const endPoint = new Vector3();

				startPoint[axis] = point;
				endPoint[axis] = point;
				
				if (axis === 'x') {
					startPoint['y'] = box.min['y'];
					startPoint['z'] = box.min['z'];
					endPoint['y'] = startPoint['y']; 
					endPoint['z'] = startPoint['z'] + 2;
					points.push(startPoint, endPoint);
					let lineGeometry = new BufferGeometry().setFromPoints(points);
					let line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['y'] = box.min['y'];
					startPoint['z'] = box.max['z'];
					endPoint['y'] = startPoint['y'] + 2; 
					endPoint['z'] = startPoint['z'];
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['y'] = box.max['y'];
					startPoint['z'] = box.min['z'];
					endPoint['y'] = startPoint['y'] - 2; 
					endPoint['z'] = startPoint['z'] ;
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['y'] = box.max['y'];
					startPoint['z'] = box.max['z'];
					endPoint['y'] = startPoint['y']; 
					endPoint['z'] = startPoint['z'] - 2;
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

				}
				else if (axis === 'y') {
					startPoint['x'] = box.min['x'];
					startPoint['z'] = box.min['z'];
					endPoint['x'] = startPoint['x'];
					endPoint['z'] = startPoint['z'] + 2; 
					points.push(startPoint, endPoint);
					let lineGeometry = new BufferGeometry().setFromPoints(points);
					let line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);
					
					startPoint['x'] = box.min['x'];
					startPoint['z'] = box.max['z'];
					endPoint['x'] = startPoint['x'] + 2;
					endPoint['z'] = startPoint['z']; 
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['x'] = box.max['x'];
					startPoint['z'] = box.min['z'];
					endPoint['x'] = startPoint['x'] - 2;
					endPoint['z'] = startPoint['z']; 
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['x'] = box.max['x'];
					startPoint['z'] = box.max['z'];
					endPoint['x'] = startPoint['x'];
					endPoint['z'] = startPoint['z'] - 2; 		
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);			

				}
				else { // z축
					startPoint['x'] = box.min['x'];
					startPoint['y'] = box.min['y'];
					endPoint['x'] = startPoint['x'] + 2; 
					endPoint['y'] = startPoint['y'];
					points.push(startPoint, endPoint);
					let lineGeometry = new BufferGeometry().setFromPoints(points);
					let line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['x'] = box.min['x'];
					startPoint['y'] = box.max['y'];
					endPoint['x'] = startPoint['x']; 
					endPoint['y'] = startPoint['y'] - 2;
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['x'] = box.max['x'];
					startPoint['y'] = box.min['y'];
					endPoint['x'] = startPoint['x']; 
					endPoint['y'] = startPoint['y'] + 2;
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);

					startPoint['x'] = box.max['x'];
					startPoint['y'] = box.max['y'];
					endPoint['x'] = startPoint['x'] - 2; 
					endPoint['y'] = startPoint['y'];
					points.push(startPoint, endPoint);
					lineGeometry = new BufferGeometry().setFromPoints(points);
					line = new Line(lineGeometry, lineMaterial);
					line.raycast = () => {};
					this.boundingBoxGroup.add(line);
				}
			}
		}
		
		const boxSize = new Vector3();
		box.getSize(boxSize);

		const boxCenter = new Vector3();
		box.getCenter(boxCenter);
		
		const planeGeometryHeight = new PlaneGeometry(boxSize.x, boxSize.z);
		const planeGeometryWidth = new PlaneGeometry(boxSize.y, boxSize.z);
		const planeGeometryDepth = new PlaneGeometry(boxSize.x, boxSize.y);
		const planeMaterial = new MeshBasicMaterial({
			color: 0x015efe,
			transparent: true,
			opacity: 0.25,
			side: DoubleSide 
		});

		this.minHeightPlane = new Mesh(planeGeometryHeight, planeMaterial);
		this.minHeightPlane.position.x = boxCenter.x;
		this.minHeightPlane.position.z = boxCenter.z;
		this.rangeMinHeight  = 0;
		this.minHeightPlane.position.y = this.minHeight + this.rangeMinHeight;		
		this.minHeightPlane.rotation.x = -Math.PI / 2;

		this.maxHeightPlane = new Mesh(planeGeometryHeight, planeMaterial);
		this.maxHeightPlane.position.x = boxCenter.x;
		this.maxHeightPlane.position.z = boxCenter.z;
		this.rangeMaxHeight  = this.maxHeight-this.minHeight;
		this.maxHeightPlane.position.y = this.minHeight + this.rangeMaxHeight;
		this.maxHeightPlane.rotation.x = -Math.PI / 2;

		this.minWidthPlane = new Mesh(planeGeometryWidth, planeMaterial);
		this.minWidthPlane.position.y = boxCenter.y;
		this.minWidthPlane.position.z = boxCenter.z;
		this.rangeMinWidth  = 0;
		this.minWidthPlane.position.x = this.minWidth + this.rangeMinWidth;
		this.minWidthPlane.rotation.y = -Math.PI / 2;
		this.minWidthPlane.rotation.z = -Math.PI / 2;

		this.maxWidthPlane = new Mesh(planeGeometryWidth, planeMaterial);
		this.maxWidthPlane.position.y = boxCenter.y;
		this.maxWidthPlane.position.z = boxCenter.z;
		this.rangeMaxWidth  = this.maxWidth-this.minWidth;
		this.maxWidthPlane.position.x = this.minWidth + this.rangeMaxWidth;
		this.maxWidthPlane.rotation.y = -Math.PI / 2;
		this.maxWidthPlane.rotation.z = -Math.PI / 2;

		this.minDepthPlane = new Mesh(planeGeometryDepth, planeMaterial);
		this.minDepthPlane.position.x = boxCenter.x;
		this.minDepthPlane.position.y = boxCenter.y;
		this.rangeMinDepth  = 0;
		this.minDepthPlane.position.z = this.maxDepth - this.rangeMinDepth;		
		
		this.maxDepthPlane = new Mesh(planeGeometryDepth, planeMaterial);
		this.maxDepthPlane.position.x = boxCenter.x;
		this.maxDepthPlane.position.y = boxCenter.y;
		this.rangeMaxDepth  = this.maxDepth-this.minDepth;
		this.maxDepthPlane.position.z = this.maxDepth - this.rangeMaxDepth;		

		this.boundingBoxGroup.add(this.minHeightPlane);
		this.boundingBoxGroup.add(this.maxHeightPlane);
		this.boundingBoxGroup.add(this.minWidthPlane);
		this.boundingBoxGroup.add(this.maxWidthPlane);
		this.boundingBoxGroup.add(this.minDepthPlane);
		this.boundingBoxGroup.add(this.maxDepthPlane);
	
		this.scene.add(this.boundingBoxGroup);		
		this.boundingBoxGroup.visible = false;		
		
		if(axisIdx==0){			
			this.minHeightPlane.visible = true;
			this.maxHeightPlane.visible = true;
			this.minWidthPlane.visible = false;
			this.maxWidthPlane.visible = false;
			this.minDepthPlane.visible = false;
			this.maxDepthPlane.visible = false;
		}
		else if(axisIdx==1){			
			this.minHeightPlane.visible = false;
			this.maxHeightPlane.visible = false;
			this.minWidthPlane.visible = true;
			this.maxWidthPlane.visible = true
			this.minDepthPlane.visible = false;
			this.maxDepthPlane.visible = false;
		}
		else if(axisIdx==2){			
			this.minHeightPlane.visible = false;
			this.maxHeightPlane.visible = false;
			this.minWidthPlane.visible = false;
			this.maxWidthPlane.visible = false;
			this.minDepthPlane.visible = true;
			this.maxDepthPlane.visible = true;
		}
		else if(axisIdx==3){
			this.minHeightPlane.visible = false;
			this.maxHeightPlane.visible = false;
			this.minWidthPlane.visible = false;
			this.maxWidthPlane.visible = false;
			this.minDepthPlane.visible = false;
			this.maxDepthPlane.visible = false;
		}		
		this.minHeightPlane.raycast = () => {};
		this.maxHeightPlane.raycast = () => {};
		this.minWidthPlane.raycast = () => {};
		this.maxWidthPlane.raycast = () => {};
		this.minDepthPlane.raycast = () => {};
		this.maxDepthPlane.raycast = () => {};
	}

	removeBoundingBox(){
		while (this.boundingBoxGroup.children.length) {
			const child = this.boundingBoxGroup.children[0];
			this.boundingBoxGroup.remove(child);
		}		
	}
}