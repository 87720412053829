import {
	Vector3,
	TubeGeometry,
	MeshBasicMaterial,
	SphereGeometry,
	Mesh,
	LineCurve3,
	BoxGeometry,
	DoubleSide,
	Quaternion
} from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

export default class LineMeasure {
	constructor(scene, modelGroup, font) {
		this.scene = scene;
		this.modelGroup = modelGroup;
		this.lines = [];
		this.lineBoxes = [];
		this.lineCount = 0;
		this.font = font;
		this.startPoint = null;
		this.endPoint = null;
	}

	getLine(index){return this.lines[index];}
	resetLines(){this.lines = [];}
	getNumLines(){return this.lines.length;}

	getLineBox(index){return this.lineBoxes[index];}
	resetLineBoxes(){this.lineBoxes = [];}
	getNumLineBoxes(){return this.lineBoxes.length;}

	resetLineCount(){
		this.lineCount=0;
	}

	increaseLineCount(){
		this.lineCount++;
	}

	decreaseLineCount(){
		this.lineCount--;
	}

	getLineCount(){return this.lineCount;}


	
	getStartEndPoint(offsetPoint){
		let point;
		if(this.lineCount>0){			
			for(let i=0;i<this.lineCount;i++){											
				const distanceToStart = offsetPoint.distanceTo(this.lines[i].startPoint);
				const distanceToEnd = offsetPoint.distanceTo(this.lines[i].endPoint);
				if(distanceToStart<0.5){					
					point = this.lines[i].startPoint;
					break;
				}
				else if(distanceToEnd<0.5){					
					point = this.lines[i].endPoint;
					break;
				}
				else point = offsetPoint;
			}
		}
		else{			
			point = offsetPoint;
		}

		return point;
	}

	setStartPoint(offsetPoint){
		this.startPoint = this.getStartEndPoint(offsetPoint);
	}

	setEndPoint(offsetPoint){
		this.endPoint = this.getStartEndPoint(offsetPoint);
	}

	drawMeasureLine(radian, save) {
		const path = new LineCurve3(
			new Vector3(this.startPoint.x, this.startPoint.y, this.startPoint.z),
			new Vector3(this.endPoint.x, this.endPoint.y, this.endPoint.z)
		);
				
		const tubeRadius = 0.05; 		
		const tubeGeometry = new TubeGeometry(path, 64, tubeRadius, 10, false);
		const tubeMaterial = new MeshBasicMaterial({ color: 0xffffff});

		const sphereGeometry = new SphereGeometry(tubeRadius*2, 32, 32);
		const sphereMaterial = new MeshBasicMaterial({ color: 0xffffff });

		const tube = new Mesh(tubeGeometry, tubeMaterial);
		const startSphere = new Mesh(sphereGeometry, sphereMaterial);
		const endSphere = new Mesh(sphereGeometry, sphereMaterial);

		tube.raycast = () => {};
		startSphere.raycast = () => {};
		endSphere.raycast = () => {};

		startSphere.position.copy(this.startPoint);
		endSphere.position.copy(this.endPoint);
		
		if(this.lines.length == this.lineCount){

			this.scene.add(tube);
			this.scene.add(startSphere);
			this.scene.add(endSphere);

			this.lines.push({tube: tube, startSphere: startSphere, endSphere: endSphere
											,startPoint: this.startPoint, endPoint: this.endPoint});			
		}
		else{			
			const lastLine = this.lines[this.lines.length - 1];
			this.scene.remove(lastLine.tube);
			lastLine.tube.geometry.dispose();
			lastLine.tube.material.dispose();
			this.scene.remove(lastLine.startSphere);
			lastLine.startSphere.geometry.dispose();
			lastLine.startSphere.material.dispose();
			this.scene.remove(lastLine.endSphere);
			lastLine.endSphere.geometry.dispose();
			lastLine.endSphere.material.dispose();

			if(save){
				const axis = new Vector3(0, 1, 0); // y축 기준
				const quaternion = new Quaternion().setFromAxisAngle(axis, -radian);

				const rotatedStartPoint = this.startPoint.clone().applyQuaternion(quaternion);
				const rotatedEndPoint = this.endPoint.clone().applyQuaternion(quaternion);
				const rotatedPath = new LineCurve3(rotatedStartPoint, rotatedEndPoint);
				const rotatedTubeGeometry = new TubeGeometry(rotatedPath, 64, tubeRadius, 10, false);

				startSphere.position.copy(rotatedStartPoint);
				endSphere.position.copy(rotatedEndPoint);

				tube.geometry.dispose(); // 이전 Geometry 메모리 해제
				tube.geometry = rotatedTubeGeometry;

				this.modelGroup.add(tube);
				this.modelGroup.add(startSphere);
				this.modelGroup.add(endSphere);

				this.lines[this.lines.length - 1] = {tube: tube, startSphere: startSphere, endSphere: endSphere
																						,startPoint: rotatedStartPoint, endPoint: rotatedEndPoint};
			}
			else{
				this.scene.add(tube);
				this.scene.add(startSphere);
				this.scene.add(endSphere);

				this.lines[this.lines.length - 1] = {tube: tube, startSphere: startSphere, endSphere: endSphere
																						,startPoint: this.startPoint, endPoint: this.endPoint};
			}
		}
	}

	drawLineTextBox(radian, camera){
		const distance = this.startPoint.distanceTo(this.endPoint);					
		const roundedDistance = Math.round(distance * 10) / 10;

		if(this.font){
			const textGeo = new TextGeometry(`${roundedDistance} m`, {
				font: this.font,
				size: 0.3,
				depth: 0.1,
			});
		textGeo.computeBoundingBox();
		const textWidth = textGeo.boundingBox.max.x - textGeo.boundingBox.min.x;
		const textHeight = textGeo.boundingBox.max.y - textGeo.boundingBox.min.y;

		const textMaterial = new MeshBasicMaterial({ color: 0x000000, side: DoubleSide });						
		const textMeshFront = new Mesh(textGeo, textMaterial);    
		const textMeshBack = textMeshFront.clone();						

		const boxDepth = 0.2; 
		const textOffsetX = -textWidth / 2; 
		const textOffsetY = -textHeight / 2; 
		textMeshFront.position.set(textOffsetX, textOffsetY, boxDepth / 2);

		textMeshBack.position.set(textOffsetX, textOffsetY, -boxDepth / 2);
		textMeshBack.rotation.y = Math.PI;
		textMeshBack.position.x += textWidth;				

		const boxWidth = textWidth * 1.4; 
		const boxHeight = textHeight * 2.0;						
		const boxGeometry = new BoxGeometry(boxWidth, boxHeight, boxDepth);
		const boxMaterial = new MeshBasicMaterial({ color: 0xffffff });
		const boxMesh = new Mesh(boxGeometry, boxMaterial);
		const midPoint = new Vector3().addVectors(this.startPoint, this.endPoint).multiplyScalar(0.5);
		const rotAxis = new Vector3(0, 1, 0);
		const quaternion = new Quaternion().setFromAxisAngle(rotAxis, -radian);
		midPoint.applyQuaternion(quaternion);					
		boxMesh.position.copy(midPoint);
		
		boxMesh.position.y += boxHeight / 2;
		boxMesh.lookAt(camera.position);			

		boxMesh.add(textMeshFront);
		boxMesh.add(textMeshBack);
				
		this.lineBoxes.push({boxMesh:boxMesh, checked: false});
		this.modelGroup.add(boxMesh);
		}
	}

	removeDrawings(){
		const lastLine = this.lines[this.lines.length - 1];
		this.scene.remove(lastLine.tube);
		lastLine.tube.geometry.dispose();
		lastLine.tube.material.dispose();
		this.scene.remove(lastLine.startSphere);
		lastLine.startSphere.geometry.dispose();
		lastLine.startSphere.material.dispose();
		this.scene.remove(lastLine.endSphere);
		lastLine.endSphere.geometry.dispose();
		lastLine.endSphere.material.dispose();
		this.lines.pop();
	}

	removeLineMeasure(){
		for(let i=this.lineBoxes.length-1;i>=0;i--){
			if(this.lineBoxes[i].checked){
				const tempBoxMesh = this.lineBoxes[i].boxMesh;
				this.modelGroup.remove(tempBoxMesh);
				tempBoxMesh.geometry.dispose();
				tempBoxMesh.material.dispose();
				this.lineBoxes.splice(i,1);

				const tempLine = this.lines[i];
				this.modelGroup.remove(tempLine.tube);
				tempLine.tube.geometry.dispose();
				tempLine.tube.material.dispose();
				this.modelGroup.remove(tempLine.startSphere);
				tempLine.startSphere.geometry.dispose();
				tempLine.startSphere.material.dispose();
				this.modelGroup.remove(tempLine.endSphere);
				tempLine.endSphere.geometry.dispose();
				tempLine.endSphere.material.dispose();
				this.lines.splice(i,1);
				this.decreaseLineCount();				
			}
		}
	}
}