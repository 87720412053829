class Canvas{
  constructor(ip,port){
    this.ip = ip;
    this.port = port;
    this.infos=[];
    this.crackPointsArray;
    this.classBoxArray;
  }
  imgLoad(url, infos, camera){
    this.infos = infos;

    if(this.originImgResize) {
      this.originImgResize.onload = null; 
    }

    this.originImgResize = new Image();
    this.originImgResize.crossOrigin = "anonymous";
    this.originImgResize.src = url;

    this.originImgResize.onload = ()=>{
      let imgCanvas = document.querySelector(".report_detail_img");
      this.imgCtx = imgCanvas.getContext('2d');

      this.resultImgCanvas = document.querySelector(".report_detail_result_img");
      this.resultImgCtx = this.resultImgCanvas.getContext('2d');

      this.imgCtx.imageSmoothingEnabled = true;
      this.imgCtx.imageSmoothingQuality = 'high';
      this.imgCtx.webkitImageSmoothingEnabled = true;
      this.imgCtx.mozImageSmoothingEnabled = true;
      this.imgCtx.msImageSmoothingEnabled = true;
  
      this.resultImgCtx.imageSmoothingEnabled = true;
      this.resultImgCtx.imageSmoothingQuality = 'high';
      this.resultImgCtx.webkitImageSmoothingEnabled = true;
      this.resultImgCtx.mozImageSmoothingEnabled = true;
      this.resultImgCtx.msImageSmoothingEnabled = true;

      imgCanvas.width = this.originImgResize.width;
      imgCanvas.height = this.originImgResize.height;
      this.imgCtx.drawImage(this.originImgResize, 0, 0, imgCanvas.width, imgCanvas.height);
      this.imgData  = this.imgCtx.getImageData(0 ,0 ,imgCanvas.width, imgCanvas.height);

      this.resultImgCanvas.width = this.originImgResize.width;
      this.resultImgCanvas.height = this.originImgResize.height;
      this.resultImgCtx.drawImage(this.originImgResize, 0, 0, this.resultImgCanvas.width, this.resultImgCanvas.height);

      if(camera){
        let xRatio = this.originImgResize.width/camera.photoWidth;
        let yRatio = this.originImgResize.height/camera.photoHeight;
        this.drawBox(this.resultImgCtx ,xRatio, yRatio);
        this.saveImgData('result');
        this.drawCrackALL(xRatio,yRatio,'result');
        this.setImgData('result');
      }
    };
  }
  editImgLoad(url, infos){
    this.infos = infos;

    if(this.originImg) {
      this.originImg.onload = null; 
    }
    
    this.originImg = new Image();
    this.originImg.crossOrigin = "anonymous";
    this.originImg.src = url;

    this.originImg.onload = ()=>{
      this.editImgCanvas = document.querySelector(".report_editor_img");
      this.editResultImgCanvas = document.querySelector(".report_editor_result_img");
  
      this.editImgCtx = this.editImgCanvas.getContext('2d');
      this.editResultImgCtx = this.editResultImgCanvas.getContext('2d');
  
      this.editImgCtx.imageSmoothingEnabled = true;
      this.editImgCtx.imageSmoothingQuality = 'high';
      this.editImgCtx.webkitImageSmoothingEnabled = true;
      this.editImgCtx.mozImageSmoothingEnabled = true;
      this.editImgCtx.msImageSmoothingEnabled = true;
  
      this.editResultImgCtx.imageSmoothingEnabled = true;
      this.editResultImgCtx.imageSmoothingQuality = 'high';
      this.editResultImgCtx.webkitImageSmoothingEnabled = true;
      this.editResultImgCtx.mozImageSmoothingEnabled = true;
      this.editResultImgCtx.msImageSmoothingEnabled = true;

      this.crackPointsArray = [];
      this.classBoxArray = [];

      this.editImgCanvas.width = this.originImg.width;
      this.editImgCanvas.height = this.originImg.height;
      this.editImgCtx.drawImage(this.originImg, 0, 0, this.editImgCanvas.width, this.editImgCanvas.height);
      this.editImgData = this.editImgCtx.getImageData(0 ,0 ,this.editImgCanvas.width, this.editImgCanvas.height);

      this.editResultImgCanvas.width = this.originImg.width;
      this.editResultImgCanvas.height = this.originImg.height;
      this.editResultImgCtx.drawImage(this.originImg, 0, 0, this.editResultImgCanvas.width, this.editResultImgCanvas.height);
      this.drawBox(this.editResultImgCtx,1,1)
      this.saveImgData('edit');
      this.drawCrackALL(1,1,'edit');
      this.setImgData('edit');
    };
  }
  setImgData(canvas){
    if(canvas == "result"){
      this.resultImgCtx.putImageData(this.resultImgData, 0, 0);
    }
    else if(canvas == "edit"){
      this.editResultImgCtx.putImageData(this.editResultImgData, 0,0);
    }
  }
  saveImgData(canvas){
    if(canvas == "result"){
      this.resultImgData = this.resultImgCtx.getImageData(0 ,0, this.resultImgCanvas.width, this.resultImgCanvas.height);
    }
    else if(canvas == "edit"){
      this.editResultImgData = this.editResultImgCtx.getImageData(0 ,0 ,this.editResultImgCanvas.width, this.editResultImgCanvas.height);
    }
  }
  drawCrack(xRatio,yRatio,canvas,index){
    let xmin = Math.round(this.infos[index].box.xmin * xRatio);
    let ymin = Math.round(this.infos[index].box.ymin * yRatio);

    if(this.infos[index].point){
      for(let i=0; i<this.infos[index].point.points.length; i++){
        let x = Math.round(this.infos[index].point.points[i][0] * xRatio);
        let y = Math.round(this.infos[index].point.points[i][1] * yRatio);
  
        if(canvas == 'result'){
          this.resultImgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4] = 255;
          this.resultImgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4 + 1] = 0;
          this.resultImgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4 + 2] = 0;
          this.resultImgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4 + 3] = 255;
        }
        else if(canvas == 'edit'){
          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4] = 255;
          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 1] = 0;
          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 2] = 0;
          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 3] = 255;
        }
      }
    }
  }
  deleteCrack(xRatio,yRatio,canvas,index){
    let xmin = Math.round(this.infos[index].box.xmin * xRatio);
    let ymin = Math.round(this.infos[index].box.ymin * yRatio);

    if(this.infos[index].point){
      for(let i=0; i<this.infos[index].point.points.length; i++){
        let x = Math.round(this.infos[index].point.points[i][0] * xRatio);
        let y = Math.round(this.infos[index].point.points[i][1] * yRatio);

        if(canvas == 'result'){
          this.resultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4] =
          this.imgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4];

          this.resultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 1] =
          this.imgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4 + 1];

          this.resultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 2] =
          this.imgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4 + 2];

          this.resultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 3] = 
          this.imgData.data[((y+ymin)*this.originImgResize.width + (x+xmin))*4 + 3];
        }
        else if(canvas == 'edit'){
          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4] =
          this.editImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4];

          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 1] =
          this.editImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 1];

          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 2] =
          this.editImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 2];

          this.editResultImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 3] = 
          this.editImgData.data[((y+ymin)*this.originImg.width + (x+xmin))*4 + 3];
        }
      }
    }
  }
  drawCrackALL(xRatio,yRatio,canvas){
    for(let i=0; i<this.infos.length; i++){
      if(this.infos[i].type == "crack"){
        this.drawCrack(xRatio,yRatio,canvas,i)
      }
    }
  }
  deleteCrackAll(xRatio,yRatio,canvas){
    for(let i=0; i<this.infos.length; i++){
      if(this.infos[i].type == 'crack'){
        this.deleteCrack(xRatio,yRatio,canvas,i);
      }
    }
  }
  drawBox(imgCtx, xRatio, yRatio){
    let xmin;
    let ymin;
    let xmax;
    let ymax;
    imgCtx.lineWidth = 7*xRatio;
    imgCtx.font = `${150*yRatio}px Arial`;
    for(let i=0; i<this.infos.length; i++){
      switch (this.infos[i].type) {
        case 'crack':
          imgCtx.fillStyle = '#00FFFF';
          imgCtx.strokeStyle = '#00FFFF';
          break;
        case 'efflorescence':
          imgCtx.fillStyle = '#00FF00';
          imgCtx.strokeStyle = '#00FF00';
          break;
        case 'spalling':
          imgCtx.fillStyle = '#0000FF';
          imgCtx.strokeStyle = '#0000FF';
          break;
        case 'rebar':
          imgCtx.fillStyle = '#FFFF00';
          imgCtx.strokeStyle = '#FFFF00';
          break;
        case 'leakage':
          imgCtx.fillStyle = '#FFA500';
          imgCtx.strokeStyle = '#FFA500';
          break;
        case 'net_crack':
          imgCtx.fillStyle = '#9D00FF';
          imgCtx.strokeStyle = '#9D00FF';
          break;
        case 'etc':
          imgCtx.fillStyle = '#FFC0CB';
          imgCtx.strokeStyle = '#FFC0CB';
          break;
        default:
          break;
      }
      xmin = Math.round(this.infos[i].box.xmin * xRatio);
      ymin = Math.round(this.infos[i].box.ymin * yRatio);
      xmax = Math.round(this.infos[i].box.xmax * xRatio);
      ymax = Math.round(this.infos[i].box.ymax * yRatio);
      imgCtx.strokeRect(xmin, ymin, (xmax - xmin), (ymax - ymin));
      if(ymin < 150*yRatio){
        imgCtx.fillText(i+1, xmin, ymin + 150*yRatio);  
      }
      else{
        imgCtx.fillText(i+1, xmin, ymin-10*yRatio);
      }
    }
  }
  setImageEventLine(){
    this.crackPoints = [];
    this.clickEvent = (e)=>{
      this.editResultImgCtx.lineWidth = 2;
      this.editResultImgCtx.fillStyle = '#FF0000';
      this.editResultImgCtx.strokeStyle = '#FF0000'; 

      let rect = this.editResultImgCanvas.getBoundingClientRect();

      let imgWidth = this.editResultImgCanvas.width;
      let imgHeight = this.editResultImgCanvas.height;

      if(imgWidth/imgHeight > rect.width/rect.height){
        imgHeight = rect.width * (imgHeight/imgWidth);
        imgWidth = rect.width;
      }
      else{
        imgWidth = rect.height * (imgWidth/imgHeight);
        imgHeight = rect.height;
      }

      let imgLeft = rect.left + (rect.width - imgWidth)/2;
      let imgTop = rect.top + (rect.height - imgHeight)/2;

      let x = (e.clientX - imgLeft) * (this.editResultImgCanvas.width/imgWidth);
      let y = (e.clientY - imgTop) * (this.editResultImgCanvas.height/imgHeight);
      
      this.crackPoints.push({x: x, y: y});
  
      this.editResultImgCtx.beginPath();
      this.editResultImgCtx.arc(x, y, 1, 0, Math.PI * 2);
      this.editResultImgCtx.fill();

      if(this.crackPoints.length > 1) {
        this.editResultImgCtx.beginPath();
        this.editResultImgCtx.moveTo(this.crackPoints[this.crackPoints.length-2].x, this.crackPoints[this.crackPoints.length-2].y);
        this.editResultImgCtx.lineTo(this.crackPoints[this.crackPoints.length-1].x, this.crackPoints[this.crackPoints.length-1].y);
        this.editResultImgCtx.stroke();
      }
    }
    this.editResultImgCanvas.addEventListener("click", this.clickEvent);
  }
  setImageEventBox(method){
    this.isDrawingBox = false;
    this.startPoint = null;
    this.endPoint = null;
    this.beforePoint = null;
    this.editResultImgCtx.lineWidth = 4;
    if(method == 'efflorescence') this.editResultImgCtx.strokeStyle = '#00FF00'; 
    else if(method == 'spalling') this.editResultImgCtx.strokeStyle = '#0000FF'; 
    else if(method == 'rebar') this.editResultImgCtx.strokeStyle = '#FFFF00'; 
    else if(method == 'leakage') this.editResultImgCtx.strokeStyle = '#FFA500'; 
    else if(method == 'net_crack') this.editResultImgCtx.strokeStyle = '#9D00FF'; 
    else if(method == 'etc') this.editResultImgCtx.strokeStyle = '#FFC0CB'; 

    this.mousedownEvent = (e) => {
      this.isDrawingBox = true;

      let rect = this.editResultImgCanvas.getBoundingClientRect();

      let imgWidth = this.editResultImgCanvas.width;
      let imgHeight = this.editResultImgCanvas.height;

      if(imgWidth/imgHeight > rect.width/rect.height){
        imgHeight = rect.width * (imgHeight/imgWidth);
        imgWidth = rect.width;
      }
      else{
        imgWidth = rect.height * (imgWidth/imgHeight);
        imgHeight = rect.height;
      }

      let imgLeft = rect.left + (rect.width - imgWidth)/2;
      let imgTop = rect.top + (rect.height - imgHeight)/2;

      let x = (e.clientX - imgLeft) * (this.editResultImgCanvas.width/imgWidth);
      let y = (e.clientY - imgTop) * (this.editResultImgCanvas.height/imgHeight);

      this.startPoint = { x, y };
    }
    this.mousemoveEvent = (e) => {
      if (!this.isDrawingBox) return;
  
      let rect = this.editResultImgCanvas.getBoundingClientRect();
  
      let imgWidth = this.editResultImgCanvas.width;
      let imgHeight = this.editResultImgCanvas.height;
  
      if (imgWidth / imgHeight > rect.width / rect.height) {
        imgHeight = rect.width * (imgHeight / imgWidth);
        imgWidth = rect.width;
      } else {
        imgWidth = rect.height * (imgWidth / imgHeight);
        imgHeight = rect.height;
      }
  
      let imgLeft = rect.left + (rect.width - imgWidth) / 2;
      let imgTop = rect.top + (rect.height - imgHeight) / 2;
  
      let x = (e.clientX - imgLeft) * (this.editResultImgCanvas.width / imgWidth);
      let y = (e.clientY - imgTop) * (this.editResultImgCanvas.height / imgHeight);
  
      this.endPoint = { x, y };
  
      if(this.beforePoint){
        let dirtyX = Math.min(this.beforePoint.startPoint.x, this.beforePoint.endPoint.x) - this.editResultImgCtx.lineWidth;
        let dirtyY = Math.min(this.beforePoint.startPoint.y, this.beforePoint.endPoint.y) - this.editResultImgCtx.lineWidth;
        let dirtyWidth = Math.abs(this.beforePoint.endPoint.x - this.beforePoint.startPoint.x) + 2 * this.editResultImgCtx.lineWidth;
        let dirtyHeight = Math.abs(this.beforePoint.endPoint.y - this.beforePoint.startPoint.y) + 2 * this.editResultImgCtx.lineWidth;
        this.editResultImgCtx.putImageData(this.editResultImgData, 0, 0, dirtyX, dirtyY, dirtyWidth, dirtyHeight);
      }
      this.editResultImgCtx.beginPath();
      this.editResultImgCtx.rect(this.startPoint.x, this.startPoint.y, this.endPoint.x - this.startPoint.x, this.endPoint.y - this.startPoint.y);
      this.editResultImgCtx.stroke();
  
      this.beforePoint = {'startPoint' : this.startPoint, 'endPoint' : this.endPoint };
    }
  
    this.mouseupEvent = () => {
      this.isDrawingBox = false;
    }
  
    this.editResultImgCanvas.addEventListener('pointerdown', this.mousedownEvent);
    this.editResultImgCanvas.addEventListener('pointermove', this.mousemoveEvent);
    this.editResultImgCanvas.addEventListener('pointerup', this.mouseupEvent);
  }
  deleteImageEvent(){
    if(this.clickEvent){
      this.editResultImgCanvas.removeEventListener("click", this.clickEvent);
      this.clickEvent = null;
    }
    if(this.mousedownEvent){
      this.editResultImgCanvas.removeEventListener('pointerdown', this.mousedownEvent);
      this.mousedownEvent = null;
    }
    if(this.mousemoveEvent){
      this.editResultImgCanvas.removeEventListener('pointermove', this.mousemoveEvent);
      this.mousemoveEvent = null;
    }
    if(this.mouseupEvent){
      this.editResultImgCanvas.removeEventListener('pointerup', this.mouseupEvent);
      this.mouseupEvent = null;
    }
  }
  drawLine(thickness){
    this.setImgData('edit');
    this.editResultImgCtx.fillStyle = '#FF0000';
    this.editResultImgCtx.strokeStyle = '#FF0000';
    if (this.crackPoints.length > 1) {
      this.editResultImgCtx.beginPath();
      this.editResultImgCtx.moveTo(this.crackPoints[0].x, this.crackPoints[0].y);
      this.editResultImgCtx.lineWidth = thickness; 
      for (let i = 1; i < this.crackPoints.length; i++) {
        this.editResultImgCtx.lineTo(this.crackPoints[i].x, this.crackPoints[i].y);
      }
      this.editResultImgCtx.stroke();
      for (let i = 0; i < this.crackPoints.length; i++) {
        this.editResultImgCtx.beginPath();
        this.editResultImgCtx.arc(this.crackPoints[i].x, this.crackPoints[i].y, thickness/2, 0, Math.PI * 2); // 원의 반지름을 선의 굵기의 절반으로 설정
        this.editResultImgCtx.fill();
      }
    }
  }
  saveLine(){
    this.saveImgData('edit');
    this.crackPointsArray.push(this.crackPoints);
    this.crackPoints = [];
  }
  saveBox(method){
    this.saveImgData('edit');
    this.beforePoint.type = method;
    this.classBoxArray.push(this.beforePoint);
  }
  deleteDraw(){
    this.editResultImgCtx.putImageData(this.editResultImgData, 0, 0);
  }
  saveJson(indexArray, callback){
    if(indexArray.length != 0){
      let removeArray = indexArray;
      removeArray.sort((a,b) => b-a);
      removeArray.forEach(index => {
        this.infos.splice(index,1);
      });
    }
    if(this.crackPointsArray.length != 0){
      for(let i=0; i<this.crackPointsArray.length; i++){
        this.getBoundingBoxFromPoints(this.crackPointsArray[i]);
      }
    }
    if(this.classBoxArray.length != 0){
      for(let i=0; i<this.classBoxArray.length; i++){
        let xmax;
        let xmin;
        let ymax;
        let ymin;
        if(this.classBoxArray[i].startPoint.x < this.classBoxArray[i].endPoint.x){
          xmax = this.classBoxArray[i].endPoint.x;
          xmin = this.classBoxArray[i].startPoint.x;
        }
        else{
          xmax = this.classBoxArray[i].startPoint.x;
          xmin = this.classBoxArray[i].endPoint.x;
        }
        if(this.classBoxArray[i].startPoint.y < this.classBoxArray[i].endPoint.y){
          ymax = this.classBoxArray[i].endPoint.y;
          ymin = this.classBoxArray[i].startPoint.y;
        }
        else{
          ymax = this.classBoxArray[i].startPoint.y;
          ymin = this.classBoxArray[i].endPoint.y;
        }
        let crack_length = ymax - ymin;
        let crack_width = xmax - xmin;
        let infoJson = {box: {xmax:xmax, xmin:xmin, ymax:ymax, ymin:ymin}, crack_length: crack_length, crack_width:crack_width, type:this.classBoxArray[i].type};
        this.infos.push(infoJson);
      }
    }
    this.infos.sort((a, b) => a.box.ymin - b.box.ymin);
    callback(this.infos);
  }
  getBoundingBoxFromPoints(crackPoints){
    let xmin = Infinity;
    let xmax = -Infinity;
    let ymin = Infinity;
    let ymax = -Infinity;
    for(let i=0; i<crackPoints.length; i++){
      let x = Math.round(crackPoints[i].x);
      let y = Math.round(crackPoints[i].y);
      if(xmin > x){
        xmin = x;
      }
      if(xmax < x){
        xmax = x;
      }
      if(ymin > y){
        ymin = y;
      }
      if(ymax < y){
        ymax = y;
      }
    }
    this.getPixelFromBoundingBox(xmin,ymin,xmax,ymax);
  }
  getPixelFromBoundingBox(xmin,ymin,xmax,ymax) {
    let imageData = this.editResultImgCtx.getImageData(xmin, ymin, xmax-xmin, ymax-ymin);
    let points = [];

    for (let i = 0; i < imageData.data.length; i += 4) {
        const r = imageData.data[i];
        const g = imageData.data[i + 1];
        const b = imageData.data[i + 2];
        const a = imageData.data[i + 3];

        if (r === 255 && g === 0 && b === 0 && a === 255) {
          const x = (i / 4) % imageData.width;
          const y = Math.floor((i / 4) / imageData.width);
          points.push([x,y]);
        }
    }
    let crack_length = Math.max(xmax-xmin,ymax-ymin);
    let crack_width = points.length / crack_length;
    
    let infoJson = {box: {xmax: xmax, xmin:xmin, ymax:ymax, ymin:ymin}, crack_length: crack_length, crack_width:crack_width,
                    point: {points : points}, type:"crack"};
    this.infos.push(infoJson);
  }
}

export default Canvas