<template>
  <div class="home_site_modal">
    <div class="home_modal_x_button_wrap">
      <div class="home_modal_x_button" @click="changeModalState(false)"></div>
    </div>
    <div class="home_modal_edit_thumb_wrap">
      <div class="home_modal_thumb_header">
        <input
          class="home_modal_edit_thumb_header_title input"
          :placeholder="selectedSite.name"
          ref="inputName"
          @input="checkInputLimit($event, 10)"
        />
      </div>
      <label class="home_modal_edit_thumb button" for="home_file_input">
        {{ fileName }}
        <input
          id="home_file_input"
          ref="homeFileInput"
          type="file"
          @change="updateFile($event)"
        />
      </label>
      <div class="home_modal_edit_position_wrap">
        <div class="home_modal_edit_position_wrap_title">위도 :</div>
        <input
          class="home_modal_edit_info_input input"
          :placeholder="selectedSite.lon"
          ref="inputLon"
          @blur="updateSitePosition"
          @input="validateNumber($event, 10)"
        />
      </div>
      <div class="home_modal_edit_position_wrap">
        <div class="home_modal_edit_position_wrap_title">경도 :</div>
        <input
          class="home_modal_edit_info_input input"
          :placeholder="selectedSite.lat"
          ref="inputLat"
          @blur="updateSitePosition"
          @input="validateNumber($event, 10)"
        />
      </div>
      <div class="home_modal_detail_info_title">정보</div>
      <div class="home_modal_edit_detail_info">
        <textarea
          class="home_modal_edit_info_input_text input"
          type="text"
          ref="inputInfo"
          :placeholder="selectedSite.description"
          @input="checkInputLimit($event, 300)"
        ></textarea>
      </div>
    </div>
    <div class="home_modal_edit_button_wrap">
      <div class="home_modal_edit_button button" @click="save">
        <div class="home_modal_button_text">저장</div>
      </div>
      <div class="home_modal_edit_button button" @click="cancel">
        <div class="home_modal_button_text">취소</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  emits: [
    "changeModalState",
    "updateSite",
    "updateSitePosition",
    "uploadStart",
    "changeAlert",
  ],
  computed: {
    ...mapState(["user", "apiIp", "apiPort", "selectedSite"]),
  },
  props: {
    markerPosition: Object,
  },
  data() {
    return {
      fileName: "파일을 선택해주세요.",
      file: null,
    };
  },
  watch: {
    "markerPosition.lat": {
      handler(markerPositionLat) {
        if (markerPositionLat) {
          this.$refs.inputLat.value = markerPositionLat.toFixed(5);
        } else {
          this.$refs.inputLat.value = null;
        }
      },
    },
    "markerPosition.lng": {
      handler(markerPositionLng) {
        if (markerPositionLng) {
          this.$refs.inputLon.value = markerPositionLng.toFixed(5);
        } else {
          this.$refs.inputLon.value = null;
        }
      },
    },
  },
  methods: {
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        this.$emit("changeAlert", `입력 값은 ${limit}자를 초과할 수 없습니다.`);
        event.target.value = event.target.value.substr(0, limit);
      }
    },
    validateNumber(event, limit) {
      let regex = /[^0-9.]/g;
      if (regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(regex, "");
      }
      this.checkInputLimit(event, limit);
    },
    changeModalState(modalState) {
      this.$emit("changeModalState", modalState);
    },
    cancel() {
      this.changeModalState(true);
    },
    updateSitePosition() {
      if (this.$refs.inputLat.value && this.$refs.inputLon.value) {
        let position = {
          lat: parseFloat(this.$refs.inputLat.value),
          lng: parseFloat(this.$refs.inputLon.value),
        };
        this.$emit("updateSitePosition", position);
      }
    },
    save() {
      if (confirm("저장하시겠습니까?")) {
        let newSite = {
          lat: this.$refs.inputLat.value
            ? this.$refs.inputLat.value
            : this.selectedSite.lat,
          lon: this.$refs.inputLon.value
            ? this.$refs.inputLon.value
            : this.selectedSite.lon,
          name: this.$refs.inputName.value
            ? this.$refs.inputName.value
            : this.selectedSite.name,
          thumbnailUrl: this.selectedSite.thumbnailUrl,
          userId: this.user.id,
          description: this.$refs.inputInfo.value
            ? this.$refs.inputInfo.value
            : this.selectedSite.description,
        };
        if (newSite.lat && newSite.lon && newSite.lat && newSite.name) {
          this.$emit("updateSite", newSite, this.file);
        } else {
          this.$emit("changeAlert", "모든 항목을 기입해주세요.");
        }
      } else {
        return false;
      }
    },
    updateFile(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        let validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validImageTypes.includes(file.type)) {
          this.$emit(
            "이미지 파일만 업로드 가능합니다. (예: .jpg, .jpeg, .png)"
          );
          event.target.value = "";
          this.fileName = "파일을 선택해주세요.";
        } else {
          this.file = file;
          this.fileName = event.target.files[0].name;
        }
      } else {
        this.fileName = "파일을 선택해주세요.";
      }
    },
  },
};
</script>

<style lang="scss">
.home_modal_edit_thumb_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  background: rgba(23, 23, 23, 0.85);
  border-radius: 10px;
  padding: 10px;
}
.home_modal_edit_thumb {
  @include flexbox;
  height: 150px;
  border-radius: 10px;
  background-image: url("@/../public/images/cloud_upload.svg");
  background-position: top 30px center;
  background-size: 30px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  overflow: hidden;
  margin-bottom: 5px;
}
.home_modal_edit_thumb:hover {
  background-image: url("@/../public/images/cloud_upload_hover.svg");
  background-position: top 30px center;
  background-size: 30px 30px;
}
.home_modal_edit_thumb_header_title {
  @include flexbox;
  width: 100px;
  height: 25px;
  font-size: 1.2rem;
  font-weight: bold;
}
.home_modal_edit_position_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 5px 0px 0px 10px;
}
.home_modal_edit_position_wrap_title {
  display: flex;
  align-items: center;
  width: 50px;
  height: 25px;
  color: $main_white;
  font-size: 1.3rem;
}
.home_modal_edit_info_input {
  @include flexbox;
  width: 50%;
  height: 25px;
  font-size: 1.2rem;
  font-weight: bold;
}
.home_modal_edit_info_input::placeholder {
  color: gray;
}
.home_modal_edit_detail_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 285px);
  padding: 0px 10px 10px 10px;
  overflow: auto;
}
.home_modal_edit_info_input_text {
  @include flexbox;
  width: 100%;
  height: calc(100% - 5px);
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5px;
  resize: none;
  padding: 0px 5px 0px 5px;
  overflow: hidden;
}
.home_modal_detail_info_input_text::-webkit-scrollbar {
  width: 4px;
}
.home_modal_detail_info_input_text::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
.home_modal_detail_info_input_text::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.home_modal_edit_button_wrap {
  @include flexbox;
  width: 100%;
  height: 35px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
}
.home_modal_edit_button {
  display: flex;
  align-items: center;
  background: #171717;
  justify-content: center;
  width: 100px;
  height: 30px;
  margin-right: 10px;
}
.home_modal_edit_button:last-child {
  margin-right: 0px;
}
#home_file_input {
  display: none;
}
.home_modal_button_text {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
