/**
 * 특정 영역의 바깥을 클릭했을 때 특정 요소를 닫아준다.
 * @param {*} element - 제외할 영역 (ex: 헤더 바깥 클릭 닫기 라면? this.$refs.headerMenuButton)
 * @param {*} callback - 바깥 영역 클릭했을 때 동작할 기능 (ex: this.isMenuShow = false;)
 * @returns
 */

export function addOutsideClickHandler(element, callback) {
  // 이벤트 핸들러 정의
  function handleClickOutside(event) {
    if (element && !element.contains(event.target)) {
      callback();
    }
  }

  // 문서에 이벤트 리스너 추가
  document.addEventListener("click", handleClickOutside);

  // 제거 함수 반환
  return () => {
    document.removeEventListener("click", handleClickOutside);
  };
}
