<template>
  <header>
    <div class="header_wrap">
      <h1>
        <router-link :to="'/'">
          <img src="@/../public/images/header-logo.png" alt="메인 홈으로"
        /></router-link>
      </h1>
      <!-- 로그아웃/사용자 관리/내 정보 수정 컴포넌트 -->
      <HeaderUserBox />
    </div>
  </header>
  <main>
    <slot></slot>
  </main>
</template>
<script>
import { mapState } from "vuex";
import HeaderUserBox from "./component/HeaderUserBox.vue";

export default {
  name: "DefaultLayout",
  components: {
    HeaderUserBox,
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/component/header";

main {
  min-width: 100vw;
  min-height: 100vh;
}
</style>
