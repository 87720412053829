export class Element {
    constructor(id, elevationSetId, degree, name, maxColumnCount, maxRowCount, createdDatetime, wrDatetime) {
      this.id = id;
      this.elevationSetId = elevationSetId;
      this.degree = degree;
      this.name = name;
      this.maxColumnCount = maxColumnCount;
      this.maxRowCount = maxRowCount;
      this.createdDatetime = createdDatetime;
      this.wrDatetime = wrDatetime;
    }
}

export default class ElevationSet{
    constructor(elevationSet){
        this.id = null;
        this.roiPartId = null;
        this.elevationSetUri = null;
        this.analysisUri = null;
        this.createdDatetime = null;
        this.wrDatetime = null;
        this.elements = null;
  
      if(elevationSet){
        this.id = elevationSet.id;
        this.roiPartId = elevationSet.roiPartId;
        this.elevationSetUri = elevationSet.elevationSetUri;
        this.analysisUri = elevationSet.analysisUri;
        this.createdDatetime = elevationSet.createdDatetime;
        this.wrDatetime = elevationSet.wrDatetime;
        this.elements = elevationSet.elements.map(element => new Element(
            element.id, 
            element.elevationSetId,
            element.degree, 
            element.name,
            element.maxColumnCount, 
            element.maxRowCount, 
            element.createdDatetime, 
            element.wrDatetime
          ));
       }
    }
}