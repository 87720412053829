import {format} from 'date-fns'

export default class Analysis{
  constructor(analysis){
    this.id = null;
    this.albumId = null;
    this.name = null; 
    this.type = null;
    this.typeName = null;
    this.status = null;
    this.createdDatetime = null ;

    if(analysis){
      this.id = analysis.id;
      this.albumId = analysis.albumId;
      this.name = analysis.name; 
      this.type = analysis.type;
      this.typeName = analysis.typeName;
      this.status = analysis.status;
      this.createdDatetime = format(analysis.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    }
  }
}