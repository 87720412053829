<template>
  <component :is="$route.meta.layout || 'main'">
    <div id="home_page">
      <SummaryList :totalFacility="total" :facilities="facilities" />
      <FacilityListManager
        v-if="isReady"
        @handleOpenAddForm="handleOpenAddForm"
      >
        <DefaultSearchInput
          ref="searchEl"
          @handleKeywordSearch="handleKeywordSearch($event)"
          :placeHolder="'시설물 이름을 검색하세요.'"
        />
        <FacilityFilterGroup
          :clickedRegionValue="clickedRegionValue"
          :clickedTypeValue="clickedTypeValue"
          :searchKeyword="searchKeyword"
          @handleClickTypeOption="handleClickTypeOption($event)"
          @handleClickRegionOption="handleClickRegionOption($event)"
          @handleClearFilter="handleClearFilter"
        />
        <FacilitySorting
          :clickedRecentValue="clickedRecentValue"
          @handleSortArray="handleSortArray($event)"
        />
        <template v-if="filteredFacilities.length > 0">
          <FacilityList
            :facilities="filteredFacilities"
            :currentPage="currentPage"
            @handleClickFacility="handleClickFacility($event)"
            @handleRemoveFacility="handleRemoveFacility($event)"
            @handleOpenEditForm="handleOpenEditForm"
          />
          <Pagination
            class="pagination_margin"
            :totalCount="filteredTotal"
            @handleClickPaginationNumber="handleClickPaginationNumber($event)"
            :initialPageOnMount="currentPage"
            :itemsPerPage="itemsPerPage"
          />
        </template>
        <template v-else>
          <NoSearchResult />
        </template>
      </FacilityListManager>
      <NoFacilitiesMessage
        v-else
        :totalFacility="total"
        @handleOpenAddForm="handleOpenAddForm"
      />
      <!-- 라우팅 모달 / 시설 추가, 수정 모달 -->
      <CustomModalFrame v-if="isModalOpen" @closeModal="handleCloseCustomFrame">
        <RouteModalContent
          v-if="modalName === 'route'"
          @handleMappingRoute="handleMappingRoute($event, mappingType)"
          @handleRouteInspection="handleRouteInspection"
          @handleSaveMapInfo="handleSaveMapInfo($event)"
          @handleRouteReport="handleRouteReport"
        />
        <FacilityFormContent
          v-if="modalName === 'formModal'"
          :mode="modalMode"
          :userListArray="userListArray"
          @handleCloseCustomFrame="handleCloseCustomFrame"
          @handleAddFacility="handleAddFacility($event)"
          @handleEditFacility="handleEditFacility($event)"
        />
      </CustomModalFrame>
    </div>
  </component>
</template>
<script>
import { mapState } from "vuex";

import * as RESTAPI from "@/shared/communication/RESTAPI";

import FacilityListManager from "@/page/home/component/FacilityListManager.vue";
import SummaryList from "@/page/home/component/SummaryList.vue";
import CustomModalFrame from "@/component/modal/custom/CustomModalFrame.vue";
import FacilityFormContent from "@/page/home/component/FacilityFormContent.vue";
import RouteModalContent from "./component/RouteModalContent.vue";
import LoadingSpinner from "@/component/loading/LoadingSpinner.vue";
import FacilityList from "@/page/home/component/FacilityList.vue";
import FacilitySorting from "./component/FacilitySorting.vue";
import FacilityFilterGroup from "./component/FacilityFilterGroup.vue";
import NoFacilitiesMessage from "./component/NoFacilitiesMessage.vue";
import NoSearchResult from "@/component/no-result/NoSearchResult.vue";
import Pagination from "@/component/pagination/Pagination.vue";
import { paginateArray } from "@/shared/util/paginateArray";
import DefaultSearchInput from "@/component/input/DefaultSearchInput.vue";
import { countByFacilityId } from "@/shared/util/countByFacilityId";

export default {
  name: "HomePage",
  computed: {
    ...mapState(["user", "selectedSite", "selectedFacilityMap", "api"]),
  },
  components: {
    SummaryList,
    FacilityListManager,
    FacilityList,
    FacilityFilterGroup,
    FacilitySorting,
    CustomModalFrame,
    RouteModalContent,
    FacilityFormContent,
    LoadingSpinner,
    NoFacilitiesMessage,
    NoSearchResult,
    Pagination,
    DefaultSearchInput,
  },
  data() {
    return {
      isReady: true,
      total: 0,
      filteredTotal: 0,
      facilities: [],
      filteredFacilities: [],
      userListArray: [],
      isModalOpen: false,
      modalName: "",
      modalMode: "",
      clickedRegionValue: null,
      clickedTypeValue: null,
      clickedRecentValue: "최신순",
      searchKeyword: "",
      selectedMap: null,
      itemsPerPage: 6,
      currentPage: 1,
    };
  },
  methods: {
    /**
     * 내 유저 정보 업데이트
     */
    async setMyInfo() {
      // 수정한 시설물의 담당자 정보가 변경될 수 있으니, 내 정보도 같이 업데이트 해준다.
      let userData = await RESTAPI.getUser(this.user.id);
      let userDataResult = userData.result;
      console.log(userDataResult);
      this.$store.commit("setUser", { newValue: userDataResult });
      sessionStorage.setItem("user", JSON.stringify(userDataResult));
    },
    /**
     * 유저 리스트 가져오기
     */
    async setUserList() {
      const data = await RESTAPI.getUserList(this.user.affiliation.id);

      try {
        if (data.success) {
          this.isReady = true;

          this.userListArray = data.result;

          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 시실물 리스트 가져오기
     */
    async setFacilityList() {
      this.$store.commit("setIsLoading", true);
      // 유저 리스트 불러오기
      this.setUserList();

      try {
        const data = await RESTAPI.getFacilitiesList(this.user.id);

        if (data?.success) {
          // 받아온 시설물 등록
          const facilityData = data.result;

          /**
           * 담당자 이름 userId와 비교해서 뽑아오기
           */

          // facilityList 를 for문 돌려서 userId와 user List의 id와 비교해서 일치하는 사람 이름을 출력한다.
          const userDataResult = this.userListArray;
          facilityData.forEach((facility) => {
            const matchedUser = userDataResult.find(
              (user) => user.id === facility.userId
            );
            facility.fullName = matchedUser ? matchedUser.fullName : null;
          });

          /**
           * 맵 상태 뽑아오기 : 각각의 시설물 마다 map이 있는지 없는지 확인해서 넣어줌.
           */

          for (const facility of facilityData) {
            const mapResult = await RESTAPI.getMaps(facility.id);
            facility.maps = mapResult.data.success
              ? mapResult.data.result
              : null;
          }

          /**
           * albums 뽑기 : 각각의 시설물 마다 albums 있는지 없는지 확인해서 넣어줌. 없으면 albums: null
           */

          const albumResult = await RESTAPI.getAlbums(25, 70);

          if (albumResult) {
            const albumCount = countByFacilityId(albumResult.data.result);
            this.albumCount = albumCount;

            facilityData.forEach((facility) => {
              const matchingAlbum = albumCount.find(
                (album) => album.facilityId === facility.id
              );
              facility.albums = matchingAlbum
                ? { count: matchingAlbum.count, data: matchingAlbum.albums }
                : null;
            });
          }

          /**
           * TODO : 분석 상태 뽑아오기
           */
          for (const facility of facilityData) {
            if (facility.albums) {
              const albumData = facility.albums.data;

              for (const album of albumData) {
                const analysesResult = await RESTAPI.getAnalysesList(album.id);

                if (analysesResult.data.success) {
                  facility.albums.albumsInfo = analysesResult.data.result;
                  break;
                } else {
                  facility.albums.albumsInfo = null;
                }
              }
            }
          }

          this.facilities = facilityData;
          this.filteredFacilities = paginateArray(
            facilityData,
            this.itemsPerPage
          );
          this.$store.commit("setSiteList", this.facilities);
          this.setFacilityType();

          console.log(this.facilities);

          return;
        }
        this.isReady = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 시설물 종류에 맞게 분류
     */
    // 분류하여 카운트하는 함수
    countFacilitiesByType(facilities) {
      let result = [
        { type: "교량", count: 0 },
        { type: "건물", count: 0 },
        { type: "사면", count: 0 },
        { type: "터널", count: 0 },
        { type: "기타", count: 0 },
      ];

      // 각 항목을 순회하면서 typeName에 맞게 카운트
      facilities.forEach((facility) => {
        const type = facility.typeName;

        // 배열에서 type에 해당하는 객체를 찾아 count를 증가시킴
        let found = result.find((item) => item.type === type);
        if (found) {
          found.count++;
        } else {
          // 해당 type이 없다면 '기타'에 추가
          result.find((item) => item.type === "기타").count++;
        }
      });

      return result;
    },
    setFacilityType() {
      this.total = this.facilities.length;
      this.filteredTotal = this.total;

      this.$store.commit(
        "setFacilityTypeCounts",
        this.countFacilitiesByType(this.facilities)
      );
    },
    /**
     * 시설물 클릭 동작
     */
    handleClickFacility(facility) {
      this.$store.commit("setSelectedFacility", facility);
      this.$store.commit("setSelectedSite", facility);
      if (facility.maps !== null && facility.maps[0].url !== "") {
        this.$store.commit("setSelectedFacilityMap", facility.maps[0]);
      }
      this.isModalOpen = true;
      this.modalName = "route";
    },
    /**
     * 시설물 클릭시 뜨는 라우팅 모달 닫기
     */
    handleCloseCustomFrame() {
      this.isModalOpen = false;
      this.modalName = "";
      this.modalMode = "";
      this.$store.commit("setSelectedFacility", null);
      this.$store.commit("setSelectedSite", null);
    },
    /**
     * 시설물 추가(모달) 버튼 동작
     */
    async handleOpenAddForm() {
      this.isModalOpen = true;
      this.modalName = "formModal";
      this.modalMode = "add";
    },
    /**
     * 시설물 수정(모달) 버튼 동작
     */
    async handleOpenEditForm() {
      this.isModalOpen = true;
      this.modalName = "formModal";
      this.modalMode = "edit";
    },
    /**
     * 시설물 추가(생성) 버튼 동작
     */
    async handleAddFacility(data) {
      this.$store.commit("setIsLoading", true);

      const addResult = await RESTAPI.postFacility(data);

      if (addResult?.success === true) {
        this.currentPage = 1;
        this.$store.commit("setIsLoading", false);
        this.$store.commit("setSelectedFacility", null);
        this.$store.commit("setSelectedSite", null);
        this.handleCloseCustomFrame();
        // this.setFacilityList();

        // userList 를 맵 돌려서 result id값과 일치하는 이름을 반환하자.

        const matchedUser = this.userListArray.find(
          (user) => user.id === addResult.result.userId
        );

        let finalResult = addResult.result;
        finalResult.maps = null;
        finalResult.albums = null;
        finalResult.fullName = matchedUser.fullName;

        this.facilities = [finalResult, ...this.facilities];
        this.filteredFacilities = paginateArray(
          this.facilities,
          this.itemsPerPage
        );

        this.$store.commit(
          "setFacilityTypeCounts",
          this.countFacilitiesByType(this.facilities)
        );
        this.total = this.facilities.length;
        this.filteredTotal = this.total;

        this.handleClearFilter();

        // 내 정보 업데이트
        this.setMyInfo();

        return;
      } else {
        this.$store.commit("setIsLoading", false);
        return this.$store.commit(
          "openAlert",
          `시설물 추가에 실패했습니다. 다시 시도해 주세요.`
        );
      }
    },
    /**
     * 시설물 검색 기능
     */
    handleKeywordSearch(keyword) {
      // this.filteredFacilities = this.facilities.filter((facility) => {
      //   if (facility.name.toLowerCase().includes(keyword)) {
      //     return facility;
      //   }
      // });
      this.searchKeyword = keyword;
      this.handleFilterArray();
    },
    /**
     * 시설물 삭제 기능
     */
    async handleRemoveFacility(removeFacility) {
      const isConfirmed = confirm(
        `${removeFacility.facilityName}을(를) 삭제하시겠습니까?`
      );

      if (isConfirmed) {
        this.$store.commit("setIsLoading", true);
        const data = await RESTAPI.deleteFacility(removeFacility.facilityId);

        if (data.success) {
          // 내 정보 업데이트
          this.setMyInfo();

          this.$store.commit("setIsLoading", false);

          const deleteFacility = this.facilities.find(
            (facility) => facility.id === removeFacility.facilityId
          );

          if (deleteFacility) {
            // 삭제할 시설물이 배열에 존재하는 경우, 삭제시켜준다.
            const newFacilities = this.facilities.filter(
              (facility) => facility.id !== removeFacility.facilityId
            );
            this.facilities = newFacilities;
            this.filteredFacilities = paginateArray(
              newFacilities,
              this.itemsPerPage
            );
            this.total = newFacilities.length;
            this.filteredTotal = newFacilities.length;
            this.currentPage = 1;

            this.$store.commit(
              "setFacilityTypeCounts",
              this.countFacilitiesByType(newFacilities)
            );
            return;
          }
        }

        this.$store.commit("setIsLoading", false);
        this.total = this.facilities.length;
        this.filteredTotal = this.facilities.length;
        this.$store.commit(
          "openAlert",
          `시설물 삭제에 실패하였습니다. <br>새로고침하고 다시 시도해 주세요.`
        );
        return;
      }
    },
    /**
     * 시설물 수정 기능
     */
    async handleEditFacility(data) {
      let facilityId = this.selectedSite.id;

      this.$store.commit("setIsLoading", true);
      const editResult = await RESTAPI.putFacility(facilityId, data);

      const editResultData = editResult.data;

      if (editResultData?.success === true) {
        this.$store.commit("setIsLoading", false);
        this.$store.commit("setSelectedFacility", null);
        this.$store.commit("setSelectedSite", null);
        this.handleCloseCustomFrame();
        this.setFacilityList();
        this.currentPage = 1;
        this.clickedRecentValue = "최신순";

        // 내 정보 업데이트
        this.setMyInfo();

        return;
      } else {
        this.$store.commit("setIsLoading", false);
        return this.$store.commit(
          "openAlert",
          `시설물 수정에 실패했습니다. 다시 시도해 주세요.`
        );
      }
    },
    /**
     * 시설물 소팅 기능
     */
    handleSortArray(value) {
      this.currentPage = 1;
      if (value === "최신순") {
        this.clickedRecentValue = "최신순";

        // 평면 배열로 변환
        const flattenedArray = this.filteredFacilities.flat();

        // 최신순으로 정렬
        const desc = flattenedArray.sort((a, b) => {
          const dateA = new Date(a.createdDatetime);
          const dateB = new Date(b.createdDatetime);
          return dateB - dateA; // 최신순
        });

        // 다시 페이지 단위로 나눔
        this.filteredFacilities = paginateArray(desc, this.itemsPerPage);
      } else if (value === "오래된순") {
        this.clickedRecentValue = "오래된순";

        // 평면 배열로 변환
        const flattenedArray = this.filteredFacilities.flat();

        // 오래된 순으로 정렬
        const asc = flattenedArray.sort((a, b) => {
          const dateA = new Date(a.createdDatetime);
          const dateB = new Date(b.createdDatetime);
          return dateA - dateB; // 오래된 순
        });

        // 다시 페이지 단위로 나눔
        this.filteredFacilities = paginateArray(asc, this.itemsPerPage);
      }
    },

    /**
     * 시설물 전체 필터링/소팅 기능
     * ! 필터 값 + input 값 + 소팅 값
     */
    handleFilterArray() {
      this.currentPage = 1;
      this.clickedRecentValue = "최신순";
      const keyword = this.searchKeyword
        ? this.searchKeyword.toLowerCase()
        : ""; // 검색어가 빈 문자열이면 빈 문자열 유지
      const typeName = this.clickedTypeValue
        ? this.clickedTypeValue.name
        : null; // typeName이 null일 수 있음
      const regionName = this.clickedRegionValue
        ? this.clickedRegionValue.name
        : null; // regionName이 null일 수 있음

      // 원본 데이터를 사용하여 필터링 (this.facilities)
      const filteredArray = this.facilities.filter((facility) => {
        // 조건이 null이거나 빈 문자열이면 해당 조건을 무시
        const matchesName = keyword
          ? facility.name && facility.name.toLowerCase().includes(keyword)
          : true; // keyword가 비어있으면 무시
        const matchesType = typeName ? facility.typeName === typeName : true; // typeName이 null이면 무시
        const matchesRegion = regionName
          ? facility.location && facility.location.includes(regionName)
          : true; // regionName이 null이면 무시

        // 모든 조건 설정 되어야하지만, null이나 빈 문자열은 무시함
        return matchesName && matchesType && matchesRegion;
      });

      // 필터된 배열을 잘라서 this.filteredFacilities에 넣어줌
      this.filteredFacilities = paginateArray(filteredArray, this.itemsPerPage);
      this.filteredTotal = filteredArray.length;
    },

    /**
     * 시설물 타입 옵션 선택 기능
     */
    handleClickTypeOption(value) {
      this.clickedTypeValue = value;
      this.handleFilterArray();
    },
    /**
     * 시설물 지역 옵션 선택 기능
     */
    handleClickRegionOption(value) {
      this.clickedRegionValue = value;
      this.handleFilterArray();
    },
    /**
     * 시설물 필터링 초기화
     */
    handleClearFilter() {
      this.searchKeyword = "";
      this.clickedTypeValue = null;
      this.clickedRegionValue = null;
      this.clickedRecentValue = "최신순";
      this.filteredFacilities = paginateArray(
        this.facilities,
        this.itemsPerPage
      );
      this.total = this.facilities.length;
      this.filteredTotal = this.facilities.length;

      const searchArea = this.$refs.searchEl.$el;
      const specificInput = searchArea.querySelector("#searchInput");
      if (specificInput) {
        specificInput.value = "";
      }
    },
    /**
     * 매핑 라우트 클릭
     */
    async handleMappingRoute(mappingType) {
      const facilityId = this.selectedSite.id;

      try {
        this.$store.commit("setIsLoading", true);
        if (mappingType === "first") {
          // 아예 매핑 생성 자체가 된 적 없는 상태일 때
          const map = await RESTAPI.postMap(facilityId);
          const result = map.data.result;
          this.$router.push({
            name: "mapping",
            params: { siteId: facilityId, mapId: result.id },
          });
        } else if (mappingType === "exist" || mappingType === "new") {
          let emptyMap = this.selectedSite.maps.find((map) => map.url == "");

          if (emptyMap) {
            this.$router.push({
              name: "mapping",
              params: {
                siteId: facilityId,
                mapId: emptyMap.id,
              },
            });
          } else {
            const map = await RESTAPI.postMap(facilityId);
            const result = map.data.result;
            this.$router.push({
              name: "mapping",
              params: { siteId: facilityId, mapId: result.id },
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 선택한 맵 정보 저장하는 기능
     */
    handleSaveMapInfo(map) {
      this.selectedMap = map;
      this.$store.commit("setSelectedFacilityMap", map);
    },
    /**
     * 라우트에서 선택한 맵 id를 저장해서 인스펙션 페이지로 이동 시키기
     */
    handleRouteInspection() {
      if (this.selectedFacilityMap === null) {
        return this.$store.commit("openAlert", `맵을 선택해 주세요.`);
      }
      this.$router.push({
        name: "inspection",
        params: {
          siteId: this.selectedSite.id,
          mapId: this.selectedFacilityMap.id,
        },
      });
    },
    /**
     * 라우트에서 레포트 페이지로 이동 시키기
     */
    handleRouteReport() {
      this.$router.push({
        name: "report",
        params: { siteId: this.selectedSite.id },
      });
    },
    /**
     * 페이지 네이션 숫자 클릭 동작
     */
    handleClickPaginationNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  mounted() {
    this.setFacilityList();
    this.api.putUser({ taskStatus: "waiting" }, this.user.id);
  },
};
</script>
<style lang="scss" scoped>
#home_page {
  padding-top: 72px;
  padding-bottom: 80px;
  background: $main_black;
  min-height: 100vh;

  section {
    @include maxInner(1280px);
    color: $main_white;
    margin-bottom: 80px;
  }

  .pagination_margin {
    margin-top: 50px;
  }
}
</style>
