<template>
  <div v-if="uploadState" class="report_upload_modal">
    <div class="report_upload_modal_header">앨범 업로드</div>
    <div class="report_upload_modal_x" @click="closeUploadModal"></div>
    <div class="report_upload_modal_content_wrap">
      <div class="report_upload_modal_content">
        <input
          class="report_upload_modal_file_name input"
          placeholder="앨범 이름을 입력해주세요."
          ref="album_name"
          @input="checkInputLimit($event, 10)"
        />
      </div>
      <div class="report_upload_modal_content">
        <label class="report_upload_modal_input button" for="report_file_input">
          파일 선택
          <input
            id="report_file_input"
            ref="reportFileInput"
            type="file"
            @change="updateFile($event)"
          />
        </label>
        <div class="report_upload_modal_file_name">
          {{ fileName }}
          <div class="report_upload_modal_gage" ref="reportUploadGage"></div>
        </div>
        <div class="report_upload_modal_button button" @click="clickUpload">
          전송
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISO } from "date-fns";
import { mapState } from "vuex";

export default {
  emits: ["closeUploadModal", "changeAlert", "clickUpload", "uploadAlbum"],
  props: {
    uploadState: Boolean,
    volume: Object,
  },
  computed: {
    ...mapState(["selectedSite"]),
  },
  data() {
    return {
      fileName: "파일을 선택해주세요.",
      file: null,
    };
  },
  methods: {
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        this.$emit("changeAlert", `입력 값은 ${limit}자를 초과할 수 없습니다.`);
        event.target.value = event.target.value.substr(0, limit);
      }
    },
    closeUploadModal(){
      this.$emit("closeUploadModal");
      this.fileName = "파일을 선택해주세요.";
      this.file = null;
      this.$refs.reportFileInput.value = null;
    },
    updateFile(event) {
      if (event.target.files.length > 0) {
        let allowedExtensions = ["zip", "tgz", "tar"];
        if (
          !allowedExtensions.includes(
            event.target.files[0].name.split(".").pop().toLowerCase()
          )
        ) {
          this.$emit(
            "changeAlert",
            "올바른 파일 형식이 아닙니다. zip, tgz 또는 tar 파일을 선택해주세요."
          );
          this.$refs.reportFileInput.value = null;
        }
        else{
          this.fileName = event.target.files[0].name;
          this.file = event.target.files[0];
        }
      } else {
        this.fileName = "파일을 선택해주세요.";
      }
    },
    clickUpload() {
      if (this.file && this.$refs.album_name.value) {
        let body = {
          facilityId: this.$route.params.siteId,
          facilityMapId: this.selectedSite.map.id,
          cameraId: 0,
          name: this.$refs.album_name.value,
          photoCount: 0,
          createdDatetime: formatISO(new Date()),
        };
        this.$emit("clickUpload", body, this.file);
      } else if (!this.file) {
        this.$emit("changeAlert", "파일을 선택해주세요.");
      } else if (!this.$refs.album_name.value) {
        this.$emit("changeAlert", "앨범의 이름을 작성해주세요.");
      }
    },
    // postAlbumsCallback(result) {
    //   let formData = new FormData();
    //   formData.append("file", this.file);
    //   this.$axios
    //     .post(
    //       `http://1.222.89.166:20020/api/facilities/${this.$route.params.siteId}/albums/${result.id}`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //         onUploadProgress: (progressEvent) => {
    //           var percentComplete = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           if (this.$refs.reportUploadGage) {
    //             this.$refs.reportUploadGage.style.width = percentComplete + "%";
    //           }
    //           if (percentComplete === 100)
    //             this.$refs.reportUploadGage.style.width = "0%";
    //         },
    //       }
    //     )
    //     .then((data) => {
    //       this.$refs.reportFileInput.value = null;
    //       this.$emit("uploadAlbum", result.id, data.data.result);
    //       this.closeUploadModal();
    //     })
    //     .catch();
    // },
      postAlbumsCallback(result) {
      let formData = new FormData();
      formData.append("file", this.file);
      this.$emit("uploadAlbum", result.id, formData, this.$refs.reportUploadGage);

      // this.$axios.post(
      //     `http://:20020/api/facilities/${this.$route.params.siteId}/albums/${result.id}`,
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //       onUploadProgress: (progressEvent) => {
      //         var percentComplete = Math.round(
      //           (progressEvent.loaded * 100) / progressEvent.total
      //         );
      //         if (this.$refs.reportUploadGage) {
      //           this.$refs.reportUploadGage.style.width = percentComplete + "%";
      //         }
      //         if (percentComplete === 100)
      //           this.$refs.reportUploadGage.style.width = "0%";
      //       },
      //     }
      //   )
      //   .then((data) => {
      //     this.$refs.reportFileInput.value = null;
      //     this.$emit("uploadAlbum", result.id, data.data.result);
      //     this.closeUploadModal();
      //   })
      //   .catch();
    },
  },
};
</script>

<style lang="scss">
.report_upload_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: calc(50% - 200px);
  top: calc(50% - 45px);
  width: 400px;
  height: 140px;
  z-index: 10;
  background: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
}
.report_upload_modal_header {
  @include flexbox;
  color: $main_white;
  font-size: 2rem;
  background-color: rgba(32, 32, 32, 0.85);
  width: 100%;
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.report_upload_modal_x {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
  cursor: pointer;
}
.report_upload_modal_content_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100px;
}
.report_upload_modal_content {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 50px;
  padding: 0px 20px 0px 20px;
}
.report_upload_modal_input {
  @include flexbox;
  width: 60px;
  height: 30px;
  font-size: 1.2rem;
}
#report_file_input {
  display: none;
}
.report_upload_modal_file_name {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 130px);
  height: 30px;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  color: $main_white;
  font-size: 1rem;
  padding-left: 10px;
  overflow: hidden;
}
.report_upload_modal_gage {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0%;
  height: 30px;
  background-color: blue;
}

.report_upload_modal_button {
  @include flexbox;
  width: 50px;
  height: 30px;
  font-size: 1.4rem;
}
</style>
