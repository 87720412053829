export function repaintHeightBaseColor(modelObject, minHeight, maxHeight){
  modelObject.traverse((child) => {
    if (child.isMesh && child.material) {				
      const heightMaterial = child.material;			
        
      if (!heightMaterial.userData.uniforms) {
        heightMaterial.userData.uniforms = {
          customModelMatrix: { value: child.matrixWorld }
        };

        heightMaterial.onBeforeCompile = (shader) => {
          shader.uniforms.customModelMatrix = heightMaterial.userData.uniforms.customModelMatrix;

          shader.vertexShader = shader.vertexShader.replace(
            '#include <common>',
            `
            #include <common>
            uniform mat4 customModelMatrix;
            out vec3 vWorldPosition;
            `
          );

          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
            #include <begin_vertex>
            vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <common>',
            `
            #include <common>
            in vec3 vWorldPosition;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <dithering_fragment>',
            `
            vec3 colors[7];
            colors[0] = vec3(0.65, 0.4, 0.65); // 진한 파스텔 보라색
            colors[1] = vec3(0.65, 0.45, 0.65); // 진한 파스텔 남색
            colors[2] = vec3(0.4, 0.4, 0.9);   // 진한 파스텔 파란색
            colors[3] = vec3(0.4, 0.8, 0.4);   // 진한 파스텔 초록색
            colors[4] = vec3(0.8, 0.8, 0.4);   // 진한 파스텔 노란색
            colors[5] = vec3(0.8, 0.6, 0.4);   // 진한 파스텔 주황색
            colors[6] = vec3(0.8, 0.4, 0.4);   // 진한 파스텔 빨간색
            
            float heightFactor = (vWorldPosition.y - ${minHeight.toFixed(10)}) / (${maxHeight.toFixed(10)} - ${minHeight.toFixed(10)});

            float section = heightFactor * 6.0;
            int idx = int(section);
            float fract = section - float(idx);

            vec3 heightColor;
            if (idx < 6) {
                heightColor = mix(colors[idx], colors[idx + 1], fract);
            } else {
                heightColor = colors[6];
            }

            gl_FragColor = vec4(heightColor, 1.0);
            #include <dithering_fragment>
            `
          );
        };				
        heightMaterial.needsUpdate = true;
      }
      else{
        heightMaterial.userData.uniforms.customModelMatrix.value = child.matrixWorld;
      }
    }
  });
}

export function repaintVolumeColor(modelObject, 
                                   minHeight, rangeMinHeight, rangeMaxHeight,
                                   minWidth, rangeMinWidth, rangeMaxWidth,
                                   maxDepth, rangeMinDepth, rangeMaxDepth){
  modelObject.traverse((child) => {
    if (child.isMesh && child.material) {
      const material = child.material;

      if (!material.userData.uniforms) {
        material.userData.uniforms = {
          minHeight: { value: minHeight + rangeMinHeight },
          maxHeight: { value: minHeight + rangeMaxHeight },
          minWidth:  { value: minWidth  + rangeMinWidth },
          maxWidth:  { value: minWidth  + rangeMaxWidth },						
          minDepth:  { value: maxDepth  - rangeMaxDepth },
          maxDepth:  { value: maxDepth  - rangeMinDepth },
          customModelMatrix: { value: child.matrixWorld }
        };


        material.onBeforeCompile = (shader) => {
          shader.uniforms.minHeight = material.userData.uniforms.minHeight;
          shader.uniforms.maxHeight = material.userData.uniforms.maxHeight;
          shader.uniforms.minWidth  = material.userData.uniforms.minWidth;
          shader.uniforms.maxWidth  = material.userData.uniforms.maxWidth;
          shader.uniforms.minDepth  = material.userData.uniforms.minDepth;
          shader.uniforms.maxDepth  = material.userData.uniforms.maxDepth;
          shader.uniforms.customModelMatrix = material.userData.uniforms.customModelMatrix;

          shader.vertexShader = shader.vertexShader.replace(
            '#include <common>',
            `
            #include <common>
            uniform mat4 customModelMatrix;
            out vec3 vWorldPosition;
            `
          );

          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
            #include <begin_vertex>
            vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            `
          );
  
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <common>',
            `
            #include <common>
            in vec3 vWorldPosition;
            uniform float minHeight;
            uniform float maxHeight;
            uniform float minWidth;
            uniform float maxWidth;
            uniform float minDepth;
            uniform float maxDepth;
            `
          );
                  
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <dithering_fragment>',
            `						
            if(vWorldPosition.y > minHeight && vWorldPosition.y < maxHeight && 
              vWorldPosition.x > minWidth && vWorldPosition.x < maxWidth && 
              vWorldPosition.z > minDepth && vWorldPosition.z < maxDepth){
              vec3 colors[7];
              colors[0] = vec3(0.65, 0.4, 0.65); // 진한 파스텔 보라색
              colors[1] = vec3(0.65, 0.45, 0.65); // 진한 파스텔 남색
              colors[2] = vec3(0.4, 0.4, 0.9);   // 진한 파스텔 파란색
              colors[3] = vec3(0.4, 0.8, 0.4);   // 진한 파스텔 초록색
              colors[4] = vec3(0.8, 0.8, 0.4);   // 진한 파스텔 노란색
              colors[5] = vec3(0.8, 0.6, 0.4);   // 진한 파스텔 주황색
              colors[6] = vec3(0.8, 0.4, 0.4);   // 진한 파스텔 빨간색
              
              float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);
              float section = heightFactor * 6.0;
              int idx = int(section);
              float fract = section - float(idx);

              vec3 heightColor;
              if (idx < 6) {
                heightColor = mix(colors[idx], colors[idx + 1], fract);
              }
              else {
                heightColor = colors[6];
              }
              gl_FragColor = vec4(heightColor, 1.0);
            }
            else{								
              gl_FragColor = gl_FragColor;
            }
            #include <dithering_fragment>
            `
          );
        };
        material.needsUpdate = true;
      }
      else{
        material.userData.uniforms.minHeight.value = minHeight + rangeMinHeight;
        material.userData.uniforms.maxHeight.value = minHeight + rangeMaxHeight;
        material.userData.uniforms.minWidth.value = minWidth + rangeMinWidth;
        material.userData.uniforms.maxWidth.value = minWidth + rangeMaxWidth;					
        material.userData.uniforms.minDepth.value = maxDepth - rangeMaxDepth;
        material.userData.uniforms.maxDepth.value = maxDepth - rangeMinDepth;
        material.userData.uniforms.customModelMatrix.value = child.matrixWorld;
      }
      material.version++;
    }
  });
}

export function repaintCroppedColor(modelObject, 
                                    minHeight, rangeMinHeight, rangeMaxHeight,
                                    minWidth, rangeMinWidth, rangeMaxWidth,
                                    maxDepth, rangeMinDepth, rangeMaxDepth){

  modelObject.traverse((child) => {
    if (child.isMesh && child.material) {								
      const material = child.material;

      if (!material.userData.uniforms) {
        material.userData.uniforms = {
          minHeight: { value: minHeight + rangeMinHeight },
          maxHeight: { value: minHeight + rangeMaxHeight },
          minWidth:  { value: minWidth  + rangeMinWidth },
          maxWidth:  { value: minWidth  + rangeMaxWidth },						
          minDepth:  { value: maxDepth  - rangeMaxDepth },
          maxDepth:  { value: maxDepth  - rangeMinDepth },
          customModelMatrix: { value: child.matrixWorld }
        };

        material.onBeforeCompile = (shader) => {						
          shader.uniforms.minHeight = material.userData.uniforms.minHeight;
          shader.uniforms.maxHeight = material.userData.uniforms.maxHeight;
          shader.uniforms.minWidth  = material.userData.uniforms.minWidth;
          shader.uniforms.maxWidth  = material.userData.uniforms.maxWidth;
          shader.uniforms.minDepth  = material.userData.uniforms.minDepth;
          shader.uniforms.maxDepth  = material.userData.uniforms.maxDepth;
          shader.uniforms.customModelMatrix = material.userData.uniforms.customModelMatrix;
                  
          shader.vertexShader = shader.vertexShader.replace(
            '#include <common>',
            `
            #include <common>
            uniform mat4 customModelMatrix;
            out vec3 vWorldPosition;
            `
          );

          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
            #include <begin_vertex>
            vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <common>',
            `
            #include <common>
            in vec3 vWorldPosition;
            uniform float minHeight;
            uniform float maxHeight;
            uniform float minWidth;
            uniform float maxWidth;
            uniform float minDepth;
            uniform float maxDepth;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <dithering_fragment>',
            `						
            if(vWorldPosition.y < minHeight || vWorldPosition.y > maxHeight || 
                vWorldPosition.x < minWidth || vWorldPosition.x > maxWidth || 
                vWorldPosition.z < minDepth || vWorldPosition.z > maxDepth){
                discard;
            }
            
            #include <dithering_fragment>
            `
          );
        };
        material.needsUpdate = true;
      }
      else{
        material.userData.uniforms.minHeight.value = minHeight + rangeMinHeight;
        material.userData.uniforms.maxHeight.value = minHeight + rangeMaxHeight;
        material.userData.uniforms.minWidth.value = minWidth + rangeMinWidth;
        material.userData.uniforms.maxWidth.value = minWidth + rangeMaxWidth;					
        material.userData.uniforms.minDepth.value = maxDepth - rangeMaxDepth;
        material.userData.uniforms.maxDepth.value = maxDepth - rangeMinDepth;
        material.userData.uniforms.customModelMatrix.value = child.matrixWorld;
      }
      material.version++;
    }
  });
}

export function repaintVolumePolygonColor(modelObject, minHeight, rangeMinHeight, rangeMaxHeight, verticesArray, vertexCount) {
  modelObject.traverse((child) => {
    if (child.isMesh && child.material) {
      const material = child.material;

      if (!material.userData.uniforms || !material.userData.uniforms.polygonVertices || !material.userData.uniforms.vertexCount) {
        material.userData.uniforms = {
          minHeight: { value: minHeight + rangeMinHeight },
          maxHeight: { value: minHeight + rangeMaxHeight },
          polygonVertices: { value: verticesArray },
          vertexCount: { value: vertexCount },
          customModelMatrix: { value: child.matrixWorld }
        };

        material.onBeforeCompile = (shader) => {
          shader.uniforms.minHeight = material.userData.uniforms.minHeight;
          shader.uniforms.maxHeight = material.userData.uniforms.maxHeight;
          shader.uniforms.polygonVertices = material.userData.uniforms.polygonVertices;
          shader.uniforms.vertexCount = material.userData.uniforms.vertexCount;
          shader.uniforms.customModelMatrix = material.userData.uniforms.customModelMatrix;

          shader.vertexShader = shader.vertexShader.replace(
            '#include <common>',
            `
            #include <common>
            uniform mat4 customModelMatrix;
            out vec3 vWorldPosition;
            `
          );

          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
            #include <begin_vertex>
            vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <common>',
            `
            #include <common>
            in vec3 vWorldPosition;
            uniform float minHeight;
            uniform float maxHeight;
            uniform vec2 polygonVertices[100];
            uniform int vertexCount;
            `
          );
              
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <dithering_fragment>',
            `		
            vec2 pt = vec2(vWorldPosition.x, vWorldPosition.z);
            bool inside = false;
            
            if(vertexCount<3){
              gl_FragColor = gl_FragColor;
            }
            else{
              vec2 centerPoint = vec2(0.0);

              for (int i = 0; i < vertexCount; i++) {
                centerPoint += polygonVertices[i];
              }

              centerPoint = centerPoint / float(vertexCount);

              for (int i = 0; i < vertexCount-1; i++) { 
                float d1, d2, d3;
                bool has_neg, has_pos;

                vec2 v1 = polygonVertices[i];
                vec2 v2 = polygonVertices[i+1];
                vec2 v3 = centerPoint;

                d1 = sign(pt.x * (v1.y - v2.y) + v1.x * (v2.y - pt.y) + v2.x * (pt.y - v1.y));
                d2 = sign(pt.x * (v2.y - v3.y) + v2.x * (v3.y - pt.y) + v3.x * (pt.y - v2.y));
                d3 = sign(pt.x * (v3.y - v1.y) + v3.x * (v1.y - pt.y) + v1.x * (pt.y - v3.y));

                has_neg = (d1 < 0.0) || (d2 < 0.0) || (d3 < 0.0);
                has_pos = (d1 > 0.0) || (d2 > 0.0) || (d3 > 0.0);

                if(!(has_neg && has_pos)) {
                  inside = true;
                  break;
                }
              }

              if(vWorldPosition.y > minHeight && vWorldPosition.y < maxHeight && inside){
                vec3 colors[7];
                colors[0] = vec3(0.65, 0.4, 0.65); // 진한 파스텔 보라색
                colors[1] = vec3(0.65, 0.45, 0.65); // 진한 파스텔 남색
                colors[2] = vec3(0.4, 0.4, 0.9);   // 진한 파스텔 파란색
                colors[3] = vec3(0.4, 0.8, 0.4);   // 진한 파스텔 초록색
                colors[4] = vec3(0.8, 0.8, 0.4);   // 진한 파스텔 노란색
                colors[5] = vec3(0.8, 0.6, 0.4);   // 진한 파스텔 주황색
                colors[6] = vec3(0.8, 0.4, 0.4);   // 진한 파스텔 빨간색
                
                float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);
                float section = heightFactor * 6.0;
                int idx = int(section);
                float fract = section - float(idx);

                vec3 heightColor;
                if (idx < 6) {
                  heightColor = mix(colors[idx], colors[idx + 1], fract);
                }
                else{
                  heightColor = colors[6];
                }
                gl_FragColor = vec4(heightColor, 1.0);
              }
              else{								
                gl_FragColor = gl_FragColor;
              }
            }
            
            #include <dithering_fragment>
            `
          );
        };
        material.needsUpdate = true;
      }
      else{
        material.userData.uniforms.minHeight.value = minHeight + rangeMinHeight;
        material.userData.uniforms.maxHeight.value = minHeight + rangeMaxHeight;
        material.userData.uniforms.polygonVertices.value  = verticesArray;
        material.userData.uniforms.vertexCount.value  = vertexCount;
        material.userData.uniforms.customModelMatrix.value = child.matrixWorld;
      }
      material.version++;
    }
  });
}

export function repaintCropPolygonColor(modelObject, minHeight, rangeMinHeight, rangeMaxHeight, verticesArray, vertexCount) {
  modelObject.traverse((child) => {
    if (child.isMesh && child.material) {
      const material = child.material;

      if (!material.userData.uniforms) {
        material.userData.uniforms = {
          minHeight: { value: minHeight + rangeMinHeight },
          maxHeight: { value: minHeight + rangeMaxHeight },
          polygonVertices: { value: verticesArray },
          vertexCount: { value: vertexCount },
          customModelMatrix: { value: child.matrixWorld }
        };

        material.onBeforeCompile = (shader) => {
          shader.uniforms.minHeight = material.userData.uniforms.minHeight;
          shader.uniforms.maxHeight = material.userData.uniforms.maxHeight;
          shader.uniforms.polygonVertices = material.userData.uniforms.polygonVertices;
          shader.uniforms.vertexCount = material.userData.uniforms.vertexCount;
          shader.uniforms.customModelMatrix = material.userData.uniforms.customModelMatrix;

          shader.vertexShader = shader.vertexShader.replace(
            '#include <common>',
            `
            #include <common>
            uniform mat4 customModelMatrix;
            out vec3 vWorldPosition;
            `
          );

          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
            #include <begin_vertex>
            vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            `
          );

          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <common>',
            `
            #include <common>
            in vec3 vWorldPosition;
            uniform float minHeight;
            uniform float maxHeight;
            uniform vec2 polygonVertices[100];
            uniform int vertexCount;
            `
          );
              
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <dithering_fragment>',
            `		
            vec2 pt = vec2(vWorldPosition.x, vWorldPosition.z);
            bool inside = false;
            
            if(vertexCount<3){
              inside=true;
            }
            else{
              vec2 centerPoint = vec2(0.0);

              for (int i = 0; i < vertexCount; i++) {
                centerPoint += polygonVertices[i];
              }

              centerPoint = centerPoint / float(vertexCount);

              for (int i = 0; i < vertexCount-1; i++) { 
                float d1, d2, d3;
                bool has_neg, has_pos;

                vec2 v1 = polygonVertices[i];
                vec2 v2 = polygonVertices[i+1];
                vec2 v3 = centerPoint;

                d1 = sign(pt.x * (v1.y - v2.y) + v1.x * (v2.y - pt.y) + v2.x * (pt.y - v1.y));
                d2 = sign(pt.x * (v2.y - v3.y) + v2.x * (v3.y - pt.y) + v3.x * (pt.y - v2.y));
                d3 = sign(pt.x * (v3.y - v1.y) + v3.x * (v1.y - pt.y) + v1.x * (pt.y - v3.y));

                has_neg = (d1 < 0.0) || (d2 < 0.0) || (d3 < 0.0);
                has_pos = (d1 > 0.0) || (d2 > 0.0) || (d3 > 0.0);

                if(!(has_neg && has_pos)) {
                  inside = true;
                  break;
                }
              }
            }

            if(vWorldPosition.y > minHeight && vWorldPosition.y < maxHeight && inside){
              gl_FragColor = gl_FragColor;
            }
            else{																
              gl_FragColor = vec4(1.0, 1.0, 1.0, 0.0);
            }
            
            #include <dithering_fragment>
            `
          );
        };
        material.needsUpdate = true;
      }
      else{
        material.userData.uniforms.minHeight.value = minHeight + rangeMinHeight;
        material.userData.uniforms.maxHeight.value = minHeight + rangeMaxHeight;
        material.userData.uniforms.polygonVertices.value  = verticesArray;
        material.userData.uniforms.vertexCount.value  = vertexCount;
        material.userData.uniforms.customModelMatrix.value = child.matrixWorld;
      }
      material.version++;
    }
  });
}
  