// 현재 시간을 가져오는 함수
export const getCurrentTime = () => {
  // 현재 시간을 UTC로 가져옵니다.
  const now = new Date();

  // 시간을 지정된 형식으로 포맷합니다.
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');

  // 형식에 맞춘 문자열을 반환합니다.
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};
