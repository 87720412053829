<template>
  <div class="user_info_box">
    <div class="user_info" ref="headerMenuButton">
      <button
        type="button"
        class="user_info_button"
        @click="handleShowHeaderMenu"
      >
        <h3>{{ user.userLoginId }}</h3>
        <div
          class="profile"
          :class="user.profileImageUrl ? '' : 'no_image'"
          :style="[
            user.profileImageUrl
              ? { backgroundImage: `url(${encodeURI(user.profileImageUrl)})` }
              : null,
          ]"
        ></div>
      </button>
      <!-- TODO : 권한 처리 필요. admin만 사용자 관리 페이지에 접근 가능 -->
      <ul class="pop_menu" v-if="isMenuShow">
        <li v-if="user.role === 'admin'">
          <button
            type="button"
            class="pop_menu_button user"
            @click="handleRouteToUserPage"
          >
            <p>사용자 관리</p>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="pop_menu_button mypage"
            @click="handleOpenInfoEdit"
          >
            <p>내 정보</p>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="pop_menu_button log"
            @click="handleOpenDroneLog"
          >
            <p>비행 로그</p>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="pop_menu_button logout"
            @click="handleLogOut"
          >
            <p>로그아웃</p>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <CustomModalFrame
    :type="modalName === 'drone' ? 'hasTitle' : null"
    :title="'비행 로그'"
    v-if="isModalOpen"
    @closeModal="handleCloseCustomFrame"
  >
    <!-- TODO : 내정보 수정 모달에 myInfo 값 넘겨줘야함 ... -->
    <UserEditFormContent
      v-if="modalName === 'mypage'"
      :eidtMode="'myInfo'"
      :uploadedImageUrl="uploadedImageUrl"
      :selectedUserInfo="user"
      :isDeleted="isDeleted"
      @handleCloseCustomFrame="handleCloseCustomFrame"
      @handleUploadFile="handleUploadFile($event)"
      @handleEditUser="handleEditUser($event)"
      @handleRemoveProfileImage="handleRemoveProfileImage"
    />
    <DroneLogDownloadContent v-if="modalName === 'drone'" />
  </CustomModalFrame>
</template>
<script>
import { mapState } from "vuex";
import * as RESTAPI from "@/shared/communication/RESTAPI";

import UserEditFormContent from "@/page/user/component/UserEditFormContent.vue";
import { addOutsideClickHandler } from "@/shared/util/handleOutsideClickHandler";
import CustomModalFrame from "@/component/modal/custom/CustomModalFrame.vue";
import DroneLogDownloadContent from "@/component/drone-log/DroneLogDownloadContent.vue";
// import { paginateArray } from "@/shared/util/paginateArray";

export default {
  name: "HeaderUserBox",
  emits: ["handleOpenInfoEdit", "handleOpenDroneLog"],
  computed: {
    ...mapState(["user"]),
  },
  components: {
    CustomModalFrame,
    UserEditFormContent,
    DroneLogDownloadContent,
  },
  data() {
    return {
      isMenuShow: false,
      isModalOpen: false,
      modalName: null,
      uploadedImageUrl: null,
      uploadedImageFile: null,
      isDeleted: false,
    };
  },
  methods: {
    handleLogOut() {
      if (confirm("로그아웃하시겠습니까?")) {
        this.$store.dispatch("logout", true);
      } else {
        return false;
      }
    },
    handleShowHeaderMenu() {
      this.isMenuShow = !this.isMenuShow;
    },
    handleRouteToUserPage() {
      this.$router.push("/user");
    },
    /**
     * 모달 닫기
     */
    handleCloseCustomFrame() {
      this.isModalOpen = false;
      this.modalName = null;

      this.uploadedImageUrl = null;
      this.uploadedImageFile = null;
      this.isDeleted = false;
    },
    /**
     * 내 정보 수정 모달 띄우기
     */
    handleOpenInfoEdit() {
      this.isModalOpen = true;
      this.modalName = "mypage";
    },
    /**
     * 프로필 이미지 파일 업로드
     */
    handleUploadFile(file) {
      let image = window.URL.createObjectURL(file);
      this.uploadedImageFile = file;
      this.uploadedImageUrl = image;
      this.isDeleted = false;
    },
    /**
     * 유저 프로필 이미지 삭제
     */
    handleRemoveProfileImage() {
      this.uploadedImageUrl = null;
      this.uploadedImageFile = "";
      this.isDeleted = true;
      console.log("isDeleted");
      console.log(this.isDeleted);
      console.log("uploadedImageUrl");
      console.log(this.uploadedImageUrl);
      console.log("uploadedImageFile");
      console.log(this.uploadedImageFile);
      console.log("user");
      console.log(this.user.profileImageUrl);
    },
    /**
     * 비행 로그 다운로드 모달 열기
     */
    handleOpenDroneLog() {
      this.isModalOpen = true;
      this.modalName = "drone";
    },
    /**
     * 유저 수정
     */
    async handleEditUser(newUserInfo) {
      this.$store.commit("setIsLoading", true);

      try {
        /**
         * ? : 이미지 파일이 존재한다면 formData로 전송
         */

        if (this.uploadedImageFile || this.isDeleted) {
          const formData = new FormData();

          if (this.uploadedImageFile) {
            // 프로필 이미지 업로드하면 file object
            formData.append("userProfile", this.uploadedImageFile);
          } else if (this.isDeleted) {
            // 프로필 이미지 삭제하면 빈 값
            formData.append(
              "userProfile",
              new File([], "", { type: "text/plain" })
            );
          }

          // FormData 확인용 로그
          // for (let pair of formData.entries()) {
          //   console.log(`${pair[0]}:`, pair[1]);
          // }

          const thumbnailData = await RESTAPI.postUserThumbnail(
            this.user.id,
            formData
          );

          console.log(thumbnailData);
        }

        /**
         * 이미지 파일을 제외한 데이터 전송
         */
        newUserInfo.affiliation.id = this.user.affiliation.id;
        const editData = await RESTAPI.patchUser(this.user.id, newUserInfo);

        if (editData.success) {
          /**
           * ! : 사용자 수정했을 때도 담당 시설물 리스트를 받아올 수 있을지?
           */
          let userData = await RESTAPI.getUser(this.user.id);
          let userDataResult = userData.result;

          if (userData.success) {
            this.$store.commit("setUser", { newValue: userDataResult });
            sessionStorage.setItem("user", JSON.stringify(userDataResult));

            this.handleCloseCustomFrame();
          }
        }

        if (editData.status === 415) {
          return this.$store.commit(
            "openAlert",
            `에러 발생 : ${editData.data.error}<br>새로고침하거나 다시 시도해 주세요.`
          );
        }
      } catch (error) {
        console.log(error);
        return this.$store.commit(
          "openAlert",
          `에러 발생 : ${error}<br>새로고침하거나 다시 시도해 주세요.`
        );
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
  },
  mounted() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    this.clickHandlerRemover = addOutsideClickHandler(
      this.$refs.headerMenuButton,
      () => {
        this.isMenuShow = false;
      }
    );
  },
  beforeUnmount() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    if (this.clickHandlerRemover) {
      this.clickHandlerRemover();
    }
  },
};
</script>
<style lang="scss" scoped>
.user_info_box {
  display: flex;
  align-content: flex-end;
  @include flexbox(flex-end, flex-start, row);
  gap: 8px;

  .user_info {
    position: relative;

    .user_info_button {
      color: $main_white;
      @include flexbox;
      gap: 12px;

      &:hover {
        h3 {
          color: $main_color;
        }
      }

      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        transition: 0.15s;
      }

      .profile {
        width: 28px;
        height: 28px;
        border-radius: 50px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &.no_image {
          @include basicProfile;
        }
      }
    }

    .pop_menu {
      position: absolute;
      top: 120%;
      right: 0;
      min-width: 120px;
      max-width: 180px;
      background: $black_26262d;
      border-radius: 4px;
      @include flexbox(center, center, column);

      li {
        padding: 12px 0;
      }
    }

    .pop_menu_button {
      transition: 0.2s;
      color: $main_white;
      font-size: 1.4rem;
      padding-right: 24px;

      @include hoverPointer {
        &:hover {
          color: $main_color;
        }
      }

      p {
        width: 100%;
      }

      &.user {
        background: url("@/../public/images/users_gear_regular.svg") no-repeat
          center right;
        background-size: 19px 19px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/users_gear_regular_main.svg")
              no-repeat center right;
            background-size: 19px 19px;
          }
        }
      }

      &.mypage {
        background: url("@/../public/images/user_pen_regular.svg") no-repeat
          center right;
        background-size: 19px 19px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/user_pen_regulqr_main.svg")
              no-repeat center right;
            background-size: 19px 19px;
          }
        }
      }

      &.log {
        padding-right: 27px;
        background: url("@/../public/images/drone_bold_whtie.svg") no-repeat
          center right;
        background-size: 22px 22px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/drone_bold_main.svg") no-repeat
              center right;
            background-size: 22px 22px;
          }
        }
      }

      &.logout {
        background: url("@/../public/images/logout_white.svg") no-repeat center
          right;
        background-size: 14px 14px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/logout_main.svg") no-repeat
              center right;
            background-size: 14px 14px;
          }
        }
      }
    }
  }
}
</style>
