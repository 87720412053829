export default class Info{
  constructor(info){
    this.box = null;
    this.crack_length = null;
    this.crack_width = null;
    this.cross_point_cloud =  null;
    this.distance = null;
    this.mask_id = null;
    this.type = null;
    if(info){
      this.box = info.box;
      this.crack_length = info.crack_length;
      this.crack_width = info.crack_width;
      this.cross_point_cloud = info.cross_point_cloud;
      this.distance = info.distance;
      this.mask_id = info.mask_id;
      this.type = null;
    }
  }
}