<template>
  <div class="form_container">
    <h1>
      <img src="@/../public/images/login_icon.png" alt="시리우스 로고" />
    </h1>
    <form
      action=""
      @submit="
        submitLogin($event, $refs.userName.value, $refs.userPassword.value)
      "
    >
      <div class="input_group">
        <div class="input_container focus">
          <input
            ref="userName"
            type="text"
            @input="checkInput($event)"
            placeholder="아이디"
          />
        </div>
        <div class="input_container focus">
          <input
            type="password"
            ref="userPassword"
            @input="checkInput($event)"
            placeholder="비밀번호"
            autocomplete="off"
          />
          <button
            class="eye_button"
            :class="isPasswordShow ? 'on' : ''"
            @click="showPassword($event)"
            type="button"
          ></button>
        </div>
      </div>
      <button class="default_bg_button" type="submit">로그인</button>
    </form>
  </div>
</template>
<script>
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { changeSiblingInputType } from "@/shared/util/changeSiblingInputType";

export default {
  name: "LoginForm",
  emits: ["handleSubmitLogin"],
  data() {
    return {
      isPasswordShow: false,
    };
  },
  methods: {
    checkInput(event) {
      checkInputLimit(event, 20, this.$store);
    },
    submitLogin(event, userName, userPassword) {
      event.preventDefault();

      if (userName && userPassword) {
        return this.$emit("handleSubmitLogin", userName, userPassword);
      }

      return this.$store.commit("openAlert", `모든 값을 입력해 주세요.`);
    },
    showPassword(event) {
      this.isPasswordShow = !this.isPasswordShow;
      changeSiblingInputType(event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/component/inputs";

h1 {
  width: 100%;
  @include flexbox;
  margin-bottom: 60px;

  img {
    width: 150px;
    height: 100%;
    object-fit: contain;
  }
}

.form_container {
  @include maxInner(300px, 90%);
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input_group {
  @include flexbox(center, center, column);
  gap: 10px;
}

.default_bg_button {
  padding: 12px;
  margin-top: 20px;
}
</style>
