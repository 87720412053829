<template>
  <section class="no_facility">
    <div class="icon_box" :style="{ width: iconSize, height: iconSize }"></div>
    <h3 :style="{ fontSize: textSize }"><slot></slot></h3>
  </section>
</template>
<script>
export default {
  name: "EmptyResult",
  props: {
    textSize: {
      type: String,
      required: false,
      default: "2.4rem",
    },
    iconSize: {
      type: String,
      required: false,
      default: "40px",
    },
  },
};
</script>
<style lang="scss" scoped>
.no_facility {
  text-align: center;
}
h3 {
  font-weight: 400;
  line-height: 1.5;

  @include mobile {
    font-size: 2rem;
  }
}
.icon_box {
  display: inline-block;
  background: url("@/../public/images/location_exclamation.svg") no-repeat
    center;
  background-size: contain;
  margin-bottom: 20px;
}
.add_facility {
  margin-top: 30px;
  font-size: 1.6rem;
}
</style>
