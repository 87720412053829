<template>
  <div id="form_content_wrap">
    <form action="" v-if="mode === 'add'">
      <!-- TODO : 시설물 추가할 때 담당자는 추가하는 본인으로 들어가도록 설정 -->
      <div class="content_area custom_scrollbar">
        <label class="required">시설물 이름</label>
        <div class="input_container focus">
          <input
            class=""
            ref="name"
            type="text"
            placeholder="시설물 이름"
            autocomplete="off"
            @input="inputCheck($event, 10)"
          />
        </div>
        <label class="required">시설물 종류</label>
        <DefaultSelectBox
          :className="`focus full`"
          :isOptionOpen="isFacilityTypeOptionOpen"
          :defaultName="`시설물의 타입을 선택하세요.`"
          :selectedName="clickedTypeValue"
          @update:isOptionOpen="isFacilityTypeOptionOpen = $event"
          @controlOptionToggle="controlTypeOptionToggle"
        >
          <ul>
            <li
              v-for="(type, index) in FACILITY_TYPE"
              :key="type + index"
              @click="handleClickTypeOption(type)"
              :class="type === this.clickedTypeValue ? 'active' : ''"
            >
              <p>{{ type }}</p>
            </li>
          </ul></DefaultSelectBox
        >
        <label class="required">시설물 위치</label>
        <div class="input_flex_group">
          <div class="input_container">
            <input
              class=""
              autocomplete="off"
              id="postcode"
              ref="location"
              type="text"
              :placeholder="'주소 검색 버튼을 눌러 입력하세요.'"
              :value="location !== '' ? location : ''"
              readonly
            />
          </div>
          <button
            type="button"
            @click="handleClickOpenPost"
            class="default_border_hover_button find_address_button"
          >
            주소 검색
          </button>
        </div>
        <div class="post_code_box" v-if="isPostSearchOpen">
          <VueDaumPostcode @complete="handleAddress" />
        </div>
        <label>시설물 설명</label>
        <div class="input_container focus">
          <textarea
            class=""
            ref="description"
            type="text"
            placeholder="시설물 설명을 입력하세요."
            @input="inputCheck($event, 300)"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="button_box">
        <button @click="submitAdd" class="default_bg_button" type="button">
          추가
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          취소
        </button>
      </div>
    </form>
    <form action="" v-if="mode === 'edit' && this.selectedSite">
      <div class="content_area custom_scrollbar">
        <label>시설물 이름</label>
        <div class="input_container focus">
          <input
            class=""
            ref="name"
            type="text"
            :placeholder="this.selectedSite.name"
            @input="inputCheck($event, 10)"
          />
        </div>
        <label>시설물 담당자</label>
        <DefaultSelectBox
          :className="`focus full`"
          :isOptionOpen="isFacilityManagerOptionOpen"
          :defaultName="
            this.selectedSite.fullName
              ? this.selectedSite.fullName
              : '담당자 선택'
          "
          :selectedName="this.clickedManagerValue?.fullName"
          @update:isOptionOpen="isFacilityManagerOptionOpen = $event"
          @controlOptionToggle="controlManagerOptionToggle"
        >
          <ul>
            <li
              v-for="user in userListArray"
              :key="user.id"
              @click="handleClickManagerOption(user.fullName, user.id)"
              :class="
                user.fullName === this.clickedManagerValue?.fullName
                  ? 'active'
                  : ''
              "
              :ref="
                user.fullName === this.clickedManagerValue?.fullName
                  ? 'activeManager'
                  : ''
              "
            >
              <p>{{ user.fullName }}</p>
            </li>
          </ul></DefaultSelectBox
        >
        <label>시설물 종류</label>
        <DefaultSelectBox
          :className="`focus full`"
          :isOptionOpen="isFacilityTypeOptionOpen"
          :defaultName="this.selectedSite.typeName"
          :selectedName="clickedTypeValue"
          @update:isOptionOpen="isFacilityTypeOptionOpen = $event"
          @controlOptionToggle="controlTypeOptionToggle"
        >
          <ul>
            <li
              v-for="(type, index) in FACILITY_TYPE"
              :key="type + index"
              @click="handleClickTypeOption(type)"
              :class="type === this.clickedTypeValue ? 'active' : ''"
            >
              <p>{{ type }}</p>
            </li>
          </ul></DefaultSelectBox
        >
        <label>시설물 위치</label>
        <div class="input_flex_group">
          <div class="input_container">
            <input
              class=""
              id="postcode"
              ref="location"
              type="text"
              :placeholder="this.selectedSite.location"
              :value="location !== '' ? location : ''"
              readonly
            />
          </div>
          <button
            type="button"
            @click="handleClickOpenPost"
            class="default_border_hover_button find_address_button"
          >
            주소 검색
          </button>
        </div>
        <div class="post_code_box" v-if="isPostSearchOpen">
          <VueDaumPostcode @complete="handleAddress" />
        </div>
        <!-- <div class="input_container focus location_input_box">
          <input
            class=""
            ref="extraLocation"
            type="text"
            :placeholder="getTextAfterParenthesis(this.selectedSite.location)"
          />
        </div> -->
        <label>시설물 설명</label>
        <div class="input_container focus">
          <textarea
            class=""
            ref="description"
            type="text"
            autocomplete="off"
            :placeholder="this.selectedSite.description"
            @input="inputCheck($event, 300)"
          />
        </div>
      </div>
      <div class="button_box">
        <button @click="submitEdit" class="default_bg_button" type="button">
          수정
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          취소
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { FACILITY_TYPE } from "@/shared/constant/facilityType.js";
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { getCurrentTime } from "@/shared/util/getCurrentTime";
import { mapState } from "vuex";
import { VueDaumPostcode } from "vue-daum-postcode";
import { getTextAfterParenthesis } from "@/shared/util/getTextAfterParenthesis";

export default {
  name: "FacilityFormContent",
  computed: {
    ...mapState(["user", "selectedSite", "isLoading"]),
  },
  emits: ["handleCloseCustomFrame", "handleAddFacility", "handleEditFacility"],
  components: {
    DefaultSelectBox,
    VueDaumPostcode,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    userListArray: {
      type: Array,
      required: true,
    },
  },
  watch: {
    // Dropdown이 열릴 때 포커스 처리
    isFacilityManagerOptionOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const activeElement = this.$refs.activeManager;
          if (activeElement && activeElement[0]) {
            activeElement[0].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
      }
    },
  },
  data() {
    return {
      isFacilityTypeOptionOpen: false,
      isFacilityManagerOptionOpen: false,
      clickedTypeValue: null,
      clickedManagerValue: {
        fullName: this.selectedSite?.fullName,
        userId: this.selectedSite?.userId,
      },
      FACILITY_TYPE,
      isPostSearchOpen: false,
      location: "",
    };
  },
  methods: {
    getTextAfterParenthesis,
    inputCheck(event, limit) {
      checkInputLimit(event, limit, this.$store);
    },
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlManagerOptionToggle() {
      this.isFacilityManagerOptionOpen = !this.isFacilityManagerOptionOpen;
    },
    controlTypeOptionToggle() {
      this.isFacilityTypeOptionOpen = !this.isFacilityTypeOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickManagerOption(fullName, userId) {
      this.isFacilityManagerOptionOpen = false;
      this.clickedManagerValue = { fullName, userId };
    },
    handleClickTypeOption(value) {
      this.isFacilityTypeOptionOpen = false;
      this.clickedTypeValue = value;
    },
    /**
     * 시설물 등록 동작
     */
    submitAdd() {
      let name = this.$refs.name.value,
        location = this.$refs.location.value,
        description = this.$refs.description.value;

      const data = {
        userId: this.user.id,
        name: name,
        location: location,
        description: description,
        typeName: this.clickedTypeValue,
        createdDatetime: getCurrentTime(),
      };

      if (
        data.userId === "" ||
        data.name === "" ||
        data.location === "" ||
        data.typeName === "" ||
        data.createdDatetime === ""
      ) {
        return this.$store.commit("openAlert", `필수 항목을 입력해 주세요.`);
      }

      this.$emit("handleAddFacility", data);
    },
    /**
     * 주소 입력창 띄우기
     */
    handleClickOpenPost() {
      this.isPostSearchOpen = !this.isPostSearchOpen;
    },
    /**
     * 주소 입력 동작
     */
    handleAddress(data) {
      try {
        let jibunAddress = data.jibunAddress;
        let roadAddress = data.roadAddress;
        let zonecode = data.zonecode;

        let fullAddress = jibunAddress ? jibunAddress : roadAddress;

        if (zonecode !== "" || zonecode !== undefined) {
          fullAddress += ` (${zonecode})`;
        }

        this.location = fullAddress;
      } finally {
        this.isPostSearchOpen = false;
      }
    },
    /**
     * 시설물 수정 동작
     */
    submitEdit() {
      if (
        this.$refs.name.value === "" &&
        this.location === "" &&
        this.clickedTypeValue === null &&
        this.$refs.description.value === "" &&
        this.clickedManagerValue === null
      ) {
        return this.$store.commit(
          "openAlert",
          `새로운 값을 입력하시거나, 이전 값을 유지하고 싶으신 경우 취소 버튼을 눌러주세요.`
        );
      }

      let customLocation = this.location
        ? this.location
        : this.selectedSite.location;
      // let addExtraLocation = this.$refs.extraLocation.value
      //   ? this.$refs.extraLocation.value
      //   : getTextAfterParenthesis(this.selectedSite.location);

      let name = this.$refs.name.value
          ? this.$refs.name.value
          : this.selectedSite.name,
        location = customLocation,
        // extraLocation = addExtraLocation,
        description = this.$refs.description.value
          ? this.$refs.description.value
          : this.selectedSite.description,
        typeName = this.clickedTypeValue
          ? this.clickedTypeValue
          : this.selectedSite.typeName,
        createdDatetime = this.selectedSite.createdDatetime,
        userId = this.clickedManagerValue
          ? this.clickedManagerValue.userId
          : this.selectedSite.userId;

      const data = {
        userId: userId,
        name: name,
        location: location,
        // location: `${location} ${extraLocation}`,
        description: description,
        typeName: typeName,
        createdDatetime: createdDatetime,
        lat: null,
        lon: null,
        thumbnailUrl: null,
        wrDatetime: null,
      };

      console.log(data);

      this.$emit("handleEditFacility", data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/component/inputs";
</style>
