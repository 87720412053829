/**
 * 파일 경로에 .log 앞에 날짜만을 추출해서 반환한다.
 * @param {String} filePath
 * @returns
 */
export const extractOnlyDateFromFilePath = (filePath) => {
  // 날짜 패턴: .log 앞에 있는 YYYY-MM-DD
  const pattern = /(\d{4}-\d{2}-\d{2})(?=\.log)/;
  const match = filePath.match(pattern);
  return match ? match[0] : null;
};
