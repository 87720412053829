export default class StorageVolume {
  constructor(volume) {
    this.id = null;
    this.affiliationId = null;
    this.currentUsage = null;
    this.maxStorageSize = null;
    this.repositoryPath = null;
    if(volume){
      this.id = volume.id;
      this.affiliationId = volume.affiliationId;
      this.currentUsage = volume.currentUsage;
      this.maxStorageSize = volume.maxStorageSize;
      this.repositoryPath = volume.repositoryPath;
    }
  }
}
