/**
 * 기본 주소에서 상세 주소만 출력한다.
 * @param {string} address
 * @returns 상세 주소 출력
 */

export const getTextAfterParenthesis = (address) => {
  const parts = address.split(')');
  return parts.length > 1 ? parts[1].trim() : '';
};
