<template>
  <div class="main korean">
    <MainHeaderVue :navigator="navigator"/>
    <MainLoadingVue ref="mainLoading"/>
    <!-- <div ref="pcd_viewer_3d" class="pcd_viewer_3d" @mousemove="handleMouseMove" @mousedown="handleMouseDown"> -->
    <div ref="pcd_viewer_3d" class="pcd_viewer_3d" @mousedown="handleMouseDown">
      <!-- <div ref="magnifier" class="magnifier"></div> -->
    </div>
    <PCMeasureInfoVue :measureMethod = "measureMethod" @changeMethod="handleMesureMethod"
                      @deleteMeasure="deleteMeasure"></PCMeasureInfoVue>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PCManager from '@/module/Measure/PCManager_3D.js';
import API from "@/module/Communication/RESTAPI.js";

import MainHeaderVue from '../Common/MainHeader.vue';
import MainLoadingVue from '../Common/MainLoading.vue';
import PCMeasureInfoVue from './PCMeasureInfo.vue'

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";


export default {
  data() {
    return {
      navigator : 'measure',
      measureMethod : null,
    }
  },
  computed : {
    ...mapState(['user','apiIp','apiPort','siteList','selectedSite']),
  },
  components : {
    MainHeaderVue,
    MainLoadingVue,
    PCMeasureInfoVue,
  },
  methods : {
    handleKeydown(event){      
      if (event.key === "Shift"){        
        this.loader.getShiftKey(true);
      }
    },
    handleKeyUp(event){      
      if (event.key === "Shift") {        
        this.loader.getShiftKey(false);
      }
    },
    handleMouseMove(event){      
      if(this.measureMethod) this.loader.onMouseMove(event);
    },
    handleMouseDown(event){
      event.preventDefault();
      this.loader.onMouseDown(event);
    },
    getSites(){
      if(!this.siteList){
        this.api.getSites(this.user.id, this.getSitesCallback);
      }
      else{
        this.getSite();
      }
    },
    getSitesCallback(data){
      let result = data.data.result;
      let siteList = [];
      if(result){
        for(let i=0; i<result.length; i++){
          siteList.push(new Site(result[i]));
        }
      }
      this.$store.dispatch('setSiteList', siteList);
      let setSelectedSite = siteList.find(site => site.id == this.$route.params.siteId);
      this.$store.dispatch('setSelectedSite', setSelectedSite);
      this.getSite();
    },
    getSite(){
      if(!this.selectedSite || this.selectedSite.id != this.$route.params.siteId){
        this.api.getSite(this.getSiteCallback, this.$route.params.siteId);
      }
      else{
        this.getMaps();
      }
    },
    getSiteCallback(data){
      let result = data.data.result;
      this.$store.dispatch('setSelectedSite', new Site(result));
      this.getMaps();
    },
    getMaps(){
      this.api.getMaps(this.getMapsCallback, this.$route.params.siteId);
    },
    getMapsCallback(data){
      let result = data.data.result;
      let mapList = [];
      if(result){
        result.sort((a,b) => a.createdDatetime - b.createdDatetime);
        for(let i=0; i<result.length; i++){
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch('setMapList', mapList);
        let map = mapList.find(map => map.id == this.$route.params.mapId);
        this.$store.dispatch('updateMap', map);
        this.loadPCD(this.selectedSite.map.url);
      }
    },
    loadPCD(url){
      this.loader = new PCManager(this.$refs.pcd_viewer_3d, this.$refs.mainLoading);
      this.loader.loadPCD(url);
    },
    handleMesureMethod(method){
      this.loader.removeDrawings();
      if(this.measureMethod == method){
        this.measureMethod = null;
      }
      else{
        this.measureMethod = method;
        this.loader.setMeasureMethod(method);
      }
    },
    deleteMeasure(){
      this.loader.deleteMeasurements();
    },
  },
  
  created(){
  this.api = new API(this.apiIp, this.apiPort);
  },
  mounted(){
    this.getSites();
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeUnmount(){
    if(this.loader){
      this.loader.dispose();
    }
  }
}
</script>

<style>
.pcd_viewer_3d{
  position:absolute;
  top: 70px;
  left: 0px; 
  width: 100%;
  height: calc(100% - 70px);
  overflow: hidden;
}
</style>