<template>
  <div v-if="isVisible" class="inspection_monitor_modal_overlay">
    <div class="inspection_monitor_status_container">
      <div class="inspection_monitor_title">상태</div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">FCU</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].fcu }}
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">배터리</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].battery }}%
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">GPS 모드</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].gpsMode }}
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">비행 모드</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].flightMode }}
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">속도</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].speed }} &nbsp;&nbsp;
          <span style="color: white">m/s</span>
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">슬램 상태</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].slamState }}
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">저장공간</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].storage }}%
        </div>
      </div>
    </div>
    <div
      class="inspection_monitor_status_container"
      :style="{ height: '90px' }"
    >
      <div class="inspection_monitor_title">위치/비행 시간</div>
      <div class="inspection_monitor_content_wrapper">
        <div
          class="inspection_monitor_content_status"
          :style="{ width: '100%' }"
        >
          <div class="inspection_monitor_lf_value">
            <span style="color: white">x</span> &nbsp;&nbsp;
            <div :style="{ textAlign: 'end', width: '100%' }">
              <span> {{ droneData[currentDroneId].pose.x.toFixed(2) }} </span>
            </div>
          </div>
          <div class="inspection_monitor_lf_value">
            <span style="color: white">y</span> &nbsp;&nbsp;
            <div :style="{ textAlign: 'end', width: '100%' }">
              <span> {{ droneData[currentDroneId].pose.y.toFixed(2) }}</span>
            </div>
          </div>
          <div class="inspection_monitor_lf_value">
            <span style="color: white">z</span> &nbsp;&nbsp;
            <div :style="{ textAlign: 'end', width: '100%' }">
              <span> {{ droneData[currentDroneId].pose.z.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div
          class="inspection_monitor_content_status"
          :style="{ width: '100%' }"
        >
          <div class="inspection_monitor_lf_value">
            <span style="color: white">시</span> &nbsp;&nbsp;
            <div :style="{ textAlign: 'end', width: '100%' }">
              <span> {{ droneData[currentDroneId].flightTime.h }}</span>
            </div>
          </div>
          <div class="inspection_monitor_lf_value">
            <span style="color: white">분</span> &nbsp;&nbsp;
            <div :style="{ textAlign: 'end', width: '100%' }">
              <span> {{ droneData[currentDroneId].flightTime.m }}</span>
            </div>
          </div>
          <div class="inspection_monitor_lf_value">
            <span style="color: white">초</span> &nbsp;&nbsp;
            <div :style="{ textAlign: 'end', width: '100%' }">
              <span> {{ droneData[currentDroneId].flightTime.s }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="inspection_monitor_status_container"
      :style="{ height: '120px' }"
    >
      <div class="inspection_monitor_title">센서 모니터</div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">라이다</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].sensorState.lidar }}
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">IMU</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].sensorState.imu }}
        </div>
      </div>
      <div class="inspection_monitor_content_wrapper">
        <div class="inspection_monitor_content_name">엔코더</div>
        <div class="inspection_monitor_content_status">
          {{ droneData[currentDroneId].sensorState.encoder }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="isVisible" class="inspection_monitor_attitude_overlay">
    <div class="inspection_monitor_attitude_wrapper">
      <div class="inspection_monitor_attitude_name">ROLL</div>
      <div class="inspection_monitor_attitude_img" id="inspection_roll"></div>
    </div>
    <div class="inspection_monitor_attitude_wrapper">
      <div class="inspection_monitor_attitude_name">PITCH</div>
      <div class="inspection_monitor_attitude_img" id="inspection_pitch"></div>
    </div>
    <div class="inspection_monitor_attitude_wrapper">
      <div class="inspection_monitor_attitude_name">YAW</div>
      <div class="inspection_monitor_attitude_img" id="inspection_yaw"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    droneData: Array,
    currentDroneId: Number,
  },
  emits: ["close"],
};
</script>

<style lang="scss">
.inspection_monitor_modal_overlay {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% / 24 * 5 - 10px);
  height: 470px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  /* backdrop-filter: blur(1px); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspection_monitor_status_container {
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 100%;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
}

.inspection_monitor_title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 30px;
  color: $main_color;
  font-size: 1.4rem;
  font-weight: bold;
}

.inspection_monitor_content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100%;
}

.inspection_monitor_content_name {
  display: flex;
  align-items: center;
  height: 100%;
  width: 50%;
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
}

.inspection_monitor_content_status {
  @include flexbox;
  height: 20px;
  width: 40%;
  margin-left: auto;
  background-color: rgb(23, 23, 23);
  color: $main_color;
  font-size: 1.2rem;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
}

.inspection_monitor_lf_value {
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% / 3);
  padding-left: 25px;
  padding-right: 25px;
  color: $main_color;
  font-size: 1.2rem;
}

.inspection_monitor_attitude_overlay {
  position: fixed;
  bottom: 20px;
  left: calc(50% - 175px);
  width: 350px;
  height: 110px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inspection_monitor_attitude_wrapper {
  display: flex;
  flex-direction: column;
  width: 110px;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
}

.inspection_monitor_attitude_name {
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
  padding-left: 10px;
  color: $main_color;
  font-size: 1.2rem;
}

.inspection_monitor_attitude_img {
  height: 90px;
  width: 100%;
}
#inspection_roll {
  background: url("@/../public/images/roll.png") no-repeat center;
  background-size: 80px, 80px;
}
#inspection_pitch {
  background: url("@/../public/images/pitch.png") no-repeat center;
  background-size: 80px, 80px;
}
#inspection_yaw {
  background: url("@/../public/images/yaw.png") no-repeat center;
  background-size: 80px, 80px;
}
</style>
