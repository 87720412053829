<template>
  <div class="mapping_setting_tap_wrap">
      <div class="mapping_setting_tap button on">드론 제어 파라미터</div>
      <div class="mapping_setting_tap button " @click="clickSettingTab(3)">포인트 클라우드</div>
  </div>
  <div class="mapping_setting_content_wrap">
    <div class="mapping_setting_sub_title">공통</div>
    <div class="mapping_setting_common">
      <div class="mapping_setting_common_title">통신</div>
      <div class="mapping_setting_common_content_wrap">
        <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">모듈 종류</div>
          <div class="mapping_setting_common_select"
                @click="clickDroneTypeSelect"
                >{{selectedDroneType.name}}
          </div>
          <div class="mapping_setting_common_content_unlink_type button"
               v-if="selectedDroneType.name"
               @click="clickUnlinkDroneType"></div>
          <div v-if="droneTypeSelectModal"
               class="mapping_setting_common_select_option_wrap" :style="{ height: getHeightDroneType }">
            <div v-for="(droneType, index) in droneTypeList" :key="index"
                 class="mapping_setting_common_select_option"
                 @click="selectDroneType(droneType)">
              {{droneType.name}}
            </div>
          </div>
        </div>
        <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">모듈 아이디</div>
          <div class="mapping_setting_common_select"
                @click="clickDroneSelect"
                >{{selectedDrone.droneSerialNumber}}
          </div>
          <div class="mapping_setting_common_content_unlink button"
               v-if="selectedDrone.droneSerialNumber"
               @click="clickUnlinkDrone"></div>
          <div v-if="droneSelectModal"
               class="mapping_setting_common_select_option_wrap" id="mapping_drone"
               :style="{ height: getHeightDrone }">
            <div v-for="(drone, index) in droneList" :key="index"
                 class="mapping_setting_common_select_option"
                 @click="selectDrone(drone)">
              {{drone.droneSerialNumber}}
            </div>
          </div>
        </div>
        <!-- <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">UDP 포트</div>
          <input
            type="number"
            class="mapping_setting_common_input"
            ref="inputUdpPort"
            :placeholder="udpPort"
            @blur="blurUdpPort"
          />
        </div> -->
      </div>
    </div>
    <!-- <div class="mapping_setting_common">
      <div class="mapping_setting_common_title">배터리 전압</div>
      <div class="mapping_setting_common_content_wrap">
        <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">최소 값</div>
          <input class="mapping_setting_common_input" ref="inputVoltageMin" :placeholder="selectedDroneType.minVoltage" @input="validateNumber($event,5)">
        </div>
        <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">최대 값</div>
          <input class="mapping_setting_common_input" ref="inputVoltageMax" :placeholder="selectedDroneType.maxVoltage" @input="validateNumber($event,5)">
        </div>
      </div>
    </div> -->
    <!-- <div class="mapping_setting_sub_title">속도</div>
    <div class="mapping_setting_manual">
      <div class="mapping_setting_manual_content">
        <div class="mapping_setting_manual_content_title">최대 속도</div>
        <input
          class="mapping_setting_common_input"
          ref="inputMaxSpeed"
          :placeholder="droneParams.maxSpeed"
          @input="validateNumber($event, 5)"
          @blur="blurSpeed"
        />
        <div class="mapping_setting_manual_content_unit">cm/s</div>
      </div>
      <div class="mapping_setting_manual_content">
        <div class="mapping_setting_manual_content_title">최대 YAW 속도</div>
        <input
          class="mapping_setting_common_input"
          ref="inputMaxYawSpeed"
          :placeholder="droneParams.maxYawSpeed"
          @input="validateNumber($event, 5)"
          @blur="blurYawSpeed"
        />
        <div class="mapping_setting_manual_content_unit">cdeg/s</div>
      </div>
    </div>
    <div class="mapping_setting_sub_title">GPS 모드</div>
    <div class="mapping_setting_gps_mode">
      <div
        class="mapping_setting_radio radio"
        @click="clickGpsMode(true)"
        :class="{ on: droneParams.gpsMode }"
      ></div>
      <div class="mapping_setting_radio_title">GPS 모드</div>
      <div
        class="mapping_setting_radio radio"
        @click="clickGpsMode(false)"
        :class="{ on: !droneParams.gpsMode }"
      ></div>
      <div class="mapping_setting_radio_title">비 GPS 모드</div>
    </div> -->
  </div>
</template>

<script>
export default {
  emits: [
    "changeSettingTab",
    "selectDrone",
    "selectDroneType",
    // "changeGpsMode",
    // "changeDroneParam",
    // "changeAlert",
  ],
  data() {
    return {
      droneSelectModal: false,
      droneTypeSelectModal : false,
    };
  },
  props: {
    // droneParams: Object,
    droneList: Array,
    selectedDrone: Object,
    droneTypeList : Array,
    selectedDroneType : Object,
  },
  computed : {
    getHeightDrone() {
      if (this.droneList){
        return this.droneList.length * 25 + "px";
      } else {
        return "0px";
      }
    },
    getHeightDroneType() {
      if (this.droneTypeList){
        return this.droneTypeList.length * 25 + "px";
      } else {
        return "0px";
      }
    },
  },
  methods: {
    // checkInputLimit(event, limit) {
    //   if (event.target.value.length > limit) {
    //     this.$emit("changeAlert", `입력 값은 ${limit}자를 초과할 수 없습니다.`);
    //     event.target.value = event.target.value.substr(0, limit);
    //   }
    // },
    // validateNumber(event, limit) {
    //   let regex = /[^0-9.]/g;
    //   if (regex.test(event.target.value)) {
    //     event.target.value = event.target.value.replace(regex, "");
    //   }
    //   this.checkInputLimit(event, limit);
    // },
    clickSettingTab(tabNumber) {
      this.$emit("changeSettingTab", tabNumber);
    },
    clickDroneSelect(){
      this.droneSelectModal = !this.droneSelectModal;
      if(this.droneSelectModal) this.droneTypeSelectModal = false;
    },
    clickDroneTypeSelect(){
      this.droneTypeSelectModal = !this.droneTypeSelectModal;
      if(this.droneTypeSelectModal) this.droneSelectModal = false;
    },
    selectDrone(drone) {
      this.$emit("selectDrone", drone);
      this.droneSelectModal = false;
    },
    selectDroneType(droneType) {
      this.$emit("selectDroneType", droneType);
      this.droneTypeSelectModal = false;
    },
    // clickGpsMode(boolean) {
    //   this.$emit("changeGpsMode", boolean);
    // },
    clickUnlinkDrone(){
      this.$emit("selectDrone");
    },
    clickUnlinkDroneType(){
      this.$emit("selectDroneType");
    },
    // blurDroneId(){
    //   if(this.droneParams.id != this.$refs.inputDroneId.value &&
    //     this.$refs.inputDroneId.value){
    //     this.$emit("changeDroneId", this.$refs.inputDroneId.value);
    //   }
    //   this.$refs.inputDroneId.value = null;
    // },
    // blurUdpPort(){
    //   if (this.$refs.inputUdpPort.value >= 40011 &&
    //       this.$refs.inputUdpPort.value <= 40015 || !this.$refs.inputUdpPort.value){
    //     this.$emit("changePcdSocket", this.$refs.inputUdpPort.value);
    //   }
    //   else {
    //     this.$emit(
    //       "changeAlert",
    //       "사용할 수 있는 UDP 포트는 40011~40015까지 있습니다."
    //     );
    //   }
    //   this.$refs.inputUdpPort.value = null;
    // },
    // blurYawSpeed(){
    //   if(this.$refs.inputMaxYawSpeed.value){
    //     this.$emit(
    //       "changeDroneParam",
    //       "maxYawSpeed",
    //       parseFloat(this.$refs.inputMaxYawSpeed.value)
    //     );
    //   }
    //   this.$refs.inputMaxYawSpeed.value = null;
    // },
    // blurSpeed(){
    //   if (this.$refs.inputMaxSpeed.value) {
    //     this.$emit(
    //       "changeDroneParam",
    //       "maxSpeed",
    //       parseFloat(this.$refs.inputMaxSpeed.value)
    //     );
    //   }
    //   this.$refs.inputMaxSpeed.value = null;
    // }
  },
};
</script>

<style></style>
