import {format} from 'date-fns'

export default class Camera{
  constructor(camera){
    this.id = null; 
    this.name = null; 
    this.company = null;
    this.model_name = null;
    this.photoWidth = null;
    this.photoHeight = null;
    this.exposureTime = null;
    this.exposureBias = null;
    this.focusDistance = null;
    this.horizontalFov = null;
    this.verticalFov = null;
    this.iso = null;
    this.createdDatetime = null;
    this.fstop = null;
    if(camera){
      this.id = camera.id; 
      this.name = camera.name; 
      this.company = camera.company;
      this.model_name = camera.model_name;
      this.photoWidth = camera.photoWidth;
      this.photoHeight = camera.photoHeight;
      this.exposureTime = camera.exposureTime;
      this.exposureBias = camera.exposureBias;
      this.focusDistance = camera.focusDistance;
      this.horizontalFov = camera.horizontalFov;
      this.verticalFov = camera.verticalFov;
      this.iso = camera.iso;
      this.createdDatetime = format(camera.createdDatetime, "yyyy-MM-dd HH:mm:ss");
      this.fstop = camera.fstop;
    }
  }
}