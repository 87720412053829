<template>
  <div class="mapping_setting_tap_wrap">
    <div class="mapping_setting_tap button" @click="clickSettingTab(1)">드론 제어 파라미터</div>
    <!-- <div class="mapping_setting_tap button" @click="clickSettingTab(2)">지도</div> -->
    <div class="mapping_setting_tap button on">포인트 클라우드</div>
  </div>
  <div class="mapping_setting_content_wrap">
    <div class="mapping_setting_sub_title">포인트 크기</div>
    <div class="mapping_setting_point_content">
      <input
        class="mapping_setting_point_content_input input"
        type="number"
        :placeholder="pointCloudParams.pointSize"
        v-model="inputPointSize"
        @blur="blurPointSize"
      />
    </div>
    <div class="mapping_setting_sub_title">포인트 투명도</div>
    <div class="mapping_setting_point_content">
      <input
        class="mapping_setting_point_content_input input"
        type="number"
        :placeholder="pointCloudParams.opacity"
        v-model="inputPointOpacity"
        @blur="blurPointOpacity"
      />
    </div>
    <div class="mapping_setting_sub_title">다운 샘플링</div>
    <div class="mapping_setting_point_content">
      <input
        class="mapping_setting_point_content_input input"
        type="number"
        :placeholder="pointCloudParams.downSampling"
        v-model="inputDownSampling"
        @blur="blurDownSampling"
      />
    </div>
    <div class="mapping_setting_sub_title">포인트 색상</div>
    <div class="mapping_setting_point_content">
      <div
        class="mapping_setting_radio radio"
        :class="{ on: pointCloudParams.mode == 'intensity' }"
        @click="clickPointCloudMode('intensity')"
      ></div>
      <div class="mapping_setting_radio_title">반사율</div>
      <div
        class="mapping_setting_radio radio"
        :class="{ on: pointCloudParams.mode == 'height' }"
        @click="clickPointCloudMode('height')"
      ></div>
      <div class="mapping_setting_radio_title">높이</div>
    </div>
    <div class="mapping_setting_point_content" v-if="pointCloudParams.mode == 'height'">
      <div class="mapping_setting_point_content_title">최솟값</div>
      <input class="mapping_setting_point_content_div input"
        type="number"
        :placeholder="pointCloudParams.height.min"
        v-model="inputHeight.min"
        @blur="blurHeight('min')">
      <div class="mapping_setting_point_content_unit">m</div>
    </div>      
    <div class="mapping_setting_point_content" v-if="pointCloudParams.mode == 'height'">
      <div class="mapping_setting_point_content_title">최댓값</div>
      <input class="mapping_setting_point_content_div input"
        type="number"
        :placeholder="pointCloudParams.height.max"
        v-model="inputHeight.max"
        @blur="blurHeight('max')">
      <div class="mapping_setting_point_content_unit">m</div>
    </div>      
  </div>
</template>

<script>
export default {
  emits: ["changeSettingTab", "changePointCloudParam", "changeAlert"],
  data() {
    return {
      inputDownSampling: null,
      inputPointSize: null,
      inputPointOpacity: null,
      inputHeight : {
        min : null,
        max : null,
      }
    };
  },
  props: {
    pointCloudParams: Object,
  },
  methods: {
    clickSettingTab(tabNumber) {
      this.$emit("changeSettingTab", tabNumber);
    },
    clickPointCloudMode(mode) {
      this.$emit("changePointCloudParam", "mode", mode);
    },
    blurDownSampling() {
      if (
        this.pointCloudParams.downSampling != this.inputDownSampling &&
        this.inputDownSampling
      ) {
        if (this.inputDownSampling <= 10 && this.inputDownSampling >= 1) {
          this.$emit(
            "changePointCloudParam",
            "downSampling",
            this.inputDownSampling
          );
        } else {
          this.$emit("changeAlert", " 1~10 사이의 값만 입력이 가능합니다.");
        }
      }
      this.inputDownSampling = null;
    },
    blurPointSize() {
      if (
        this.pointCloudParams.pointSize != this.inputPointSize &&
        this.inputPointSize != undefined
      ) {
        if (this.inputPointSize <= 10 && this.inputPointSize >= 1) {
          this.$emit("changePointCloudParam", "pointSize", this.inputPointSize);
        } else {
          this.$emit("changeAlert", " 1~10 사이의 값만 입력이 가능합니다.");
        }
      }
      this.inputPointSize = null;
    },
    blurPointOpacity() {
      if (
        this.pointCloudParams.opacity != this.inputPointOpacity &&
        this.inputPointOpacity != undefined
      ) {
        if (this.inputPointOpacity <= 1 && this.inputPointOpacity >= 0) {
          this.$emit(
            "changePointCloudParam",
            "opacity",
            this.inputPointOpacity
          );
        } else {
          this.$emit("changeAlert", " 0~1사이의 값만 입력이 가능합니다.");
        }
      }
      this.inputPointOpacity = null;
    },
    blurHeight(type){
      switch (type){
        case 'min':
          if(this.inputHeight.min != this.pointCloudParams.height.min && this.inputHeight.min != undefined){
            if(this.inputHeight.min >= this.pointCloudParams.height.max){
              this.$emit("changeAlert", " 입력하신 최솟값이 최댓값보다 같거나 큽니다. 더 낮은 숫자를 입력해주세요.");
            }
            else{
              this.$emit("changePointCloudParam", "heightMin", this.inputHeight.min);
            }
          }
          this.inputHeight.min = null;
          break;
        case 'max':
          if(this.inputHeight.max != this.pointCloudParams.height.max && this.inputHeight.max != undefined){
            if(this.inputHeight.max <= this.pointCloudParams.height.min){
              this.$emit("changeAlert", " 입력하신 최댓값이 최솟값보다 같거나 작습니다. 더 큰 숫자를 입력해주세요.");
            }
            else{
              this.$emit("changePointCloudParam", "heightMax", this.inputHeight.max);
            }
          }
          this.inputHeight.max = null;
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style lang="scss"></style>
