<template>
  <div v-if="isVisible" class="summary_report">
    <div class="summary_modal_content">
      <div class="summary_modal_header">
        <div class="title">결과 요약</div>
        <span class="close" @click="closeModal">&times;</span>
      </div>
      <div class="summary_modal_body">
        <div class="summary_top_section">
          <div class="summary_left_panel">
            <div class="summary_tab_container">
              <div
                class="summary_tab"
                v-for="(type, index) in localSummaryTypes"
                :key="type.id"
                :class="{ active: currentSummaryType === type.id }"
                @click="handleTapClick(index, type.id)"
              >
                <div v-if="index === 4" class="summary_label">
                  {{ type.label }}
                </div>
                <template v-else>
                  <div v-if="!isTapEditing(index)" class="summary_label">
                    {{ type.label }}
                  </div>
                  <input
                    v-else
                    :ref="(el) => setInputRef(el, index)"
                    v-model="type.label"
                    class="summary_input"
                    @blur="stopTapEditing(index, type.elementId, type.label)"
                    @keyup.enter="
                      stopTapEditing(index, type.elementId, type.label)
                    "
                    @click.stop
                  />
                  <button @click.stop="deleteTab(index)">X</button>
                </template>
              </div>
            </div>
          </div>
          <div class="summary_center_panel">
            <div class="summary_all_defection_title">외관조사 결과</div>
            <div class="summary_all_defection_table">
              <div class="summary_defection_table_header">
                <div class="summary_defection_table_row">
                  <div class="summary_defection_table_cell">구분</div>
                  <div class="summary_defection_table_cell">개소</div>
                  <div class="summary_defection_table_cell">물량</div>
                  <div class="summary_defection_table_cell">우선순위</div>
                  <div class="summary_defection_table_cell">보수공법</div>
                </div>
              </div>
              <div class="summary_defection_table_body">
                <div
                  class="summary_defection_table_row"
                  v-for="item in networkMapSummaryList"
                  :key="item.구분"
                >
                  <div class="summary_defection_table_cell">
                    {{ item.type || "-" }}
                  </div>
                  <div class="summary_defection_table_cell">
                    {{ item.count || "-" }}
                  </div>
                  <div class="summary_defection_table_cell">
                    {{ item.quantity || "-" }}
                  </div>
                  <div class="summary_defection_table_cell">
                    {{ item.priority || "-" }}
                  </div>
                  <div class="summary_defection_table_cell">
                    {{ item.repair_method || "-" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="summary_right_panel">
            <div class="summary_pattern_title">외관조사망도</div>
            <div class="summary_pattern_image" ref="pattern_viewer">
              <div v-if="currentSummaryType === 5" class="dxf_tab_container">
                <div
                  class="dxf_tab"
                  v-for="(type, index) in filteredSummaryTypes"
                  :key="type.id"
                  :class="{ active: currentDXFSummaryType === type.id }"
                  @click="handleDxfTapClick(index, type.id)"
                >
                  <div v-if="index != 4" class="summary_label">
                    {{ type.label }}
                  </div>
                </div>
              </div>
              <div class="legend">
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(255, 0, 0)"
                  ></span>
                  균열
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(0, 255, 0)"
                  ></span>
                  백태
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(64, 224, 208)"
                  ></span>
                  파손
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(0, 0, 255)"
                  ></span>
                  박락
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(255, 255, 0)"
                  ></span>
                  철근노출
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(255, 165, 0)"
                  ></span>
                  누수
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(128, 0, 128)"
                  ></span>
                  망상균열
                </div>
                <div class="legend-item">
                  <span
                    class="legend-color"
                    style="background-color: rgb(255, 192, 203)"
                  ></span>
                  기타
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="summary_bottom_section">
          <div class="summary_talbe_title">대표결함</div>
          <div class="summary_table_section">
            <div
              v-for="(item, index) in localPrimarDefectList"
              :key="index"
              class="summary_table_container"
            >
              <div class="image-section">
                <div class="image-cell">
                  <img
                    :src="`${item.thumbnailsUri}?t=${new Date().getTime()}`"
                    alt="Thumbnail"
                    class="thumbnail-image"
                  />
                </div>
              </div>
              <div class="data-section">
                <div class="data-row">
                  <div class="label-cell">현황</div>
                  <div
                    class="value-cell"
                    @click="editSummaryField(index, '현황')"
                  >
                    <span v-if="!isSummaryEditing(index, '현황')">{{
                      item.currentState
                    }}</span>
                    <input
                      v-else
                      v-model="item.currentState"
                      @blur="
                        stopSummaryEditing(
                          index,
                          item.id,
                          item.currentState,
                          item.cause,
                          item.solution
                        )
                      "
                    />
                  </div>
                </div>
                <div class="data-row">
                  <div class="label-cell">원인</div>
                  <div
                    class="value-cell"
                    @click="editSummaryField(index, '원인')"
                  >
                    <span v-if="!isSummaryEditing(index, '원인')">{{
                      item.cause
                    }}</span>
                    <input
                      v-else
                      v-model="item.cause"
                      @blur="
                        stopSummaryEditing(
                          index,
                          item.id,
                          item.currentState,
                          item.cause,
                          item.solution
                        )
                      "
                    />
                  </div>
                </div>
                <div class="data-row">
                  <div class="label-cell">대책</div>
                  <div
                    class="value-cell"
                    @click="editSummaryField(index, '대책')"
                  >
                    <span v-if="!isSummaryEditing(index, '대책')">{{
                      item.solution
                    }}</span>
                    <input
                      v-else
                      v-model="item.solution"
                      @blur="
                        stopSummaryEditing(
                          index,
                          item.id,
                          item.currentState,
                          item.cause,
                          item.solution
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import DxfParser from "dxf-parser";
import { nextTick } from "vue";

let scene, camera, renderer, controls;

const dxfColors = {
  1: { r: 255, g: 0, b: 0 }, // Red
  2: { r: 255, g: 255, b: 0 }, // Yellow
  3: { r: 0, g: 255, b: 0 }, // Green
  64: { r: 64, g: 224, b: 208 }, // Turquoise
  4: { r: 0, g: 255, b: 255 }, // Cyan
  5: { r: 0, g: 0, b: 255 }, // Blue
  6: { r: 255, g: 0, b: 255 }, // Magenta
  7: { r: 255, g: 255, b: 255 }, // White
  40: { r: 255, g: 165, b: 0 }, // Orange
  214: { r: 128, g: 0, b: 128 }, // Purple
  254: { r: 255, g: 192, b: 203 }, // Pink
};

export default {
  emits: [
    "summaryTypeChanged",
    "summaryTypeNameChanged",
    "primaryDefectionChanged",
    "dxfTabChanged",
  ],
  props: {
    isVisible: Boolean,
    summaryTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    networkMapList: {
      type: Array,
      required: true,
      default: () => [],
    },
    networkMapSummaryList: {
      type: Array,
      required: true,
      default: () => [],
    },
    primaryDefectList: {
      type: Array,
      required: true,
      default: () => [],
    },
    dxfText: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      interval: 1000 / 60,
      animationFrameId: null,
      currentSummaryType: 1,
      currentDXFSummaryType: 1,
      editingIndex: null,
      clickTimeout: null,
      inputRefs: [],
      localSummaryTypes: [...this.summaryTypes],
      localPrimarDefectList: [...this.primaryDefectList],
      editingSummary: {
        index: null,
        field: null,
      },
    };
  },
  computed: {
    currentSummaryTypeLabel() {
      const currentType = this.localSummaryTypes.find(
        (type) => type.id === this.currentSummaryType
      );
      return currentType ? currentType.label : "";
    },
    filteredSummaryTypes() {
      return this.localSummaryTypes.slice(0, -1);
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        nextTick(() => {
          this.initThree();
          if (this.dxfText) {
            this.handleDxfTextChange(this.dxfText);
          }
        });
      } else {
        if (this.animationFrameId) {
          cancelAnimationFrame(this.animationFrameId);
          this.animationFrameId = null;
        }
        this.disposeThree();
      }
    },
    summaryTypes: {
      immediate: true, // 모달이 처음 열릴 때에도 데이터를 초기화하도록 설정
      handler(newVal) {
        this.localSummaryTypes = [...newVal];
      },
    },
    primaryDefectList: {
      immediate: true, // 모달이 처음 열릴 때에도 데이터를 초기화하도록 설정
      handler(newVal) {
        this.localPrimarDefectList = [...newVal];
      },
    },
    dxfText(newVal) {
      console.log("dxfText has changed in the modal component.");
      this.handleDxfTextChange(newVal);
    },
  },
  methods: {
    initThree() {
      const container = this.$refs.pattern_viewer;
      if (!container) return;

      const width = container.clientWidth;
      const height = container.clientHeight;

      scene = new THREE.Scene();
      camera = new THREE.OrthographicCamera(
        width / -2,
        width / 2,
        height / 2,
        height / -2,
        1,
        1000
      );
      camera.position.z = 500;

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(width, height);
      container.appendChild(renderer.domElement);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableZoom = true;
      controls.enablePan = true;
      controls.enableRotate = false;

      this.animate();
    },
    disposeThree() {
      if (renderer) {
        renderer.dispose();
        renderer.domElement.parentNode.removeChild(renderer.domElement);
      }
      if (controls) {
        controls.dispose();
      }
      scene = null;
      camera = null;
      renderer = null;
      controls = null;
    },
    handleDxfTextChange(newDxfText) {
      try {
        const parser = new DxfParser();
        const dxf = parser.parseSync(newDxfText);
        this.drawDxf(dxf);
      } catch (error) {
        console.error("Error parsing DXF text:", error);
      }
    },
    setCurrentSummaryType(index, type) {
      this.currentSummaryType = type;
      this.editingIndex = null;
      this.currentDXFSummaryType = 1;
      this.$emit("summaryTypeChanged", index);
    },

    handleTapClick(index, id) {
      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
        this.editTab(index);
      } else {
        this.clickTimeout = setTimeout(() => {
          this.setCurrentSummaryType(index, id);
          this.clickTimeout = null;
        }, 300);
      }
    },
    editTab(index) {
      this.editingIndex = index;
      this.$nextTick(() => {
        const inputRef = this.inputRefs[index];
        if (inputRef) {
          inputRef.focus();
        }
      });
    },
    isTapEditing(index) {
      return this.editingIndex === index;
    },
    stopTapEditing(idx, id, name) {
      this.$emit("summaryTypeNameChanged", idx, id, name);
      this.editingIndex = null;
    },
    deleteTab(index) {
      this.localSummaryTypes.splice(index, 1);
      if (this.currentSummaryType === this.localSummaryTypes[index]?.id) {
        this.currentSummaryType = null;
      }
    },
    setInputRef(el, index) {
      if (el) {
        this.inputRefs[index] = el;
      } else {
        this.inputRefs.splice(index, 1);
      }
    },
    handleDxfTapClick(index, type) {
      this.currentDXFSummaryType = type;
      this.$emit("dxfTabChanged", index);
    },
    drawDxf(dxf) {
      if (!scene) return;

      while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
      }

      if (dxf == null) return;

      let minX = Infinity,
        maxX = -Infinity,
        minY = Infinity,
        maxY = -Infinity;

      dxf.entities.forEach((entity) => {
        switch (entity.layer) {
          case "BoundingLineLayer": {
            const points = [];

            points.push(
              new THREE.Vector3(entity.vertices[0].x, entity.vertices[0].y, 0)
            );
            points.push(
              new THREE.Vector3(entity.vertices[1].x, entity.vertices[1].y, 0)
            );

            if (points.length > 0) {
              const geometry = new THREE.BufferGeometry().setFromPoints(points);

              const material = new THREE.LineBasicMaterial({
                color: new THREE.Color(0x00ff00),
                linewidth: 1,
              });

              const line = new THREE.Line(geometry, material);
              scene.add(line);

              minX = Math.min(minX, entity.vertices[0].x, entity.vertices[1].x);
              maxX = Math.max(maxX, entity.vertices[0].x, entity.vertices[1].x);
              minY = Math.min(minY, entity.vertices[0].y, entity.vertices[1].y);
              maxY = Math.max(maxY, entity.vertices[0].y, entity.vertices[1].y);
            }
            break;
          }
          case "BoundingCircleLayer": {
            if (entity.type === "CIRCLE") {
              const radius = entity.radius;
              const center = new THREE.Vector3(
                entity.center.x,
                entity.center.y,
                0
              );

              const geometry = new THREE.CircleGeometry(radius, 32);
              const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
              const circle = new THREE.Mesh(geometry, material);

              circle.position.set(center.x, center.y, center.z);
              scene.add(circle);
            }
            break;
          }
          case "CrackLineLayer": {
            const points = [];
            points.push(
              new THREE.Vector3(entity.vertices[0].x, entity.vertices[0].y, 0)
            );
            points.push(
              new THREE.Vector3(entity.vertices[1].x, entity.vertices[1].y, 0)
            );
            if (points.length > 0) {
              const geometry = new THREE.BufferGeometry().setFromPoints(points);

              const material = new THREE.LineBasicMaterial({
                color: new THREE.Color(0xff0000),
                linewidth: 1,
              });

              const line = new THREE.Line(geometry, material);
              scene.add(line);
            }
            break;
          }
          case "OtherLineLayer": {
            const points = [];
            points.push(
              new THREE.Vector3(entity.vertices[0].x, entity.vertices[0].y, 0)
            );
            points.push(
              new THREE.Vector3(entity.vertices[1].x, entity.vertices[1].y, 0)
            );
            if (points.length > 0) {
              const geometry = new THREE.BufferGeometry().setFromPoints(points);
              const colorNumber = entity.colorIndex;
              const color = dxfColors[colorNumber]
                ? new THREE.Color(
                    `rgb(${dxfColors[colorNumber].r}, ${dxfColors[colorNumber].g}, ${dxfColors[colorNumber].b})`
                  )
                : new THREE.Color(0x00ff00);

              const material = new THREE.LineBasicMaterial({
                color: color,
                linewidth: 1,
              });

              const line = new THREE.Line(geometry, material);
              scene.add(line);
            }
            break;
          }
          default:
            break;
        }
      });

      const centerX = (minX + maxX) / 2;
      const centerY = (minY + maxY) / 2;
      const width = maxX - minX;
      const height = maxY - minY;

      const aspect = window.innerWidth / window.innerHeight;
      if (width / height > aspect) {
        camera.left = centerX - width / 2;
        camera.right = centerX + width / 2;
        camera.top = centerY + width / 2 / aspect;
        camera.bottom = centerY - width / 2 / aspect;
      } else {
        camera.left = centerX - (height / 2) * aspect;
        camera.right = centerX + (height / 2) * aspect;
        camera.top = centerY + height / 2;
        camera.bottom = centerY - height / 2;
      }
      camera.updateProjectionMatrix();

      controls.update();
    },
    animate() {
      const frame = () => {
        this.animationFrameId = requestAnimationFrame(frame);
        if (renderer && scene && camera) {
          renderer.render(scene, camera);
        }
      };
      this.animationFrameId = requestAnimationFrame(frame);
    },
    editSummaryField(index, field) {
      this.editingSummary.index = index;
      this.editingSummary.field = field;
    },
    isSummaryEditing(index, field) {
      return (
        this.editingSummary.index === index &&
        this.editingSummary.field === field
      );
    },
    stopSummaryEditing(idx, id, currentState, cause, solution) {
      this.$emit(
        "primaryDefectionChanged",
        idx,
        id,
        currentState,
        cause,
        solution
      );

      this.editingSummary.index = null;
      this.editingSummary.field = null;
    },
    closeModal() {
      this.$emit("update:isVisible", false);
    },
  },
  beforeUnmount() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.disposeThree();
  },
};
</script>

<style scoped>
.summary_report {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}

.summary_modal_content {
  margin: auto;
  width: 80%;
  height: 80%;
  padding: 10px;
  border: 1px solid #888;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.summary_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  padding: 0 0px;
}
.title {
  font-size: 20px;
  color: lightgray;
  font-weight: bold;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.summary_modal_body {
  height: 95%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.summary_top_section {
  flex: 6; 
  display: flex;
  background-color: transparent;
}
.summary_left_panel {
  width: 7%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.summary_tab_container{
  margin-top:30px;
}
.summary_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  border-left: 4px solid transparent;
  border: 1px solid #444;
  border-radius: 4px; 
  background-color: #007bff;
  transition: background-color 0.3s, border-left 0.3s;
  font-size: 12px;
}
.summary_tab:hover {
  background-color: #0056b3; 
}
.summary_tab.active {
  border-left: 4px solid rgba(1,250,254,1);
  background-color: #17a2b8;
}
.summary_label, .summary_input {
  height: 30px;
  display: inline-flex; /* Flexbox를 사용하여 정렬 */
  align-items: center; /* 수직 방향으로 가운데 정렬 */
  justify-content: center; 
  white-space: nowrap; 
  writing-mode: horizontal-tb;
  text-orientation: mixed; 
}
.summary_tab input {
  width: 80%;
  padding: 5px;
  border: none;
  border-radius: 4px;
}
.summary_tab button {
  margin-left: 5px;
  padding: 5px;
  border: none;
  background-color: transparent;
  color: #cccccc;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}
.summary_tab button:hover {
  color: #666666;
}
.summary_center_panel {
  width: 48%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.summary_all_defection_title{
  display: flex;
  align-items: center;
  height:5%;
  width:calc(100% - 30px);
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
}
.summary_all_defection_table{
  display: flex;
  flex-direction: column;
  height:95%;
  width:calc(100% - 30px);
  margin-left: 20px;
  margin-right: 10px;
  /* border: 2px solid #ccc; */
}
.summary_defection_table_header, .summary_defection_table_body {
  display: flex;
  flex-direction: column;
}
.summary_defection_table_row {
  display: flex;
  width: 100%;
  height: 38px;
}
.summary_defection_table_cell {
  flex: 1;
  font-size: 12px;
  padding: 8px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.summary_defection_table_header .summary_defection_table_cell {
  background-color: gray;
  font-weight: bold;
}

.summary_right_panel {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.summary_pattern_title {
  display: flex;
  align-items: center;
  height: 5%;
  width: calc(100% - 10px);
  font-size: 13px;
  margin-top: 10px;
  margin-left: 10px;
}
.summary_pattern_image {
  width: 100%;
  position: relative;
  display: flex;
  margin-left: 10px;
  height: 95%;
  width: calc(100% - 10px);
  border: 2px solid rgba(1, 250, 254, 1);
}

.summary_bottom_section {
  flex: 4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.summary_talbe_title {
  display: flex;
  height: 10%;
  width: 100%;
  align-items: center;
  font-size: 13px;
}

.summary_table_section {
  display: flex;
  flex-wrap: nowrap;
  height: 90%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
}
.summary_table_section::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* 스크롤바 트랙 스타일 */
.summary_table_section::-webkit-scrollbar-track {
  background: transparent;
}
/* 스크롤바 손잡이 스타일 */
.summary_table_section::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.summary_table_section::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}

.summary_table_container {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 20%;
  height: 90%;
  margin-right: 20px;
  margin-top: 10px;
  border: 2px solid #ccc;
}

.image-section {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}

.image-cell {
  width: 100%; /* 부모 div의 너비를 100%로 설정 */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.thumbnail-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* 비율을 유지하면서 부모 div를 채우도록 설정 */
  display: block;
  margin: auto;
}

.data-section {
  height: 30%;
  display: flex;
  flex-direction: column;
}

.data-row {
  flex: 1;
  display: flex;
}

  .label-cell {
    width: 20%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    font-size: 10px;
    font-weight: bold;
  }

  .value-cell {
    width: 80%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 10px;
  }

.legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1rem;
  height: 20px; /* 높이 설정 */
}

.legend-item:last-child {
  margin-bottom: 0; /* 마지막 항목의 마진 제거 */
}

.legend-color {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border: 1px solid #000;
  margin-bottom: 3px;
}

.dxf_tab_container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 50px;
  background-color: transparent;
}

.dxf_tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  border-left: 4px solid transparent;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: rgba(0, 123, 255, 0.5);
  transition: background-color 0.3s, border-left 0.3s;
  font-size: 1.2rem;
  width: 50px;
}
.dxf_tab:hover {
  background-color: rgba(0, 86, 179, 0.5);
}
.dxf_tab.active {
  border-left: 4px solid rgba(1, 250, 254, 0.5);
  background-color: rgba(23, 162, 184, 0.5);
}
</style>
